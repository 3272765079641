import {
  BmSubscriptionPlans,
  StripePrice,
  StripeSubscriptionIntervals,
  StipePromoCode,
  StripePriceExpanded,
} from '../../graphql/types';
import { PLANS_TYPES } from '../../apollo/stateFields/stripe/stripeFields';

export const getUserCountByTitle = (title: string): string | null => {
  switch (title) {
    case 'Remote Work Skills Development':
      return '5-15 users';
    case 'Certification Package':
      return 'up to 20 Users';
    case 'Enterprise':
      return 'more than 15 users';
    default:
      return null;
  }
};

export const getPlanByType = <T extends StripePrice | StripePriceExpanded>(
  data: T[] | undefined | null,
  type: BmSubscriptionPlans,
  interval?: StripeSubscriptionIntervals,
): T | undefined => {
  if (data) {
    return data?.find((plan) => {
      if (type === BmSubscriptionPlans.SelfGuidedFree) {
        return plan.bmSubscriptionPlan === BmSubscriptionPlans.SelfGuidedIndividual;
      }
      if (!!interval) {
        return plan.bmSubscriptionPlan === type && plan.interval === interval;
      } else {
        return plan.bmSubscriptionPlan === type;
      }
    });
  } else {
    return undefined;
  }
};

export const getPlanPriceInfo = (
  plan: StripePrice | StripePriceExpanded,
  interval: StripeSubscriptionIntervals,
) => {
  if (plan.bmSubscriptionPlan === BmSubscriptionPlans.FlexibleWorkProfessionalIndividual) {
    return 'one time payment';
  }
  if (plan.bmSubscriptionPlan.toLowerCase().includes(PLANS_TYPES.Individual.toLowerCase())) {
    return interval === StripeSubscriptionIntervals.Year ? 'Per month' : 'Per month';
  } else {
    if (plan.bmSubscriptionPlan === BmSubscriptionPlans.EnterpriseTeams) {
      return 'Based on your specific needs';
    }
    if (plan.bmSubscriptionPlan === BmSubscriptionPlans.CertificationPackageTeams) {
      return 'per user one time payment';
    }
    if (plan.bmSubscriptionPlan === BmSubscriptionPlans.RemoteWorkSkillsDevelopmentTeams) {
      return 'per user per month';
    }
  }
  return '';
};

export const getFilteredDataByTypeAndInterval = <T extends StripePrice | StripePriceExpanded>(
  data: T[] | undefined | null,
  type: PLANS_TYPES,
  interval: StripeSubscriptionIntervals,
) => {
  let filteredPlans: T[] = [];
  if (data) {
    if (type === PLANS_TYPES.Individual) {
      const individualPlans = data.filter((plan) =>
        plan.bmSubscriptionPlan.toLowerCase().includes(PLANS_TYPES.Individual.toLowerCase()),
      );
      if (interval === StripeSubscriptionIntervals.Year) {
        filteredPlans = [
          getPlanByType(individualPlans, BmSubscriptionPlans.SelfGuidedIndividual, interval),
          getPlanByType(individualPlans, BmSubscriptionPlans.CoachLedIndividual, interval),
          getPlanByType(individualPlans, BmSubscriptionPlans.FlexibleWorkProfessionalIndividual),
        ].filter((item) => !!item!) as T[];
      } else {
        filteredPlans = [
          getPlanByType(individualPlans, BmSubscriptionPlans.SelfGuidedIndividual, interval),
          getPlanByType(individualPlans, BmSubscriptionPlans.CoachLedIndividual, interval),
          getPlanByType(individualPlans, BmSubscriptionPlans.FlexibleWorkProfessionalIndividual),
        ].filter((item) => !!item) as T[];
      }
    } else {
      const teamPlans = data.filter((plan) => {
        return plan.bmSubscriptionPlan.toLowerCase().includes(PLANS_TYPES.Teams.toLowerCase());
      });
      if (interval === StripeSubscriptionIntervals.Year) {
        filteredPlans = [
          getPlanByType(teamPlans, BmSubscriptionPlans.RemoteWorkSkillsDevelopmentTeams, interval),
          getPlanByType(teamPlans, BmSubscriptionPlans.CertificationPackageTeams, interval),
          getPlanByType(teamPlans, BmSubscriptionPlans.EnterpriseTeams),
        ].filter((item) => !!item) as T[];
      } else {
        filteredPlans = [
          getPlanByType(teamPlans, BmSubscriptionPlans.RemoteWorkSkillsDevelopmentTeams, interval),
          getPlanByType(teamPlans, BmSubscriptionPlans.CertificationPackageTeams),
          getPlanByType(teamPlans, BmSubscriptionPlans.EnterpriseTeams),
        ].filter((item) => !!item) as T[];
      }
    }
  }
  return filteredPlans;
};

export const getDiscount = (price: number, couponResponse?: StipePromoCode | null) => {
  if (!couponResponse) return;
  let result = undefined;

  if (couponResponse.coupon.percentOff) {
    result = (price / 100) * couponResponse.coupon.percentOff;
  }
  if (couponResponse.coupon.amountOff) {
    result = couponResponse.coupon.amountOff / 100;
  }
  if (result && result > price) {
    result = price;
  }
  return result;
};

