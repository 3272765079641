import { Collapse, Typography } from 'antd';
import CsvHeadersTable from './CsvHeadersTable';
import { CsvTemplateType } from '__generated__/graphql';
import CsvTemplateExample from '../CsvTemplateExample/CsvTemplateExample';

const { Panel } = Collapse;

const { Text, Title, Paragraph } = Typography;

const csvHeaderDataSource = [
  {
    header: 'email',
    description: 'Email associated with the user you want to delete',
  },
  {
    header: 'save primary user shared space',
    description:
      'Filling this field with anything will be considered true for Saving workspaces that this user is primary owner of and is shared with others users',
  },
];

interface ICsvDeleteInstructionsProps {
  onError: (errorMessage: string | null) => void;
  onSuccess: (isSuccess: boolean) => void;
}

const csvValidExampleText = /* CSV */ `email,save primary user shared space
michael.phelps@deletelater.org,true
jim.carrey@deletelater.org,yes
michael.jordan@deletelater.org,indeed
rachel.mcadams@deletelater.org,
kate.beckinsale@deletelater.org,
will.smith@deletelater.org,false`;

const csv1ColumnValidExampleText = /* CSV */ `email
michael.phelps@deletelater.org
jim.carrey@deletelater.org
michael.jordan@deletelater.org
rachel.mcadams@deletelater.org
kate.beckinsale@deletelater.org
will.smith@deletelater.org`;

const csvInvalidExampleText = /* CSV */ `email,save primary user shared space
michael.phelps@deletelater,true
jim.carreydeletelater.org,true
,true`;

const CsvDeleteInstructions = ({ onError, onSuccess }: ICsvDeleteInstructionsProps) => {
  return (
    <Collapse ghost>
      <Panel header="Tips for uploading" key="0">
        <Collapse ghost>
          <Panel header="CSV Headers" key="00">
            <CsvHeadersTable dataSource={csvHeaderDataSource} />
          </Panel>
          <Panel header="Example" key="03">
            <Paragraph>
              <Title level={5}>Valid</Title>
              <CsvTemplateExample
                type={CsvTemplateType.Delete}
                onError={onError}
                onSuccess={onSuccess}
                csvText={csvValidExampleText}
              />
              Notice that filling field with "false" will also be considered <Text code>true</Text>{' '}
              for saving shared user workspaces
              <Title level={5}>Valid with only email column</Title>
              Not adding "save user shared space" at all will be considered <Text code>
                false
              </Text>{' '}
              for every user uploaded.
              <CsvTemplateExample
                csvText={csv1ColumnValidExampleText}
                type={CsvTemplateType.DeleteWithOneColumn}
                onError={onError}
                onSuccess={onSuccess}
              />
              <Title level={5}>Invalid</Title>
              This section will include mostly invalid or missing emails
              <CsvTemplateExample csvText={csvInvalidExampleText} />
            </Paragraph>
          </Panel>
        </Collapse>
      </Panel>
    </Collapse>
  );
};

export default CsvDeleteInstructions;

