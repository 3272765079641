import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

interface IServiceResultCellProps {
  serviceResult?: string[] | null;
}

const ServiceResultCell = ({ serviceResult }: IServiceResultCellProps) => {
  if (!serviceResult)
    return (
      <span>
        <Tag icon={<MinusCircleOutlined />} color="default">
          Not Applicable
        </Tag>
      </span>
    );

  if (serviceResult.length)
    return (
      <span>
        <Tag icon={<CloseCircleOutlined />} color="error">
          {serviceResult[0]}
        </Tag>
      </span>
    );

  return (
    <span>
      <Tag icon={<CheckCircleOutlined />} color="success">
        Deleted successfully
      </Tag>
    </span>
  );
};

export default ServiceResultCell;

