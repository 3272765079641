import { CopyOutlined } from '@ant-design/icons';
import { CsvTemplateType } from '__generated__/graphql';
import Paragraph from 'antd/es/typography/Paragraph';
import DownloadButton from 'core/components/common/DownloadButton/DownloadButton';
import { API_REST_CSV_TEMPLATE } from 'core/integrations/api/config';
import { snakeCase } from 'lodash-es';
import styled from 'styled-components';

type ICsvTemplateExampleProps = {
  csvText: string;
} & (
  | {
      type?: never;
      onSuccess?: never;
      onError?: never;
    }
  | {
      type: CsvTemplateType;
      onSuccess: (isSuccess: boolean) => void;
      onError: (errorMessage: string | null) => void;
    }
);

const ContainerDiv = styled.div`
  position: relative;
`;

const StyledPre = styled.pre`
  padding-bottom: 1rem !important;
`;

const DownloadButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const StyledCopyOutlined = styled(CopyOutlined)`
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
  font-size: 1.5rem;
  color: inherit;
`;

const PreWrapper = ({ children, type }: { children: React.ReactNode; type?: CsvTemplateType }) => {
  console.log({ type });
  if (type) {
    return <StyledPre>{children}</StyledPre>;
  }
  return <pre>{children}</pre>;
};

const CsvTemplateExample = ({ csvText, onError, onSuccess, type }: ICsvTemplateExampleProps) => {
  return (
    <ContainerDiv>
      <PreWrapper type={type}>
        <Paragraph
          copyable={{
            icon: <StyledCopyOutlined />,
            tooltips: null,
          }}
        >
          {csvText}
        </Paragraph>
      </PreWrapper>
      {type && (
        <DownloadButtonContainer>
          <DownloadButton
            onError={onError}
            onSuccess={onSuccess}
            url={API_REST_CSV_TEMPLATE}
            body={{ type }}
            fileName={`${snakeCase(type)}_example.csv`}
            isSecondary
          >
            Download Template
          </DownloadButton>
        </DownloadButtonContainer>
      )}
    </ContainerDiv>
  );
};

export default CsvTemplateExample;

