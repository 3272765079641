import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Select } from 'antd';
import { ArrowDownIcon } from '../../../../../core/icons';
import { Action, ActionPriority, ActionStatus } from '../../../../../core/types/action';
import { ReactComponent as UrgentImportantIcon } from '../ActionCard/svg/urgent-important-1.svg';
import { ReactComponent as UrgentIcon } from '../ActionCard/svg/edit-urgent.svg';
import { ReactComponent as ImportantIcon } from '../ActionCard/svg/edit-important.svg';
import { ReactComponent as NotDefinedIcon } from '../ActionCard/svg/not-definrd.svg';
import { ReactComponent as GrayUrgentImportantIcon } from '../ActionCard/svg/gray-urgent-important.svg';
import { ReactComponent as GrayImportantIcon } from '../ActionCard/svg/edit-gray-important.svg';
import { ReactComponent as GrayUrgentIcon } from '../ActionCard/svg/edit-gray-urgent.svg';
import { PriorityTitle } from '../ActionCard/constants';
import { getPriorityTitle } from '../ActionCard/utils';
import { getUpdateFuncForMoveUpdateAction } from '../../../../interactions/action/getUpdateFuncForMoveUpdateAction';
import { toast, ToastContent } from 'react-toastify';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { UPDATE_ACTION } from '../../../graphql/mutations';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';

const { Option } = Select;

const GlobalStyle = createGlobalStyle`
  .prioritySelectMenu {
    min-width: 180px !important;
  }
`;

const StyledSelect = styled(Select).attrs<any>({
  suffixIcon: <ArrowDownIcon />,
})<{ small?: boolean; disabled?: boolean; isHighlight: boolean }>`
  && {
    display: inline-block;
    width: auto;
    min-width: 140px;

    .ant-select-arrow svg path {
      fill: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
    }

    &:not(.ant-select-customize-input) .ant-select-selector {
      height: ${({ small }) => (small ? '20px' : '32px')};
      background: ${({ isHighlight }) => (isHighlight ? 'rgba(186, 0, 0, 0.1)' : 'transparent')};
      border: 1px solid rgba(32, 38, 53, 0.15) !important;
      border-radius: 16px;
      transition: all ease-in-out 0.3ms;
    }

    &.ant-select-single {
      .ant-select-selector .ant-select-selection-item,
      .ant-select-selector .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        line-height: ${({ small }) => (small ? '20px' : '32px')};
        font-size: ${({ small }) => (small ? '12px' : '14px')};
        padding-right: 10px;
      }

      &:not(.ant-select-customize-input) .ant-select-selector {
        padding-right: ${({ small }) => (small ? '16px' : '24px')};
        padding-left: ${({ small }) => (small ? '4px' : '8px')};
      }
    }

    .ant-select-arrow {
      right: ${({ small }) => (small ? '7px' : '16px')};
    }
  }
`;

const StyledIcon = styled.div`
  flex: none;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;

  svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
`;

const getOptions = (isDone: boolean) => {
  return [
    {
      label: PriorityTitle.IMPORTANT_URGENT,
      icon: isDone ? GrayUrgentImportantIcon : UrgentImportantIcon,
    },
    {
      label: PriorityTitle.URGENT,
      icon: isDone ? GrayUrgentIcon : UrgentIcon,
    },
    {
      label: PriorityTitle.IMPORTANT,
      icon: isDone ? GrayImportantIcon : ImportantIcon,
    },
    {
      label: PriorityTitle.NOT_DEFINED,
      icon: NotDefinedIcon,
    },
  ];
};

const ActionPriorityComponent = ({ action, disabled }: { action: Action; disabled: boolean }) => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const [updateAction, { loading }] = useMutation(UPDATE_ACTION);

  const changeActionPriority = async (action: Action, priority: ActionPriority | null) => {
    try {
      await updateAction({
        update: getUpdateFuncForMoveUpdateAction(apolloClient, action, filterInput),
        variables: {
          actionValues: {
            id: action.id,
            priority,
          },
          workspaceId: action.workspaceId,
        },
      });
    } catch (error) {
      console.error(error);
      toast(error as ToastContent);
    }
  };

  const handleSelect = (value: PriorityTitle) => {
    switch (value) {
      case PriorityTitle.IMPORTANT_URGENT:
        changeActionPriority(action, ActionPriority.ImportantUrgent);
        break;
      case PriorityTitle.URGENT:
        changeActionPriority(action, ActionPriority.Urgent);
        break;
      case PriorityTitle.IMPORTANT:
        changeActionPriority(action, ActionPriority.Important);
        break;
      case PriorityTitle.NOT_DEFINED:
        changeActionPriority(action, null);
        break;
    }
  };

  return (
    <div>
      <GlobalStyle />
      <StyledSelect
        isHighlight={
          action.status !== ActionStatus.Done &&
          action.priority === ActionPriority.ImportantUrgent &&
          !loading
        }
        dropdownClassName={'prioritySelectMenu'}
        disabled={disabled || loading}
        value={getPriorityTitle(action.priority)}
        onSelect={(value) => handleSelect(value as PriorityTitle)}
      >
        {getOptions(action.status === ActionStatus.Done).map((option) => (
          <Option value={option.label} key={option.label}>
            <StyledIcon>
              <option.icon />
            </StyledIcon>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};

export default ActionPriorityComponent;

