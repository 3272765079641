import { useIdleTimer } from 'react-idle-timer';
import { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { ERROR_STATE, ErrorValue } from '../apollo/stateFields/error/errorFields';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import { UTILS_STATE, UtilsValue } from '../apollo/stateFields/utils/utilsFields';

type usePollDataOnActivityOnActiveCallback = (pollingInterval: number) => void;
type usePollDataOnActivityOnIdleCallback = () => void;

const usePollDataOnActivity = (
  onActiveCb: usePollDataOnActivityOnActiveCallback,
  onIdleCb: usePollDataOnActivityOnIdleCallback,
  refetch: () => void,
  pollingInterval: number = 30000,
  shouldStopPolling?: boolean,
) => {
  const timeout = 121000;
  const isFirstRefetch = useRef(true);
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { actionEditedEntity, outcomeEditedEntity, noteEditedEntity }: BoardEditedEntityValue =
    boardEditedData?.boardEditedEntity;
  const { data } = useQuery(ERROR_STATE);
  const { errorMessage }: ErrorValue = data?.error;
  const { data: utilsData } = useQuery(UTILS_STATE);
  const { sourceDragStartColumn }: UtilsValue = utilsData?.utils;
  const activeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleOnActive = (pollingInterval: number) => {
    if (isFirstRefetch.current) {
      isFirstRefetch.current = false;
    } else {
      refetch();
    }
    onActiveCb(pollingInterval);
  };

  const removeActiveTimeout = () => {
    if (activeTimeout.current) {
      clearTimeout(activeTimeout.current);
    }
  };
  const onIdle = () => {
    onIdleCb();
  };

  const onActive = () => {
    if (!shouldStopPolling) {
      handleOnActive(pollingInterval);
    }
  };

  useIdleTimer({
    timeout,
    onActive,
    onIdle,
    throttle: 500,
  });

  useEffect(() => {
    if (actionEditedEntity || outcomeEditedEntity || noteEditedEntity || errorMessage) {
      refetch();
    }
  }, [actionEditedEntity, outcomeEditedEntity, noteEditedEntity, errorMessage]);

  useEffect(() => {
    handleOnActive(pollingInterval);
  }, []);

  useEffect(() => {
    if (shouldStopPolling || !!errorMessage) {
      onIdle();
    } else if (sourceDragStartColumn) {
      onIdle();
      removeActiveTimeout();
    } else {
      removeActiveTimeout();
      activeTimeout.current = setTimeout(() => {
        handleOnActive(pollingInterval);
      }, pollingInterval);
    }
    return () => {
      removeActiveTimeout();
    };
  }, [sourceDragStartColumn, shouldStopPolling, errorMessage]);
};

export default usePollDataOnActivity;

