import { FetchSharedWorkspacesQuery, Permission } from '__generated__/graphql';
import { Button } from 'antd';
import { Dropdown, Menu } from 'antd/es';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from './svg/arrowDown.svg';
import { useMemo } from 'react';

const StyledButton = styled(Button)`
  background: none;
  border: 0;
  box-shadow: none;
  padding: 0;
  font-weight: 600;

  &:hover,
  &:focus,
  &:active {
    background: none;
    border: 1px;
  }

  span {
    color: var(--color-dark-blue);
  }

  svg {
    margin-left: 0.25rem;
  }
`;

export type Member = Exclude<
  FetchSharedWorkspacesQuery['fetchSharedWorkspaces'][number]['members'],
  null | undefined
>[number];

const columns: ColumnsType<Member> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Permission',
    dataIndex: 'permission',
    key: 'permission',
  },
];

export interface ISharedSpacesTableColumnsProps {
  onAssignPrimaryOwner: (user: Member) => void;
  onViewEdit: (user: Member) => void;
  onDelete: (user: Member) => void;
}

export const useSharedSpacesTableColumns = ({
  onAssignPrimaryOwner,
  onViewEdit,
  onDelete,
}: ISharedSpacesTableColumnsProps) => {
  const actionsColumn: typeof columns[number] = useMemo(
    () => ({
      title: 'Actions',
      key: 'actions',
      render: (_, user) => {
        return (
          <span>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    key="0"
                    disabled={user.permission === Permission.PrimaryOwner}
                    onClick={() => {
                      onAssignPrimaryOwner(user);
                    }}
                  >
                    Assign Primary Owner
                  </Menu.Item>
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      onViewEdit(user);
                    }}
                  >
                    View/Edit
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      onDelete(user);
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <StyledButton>
                <span>Actions</span> <ArrowDown />
              </StyledButton>
            </Dropdown>
          </span>
        );
      },
    }),
    [onAssignPrimaryOwner, onDelete, onViewEdit],
  );

  return useMemo(() => [...columns, actionsColumn], [actionsColumn]);
};
// 10785 testaccount120@mailto.plus

