import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Select } from 'antd';
import styled, { css } from 'styled-components';
import { WorkspaceItemProps, WorkSpaceMenuOptions } from './types';
import { ReactComponent as PlusIcon } from 'core/svg/plus.svg';
import Highlighter from 'react-highlight-words';
import { useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACE_TAGS } from '../../../common/graphql/queries';
import { sendEvent } from '../../../../core/integrations/sentry/events';
import { theme } from '../../../../core/styles/styled-components';
import { useMediaQuery } from 'react-responsive';
import { Card, renderContextMenuWithSharedCount } from '../../../../core/components/common';
import { ReactComponent as DotIcon } from 'core/svg/dot.svg';
import { ReactComponent as RemoveIcon } from 'core/svg/remove.svg';
import { WORKSPACE_TYPE_UNSELECTED } from '../../../../core/constants/others';
import { ContextMenuOption } from '../../../../core/components/common/Card/types';
import { SharedPeopleIcon } from '../ShareWorkspace/SharedPeopleIcon/SharedPeopleIcon';
import { ReactComponent as AddIcon } from '../../../../core/svg/add.svg';
import WorkspaceLabel from '../WorkspaceLabels/WorkspaceLabel';
import { Permission } from '../../../../core/types/workspace';
import { getMenuOptionsForWorkspaceItem } from '../../utils';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import { manageWorkspacesMutation } from '../../../../apollo/stateFields/manageWorkspaces';
import useWorkspaceInvites from '../../../../hooks/useWorkspaceInvites';

const { Option } = Select;

const StyledAddTagButton = styled(PlusIcon)`
  margin-left: 6px;
  cursor: pointer;
`;

const StyledTagsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 9px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StyledTitle = styled.div<{ isShared: boolean }>`
  display: flex;
  align-items: center;
  min-height: 24px;
  padding: 4px 6px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border-radius: 2px;
  border: 1px solid rgba(177, 190, 249, 0.4);
  background: rgba(177, 190, 249, 0.4);
  color: inherit;
  width: fit-content;
  margin-bottom: 6px;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);

  svg {
    min-width: 8px;
    min-height: 8px;
    margin-right: 6px;
    -webkit-transform: translate3d(0, 0, 0);

    circle {
      fill: #92a3f6;
    }
  }

  i {
    display: inline-block;
    width: 1px;
    height: 16px;
    background: rgba(177, 190, 249, 0.4);
    margin-right: 4px;
  }

  ${(props) =>
    props.isShared &&
    `
    border: 1px solid rgba(255, 194, 39, 0.4);
    background: rgba(255, 194, 39, 0.4);
     i {
      background: #ECAE13;
      }
     svg {
        circle {
          fill: #ECAE13;
        }
     }
    `}
`;

const StyledCard = styled(Card)<{ isUnselected?: boolean; isShared?: boolean }>`
  && {
    padding: 11px 14px 12px 10px;
    margin-bottom: 8px;

    .ant-select {
      margin: 0;
    }

    .options-button {
      top: 9px;
      right: 4px;
    }

    ${({ isUnselected }) =>
      isUnselected &&
      css`
        border: 1px solid transparent;
        background: linear-gradient(0deg, #e6e7ea, #e6e7ea);
      `}

    ${({ isShared }) =>
      isShared &&
      css`
        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: calc(100% - 6px);
          left: -1px;
          top: 3px;
          background-color: var(--color-yellow);
        }
      `}
  }
`;

const StyledSelectButton = styled(Button)<{ selected: boolean }>`
  border: 1px solid transparent;
  border-radius: 36px;
  font-size: 9px;
  line-height: 1;
  font-weight: 500;
  height: 16px;
  padding: 0 8px;

  ${(props) =>
    props.selected
      ? `
      &:focus,
  &:hover,
  &:active {
    background-color: var(--color-dark-blue);
    color: #fff;
  }
background-color: var(--color-dark-blue);
color: #fff;
`
      : `
      &:focus,
  &:hover,
  &:active {
    color: var(--color-dark-blue);
    background-color: initial;
    border-color: rgba(32, 38, 53, 0.07);
  }
    color: var(--color-dark-blue);
background-color: initial;
border-color: rgba(32, 38, 53, 0.07);
`}
`;

const StyledDescription = styled.p`
  font-size: 9px;
  line-height: 12px;
  margin-bottom: 12px;
`;

const StyledAddActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  background-color: transparent;
  border: none;
  padding-left: 0;
  box-shadow: none;
  font-weight: 400;
  font-size: 9px;
  margin-left: 10px;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  & > *:first-child {
    margin-right: 5px;
  }
`;

const StyledTag = styled.div`
  border: 1px solid rgba(32, 38, 53, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 4px;
  padding: 5px 4px;
  display: flex;
  align-items: center;
  margin: 2px 0 2px 4px;

  span {
    font-size: 9px;
    line-height: 8px;
  }

  svg {
    cursor: pointer;
    margin-left: 3px;
  }
`;

const StyledNoDataContainer = styled.div`
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WorkspaceItem = React.memo(
  ({ workspace, onSelect, onEdit, onSetDefault }: WorkspaceItemProps) => {
    const { toggleManageWorkspacesModal, setSharedWorkspace, setLeaveWorkspace } =
      manageWorkspacesMutation;
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { data: filterData } = useQuery(FILTER_STATE);
    const { filterInput }: FilterInputValue = filterData?.filterInput;
    const { setFilterInputWorkspaceTags } = filterMutation;
    const { data: workspaceTagsResponse, refetch: refetchWorkspaceTag } = useQuery(
      FETCH_USER_WORKSPACE_TAGS,
      {
        variables: {
          workspaceId: workspace.id,
        },
        fetchPolicy: 'cache-and-network',
      },
    );
    const { getWorkspaceInvitesById, getIsShared } = useWorkspaceInvites();
    const initialTagsOptions = workspaceTagsResponse?.fetchUserWorkspaceTags || [];
    const viewerPermission = useMemo(
      () => workspace?.permission === Permission.Viewer || false,
      [workspace],
    );
    const editorPermission = useMemo(
      () => workspace?.permission === Permission.Editor || false,
      [workspace],
    );

    useEffect(() => {
      if (workspace) {
        refetchWorkspaceTag();
      }
    }, []);

    const [showTagSelect, setShowTagSelect] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [sendInputEvent, setSendInputEvent] = useState<boolean>(false);
    const isTouchDevice = useMediaQuery({ query: theme.device.tablet.max });
    const isMobile = useMediaQuery({ query: theme.device.mobile.max });
    const isShared = getIsShared(workspace.id);

    const children: any[] = useMemo(() => {
      return initialTagsOptions.map((tag) => {
        return (
          <Option key={tag.id} value={tag.id}>
            {
              <Highlighter
                textToHighlight={tag.name}
                searchWords={[searchValue]}
                highlightStyle={{
                  padding: 0,
                  backgroundColor: 'rgba(255,194,39,0.3)',
                  borderRadius: '2px',
                }}
              />
            }
          </Option>
        );
      });
    }, [searchValue, workspaceTagsResponse]);

    function handleChange(value: number[]) {
      setSelectedTags(value);
      setFilterInputWorkspaceTags({ workspaceId: workspace.id, tagIds: value });
      setSendInputEvent(true);
      if (value.length === 0) {
        setShowTagSelect(false);
      }
      setSearchValue('');
    }

    useEffect(() => {
      const tagsIds =
        filterInput.find((filter) => filter.workspaceId === workspace.id)?.tagIds || [];
      setSelectedTags(tagsIds);
    }, [filterInput]);

    useEffect(() => {
      if (sendInputEvent) {
        setSendInputEvent(false);
        sendEvent('filter-update', 'Update filter input', { filterInput });
      }
    }, [filterInput, sendInputEvent]);

    const handleContextMenuOptionClick = useCallback(
      async (option: ContextMenuOption) => {
        const label = option.label;

        switch (label) {
          case WorkSpaceMenuOptions.REMOVE_WORKSPACE.label:
          case WorkSpaceMenuOptions.ADD_WORKSPACE.label:
            onSelect(workspace.id);
            break;
          case WorkSpaceMenuOptions.EDIT_WORKSPACE.label:
            onEdit(workspace.id)();
            break;
          case WorkSpaceMenuOptions.SET_AS_DEFAULT_WORKSPACE.label:
            onSetDefault(workspace.id);
            break;
          case WorkSpaceMenuOptions.SHARE.label:
            toggleManageWorkspacesModal();
            setSharedWorkspace(workspace);
            break;
          case WorkSpaceMenuOptions.LEAVE.label:
            await setLeaveWorkspace(workspace);
            break;
        }
      },
      [workspace],
    );

    const handleRemoveTag = (id: number) => () => {
      const newValue = selectedTags.filter((tagId) => tagId !== id);
      handleChange(newValue);
    };

    const renderChangeBoardButton = useCallback(() => {
      const isUnselected = workspace.type === WORKSPACE_TYPE_UNSELECTED;
      const action = () => onSelect(workspace.id);
      const label = `${isUnselected ? 'Add to' : 'Remove from'} the Board`;

      return (
        <StyledSelectButton onClick={action} selected={!isUnselected}>
          {label}
        </StyledSelectButton>
      );
    }, [workspace]);

    const renderLabels = useCallback(() => {
      return (
        <StyledLabelContainer>
          {workspace.isNewShared && <WorkspaceLabel isNew />}
          {workspace?.permission && (
            <WorkspaceLabel
              permission={workspace?.permission}
              isShared={isShared}
              isMobile={isMobile}
            />
          )}
          {isShared && <SharedPeopleIcon count={getWorkspaceInvitesById(workspace.id)?.count} />}
          {!viewerPermission && (
            <StyledAddActionButton
              onClick={() => {
                toggleManageWorkspacesModal();
                setSharedWorkspace(workspace);
              }}
              icon={<AddIcon />}
              size={'small'}
            >
              Add users
            </StyledAddActionButton>
          )}
        </StyledLabelContainer>
      );
    }, [workspace, workspace.id, isShared]);

    const renderTags = useCallback(() => {
      return selectedTags.map((tagId) => {
        const tag = initialTagsOptions.find((t) => t.id === tagId);
        return (
          <StyledTag>
            <span>{tag?.name}</span>
            <RemoveIcon onClick={handleRemoveTag(tagId)} />
          </StyledTag>
        );
      });
    }, [selectedTags, initialTagsOptions]);

    const headerCardEl = useMemo(
      () => (
        <StyledHeaderContainer>
          {renderLabels()}
          {renderChangeBoardButton()}
        </StyledHeaderContainer>
      ),
      [renderLabels, renderChangeBoardButton],
    );

    const tagsContainerEL = workspace.type !== WORKSPACE_TYPE_UNSELECTED && (
      <div>
        {!showTagSelect && (
          <StyledTagsContainer>
            <span>Configure using Tags</span>
            {renderTags()}
            <StyledAddTagButton onClick={() => setShowTagSelect(true)} />
          </StyledTagsContainer>
        )}
        {showTagSelect && (
          <Select
            dropdownAlign={{ overflow: { adjustY: 0 } }}
            mode="multiple"
            autoFocus={!isTouchDevice}
            value={selectedTags}
            filterOption={(input, option) =>
              option?.children.props.textToHighlight.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            placeholder="Select Tags"
            onChange={handleChange}
            onSearch={(value) => {
              setSearchValue(value);
            }}
            onBlur={() => {
              setShowTagSelect(false);
              setSearchValue('');
            }}
            notFoundContent={
              <StyledNoDataContainer>
                <span>No tags are defined for this Space</span>
              </StyledNoDataContainer>
            }
          >
            {children}
          </Select>
        )}
      </div>
    );

    const titleEl =
      defaultWorkspaceId === workspace.id ? (
        <StyledTitle isShared={isShared}>
          <DotIcon />
          <i />
          <span>{workspace.name}</span>
        </StyledTitle>
      ) : (
        <StyledTitle isShared={isShared}>
          <span>{workspace.name}</span>
        </StyledTitle>
      );

    return (
      <StyledCard
        headerContent={headerCardEl}
        isUnselected={workspace.type === WORKSPACE_TYPE_UNSELECTED}
        highlightedContextMenuOptions={getMenuOptionsForWorkspaceItem(
          workspace,
          viewerPermission,
          editorPermission,
        )}
        contextMenuOptions={getMenuOptionsForWorkspaceItem(
          workspace,
          viewerPermission,
          editorPermission,
        )}
        renderContextMenu={renderContextMenuWithSharedCount}
        onContextMenuOptionClick={handleContextMenuOptionClick}
        isShared={isShared}
        sharedCount={getWorkspaceInvitesById(workspace.id)?.count}
      >
        {titleEl}
        <StyledDescription>{workspace.description}</StyledDescription>
        {tagsContainerEL}
      </StyledCard>
    );
  },
);

export { WorkspaceItem };

