import { gql } from '__generated__/gql';

//TODO ==> add field outcome with name and id, when server is ready
const ACTION_FIELDS_STRING = /* GraphQL */ `
  fragment actionFields on Action {
    id
    name
    status
    description
    startDate
    actualStartDate
    actualPoints
    estimatedPoints
    endDate
    updateDate
    createDate
    workspaceId
    actualEndDate
    priority
    tags {
      id
      name
    }
    outcome {
      id
      name
      status
      isAllActionsDone
      # createDate
    }
    isArchived
    actionTimeFormat
    estimatedTime
    spendTime
  }
`;
export const ACTION_FIELDS = gql(ACTION_FIELDS_STRING);

const NOTE_FIELDS_STRING = /* GraphQL */ `
  fragment noteFields on Note {
    createDate
    updateDate
    description
    id
    isDeleted
    name
    updateDate
    workspaceId
    isArchived
  }
`;

export const NOTE_FIELDS = gql(NOTE_FIELDS_STRING);

const FOCUS_TIMER_FIELDS_STRING = /* GraphQL */ `
  fragment focusTimerFields on FocusTimer {
    actionId
    createDate
    fixedPeriodSelected
    id
    resumeTime
    spendTime
    startTime
    status
    updateDate
    userId
    isBipSound
  }
`;

export const FOCUS_TIMER_FIELDS = gql(FOCUS_TIMER_FIELDS_STRING);

