import { Table, Typography } from 'antd';

const { Text } = Typography;

const csvHeadersColumns = [
  {
    title: 'Header name',
    dataIndex: 'header',
    key: 'header',
    render: (header: any) => <Text code>{header}</Text>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

interface ICsvHeadersTableProps {
  dataSource: { header: string; description?: string }[];
}

const CsvHeadersTable = ({ dataSource }: ICsvHeadersTableProps) => {
  return (
    <Table columns={csvHeadersColumns} dataSource={dataSource} rowKey="header" pagination={false} />
  );
};

export default CsvHeadersTable;

