import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Modal } from 'antd';
import { theme } from '../../core/styles/styled-components';
import { Button } from '../../core/components/common';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_ENTITY } from '../../graphql/mutations';
import { firebase } from '../../core/integrations/firebase/init';
import { getFirstAndLastName } from '../../features/courses/utils';

const StyledModal = styled(Modal)`
  margin: auto;
  padding: 0;
  z-index: 2500;
  max-width: 360px;
  top: 20%;

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    border-radius: 3px;
    height: auto;
  }

  .ant-modal-body {
    position: relative;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${theme.device.mobile.max} {
    height: 100%;
  }
`;
const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 24px;
`;
const StyledInputContainer = styled.div``;
const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #202635;
`;
const StyledButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;

  @media ${theme.device.mobile.max} {
    margin-top: auto;
  }
`;

const StyledButton = styled(Button)`
  height: 32px;
  font-size: 14px;
`;

const StyledCancelButton = styled(StyledButton)`
  background-color: transparent;
  color: #202635;
  margin-left: 10px;

  @media ${theme.device.mobile.max} {
    border: 1px solid var(--color-dark-blue);
  }
`;

const EditUserProfileModal = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (displayName?: string) => void;
}) => {
  const [updateUserEntity] = useMutation(UPDATE_USER_ENTITY);
  const [firstNameInput, setFirstNameInput] = useState<string>('');
  const [lastNameInput, setLastNameInput] = useState<string>('');
  const [currentFirstName, setCurrentFirstName] = useState<string>('');
  const [currentLastName, setCurrentLastName] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const user = firebase.auth().currentUser;

  const handleUpdateUser = async () => {
    setLoading(true);
    const fn = firstNameInput ? firstNameInput.trim() : currentFirstName;
    const ln = lastNameInput ? lastNameInput.trim() : currentLastName;
    await updateUserEntity({
      variables: {
        userParams: {
          name: `${fn} ${ln}`,
          firstName: fn,
          lastName: ln,
        },
      },
    });
    await user?.updateProfile({
      displayName: `${fn} ${ln}`,
    });
    setLoading(false);
    onClose(`${fn} ${ln}`);
  };

  useEffect(() => {
    const { firstName, lastName } = getFirstAndLastName(user?.displayName as string | undefined);

    if (firstName) {
      setFirstNameInput(firstName);
      setCurrentFirstName(firstName);
    }
    if (lastName) {
      setLastNameInput(lastName);
      setCurrentLastName(lastName);
    }
  }, [user?.displayName]);

  return (
    <StyledModal
      destroyOnClose
      getContainer={() => document.body}
      visible={visible}
      onCancel={() => onClose()}
      footer={null}
      mask={false}
      closable={true}
    >
      <StyledContentWrapper>
        <StyledTitle>Edit User Profile</StyledTitle>
        <StyledInputContainer>
          <StyledLabel>First Name</StyledLabel>
          <Input
            value={firstNameInput}
            minLength={3}
            maxLength={100}
            placeholder={'Enter first name'}
            onChange={(e) => setFirstNameInput(e.target.value)}
          />
          <StyledLabel>Last Name</StyledLabel>
          <Input
            value={lastNameInput}
            minLength={3}
            maxLength={100}
            placeholder={'Enter last name'}
            onChange={(e) => setLastNameInput(e.target.value)}
          />
        </StyledInputContainer>

        <StyledButtonContainer>
          <StyledButton
            isDisabled={!firstNameInput.trim().length || !lastNameInput.trim().length}
            isLoading={loading}
            onClick={() => handleUpdateUser()}
          >
            Save
          </StyledButton>
          <StyledCancelButton onClick={() => onClose()}>Cancel</StyledCancelButton>
        </StyledButtonContainer>
      </StyledContentWrapper>
    </StyledModal>
  );
};

export default EditUserProfileModal;

