import { useState } from 'react';
import { Button, Descriptions } from 'antd';
import styled from 'styled-components';
import { EditFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { FETCH_CORPORATE_ACCOUNT_BY_DOMAIN } from '../../../graphql/queries';
import { Loader } from '../../../core/components/common';
import { getSubscriptionPlanTitle, getTitle } from '../../../features/courses/utils';
import { StyledModalContainer } from '../StyledComponents';
import EditOrgModal from '../EditOrgModal/EditOrgModal';
import { format } from 'date-fns';

const StyledDescriptionItem = styled(Descriptions.Item)``;
const StyledEditButton = styled(Button)``;

export enum OrgEditModalType {
  Domain = 'Domain',
  CurriculumType = 'CurriculumType',
  SubscriptionExpiration = 'SubscriptionExpiration',
}

interface OrgInfoProps {
  domain: string;
  onSuccess: () => void;
  onError: () => void;
  onDomainUpdate: (domain: string) => void;
}

const OrgInfo = ({ domain, onSuccess, onError, onDomainUpdate }: OrgInfoProps) => {
  const {
    data: orgData,
    loading,
    refetch,
  } = useQuery(FETCH_CORPORATE_ACCOUNT_BY_DOMAIN, {
    variables: { domain },
    fetchPolicy: 'network-only',
  });
  const [showEditModal, setShowEditModal] = useState<OrgEditModalType | null>(null);
  const subscriptionExpiration = orgData?.fetchCorporateAccountByDomain?.bmSubscriptionExpiration;
  const [courseTitle1, courseTitle2] =
    orgData?.fetchCorporateAccountByDomain?.coursesAvailableList?.map((course) =>
      getTitle(course),
    ) ?? [];
  const bmSubscriptionPlan = orgData?.fetchCorporateAccountByDomain?.bmSubscriptionPlan;
  return (
    <StyledModalContainer>
      {loading && <Loader size={'large'} />}
      {!loading && !!orgData?.fetchCorporateAccountByDomain?.domain ? (
        <>
          <Descriptions title="Company Info" size={'default'}>
            <StyledDescriptionItem label="Domain">
              {domain}
              {/* <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(OrgEditModalType.Domain)}
                style={{ marginLeft: '5px' }}
              /> */}
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Subscription">
              {bmSubscriptionPlan
                ? getSubscriptionPlanTitle(bmSubscriptionPlan)
                : 'No subscription'}
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(OrgEditModalType.SubscriptionExpiration)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Curriculum">
              {courseTitle1}/ {courseTitle2}
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(OrgEditModalType.CurriculumType)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Expire">
              {subscriptionExpiration != null
                ? format(subscriptionExpiration * 1000, 'MMM dd, yyyy')
                : 'No expiration'}
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(OrgEditModalType.SubscriptionExpiration)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
          </Descriptions>
          <EditOrgModal
            domain={domain}
            onError={onError}
            onSuccess={(isDomain = false) => {
              if (!isDomain) refetch();
              onSuccess();
            }}
            onDomainUpdate={onDomainUpdate}
            type={showEditModal}
            onClose={() => {
              setShowEditModal(null);
            }}
          />
        </>
      ) : null}
    </StyledModalContainer>
  );
};

export default OrgInfo;

