import { TablePaginationConfig } from 'antd';
import { useCallback, useState } from 'react';

export const usePagination = (initialPagination: TablePaginationConfig) => {
  const [pagination, setPagination] = useState(initialPagination);
  const setLimit = useCallback(
    (limit: number) => setPagination((old) => ({ ...old, pageSize: limit })),
    [],
  );
  const setTotal = useCallback((total: number) => setPagination((old) => ({ ...old, total })), []);
  const setPage = useCallback(
    (page: number) => setPagination((old) => ({ ...old, current: page })),
    [],
  );
  return [
    pagination,
    {
      setLimit,
      setTotal,
      setPage,
      setPagination,
    },
  ] as const;
};
