import { gql, makeVar } from '@apollo/client';
import { Workspace } from '../../../core/types/workspace';
import { AcceptInviteResult } from '__generated__/graphql';

export interface ManageWorkspacesValue {
  workspaceToShare: Workspace | null;
  workspaceToLeave: Workspace | null;
  userToRemove: AcceptInviteResult | null;
  isManageWorkspacesModalVisible: boolean;
  editedWorkspaceId: number | null;
}

export const manageWorkspacesInitialValue: ManageWorkspacesValue = {
  workspaceToShare: null,
  workspaceToLeave: null,
  userToRemove: null,
  isManageWorkspacesModalVisible: false,
  editedWorkspaceId: null,
};

export const manageWorkspacesVar = makeVar<ManageWorkspacesValue>(manageWorkspacesInitialValue);
export const MANAGE_WORKSPACES_STATE = gql`
  query ManageWorkspaces {
    manageWorkspaces @client
  }
`;

