import { gql } from '__generated__/gql';
import './fragments';

const FETCH_OUTCOMES_STRING = /* GraphQL */ `
  query FetchOutcomes($workspaceId: Int = -1) {
    fetchUserWorkspaceOutcomes(workspaceId: $workspaceId) {
      ...outcomeFields
    }
  }
`;

export const FETCH_OUTCOMES = gql(FETCH_OUTCOMES_STRING);

const FETCH_OUTCOME_STRING = /* GraphQL */ `
  query FetchOutcome($outcomeId: Int!, $workspaceId: Int!) {
    fetchUserWorkspaceOutcome(outcomeId: $outcomeId, workspaceId: $workspaceId) {
      ...outcomeFields
    }
  }
`;

export const FETCH_OUTCOME = gql(FETCH_OUTCOME_STRING);

