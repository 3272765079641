import styled from 'styled-components';
import { FocusTimerType } from './FocusSettingsModal';
import { useQuery } from '@apollo/client';
import { FETCH_FOCUS_TIMER_SETTINGS } from '../../../../../../graphql/queries';
import { formatTimer } from './utils';

const StyledTimerWrapper = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: white;
  min-width: 20px;
  margin-left: 3px;
`;

const Timer = ({ timeLeft, spendTime }: { timeLeft: number; spendTime: number }) => {
  const { data: settingsData } = useQuery(FETCH_FOCUS_TIMER_SETTINGS);
  const periodType = settingsData?.fetchUserSettings?.focusTimerType
    ? settingsData?.fetchUserSettings?.focusTimerType
    : FocusTimerType.Fixed;

  return (
    <StyledTimerWrapper>
      {periodType === FocusTimerType.Fixed ? formatTimer(timeLeft) : formatTimer(spendTime)}
    </StyledTimerWrapper>
  );
};

export default Timer;

