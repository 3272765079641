import { DeletedUsersResults } from '__generated__/graphql';
import { ColumnsType } from 'antd/es/table';
import ServiceResultCell from './ServiceResultCell';
import { StyledCheckbox } from 'features/common/components/CompleteOutcome/StyledComponents';

export const columns: ColumnsType<DeletedUsersResults> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Database',
    dataIndex: 'database',
    key: 'database',
    render: (database: string[] | null, { email }) => (
      <ServiceResultCell serviceResult={database} key={`${email}-database`} />
    ),
  },
  {
    title: 'Firebase',
    dataIndex: 'firebase',
    key: 'firebase',
    render: (firebase: string[] | null, { email }) => (
      <ServiceResultCell serviceResult={firebase} key={`${email}-firebase`} />
    ),
  },
  {
    title: 'Active Campaign',
    dataIndex: 'activeCampaign',
    key: 'activeCampaign',
    render: (activeCampaign: string[] | null, { email }) => (
      <ServiceResultCell key={`${email}-activeCampaign`} serviceResult={activeCampaign} />
    ),
  },
  {
    title: 'Stripe',
    dataIndex: 'stripe',
    key: 'stripe',
    render: (stripe: string[], { email }) => (
      <ServiceResultCell serviceResult={stripe} key={`${email}-stripe`} />
    ),
  },
];

export type DeletedSharedSpacesResults = Pick<DeletedUsersResults, 'email'> &
  Pick<Exclude<DeletedUsersResults['sharedSpaces'], null | undefined>[number], 'id' | 'name'> & {
    deleted: boolean;
  };

export const deletedSharedSpacesColumns: ColumnsType<DeletedSharedSpacesResults> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Shared Space ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Shared Space Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Deleted',
    dataIndex: 'deleted',
    key: 'deleted',
    render: (deleted: boolean) => (<StyledCheckbox checked={deleted} disabled />),
  },
];
