import { CoursesConstsArgs } from '../../graphql/types';
import { CoursesTitle } from '../../apollo/stateFields/courses/coursesFields';

export interface CourseCardSettings {
  index: number;
  course?: CoursesConstsArgs | null;
  title?: CoursesTitle;
}

export enum SECTION_WORKBOOK {
  Core_Skills = 'https://workbooks.app.billionminds.com/CoreSkillsWorkbook.pdf',
  Take_Back_Control_of_Your_Day = 'https://workbooks.app.billionminds.com/TakeBackControlOfYourDayWorkbook.pdf',
  Find_Meaning_in_Your_Day = 'https://workbooks.app.billionminds.com/FindMeaningInYourDayWorkbook.pdf',
  Integrate_Work_With_Your_Life = 'https://workbooks.app.billionminds.com/IntegrateWorkWithYourLifeWorkbook.pdf',
  Master_Your_Unpredictable_Day = 'https://workbooks.app.billionminds.com/MasterYourUnpredictableDayWorkbook.pdf',
}

