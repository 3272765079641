import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../../core/svg/arrow-left.svg';
import { Radio } from 'antd';
import {
  BOARD_FILTERS_STATE,
  BoardFiltersValue,
  DONE_COLUMN_FILTERS_VALUES,
  DoneColumnFilterTypes,
} from '../../../../../apollo/stateFields/boardFilters/boardFiltersFields';
import { format, getYear, startOfDay, subDays } from 'date-fns';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_SETTINGS } from '../../../../../graphql/mutations';
import {
  GlobalStyle,
  StyledAddActionButton,
  StyledBackLink,
  StyledBurgerIcon,
  StyledCustomDate,
  StyledCustomHeader,
  StyledModal,
  StyledRadio,
  StyledRadioContent,
  StyledRadioHeader,
  StyledRangePicker,
  StyledSpace,
} from '../StyledComponents/StyledComponents';
import { boardFiltersMutation } from '../../../../../apollo/stateFields/boardFilters';

const DoneColumnFilter = () => {
  const { data: boardFiltersData } = useQuery(BOARD_FILTERS_STATE);
  const {
    showDoneColumnFilter,
    doneColumnFilter,
    doneColumnStartDate,
    doneColumnEndDate,
  }: BoardFiltersValue = boardFiltersData?.boardFilters;
  const {
    setDoneColumnStartDate,
    setDoneColumnEndDate,
    setShowDoneColumnFilter,
    setDoneColumnFilter,
  } = boardFiltersMutation;
  const [showCustomPeriod, shouldShowCustomPeriod] = useState<boolean>(false);
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const rangePickerRef = useRef<any>(null);
  const [coords, setCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [updateSettings] = useMutation(UPDATE_SETTINGS);

  const handleOpenFilter = useCallback(
    (event: any) => {
      if (filterButtonRef.current) {
        setCoords({
          x: filterButtonRef.current.getBoundingClientRect().right,
          y: filterButtonRef.current.getBoundingClientRect().top,
        });
        setShowDoneColumnFilter(true);
      }
    },
    [setShowDoneColumnFilter, setCoords, filterButtonRef.current],
  );

  const handleCloseFilter = useCallback(
    (event: any) => {
      setShowDoneColumnFilter(false);
      shouldShowCustomPeriod(false);
    },
    [setShowDoneColumnFilter, setCoords],
  );

  const renderDateFilter = () => {
    if (
      doneColumnFilter === DoneColumnFilterTypes.CUSTOM &&
      !!doneColumnStartDate &&
      !!doneColumnEndDate
    ) {
      if (getYear(doneColumnStartDate) !== getYear(doneColumnEndDate)) {
        return `${format(doneColumnStartDate, 'MMM dd, yyyy')} - ${format(
          doneColumnEndDate,
          'MMM dd, yyyy',
        )}`;
      }
      return `${format(doneColumnStartDate, 'MMM dd')} - ${format(doneColumnEndDate, 'MMM dd')}`;
    }

    return doneColumnFilter;
  };

  const renderRadioGroup = () => {
    const onChange = async (e: any) => {
      setDoneColumnFilter(e.target.value as DoneColumnFilterTypes);

      const settings =
        doneColumnStartDate &&
        doneColumnEndDate &&
        (e.target.value as DoneColumnFilterTypes) === DoneColumnFilterTypes.CUSTOM
          ? {
              doneColumnFilter: {
                name: 'Done',
                filter: e.target.value,
                startDate: doneColumnStartDate.toString(),
                endDate: doneColumnEndDate.toString(),
              },
            }
          : {
              doneColumnFilter: {
                name: 'Done',
                filter: e.target.value,
              },
            };

      await updateSettings({
        variables: {
          settings,
        },
      });
    };

    return (
      <>
        <StyledRadioHeader>Done Filters</StyledRadioHeader>
        <Radio.Group onChange={onChange} value={doneColumnFilter} style={{ width: '100%' }}>
          <StyledSpace direction="vertical">
            {DONE_COLUMN_FILTERS_VALUES.map((value, index) => {
              if (value === DoneColumnFilterTypes.CUSTOM) {
                return (
                  <StyledRadio
                    value={value}
                    key={`${value}-${index}`}
                    onClick={() => shouldShowCustomPeriod(true)}
                  >
                    <StyledRadioContent>{value}</StyledRadioContent>
                    {doneColumnStartDate &&
                      doneColumnEndDate &&
                      doneColumnFilter === DoneColumnFilterTypes.CUSTOM && (
                        <StyledCustomDate>
                          {renderDateFilter()}
                          {/*  {`${format(doneColumnStartDate, 'MMM dd')} - */}
                          {/*${format(doneColumnEndDate, 'MMM dd')}`}*/}
                        </StyledCustomDate>
                      )}
                  </StyledRadio>
                );
              }

              return (
                <StyledRadio value={value} key={`${value}-${index}`}>
                  <StyledRadioContent>
                    {value}
                    {value === DoneColumnFilterTypes.LAST_7 && (
                      <span>Since {format(subDays(startOfDay(new Date()), 6), 'MMM dd')}</span>
                    )}
                    {value === DoneColumnFilterTypes.LAST_14 && (
                      <span>Since {format(subDays(startOfDay(new Date()), 13), 'MMM dd')}</span>
                    )}
                    {value === DoneColumnFilterTypes.LAST_30 && (
                      <span>Since {format(subDays(startOfDay(new Date()), 29), 'MMM dd')}</span>
                    )}
                  </StyledRadioContent>
                </StyledRadio>
              );
            })}
          </StyledSpace>
        </Radio.Group>
      </>
    );
  };

  const renderCustomPeriod = () => {
    const onChange = async (dates: any) => {
      if (!dates[0] || !dates[1]) {
        return;
      }

      setDoneColumnStartDate(dates[0].toDate());
      setDoneColumnEndDate(dates[1].toDate());

      await updateSettings({
        variables: {
          settings: {
            doneColumnFilter: {
              name: 'Done',
              filter: DoneColumnFilterTypes.CUSTOM,
              startDate: dates[0].toString(),
              endDate: dates[1].toString(),
            },
          },
        },
      });
    };

    const today = new Date();

    return (
      <>
        <GlobalStyle />
        <StyledBackLink onClick={() => shouldShowCustomPeriod(false)}>
          <LeftArrowIcon />
          <span>Done Filters</span>
        </StyledBackLink>
        <StyledCustomHeader>Select custom period</StyledCustomHeader>
        <StyledRangePicker
          ref={rangePickerRef}
          format="MMM  DD  YYYY"
          onChange={onChange}
          inputReadOnly
          defaultPickerValue={[
            doneColumnStartDate
              ? moment(doneColumnStartDate)
              : moment(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0)),
            doneColumnEndDate
              ? moment(doneColumnEndDate)
              : moment(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0)),
          ]}
          value={[
            doneColumnStartDate ? moment(doneColumnStartDate) : null,
            doneColumnEndDate ? moment(doneColumnEndDate) : null,
          ]}
        />
      </>
    );
  };

  useEffect(() => {
    if (
      doneColumnFilter === DoneColumnFilterTypes.CUSTOM &&
      (!doneColumnStartDate || !doneColumnEndDate)
    ) {
      setDoneColumnFilter(DoneColumnFilterTypes.ANYTIME);
    }
  }, [showCustomPeriod]);

  return (
    <div>
      <StyledAddActionButton
        onClick={handleOpenFilter}
        size={'small'}
        shape={'round'}
        ref={filterButtonRef}
        isAnytime={doneColumnFilter === DoneColumnFilterTypes.ANYTIME}
      >
        <span>{renderDateFilter()}</span>
        <StyledBurgerIcon isAnytime={doneColumnFilter === DoneColumnFilterTypes.ANYTIME} />
      </StyledAddActionButton>
      <StyledModal
        getContainer={() => document.body}
        visible={showDoneColumnFilter}
        onCancel={handleCloseFilter}
        footer={null}
        mask={false}
        closable={true}
        style={{
          left: coords.x - 300,
          top: coords.y,
        }}
      >
        {showCustomPeriod ? renderCustomPeriod() : renderRadioGroup()}
      </StyledModal>
    </div>
  );
};

export default DoneColumnFilter;

