import { useEffect, useMemo } from 'react';
import { stripeMutation } from '../apollo/stateFields/stripe';
import { useLazyQuery, useQuery } from '@apollo/client';
import { STRIPE_STATE, StripeValue } from '../apollo/stateFields/stripe/stripeFields';
import { BmSubscriptionPlans, StripeSubscriptionStatus } from '../graphql/types';
import { FETCH_STRIPE_SUBSCRIPTION } from '../graphql/queries';
import { setTimeoutPromise } from '../core/utils/promiseTimeout';

export const useCheckExpiredSubscription = () => {
  const [getBmSubscription, { data: userBmSubscriptionData, loading }] =
    useLazyQuery(FETCH_STRIPE_SUBSCRIPTION);
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired, isSubscriptionExpiredTrial }: StripeValue = stripeData?.stripe;
  const {
    setIsSubscriptionExpired,
    setExpiredSubscriptionText,
    setIsSubscriptionCanceled,
    setIsSubscriptionExpiredTrial,
    setIsSubscriptionLoading,
  } = stripeMutation;

  const isTrial = useMemo(() => {
    return (
      userBmSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan ===
        BmSubscriptionPlans.TrialFree ||
      userBmSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan === null
    );
  }, [userBmSubscriptionData?.fetchStripeSubscription]);

  useEffect(() => {
    setTimeoutPromise(1500).then(() => {
      getBmSubscription();
    });
  }, []);

  useEffect(() => {
    if (!loading && userBmSubscriptionData?.fetchStripeSubscription) {
      setIsSubscriptionLoading(false);
    } else if (loading) {
      setIsSubscriptionLoading(true);
    }
  }, [loading, userBmSubscriptionData]);

  useEffect(() => {
    if (
      !isSubscriptionExpired &&
      ((userBmSubscriptionData?.fetchStripeSubscription?.bmSubscription?.expiration &&
        +userBmSubscriptionData.fetchStripeSubscription?.bmSubscription?.expiration * 1000 <
          Date.now()) ||
        userBmSubscriptionData?.fetchStripeSubscription?.subscription?.status ===
          StripeSubscriptionStatus.Canceled)
    ) {
      setIsSubscriptionExpired(true);
      if (
        userBmSubscriptionData?.fetchStripeSubscription?.subscription?.status ===
        StripeSubscriptionStatus.Canceled
      ) {
        setIsSubscriptionCanceled(true);
      }
    } else if (
      !isSubscriptionExpired &&
      (userBmSubscriptionData?.fetchStripeSubscription?.bmSubscription?.expiration === null ||
        userBmSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan ===
          null)
    ) {
      setIsSubscriptionExpired(true);
    } else if (
      isSubscriptionExpired &&
      userBmSubscriptionData?.fetchStripeSubscription?.bmSubscription?.expiration &&
      +userBmSubscriptionData.fetchStripeSubscription?.bmSubscription?.expiration * 1000 >
        Date.now() &&
      userBmSubscriptionData?.fetchStripeSubscription?.subscription?.status !==
        StripeSubscriptionStatus.Canceled
    ) {
      setIsSubscriptionExpired(false);
      setIsSubscriptionCanceled(false);
    }
  }, [userBmSubscriptionData?.fetchStripeSubscription]);

  useEffect(() => {
    if (isSubscriptionExpired && isTrial && userBmSubscriptionData?.fetchStripeSubscription) {
      setIsSubscriptionExpiredTrial(true);
    } else if (isSubscriptionExpired && userBmSubscriptionData?.fetchStripeSubscription) {
      setIsSubscriptionExpiredTrial(false);
    }
  }, [isSubscriptionExpired, isTrial, userBmSubscriptionData?.fetchStripeSubscription]);

  useEffect(() => {
    if (!userBmSubscriptionData?.fetchStripeSubscription || !isSubscriptionExpired) {
      setExpiredSubscriptionText('');
    } else if (isSubscriptionExpired && isSubscriptionExpiredTrial) {
      setExpiredSubscriptionText('free trial has ended');
    } else if (isSubscriptionExpired) {
      setExpiredSubscriptionText('subscription has expired');
    }
  }, [
    userBmSubscriptionData?.fetchStripeSubscription,
    isSubscriptionExpired,
    isSubscriptionExpiredTrial,
  ]);
};

