import { gql } from '__generated__/gql';
import './fragments';

const CREATE_USER_WORKSPACE_STRING = /* GraphQL */ `
  mutation CreateUserWorkspace($workspace: NewWorkspaceInput!) {
    createUserWorkspace(workspace: $workspace) {
      ...workspaceFields
      createDate
      usersCount
    }
  }
`;

export const CREATE_USER_WORKSPACE = gql(CREATE_USER_WORKSPACE_STRING);

const UPDATE_USER_WORKSPACE_STRING = /* GraphQL */ `
  mutation UpdateUserWorkspace($workspace: WorkspaceInput!) {
    updateUserWorkspace(workspace: $workspace) {
      id
      ...workspaceFields
    }
  }
`;

export const UPDATE_USER_WORKSPACE = gql(UPDATE_USER_WORKSPACE_STRING);

const CREATE_USER_INVITES_STRING = /* GraphQL */ `
  mutation CreateUserWorkspaceInvites(
    $emails: [String!]!
    $permission: Permission!
    $workspaceId: Int!
  ) {
    createUserWorkspaceInvites(emails: $emails, permission: $permission, workspaceId: $workspaceId)
  }
`;

export const CREATE_USER_INVITES = gql(CREATE_USER_INVITES_STRING);

const ACCEPT_USER_WORKSPACE_INVITE_STRING = /* GraphQL */ `
  mutation AcceptUserWorkspaceInvite($workspaceId: Int!) {
    acceptUserWorkspaceInvite(workspaceId: $workspaceId) {
      ...workspaceUserInviteFields
    }
  }
`;

export const ACCEPT_USER_WORKSPACE_INVITE = gql(ACCEPT_USER_WORKSPACE_INVITE_STRING);

const DECLINE_USER_WORKSPACE_INVITE_STRING = /* GraphQL */ `
  mutation DeclineUserWorkspaceInvite($workspaceId: Int!) {
    declineUserWorkspaceInvite(workspaceId: $workspaceId)
  }
`;

export const DECLINE_USER_WORKSPACE_INVITE = gql(DECLINE_USER_WORKSPACE_INVITE_STRING);

const CREATE_USER_WORKSPACES_STRING = /* GraphQL */ `
  mutation CreateUserWorkspaces($workspaces: [NewWorkspaceInput]!) {
    createUserWorkspaces(workspaces: $workspaces) {
      ...workspaceFields
    }
  }
`;

export const CREATE_USER_WORKSPACES = gql(CREATE_USER_WORKSPACES_STRING);

const UPDATE_WORKSPACE_RELATION_STRING = /* GraphQL */ `
  mutation UpdateRelationUserWorkspace(
    $workspaceId: Int!
    $relationUserWokspace: RelationUserWokspaceInput!
  ) {
    updateRelationUserWorkspace(
      workspaceId: $workspaceId
      relationUserWokspace: $relationUserWokspace
    ) {
      ...workspaceFields
    }
  }
`;

export const UPDATE_WORKSPACE_RELATION = gql(UPDATE_WORKSPACE_RELATION_STRING);

const LEAVE_USER_WORKSPACE_STRING = /* GraphQL */ `
  mutation LeaveUserWorkspace($workspaceId: Int!) {
    leaveUserWorkspace(workspaceId: $workspaceId)
  }
`;

export const LEAVE_USER_WORKSPACE = gql(LEAVE_USER_WORKSPACE_STRING);

const REMOVE_USER_FROM_WORKSPACE_STRING = /* GraphQL */ `
  mutation RemoveUserFromWorkspace($email: String!, $workspaceId: Int!) {
    removeUserFromWorkspace(email: $email, workspaceId: $workspaceId)
  }
`;

export const REMOVE_USER_FROM_WORKSPACE = gql(REMOVE_USER_FROM_WORKSPACE_STRING);
