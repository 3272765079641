import { SearchOutlined } from '@ant-design/icons';
import { Row, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Button, SubmitButton } from 'core/components/common';
import { FormEventHandler, ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';

export interface ICustomTableFilterProps
  extends Pick<FilterDropdownProps, 'confirm' | 'setSelectedKeys' | 'clearFilters'> {
  children: ReactNode;
  onReset?: FormEventHandler<HTMLFormElement>;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

const CustomTableFilter = ({
  children,
  onReset,
  onSubmit,
  ...filterDropdownProps
}: ICustomTableFilterProps) => {
  const { confirm, setSelectedKeys, clearFilters } = filterDropdownProps;
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    confirm && confirm({ closeDropdown: false });
    onSubmit && onSubmit(e);
  };
  const handleReset: FormEventHandler<HTMLFormElement> = (e) => {
    // e.preventDefault();
    const { target } = e;
    if (!(target instanceof HTMLFormElement)) return;
    setSelectedKeys && setSelectedKeys([]);
    clearFilters && clearFilters();
    onReset && onReset(e);
  };
  return (
    <form style={{ padding: 8, width: 250 }} onReset={handleReset} onSubmit={handleSubmit}>
      <Row style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>{children}</div>
      </Row>
      <Row justify="end">
        <Space>
          <Button htmlType="reset" isSecondary>
            Reset
          </Button>
          <SubmitButton icon={<SearchOutlined />}>Filter</SubmitButton>
        </Space>
      </Row>
    </form>
  );
};

export const getCustomTableFilterProps = (): Pick<ColumnsType<any>[number], 'filterIcon'> => ({
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
});

export default CustomTableFilter;

