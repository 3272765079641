import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal } from 'antd';
import Vimeo from '@u-wave/react-vimeo';
import theme from '../../../core/styles/styled-components/theme';
import { ReactComponent as CloseIcon } from './svg/close.svg';
import { useMutation, useQuery } from '@apollo/client';
import { CoursesVars } from '../../../graphql/types';
import { UPDATE_COURSES_SETTINGS, UPDATE_USER_ENTITY } from '../../../graphql/mutations';
import { FETCH_COURSES_SETTINGS } from '../../../graphql/queries';
import {
  COURSES_STATE,
  CoursesFields,
  CoursesTitle,
} from '../../../apollo/stateFields/courses/coursesFields';
import { omit } from 'lodash-es';
import { sendEvent } from '../../../core/integrations/sentry/events';
import * as Sentry from '@sentry/browser';
import { coursesMutation } from '../../../apollo/stateFields/courses';
import { checkCourseSpecialIdsEqual } from '../utils';
import { TUTORIAL_STATE, TutorialValue } from '../../../apollo/stateFields/tutorial/tutorialField';
import { tutorialMutation } from '../../../apollo/stateFields/tutorial';
import { AUTH_STATE, AuthValue } from '../../../apollo/stateFields/auth/authFields';
import { authMutations } from '../../../apollo/stateFields/auth';
import { toast } from 'react-toastify';

const StyledModal = styled(Modal)<{ isLoaded: boolean }>`
  && {
    width: 768px !important;

    .ant-modal-body {
      padding: 0;
      opacity: 0;

      ${({ isLoaded }) =>
        isLoaded &&
        css`
          opacity: 1;
        `}
    }

    @media ${theme.device.tablet.max} {
      width: 100% !important;
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  top: -60px;
  right: 0;

  @media ${theme.device.mobile.max} {
    width: 32px;
    height: 32px;
    top: -44px;
  }
`;

const VideoModal = React.memo(() => {
  const { data: coursesData } = useQuery(COURSES_STATE);
  const { activeVideo, firstCourseTitle, secondCourseTitle }: CoursesFields = coursesData?.courses;
  const { setShowVideoPlayer } = coursesMutation;
  const { data } = useQuery(FETCH_COURSES_SETTINGS);
  const [updateSettings] = useMutation(UPDATE_COURSES_SETTINGS);
  const [isLoaded, setLoaded] = useState(false);

  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { showTutorialVideo }: TutorialValue = tutorialData?.tutorial;
  const { setShowTutorialVideo } = tutorialMutation;

  const handleClose = () => {
    if (showTutorialVideo) {
      setShowTutorialVideo(false);
    }
    setShowVideoPlayer(null);
    setLoaded(false);
  };

  const handleLoaded = () => {
    setLoaded(true);
  };

  const sendSentryInformation = (coursesVars?: CoursesVars) => {
    if (coursesVars) {
      const foundationsData = coursesVars?.[firstCourseTitle]?.watchedVideoIds?.length || 0;
      const masteringPersonalEffectivenessData =
        coursesVars?.[secondCourseTitle]?.watchedVideoIds?.length || 0;

      Sentry.setTag('foundations', foundationsData);
      Sentry.setTag('masteringPersonalEffectiveness', masteringPersonalEffectivenessData);
      sendEvent('course-progress', 'Course Progress', {
        Foundations: foundationsData,
        MasteringPersonalEffectiveness: masteringPersonalEffectivenessData,
      });
    }
  };

  const { data: authData } = useQuery(AUTH_STATE);
  const { isNewUser, idToken }: AuthValue = authData?.auth;
  const { setIsNewUser } = authMutations;
  const [updateUser] = useMutation(UPDATE_USER_ENTITY);
  const setUserActivated = useCallback(async () => {
    await updateUser({ variables: { userParams: { isActivated: true } } });
    setIsNewUser(false);
  }, [idToken, setIsNewUser]);

  useEffect(() => {
    if (
      showTutorialVideo &&
      data?.fetchUserSettings &&
      data?.fetchUserSettings.coursesConsts?.[firstCourseTitle]?.videos?.[0]
    ) {
      setShowVideoPlayer({
        courseTitle: firstCourseTitle,
        video: omit(
          data?.fetchUserSettings.coursesConsts?.[firstCourseTitle]?.videos?.[0],
          '__typename',
        ),
      });
      isNewUser && setUserActivated();
    }
  }, [showTutorialVideo, data?.fetchUserSettings, firstCourseTitle, isNewUser]);

  if (!activeVideo) {
    return null;
  }

  const currentCourseVarData =
    data?.fetchUserSettings.coursesVars[activeVideo.courseTitle as CoursesTitle];
  const coursesVarsData = {
    [firstCourseTitle]: data?.fetchUserSettings.coursesVars?.[firstCourseTitle],
    [secondCourseTitle]: data?.fetchUserSettings.coursesVars?.[secondCourseTitle],
  };

  const coursesVarsPayload = {
    [firstCourseTitle]: omit(coursesVarsData?.[firstCourseTitle], '__typename'),
    [secondCourseTitle]: omit(coursesVarsData?.[secondCourseTitle], '__typename'),
  };

  const getCoursesVars = () => {
    if (
      activeVideo.video.name.indexOf('Masterclass') === -1 &&
      !checkCourseSpecialIdsEqual(activeVideo.video.id, 0)
    ) {
      return {
        [activeVideo.courseTitle as CoursesTitle]: {
          ...coursesVarsPayload[activeVideo.courseTitle as CoursesTitle],
          watchedVideoIds: currentCourseVarData?.watchedVideoIds
            ? [...currentCourseVarData?.watchedVideoIds, activeVideo.video.id]
            : [activeVideo.video.id],
          dateLastWatched: new Date(),
          lastWatchedId: activeVideo.video.id,
        },
      };
    } else {
      return {
        [activeVideo.courseTitle as CoursesTitle]: {
          ...coursesVarsPayload[activeVideo.courseTitle as CoursesTitle],
          watchedVideoIds: currentCourseVarData?.watchedVideoIds
            ? [activeVideo.video.id, ...currentCourseVarData?.watchedVideoIds]
            : [activeVideo.video.id],
          dateLastWatched: new Date(),
          lastWatchedId: activeVideo.video.id,
        },
      };
    }
  };

  const onPlay = async () => {
    if (!currentCourseVarData?.watchedVideoIds?.some((id) => id === activeVideo.video.id)) {
      const coursesVars = getCoursesVars();
      const { data } = await updateSettings({
        variables: {
          settings: {
            coursesVars,
            skipNotificationTime: null,
            watchedVideoId: activeVideo.video.id.toString(),
          },
        },
        update: (cache, { data }) => {
          const { fetchUserSettings } = cache.readQuery({ query: FETCH_COURSES_SETTINGS }) || {};

          cache.writeQuery({
            query: FETCH_COURSES_SETTINGS,
            data: {
              // @ts-ignore
              fetchUserSettings: data?.updateUserSettings
                ? {
                    ...fetchUserSettings,
                    coursesVars: data?.updateUserSettings.coursesVars,
                  }
                : fetchUserSettings,
            },
          });
        },
      });
      sendSentryInformation(data?.updateUserSettings.coursesVars as CoursesVars);
    } else {
      await updateSettings({
        variables: {
          settings: {
            watchedVideoId: activeVideo.video.id.toString(),
          },
        },
      });
    }
  };

  const onError = () => {
    toast.error('Video hosting service is not available. Please, try again later.');
    handleClose();
  };

  return (
    <StyledModal
      centered
      getContainer={() => document.documentElement}
      closable={false}
      onCancel={handleClose}
      visible={!!activeVideo}
      footer={null}
      isLoaded={isLoaded}
      zIndex={5000}
    >
      <Vimeo
        video={activeVideo.video.url}
        responsive={true}
        autoplay={true}
        onPlay={onPlay}
        onLoaded={handleLoaded}
        speed={true}
        onError={onError}
      />
      <StyledCloseIcon onClick={handleClose} />
    </StyledModal>
  );
});

export default VideoModal;

