import { FilterDropdownProps } from 'antd/es/table/interface';
import CustomTableFilter, { getCustomTableFilterProps } from './CustomTableFilter';
import { Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getValueFromObjectWithKeys } from '../../../../../pages/AdminPanel/utils';
import { useCallback } from 'react';

const TextFilter = (filterDropDownProps: FilterDropdownProps) => {
  const { setSelectedKeys } = filterDropDownProps;
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      const { value } = target;
      if (!value) {
        setSelectedKeys([]);
      } else {
        setSelectedKeys([target.value]);
      }
    },
    [setSelectedKeys],
  );
  return (
    <CustomTableFilter {...filterDropDownProps}>
      <Input style={{ width: '100%', maxWidth: '100%' }} size="middle" onChange={handleChange} />
    </CustomTableFilter>
  );
};

export const getTextFilterProps = (
  ...dataIndexes: string[]
): Pick<ColumnsType<object>[number], 'filterDropdown' | 'filterIcon' | 'onFilter'> => ({
  ...getCustomTableFilterProps(),
  filterDropdown: TextFilter,
  onFilter: (text, record: object) => {
    const value: string = getValueFromObjectWithKeys(record, dataIndexes);
    return value.includes(text as string);
  },
});

export default TextFilter;

