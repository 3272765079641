import { Transfer } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TransferItem } from 'antd/es/transfer';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

interface IDynamicColTransferProps<T> {
  initialColumnKeys?: string[];
  columnKeys?: string[];
  baseTableColumns: ColumnsType<T>;
  setTableColumns: React.Dispatch<React.SetStateAction<ColumnsType<T>>>;
  disabledKeys?: string[];
  onKeysUpdate?: (newTargetKeys: string[]) => void;
}

const DynamicColTransfer = <T,>({
  initialColumnKeys,
  baseTableColumns,
  disabledKeys,
  setTableColumns,
  onKeysUpdate,
  columnKeys,
}: IDynamicColTransferProps<T>) => {
  const [targetKeys, setTargetKeys] = useState(initialColumnKeys ?? []);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const handleChange = useCallback(
    (newTargetKeys: string[]) => {
      const newTargetKeysSet = new Set(newTargetKeys);
      setTargetKeys(
        baseTableColumns.reduce((acc, { key }) => {
          if (typeof key === 'string' && newTargetKeysSet.has(key)) {
            acc.push(key);
          }
          return acc;
        }, [] as string[]),
      );
    },
    [baseTableColumns, setTableColumns, onKeysUpdate],
  );

  useEffect(() => {
    onKeysUpdate && onKeysUpdate(targetKeys);
  }, [onKeysUpdate, targetKeys]);

  useEffect(() => {
    columnKeys && setTargetKeys(columnKeys);
  }, [columnKeys]);

  useEffect(() => {
    const targetKeysSet = new Set(targetKeys);
    setTableColumns(baseTableColumns.filter(({ key }) => targetKeysSet.has(key as string)));
  }, [targetKeys, setTableColumns, baseTableColumns]);

  const handleSelectChange = useCallback(
    (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
      setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    },
    [],
  );

  const baseTransferData: TransferItem[] = useMemo(() => {
    const disabledKeysSet = new Set(disabledKeys ?? []);
    return (baseTableColumns as any[]).map(({ title, dataIndex, key }) => ({
      key,
      title,
      description: dataIndex,
      disabled: disabledKeysSet.has(key),
    }));
  }, [baseTableColumns, disabledKeys]);

  useEffect(() => {
    const targetKeysSet = new Set(initialColumnKeys ?? []);
    setTableColumns(baseTableColumns.filter(({ key }) => targetKeysSet.has(key as string)));
  }, [baseTableColumns, initialColumnKeys, setTableColumns]);

  return (
    <Transfer
      dataSource={baseTransferData}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      onSelectChange={handleSelectChange}
      titles={['Not Added Columns', 'Added Columns']}
      render={(item) => item.title!}
      onChange={handleChange}
    />
  );
};

export default memo(DynamicColTransfer) as typeof DynamicColTransfer;

