import { gql } from '__generated__/gql';
import './fragments';

const UPDATE_SETTINGS_STRING = /* GraphQL */ `
  mutation UpdateSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      boardColumns {
        outcomeColumn {
          ...dndColumnFields
        }
        backlogColumn {
          ...dndColumnFields
        }
        toDoColumn {
          ...dndColumnFields
        }
        doingColumn {
          ...dndColumnFields
        }
        doneColumn {
          ...dndColumnFields
        }
        noteColumn {
          ...dndColumnFields
        }
      }
      filterInput {
        workspaceId
        tagIds
      }
      timezone
      shouldUpdateStatusToComplete
      shouldUpdateStatusToCurrent
      setStartDateToToday
      setUpdateFilterInput
      defaultWorkspaceId
      tutorialPassedStep
      doneColumnFilter {
        name
        filter
        startDate
        endDate
      }
      toDoColumnFilter {
        name
        filter
        startDate
        endDate
      }
      subscriptionReminderTime
    }
  }
`;

export const UPDATE_SETTINGS = gql(UPDATE_SETTINGS_STRING);

export const UPDATE_FOCUS_TIMER_SETTINGS = gql(/* GraphQL */ `
  mutation UpdateFocusTimerSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      focusTimerFixedPeriod
      focusTimerIsBipSound
      focusTimerType
    }
  }
`);

const UPDATE_COURSE_SETTINGS_STRING = /* GraphQL */ `
  mutation UpdateCourseSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      coursesVars {
        ...coursesVarFields
      }
      coursesConsts {
        ...coursesConstFields
      }
      coursePassedStep
      skipNotificationTime
    }
  }
`;

export const UPDATE_COURSES_SETTINGS = gql(UPDATE_COURSE_SETTINGS_STRING);

export const UPDATE_USER_PREFERENCES_AND_QUESTIONNAIRE = gql(/* GraphQL */ `
  mutation UpdateUserPreferencesAndQuestionnaire($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      userPreferences {
        title
        roles
      }
      onboardingQuestionnaire {
        question
        answer
      }
    }
  }
`);

export const UPDATE_USER_ENTITY = gql(/* GraphQL */ `
  mutation UpdateUserEntity($userParams: UserInput!) {
    updateUserEntity(userParams: $userParams) {
      email
      firstName
      isFBaccountWithPhone
      lastName
      name
      isActivated
    }
  }
`);
export const UPDATE_USER_ENTITY_BY_EMAIL = gql(/* GraphQL */ `
  mutation UpdateUserEntityByEmail($email: String!, $userParams: UserInput!) {
    updateUserEntityByEmail(email: $email, userParams: $userParams) {
      firstName
      lastName
      email
      isActivated
      isFBaccountWithPhone
    }
  }
`);

export const UPDATE_USER_RECOMMENDED_COMPANY = gql(/* GraphQL */ `
  mutation UpdateUserRecommendedCompany($recommendedCompany: RecommendedCompanyInput!) {
    updateUserRecommendedCompany(recommendedCompany: $recommendedCompany)
  }
`);

export const UPDATE_USER_REMIND_DATE = gql(/* GraphQL */ `
  mutation UpdateUserRemindDate($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      recommendedCompanyRemindDate
    }
  }
`);

export const INIT_STRIPE_CUSTOMER = gql(/* GraphQL */ `
  mutation InitStripeCustomer($email: String!) {
    initStripeCustomer(email: $email) {
      id
    }
  }
`);

export const CREATE_STRIPE_SUBSCRIPTION = gql(/* GraphQL */ `
  mutation CreateStripeSubscription(
    $bmSubscriptionPlanId: String!
    $isCancelAtPeriodEnd: Boolean
    $promoCodeId: String
  ) {
    createStripeSubscription(
      bmSubscriptionPlanId: $bmSubscriptionPlanId
      isCancelAtPeriodEnd: $isCancelAtPeriodEnd
      promoCodeId: $promoCodeId
    ) {
      clientSecret
      subscriptionId
    }
  }
`);

export const CHANGE_STRIPE_SUBSCRIPTION = gql(/* GraphQL */ `
  mutation ChangeStripeSubscription(
    $newBmSubscriptionPlanId: String!
    $isCancelAtPeriodEnd: Boolean
    $promoCodeId: String
  ) {
    changeStripeSubscription(
      newBmSubscriptionPlanId: $newBmSubscriptionPlanId
      isCancelAtPeriodEnd: $isCancelAtPeriodEnd
      promoCodeId: $promoCodeId
    ) {
      clientSecret
      subscriptionId
    }
  }
`);

export const CANCEL_STRIPE_SUBSCRIPTION = gql(/* GraphQL */ `
  mutation CancelStripeSubscription($subscriptionId: String!) {
    cancelStripeSubscription(subscriptionId: $subscriptionId)
  }
`);

export const CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END = gql(/* GraphQL */ `
  mutation CancelStripeSubscriptionAtPeriodEnd {
    cancelStripeSubscriptionAtPeriodEnd {
      bmSubscription {
        amount
        bmSubscriptionPlan
        bmSubscriptionPlanId
        bmSubscriptionProvider
        createDate
        createDateFreeSubscription
        error
        expiration
        externalCustomerId
        externalSubscriptionId
        externalUnfinishedSubscriptionId
        id
        interval
        isEligableToFreeSubscription
        isTrialUsed
      }
      subscription {
        amount
        clientSecret
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        startDate
        status
        subscriptionId
      }
      unfinishedSubscription {
        amount
        clientSecret
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        startDate
        status
        subscriptionId
      }
    }
  }
`);

// For Admin

// Add/View/Edit User
export const ADMIN_SUBSCRIPTION_EXP_BY_EMAIL = gql(/* GraphQL */ `
  mutation UpdateFreeSubscriptionExpirationByEmail(
    $values: UpdateFreeSubscriptionExpirationByEmailInput!
  ) {
    updateFreeSubscriptionExpirationByEmail(values: $values) {
      expiration
    }
  }
`);
export const ADMIN_COURSES_BY_EMAIL = gql(/* GraphQL */ `
  mutation UpdateCoursesAvailableList($values: UpdateCoursesAvailableListInput!) {
    updateCoursesAvailableList(values: $values) {
      coursesAvailableList
    }
  }
`);

const ADMIN_ADD_NEW_USER_STRING = /* GraphQL */ `
  mutation AddNewUser($values: AddNewUserInput!) {
    addNewUser(values: $values) {
      email
      firstName
      lastName
      name
    }
  }
`;
export const ADMIN_ADD_NEW_USER = gql(ADMIN_ADD_NEW_USER_STRING);

export const ADMIN_UPDATE_USER_ENTITY = gql(/* GraphQL */ `
  mutation UpdateUserAsAdmin(
    $email: String!
    $userParams: UserUpdateAsAdminInput!
    $options: UserUpdateAsAdminOptionsInput
  ) {
    updateUserAsAdmin(email: $email, userParams: $userParams, options: $options) {
      email
      firstName
      isActivated
      isAdmin
      isRegistered
      lastName
      name
    }
  }
`);

const ADMIN_RESET_USER_CURRICULUM_STRING = /* GraphQL */ `
  mutation ResetUserCurriculumByEmail($email: String!) {
    resetUserCurriculumByEmail(email: $email)
  }
`;

export const ADMIN_RESET_USER_CURRICULUM = gql(ADMIN_RESET_USER_CURRICULUM_STRING);

// Company Settings
export const ADMIN_CREATE_CORPORATE_ACC = gql(/* GraphQL */ `
  mutation CreateCorporateAccount(
    $values: CorporateAccountCreateInput!
    $options: CorporateAccountOptionsInput
  ) {
    createCorporateAccount(values: $values, options: $options) {
      domain
    }
  }
`);
export const ADMIN_UPDATE_CORPORATE_ACCOUNT = gql(/* GraphQL */ `
  mutation UpdateCorporateAccount(
    $domain: String!
    $values: CorporateAccountUpdateInput!
    $options: CorporateAccountOptionsInput
  ) {
    updateCorporateAccount(domain: $domain, values: $values, options: $options) {
      domain
    }
  }
`);

export const ADMIN_DELETE_CORPORATE_ACCOUNT = gql(/* GraphQL */ `
  mutation DeleteCorporateAccount($values: CorporateAccountDeleteInput!) {
    deleteCorporateAccount(values: $values)
  }
`);

export const ADMIN_EXPIRATION_BY_DOMAIN = gql(/* GraphQL */ `
  mutation SwitchSubscriptionToSelfGuidedFreeByCorporateAccount(
    $values: SwitchSubscriptionToSelfGuidedFreeByCorporateAccountInput!
  ) {
    switchSubscriptionToSelfGuidedFreeByCorporateAccount(values: $values) {
      updatedUserList
    }
  }
`);

export const ADMIN_DELETE_USERS_BY_EMAILS = gql(/* GraphQL */ `
  mutation DeleteUsersByEmails($values: DeleteUsersByEmailsInput!) {
    deleteUsersByEmails(values: $values) {
      email
      stripe
      database
      firebase
      activeCampaign
      sharedSpaces {
        id
        name
      }
      savePrimaryUserSharedSpace
    }
  }
`);

export const ADMIN_ASSIGN_PRIMARY_OWNER = gql(/* GraphQL */ `
  mutation AssignPrimaryOwner($workspaceId: Int!, $newPrimaryOwnerEmail: String!) {
    assignPrimaryOwner(workspaceId: $workspaceId, newPrimaryOwnerEmail: $newPrimaryOwnerEmail)
  }
`);

