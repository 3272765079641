/* eslint-disable eqeqeq */
import { FormEvent, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { BmSubscriptionPlans } from '../../../graphql/types';
import { CoursesGroupByDefault, CoursesGroups, domainRegex } from 'pages/const';
import { ADMIN_UPDATE_CORPORATE_ACCOUNT } from '../../../graphql/mutations';
import { Input, Select } from 'antd';
import { FETCH_CORPORATE_ACCOUNT_BY_DOMAIN } from '../../../graphql/queries';
import devConsole from 'core/utils/devConsole';
import { findCourseByCurriculumType, CoursesGroupsSelectOpts } from '../utils';
import { OrgEditModalType } from '../OrgInfo/OrgInfo';
import {
  StyledButtonContainer,
  StyledCancelButton,
  StyledForm,
  StyledInputContainer,
  StyledLabel,
  StyledModal,
  StyledSubmitButton,
  StyledTitle,
} from '../StyleModalComponents';
import { getTime } from 'date-fns';
import { ExpirationDatePicker } from 'core/components/common/DatePicker/ExpirationDatePicker';
import CancelModal from '../CancelModal/CancelModal';
import LabeledCheckbox from '../ApplyUsersCheckbox/ApplyUsersCheckbox';
import { getSubscriptionPlanTitle } from 'features/courses/utils';

interface EditUserModalProps {
  type: OrgEditModalType | null;
  domain: string;
  onSuccess: (isDomain: boolean) => void;
  onError: () => void;
  onClose: () => void;
  onDomainUpdate: (domain: string) => void;
}

const BmSubscriptionTypesForOptions = {
  [BmSubscriptionPlans.SelfGuidedFree]: getSubscriptionPlanTitle(
    BmSubscriptionPlans.SelfGuidedFree,
  ),
  [BmSubscriptionPlans.TrialFree]: getSubscriptionPlanTitle(BmSubscriptionPlans.TrialFree),
};

const BmSubscriptionTypesForOptionsArray = Object.keys(
  BmSubscriptionTypesForOptions,
) as BmSubscriptionPlans[];

type SubscriptionPlanOption = {
  value: number;
  label: string;
};

const subscriptionPlanOptions: SubscriptionPlanOption[] = Object.keys(
  BmSubscriptionTypesForOptions,
).map((key, index) => ({
  value: index,
  label: BmSubscriptionTypesForOptions[key as keyof typeof BmSubscriptionTypesForOptions],
}));

const getSubscriptionPlanOptionBySubscriptionPlan = (subscriptionPlan: BmSubscriptionPlans) => {
  const title = getSubscriptionPlanTitle(subscriptionPlan);
  return subscriptionPlanOptions.find(({ label }) => label === title);
};

const EditOrgModal = ({
  type,
  domain,
  onSuccess,
  onError,
  onClose,
  onDomainUpdate,
}: EditUserModalProps) => {
  const { data: orgData } = useQuery(FETCH_CORPORATE_ACCOUNT_BY_DOMAIN, {
    variables: { domain },
  });

  const initialExpiration = orgData?.fetchCorporateAccountByDomain?.bmSubscriptionExpiration;
  const initialExpirationDate = initialExpiration ? new Date(initialExpiration * 1000) : null;
  const initialSubscriptionPlan = orgData?.fetchCorporateAccountByDomain?.bmSubscriptionPlan;
  const initialCourses = orgData?.fetchCorporateAccountByDomain?.coursesAvailableList;
  const initialSubscriptionPlanOption = initialSubscriptionPlan
    ? getSubscriptionPlanOptionBySubscriptionPlan(initialSubscriptionPlan)
    : undefined;
  const [updateOrgByDomain] = useMutation(ADMIN_UPDATE_CORPORATE_ACCOUNT);

  const [shouldUpdateCorporateUsers, setShouldUpdateCorporateUsers] = useState<boolean>(false);
  const [shouldOverrideCurriculumForCurrentUsers, setShouldOverrideCurriculumForCurrentUsers] =
    useState<boolean>(false);
  const [domainInput, setDomainInput] = useState<string>(domain);
  const [coursesInput, setCoursesInput] = useState(
    orgData?.fetchCorporateAccountByDomain?.coursesAvailableList ?? CoursesGroupByDefault,
  );
  const [expirationDateInput, setExpirationDateInput] = useState<Date | null>(
    initialExpirationDate,
  );
  const [subscriptionPlanInput, setSubscriptionPlanInput] = useState<
    SubscriptionPlanOption | undefined
  >(initialSubscriptionPlanOption);
  const [loading, setLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const handleUpdateOrg = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let isDomain = false;
    const options = {
      shouldUpdateCorporateUsers,
    };
    try {
      switch (type) {
        case OrgEditModalType.Domain:
          await updateOrgByDomain({
            variables: {
              domain,
              values: {
                domain: domainInput,
              },
              options,
            },
          });
          onDomainUpdate(domainInput);
          isDomain = true;
          break;
        case OrgEditModalType.CurriculumType:
          await updateOrgByDomain({
            variables: {
              domain,
              values: {
                coursesAvailableList: coursesInput,
              },
              options: {
                shouldUpdateCorporateUsers,
                shouldOverrideCurriculumForCurrentUsers:
                  shouldUpdateCorporateUsers && shouldOverrideCurriculumForCurrentUsers,
              },
            },
          });
          break;
        case OrgEditModalType.SubscriptionExpiration:
          if (!expirationDateInput || !subscriptionPlanInput) return;
          const bmSubscriptionExpiration = Math.floor(getTime(expirationDateInput) / 1000);
          await updateOrgByDomain({
            variables: {
              domain,
              values: {
                bmSubscriptionExpiration,
                bmSubscriptionPlan: BmSubscriptionTypesForOptionsArray[subscriptionPlanInput.value],
              },
              options,
            },
          });
          setExpirationDateInput(expirationDateInput);
          setSubscriptionPlanInput(subscriptionPlanInput);
          break;
        default:
          devConsole.warn(`Unknown edit modal type: ${type}`);
          return setLoading(false);
      }
      setShouldUpdateCorporateUsers(false);
      onSuccess(isDomain);
      onClose();
    } catch (error) {
      devConsole.error(error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const isEmpty = (() => {
    switch (type) {
      case OrgEditModalType.Domain:
        return !domainInput.trim().length;
      case OrgEditModalType.SubscriptionExpiration:
        return expirationDateInput == null && subscriptionPlanInput == null;
      default:
        return false;
    }
  })();

  const isModified = (() => {
    if (shouldUpdateCorporateUsers) return true;
    switch (type) {
      case OrgEditModalType.Domain:
        return domainInput !== domain;
      case OrgEditModalType.CurriculumType:
        if (!initialCourses) return true;
        return initialCourses[0] !== coursesInput[0] && initialCourses[1] !== coursesInput[1];
      case OrgEditModalType.SubscriptionExpiration:
        const isModifiedSubscriptionPlan =
          initialSubscriptionPlan && subscriptionPlanInput?.label
            ? subscriptionPlanInput.label != getSubscriptionPlanTitle(initialSubscriptionPlan)
            : subscriptionPlanInput?.value != null;
        const isModifiedExpirationDate = initialExpirationDate
          ? expirationDateInput?.toLocaleDateString() != initialExpirationDate.toLocaleDateString()
          : expirationDateInput != null;
        return isModifiedExpirationDate || isModifiedSubscriptionPlan;
      default:
        return false;
    }
  })();

  const isInvalid = (() => {
    switch (type) {
      case OrgEditModalType.Domain:
        return !domainInput.match(domainRegex);
      case OrgEditModalType.SubscriptionExpiration:
        return expirationDateInput == null || subscriptionPlanInput == null;
      case OrgEditModalType.CurriculumType:
        return coursesInput[0] === coursesInput[1];
      default:
        return false;
    }
  })();

  const isDisabled = !isModified || isEmpty || isInvalid;

  const handleOpenCancel = () => {
    if (isModified) {
      setShowCancelModal(true);
    } else {
      onClose();
    }
  };

  const handleAcceptCancel = () => {
    switch (type) {
      case OrgEditModalType.Domain:
        setDomainInput(domain);
        break;
      case OrgEditModalType.CurriculumType:
        setCoursesInput(
          orgData?.fetchCorporateAccountByDomain?.coursesAvailableList ?? CoursesGroupByDefault,
        );
        break;
      case OrgEditModalType.SubscriptionExpiration:
        setExpirationDateInput(initialExpirationDate);
        setSubscriptionPlanInput(initialSubscriptionPlanOption);
        break;
      default:
        devConsole.warn(`Unknown edit modal type: ${type}`);
        return;
    }
    setShouldUpdateCorporateUsers(false);
    setShowCancelModal(false);
    onClose();
  };

  return (
    <>
      <StyledModal
        destroyOnClose
        getContainer={() => document.body}
        visible={!!type}
        onCancel={handleOpenCancel}
        footer={null}
        mask={false}
        closable={true}
      >
        <StyledForm onSubmit={handleUpdateOrg}>
          <StyledTitle>Edit Organization Defaults</StyledTitle>
          <StyledInputContainer>
            {type &&
              {
                [OrgEditModalType.Domain]: (
                  <>
                    <p>
                      Modifying the domain can affect the users that are currently using curriculum
                      from this domain.
                    </p>
                    <StyledLabel>Domain</StyledLabel>
                    <Input
                      value={domainInput}
                      minLength={3}
                      maxLength={100}
                      placeholder={'Enter domain'}
                      onChange={(e) => setDomainInput(e.target.value)}
                    />
                  </>
                ),
                [OrgEditModalType.CurriculumType]: (
                  <>
                    <p>
                      Changing the curriculum type will cause new users from this domain to receive
                      the chosen curriculum
                    </p>
                    <p>
                      Existing users from this domain will be unaffected unless you also select the
                      box below.
                    </p>
                    <StyledLabel>Curriculum type</StyledLabel>
                    <Select
                      labelInValue
                      defaultValue={findCourseByCurriculumType(coursesInput)}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        const newCourses = CoursesGroups[value.value];
                        setCoursesInput(newCourses);
                      }}
                      options={CoursesGroupsSelectOpts}
                    />
                    <p>
                      Checking box below will only affect users that are on the default curriculum.
                      Users with individually assigned curriculums will not be affected unless
                      second checkbox is checked.
                    </p>
                  </>
                ),
                [OrgEditModalType.SubscriptionExpiration]: (
                  <>
                    <p>
                      Modifying the subscription expiration date will cause new users from this
                      domain to get the new expiration date and subscription plan.
                    </p>
                    <p>
                      Existing corporate users will keep their current subscription plan unless you
                      also check the box below.
                    </p>
                    <p>
                      Note: These settings will only be applied if you select both an expiration
                      date and a subscription plan
                    </p>
                    <StyledLabel>Expiration Date</StyledLabel>
                    <ExpirationDatePicker
                      value={expirationDateInput}
                      setExpirationDate={setExpirationDateInput}
                    />
                    <StyledLabel>Subscription Plan</StyledLabel>
                    <Select
                      labelInValue
                      value={subscriptionPlanInput}
                      options={subscriptionPlanOptions}
                      style={{ width: '100%' }}
                      onChange={(option) => {
                        if (option?.value != null) {
                          setSubscriptionPlanInput(option);
                        } else {
                          setSubscriptionPlanInput(undefined);
                        }
                      }}
                    />
                    Only users with internal subscription plan (such as Pre-Paid Plan, Free Trial
                    and Three Months Free Trial ) will be overridden
                  </>
                ),
              }[type]}
          </StyledInputContainer>
          <StyledInputContainer>
            <LabeledCheckbox
              setValue={setShouldUpdateCorporateUsers}
              value={shouldUpdateCorporateUsers}
              disabled={type === OrgEditModalType.Domain}
            />
            {type === OrgEditModalType.CurriculumType && (
              <LabeledCheckbox
                setValue={setShouldOverrideCurriculumForCurrentUsers}
                value={shouldOverrideCurriculumForCurrentUsers}
                disabled={!shouldUpdateCorporateUsers}
              >
                Override custom curriculum
              </LabeledCheckbox>
            )}
          </StyledInputContainer>
          <StyledButtonContainer>
            <StyledSubmitButton isDisabled={isDisabled} isLoading={loading}>
              Save
            </StyledSubmitButton>
            <StyledCancelButton onClick={handleOpenCancel}>Cancel</StyledCancelButton>
          </StyledButtonContainer>
        </StyledForm>
      </StyledModal>
      <CancelModal
        onConfirm={handleAcceptCancel}
        onCancel={() => setShowCancelModal(false)}
        visible={showCancelModal}
      />
    </>
  );
};

export default EditOrgModal;

