import { Input } from 'antd';
import { StyledButtonsContainer, StyledDescription } from '../StyledComponents';
import { FormEvent, memo, useCallback, useState } from 'react';
import { Button, SubmitButton } from 'core/components/common';
import { useLazyQuery } from '@apollo/client';
import { emailRegex, emailsOrIdsRegexp } from 'pages/const';
import { ADMIN_FETCH_SHARED_SPACES } from 'graphql/queries';
import devConsole from 'core/utils/devConsole';
import SharedSpacesTables from '../SharedSpacesTable/SharedSpacesTables';

interface SharedSpacesManagerProps {
  onSuccess: () => void;
  onError: () => void;
}

const SharedSpacesManager = ({ onError, onSuccess }: SharedSpacesManagerProps) => {
  const [emailsOrIdsValue, setEmailsOrIdsValue] = useState<string>('');
  const [fetchSharedSpaces, { loading, data, variables }] = useLazyQuery(
    ADMIN_FETCH_SHARED_SPACES,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  const isDisabled = !emailsOrIdsRegexp.test(emailsOrIdsValue) || loading;

  const handleSubmit = async (e: FormEvent, noPrimaryOwner = false) => {
    e.preventDefault();
    const emails: string[] = [];
    const workspaceIds: number[] = [];
    if (emailsOrIdsRegexp.test(emailsOrIdsValue) && !noPrimaryOwner) {
      const emailsOrIds = emailsOrIdsValue.split(',').map((email) => email.trim());
      for (const emailOrId of emailsOrIds) {
        if (!emailOrId) continue;
        if (emailRegex.test(emailOrId)) {
          emails.push(emailOrId);
        } else if (!isNaN(Number(emailOrId))) {
          workspaceIds.push(Number(emailOrId));
        } else {
          onError();
          return;
        }
      }
    }
    try {
      await fetchSharedSpaces({
        variables: {
          params: {
            emails,
            noPrimaryOwner,
            workspaceIds,
          },
        },
      });
      // onSuccess();
    } catch (e) {
      onError();
    }
  };

  const handleRefetch = useCallback(
    async (additionalEmailOrId?: string | number) => {
      const { emails = [], workspaceIds = [], noPrimaryOwner } = variables?.params ?? {};
      let email: string | undefined;
      let workspaceId: number | undefined;
      if (additionalEmailOrId) {
        if (typeof additionalEmailOrId === 'string' && emailRegex.test(additionalEmailOrId)) {
          email = additionalEmailOrId;
        } else if (!isNaN(Number(additionalEmailOrId))) {
          workspaceId = Number(additionalEmailOrId);
        } else {
          devConsole.warn('Invalid email or workspace ID', { emailOrId: additionalEmailOrId });
        }
      }
      try {
        await fetchSharedSpaces({
          variables: {
            params: {
              emails: email ? Array.from(new Set([...emails!, email])) : emails,
              workspaceIds: workspaceId
                ? Array.from(new Set([...workspaceIds!, workspaceId]))
                : workspaceIds,
              noPrimaryOwner,
            },
          },
        });
      } catch (e) {
        onError();
      }
    },
    [onError, fetchSharedSpaces, variables],
  );

  return (
    <div>
      <StyledDescription>
        Manage shared spaces for users by email or workspace ID, separated by commas
      </StyledDescription>
      <form onSubmit={handleSubmit}>
        <Input
          value={emailsOrIdsValue}
          onChange={(e) => setEmailsOrIdsValue(e.target.value)}
          bordered
          size="large"
          placeholder="user email(s) or workspace ID(s)"
          name="emails"
        />
        <StyledButtonsContainer>
          <Button
            isSecondary
            isLoading={loading}
            disabled={loading}
            isDisabled={loading}
            onClick={(e) => {
              handleSubmit(e, true);
            }}
          >
            Search Workspaces without PO
          </Button>
          <SubmitButton isLoading={loading} disabled={isDisabled}>
            Search
          </SubmitButton>
        </StyledButtonsContainer>
      </form>
      {data && (
        <SharedSpacesTables
          dataSource={data.fetchSharedWorkspaces}
          isLoading={loading}
          onError={onError}
          onSuccess={onSuccess}
          onRefetch={handleRefetch}
        />
      )}
    </div>
  );
};

export default memo(SharedSpacesManager);

