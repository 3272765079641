import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ConfirmModal } from '../../../../common/components/ConfirmModal';
import { useMutation, useQuery } from '@apollo/client';
import { REMOVE_USER_FROM_WORKSPACE } from '../../../graphql/mutations';
import { FETCH_WORKSPACE_INVITES_BY_IDS } from '../../../graphql/queries';
import { manageWorkspacesMutation } from '../../../../../apollo/stateFields/manageWorkspaces';
import {
  MANAGE_WORKSPACES_STATE,
  ManageWorkspacesValue,
} from '../../../../../apollo/stateFields/manageWorkspaces/manageWorkspacesField';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';

const StyledConfirmModal = styled(ConfirmModal)`
  && {
    .ant-modal-body > div {
      label:last-child {
        margin-top: 20px;
      }
    }
    justify-content: center;
    z-index: 1111;
  }
`;

const StyledMiddleContent = styled.p`
  && {
    text-align: center;
    margin: 0;
  }
`;

const RemoveFromWorkspaceModal = React.memo(() => {
  const { data: manageWorkspacesData } = useQuery(MANAGE_WORKSPACES_STATE);
  const { userToRemove }: ManageWorkspacesValue = manageWorkspacesData?.manageWorkspaces;
  const { setUserToRemove } = manageWorkspacesMutation;
  const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];

  const refetchQueries = userToRemove?.workspace
    ? [
        {
          query: FETCH_WORKSPACE_INVITES_BY_IDS,
          variables: {
            workspaceIds: workspaces.map((w) => w.id),
          },
        },
      ]
    : [];
  const [removeUser, { loading }] = useMutation(REMOVE_USER_FROM_WORKSPACE, {
    variables: {
      email: userToRemove?.email!,
      workspaceId: userToRemove?.workspace?.id!,
    },
    refetchQueries,
  });

  const handleRemove = useCallback(async () => {
    removeUser();
    setUserToRemove(null);
  }, [removeUser, userToRemove]);

  if (!userToRemove) {
    return null;
  }

  return (
    <StyledConfirmModal
      mask={false}
      getContainer={() => document.body}
      destroyOnClose
      closable={true}
      visible={!!userToRemove}
      title={`Do you want to remove ${userToRemove?.name || userToRemove?.email} from this Space?`}
      middleContent={
        <StyledMiddleContent>
          This user will lose access to this Space and will need a new invitation to rejoin it
        </StyledMiddleContent>
      }
      isConfirmLoading={loading}
      confirmText="Remove"
      cancelText={'Cancel'}
      onCancel={() => setUserToRemove(null)}
      onOk={() => {
        !loading && handleRemove();
      }}
    />
  );
});

export default RemoveFromWorkspaceModal;
