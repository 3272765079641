import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import theme from 'core/styles/styled-components/theme';
import { Action } from 'core/types/action';
import { sendEvent } from 'core/integrations/sentry/events';
import { getActualActionDates } from 'core/utils/action';
import { EntityType, getCacheEntityById } from '../../../utils';
import { OutcomeCardColumn } from '../../../outcomes/components/OutcomeCardColumn';
import { ActionCardColumns } from './ActionCardColumns/ActionCardColumns';
import { Note } from '../../../../core/types/note';
import { UPDATE_ACTION } from '../../graphql/mutations';
import { Outcome, OutcomeStatus } from '../../../../core/types/outcome';
import { getNowDate } from '../../../../core/utils/date';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../common/graphql/queries';
import { updateActionOutcome } from '../../../../core/utils/outcome';
import { formatISO, parseISO, startOfDay } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { useUserWorkspaceContext } from '../../../../context/userWorkspaceContext';
import { ReactComponent as LoadingIcon } from '../../../../core/svg/loading.svg';
import { getUpdateFuncForUpdateAction } from '../../../interactions/action/getUpdateFuncForUpdateAction';
import {
  getCacheWorkspaceByEntityId,
  getCacheWorkspaceById,
} from '../../../utils/getCacheEntityById';
import { Permission, Workspace } from '../../../../core/types/workspace';
import useConvertNoteToAction from '../../../interactions/note/useConvertNoteToAction';
import useConvertNoteToOutcome from '../../../interactions/note/useConvertNoteToOutcome';
import { Column, ColumnType } from '../../../../apollo/stateFields/dnd/dndFields';
import usePollDataOnActivity from '../../../../hooks/usePollDataOnActivity';
import { FILTER_USER_WORKSPACES } from '../../../workspaces/graphql/queries';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import { boardEditedEntityMutation } from '../../../../apollo/stateFields/boardEditedEntity';
import { completeMutation } from '../../../../apollo/stateFields/complete';
import { TUTORIAL_STATE, TutorialValue } from 'apollo/stateFields/tutorial/tutorialField';
import ActionBoardModals from './ActionBoardModals/ActionBoardModals';
import { allowedPathnames } from '../../../../core/components/layout';
import NewInformation from './NewInformation/NewInformation';
import { useHistory } from 'react-router-dom';
import { IHeaderHistory } from '../../../../core/components/layout/Header/types';
import { dndDispatch } from '../../../../context/dndContext/dndContext';
import { changeEntityIds } from '../../../../context/dndContext/DndActions';
import useDndContext from '../../../../context/dndContext/useDndContext';
import { FETCH_STRIPE_PRICES, FETCH_STRIPE_SUBSCRIPTION } from '../../../../graphql/queries';
import { FETCH_FOCUS_TIMER } from '../../graphql/queries';
import { highlightedEntitiesMutation } from '../../../../apollo/stateFields/highlightedEnntities';
import { ActionStatus, Workspace as WorkspaceGenerated } from '__generated__/graphql';
import { MockedFetchStripePrices } from 'graphql/types';

const StyledContainer = styled.div<{ isHighlight: boolean }>`
  height: calc(var(--app-height) - 110px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #d2eff6 13.09%, #d8fdd5 98.89%);

  @media ${theme.device.tablet.max} {
    padding-top: 110px;
    height: var(--app-height);

    ${({ isHighlight }) =>
      isHighlight &&
      css`
        padding-top: 0;
      `}
  }

  @media ${theme.device.mobile.max} {
    padding-top: 110px;

    ${({ isHighlight }) =>
      isHighlight &&
      css`
        padding-top: 0;
      `}
  }
`;

const StyledBoardWrapper = styled.div<{ isHighlight: boolean }>`
  display: flex;
  height: 100%;

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      .column-wrapper {
        .action-card-column {
          height: 80px;
          margin-top: 11px;
          padding: 6px 8px 0 8px;
          z-index: 2000;
          border-top: 1px dashed #ffffff;
          border-bottom: 1px dashed #ffffff;
          background-color: #d4f4ea;
        }
      }

      .column-wrapper:last-child {
        .action-card-column {
          border-right: 1px dashed #ffffff;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .column-wrapper:nth-child(2) {
        .action-card-column {
          border-left: 1px dashed #ffffff;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    `};
`;

const StyledContent = styled.div`
  overflow-y: hidden; //overflow: auto; before
  overflow-x: auto;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  @media ${theme.device.mobile.min} {
    height: calc(var(--app-height) - 55px);
    padding: 0 16px 0 0;
  }

  @media ${theme.device.tablet.min} {
    height: calc(100vh - 90px);
    padding: 0 24px 15px 0;
  }

  @media ${theme.device.desktop.min} {
    height: calc(100vh - 74px);
    padding: 0 24px 0 0;
  }

  @media ${theme.device.hugeDesktop.min} {
    overflow: visible;
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }
`;

const StyledInnerContainer = styled.div`
  height: 100%;
`;

const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  transition: background-color 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  z-index: 9999999999;
`;

const ActionBoard = React.memo(() => {
  const { workspaces: filterWorkspaces, loading } = useUserWorkspaceContext();
  const { boardColumns, currentOutcomeColumnStatus, changingSpaceEntityId, fetchAllLoading } =
    useDndContext();
  const dispatch = dndDispatch;
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { isNewInformationDrawerOpen }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
  const { setIsNewInformationDrawerOpen, setDataChangeLinkModal } = boardEditedEntityMutation;
  const apolloClient = useApolloClient();
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { currentStep: currentTutorialStep }: TutorialValue = tutorialData?.tutorial;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const {
    defaultWorkspaceId,
    unassignedWorkspaceId,
    shouldUpdateStartDate: startDateToToday,
    isSettingsLoaded: settingsLoadDone,
  }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { data: focusTimerData } = useQuery(FETCH_FOCUS_TIMER);
  const { setHoveredOutcomeId } = highlightedEntitiesMutation;
  const { startPolling, stopPolling, refetch } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  usePollDataOnActivity(startPolling, stopPolling, refetch, 60000);

  const [updateAction, { loading: updateActionLoading }] = useMutation(UPDATE_ACTION);
  const [noteToConvert, setNoteToConvert] = useState<null | Note>(null);
  const [convertNoteToAction] = useConvertNoteToAction(noteToConvert);
  const [convertNoteToOutcome] = useConvertNoteToOutcome(noteToConvert);
  const [getBmSubscription] = useLazyQuery(FETCH_STRIPE_SUBSCRIPTION, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [getStripePrices] = useLazyQuery<MockedFetchStripePrices>(FETCH_STRIPE_PRICES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [sourceDragStartColumn, setSourceDragStartColumn] = useState<string | null>(null);
  const [actionIdMoved, setActionIdMoved] = useState<number | null>(null);
  const [isStartDateConfirmModalVisible, setIsStartDateConfirmModalVisible] = useState(false);
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const history = useHistory<IHeaderHistory>();
  const pathname = history.location.pathname.slice(1);
  const newInformationEl = useMemo(
    () => allowedPathnames.includes(pathname) && <NewInformation />,
    [pathname],
  );

  const updateActionStatus = useCallback(
    async (id: number, prevStatus: string, newStatus: string, isSetToToday: boolean = true) => {
      const action = getCacheEntityById(apolloClient, id, EntityType.ACTION, filterInput) as Action;
      const [actualStartDate, actualEndDate] = getActualActionDates(
        prevStatus,
        newStatus,
        isSetToToday,
        action?.actualStartDate,
      );

      if (filterInput.length) {
        const result = apolloClient
          .readQuery({
            query: FILTER_USER_WORKSPACES,
            variables: { filters: filterInput },
          })
          ?.filterUserWorkspaces.map((workspace) => {
            if (workspace.id === action.workspaceId) {
              const actions = workspace.actions?.map((a) => {
                if (a.id === action.id) {
                  return {
                    ...a,
                    status: newStatus,
                    ...(actualStartDate !== undefined && { actualStartDate }),
                    ...(actualEndDate !== undefined && { actualEndDate }),
                  };
                }
                return a;
              });
              return {
                ...workspace,
                actions,
              };
            }
            return workspace;
          });

        if (result) {
          apolloClient.writeQuery({
            query: FILTER_USER_WORKSPACES,
            data: { filterUserWorkspaces: result as WorkspaceGenerated[] },
            variables: { filters: filterInput },
          });
        }
      }

      const actionValues = {
        id,
        status: newStatus as ActionStatus,
        ...(actualStartDate !== undefined && { actualStartDate }),
        ...(actualEndDate !== undefined && { actualEndDate }),
      };
      await updateAction({
        variables: { actionValues, workspaceId: action?.workspaceId || defaultWorkspaceId },
        update: getUpdateFuncForUpdateAction(
          apolloClient,
          action as Action,
          filterInput,
          setOutcomeToComplete,
          setOutcomeToIncomplete,
        ),
        refetchQueries: [
          ...(focusTimerData?.fetchFocusTimer?.actionId === action.id ? [FETCH_FOCUS_TIMER] : []),
        ],
      });
    },
    [
      apolloClient,
      updateAction,
      defaultWorkspaceId,
      filterInput,
      focusTimerData?.fetchFocusTimer?.actionId,
    ],
  );

  const onActionDragStart = useCallback(
    (start: DragStart) => {
      setHoveredOutcomeId(null);
      setSourceDragStartColumn(start.source.droppableId);
      const { draggableId, source } = start;

      if (source.droppableId === ColumnType.Note) {
        setNoteToConvert(
          getCacheEntityById(apolloClient, +draggableId, EntityType.NOTE, filterInput) as Note,
        );
      }
    },
    [setSourceDragStartColumn],
  );

  const onActionDragEnd = useCallback(
    async (result: DropResult) => {
      setHoveredOutcomeId(null);
      const { draggableId, source, destination, combine } = result;

      setSourceDragStartColumn(null);

      const entityWorkspace = getCacheWorkspaceByEntityId(
        apolloClient,
        +draggableId,
        filterInput,
      ) as Workspace;

      // @ts-ignore
      if (combine && !(entityWorkspace?.permission === Permission.Viewer)) {
        if (source.droppableId === combine.droppableId) {
          return;
        }
        if (combine.draggableId) {
          const combineWorkspace = getCacheWorkspaceByEntityId(
            apolloClient,
            +combine.draggableId,
            filterInput,
          ) as Workspace;

          // if (combineWorkspace?.permission === Permission.VIEWER) {
          //   return;
          // }
          if (
            +combine.draggableId === changingSpaceEntityId ||
            +draggableId === changingSpaceEntityId
          ) {
            return;
          }
          if (
            combineWorkspace?.permission === Permission.Viewer
            // ((combineWorkspace?.permission === Permission.EDITOR ||
            //   entityWorkspace?.permission === Permission.EDITOR) &&
            //   combineWorkspace.id !== entityWorkspace.id)
          ) {
            return;
          }
        }

        if (source.droppableId === ColumnType.Outcome) {
          const outcomeId = +draggableId;
          const actionId = +combine.draggableId;
          const outcomeNew = getCacheEntityById(
            apolloClient,
            outcomeId,
            EntityType.OUTCOME,
            filterInput,
          ) as Outcome;
          let outcomeOld = null;

          if (outcomeNew.actions?.find((action) => action.id === actionId)) {
            return;
          }

          const action = getCacheEntityById(
            apolloClient,
            actionId,
            EntityType.ACTION,
            filterInput,
          ) as Action;
          const actionValues = updateActionOutcome(action, outcomeId);
          let variables: any = {
            actionValues,
            workspaceId: action.workspaceId,
          };

          if (action.workspaceId !== outcomeNew.workspaceId) {
            variables.targetWorkspaceId = outcomeNew.workspaceId;
          }

          if (action.outcome) {
            outcomeOld = getCacheEntityById(
              apolloClient,
              action.outcome.id,
              EntityType.OUTCOME,
              filterInput,
            ) as Outcome;
          }

          handleToggleChangeLinkModal({
            type: EntityType.OUTCOME,
            variables: variables,
            callback: handleToggleChangeLinkModal,
            filterInput: filterInput,
            actionId,
            outcomeNew,
            outcomeOld,
          });
          return;
        }
        if (source.droppableId !== ColumnType.Note) {
          const action = getCacheEntityById(
            apolloClient,
            +draggableId,
            EntityType.ACTION,
            filterInput,
          ) as Action;
          const outcomeId = +combine.draggableId;
          const outcomeNew = getCacheEntityById(
            apolloClient,
            outcomeId,
            EntityType.OUTCOME,
            filterInput,
          ) as Outcome;
          let outcomeOld = null;

          if (action?.outcome && action.outcome.id === outcomeId) {
            return false;
          }

          if (action.outcome) {
            outcomeOld = getCacheEntityById(
              apolloClient,
              action.outcome.id,
              EntityType.OUTCOME,
              filterInput,
            ) as Outcome;
          }

          const actionValues = updateActionOutcome(action, outcomeId);
          const variables: any = {
            actionValues,
            workspaceId: action.workspaceId,
          };

          if (action.workspaceId !== outcomeNew.workspaceId) {
            variables.targetWorkspaceId = outcomeNew.workspaceId;
          }

          handleToggleChangeLinkModal({
            type: EntityType.ACTION,
            variables: variables,
            callback: handleToggleChangeLinkModal,
            filterInput: filterInput,
            actionId: +draggableId,
            outcomeNew,
            outcomeOld,
          });
        }
        return;
      }

      if (
        (!destination && source.droppableId === ColumnType.Note) ||
        (source.droppableId === ColumnType.Note &&
          destination?.droppableId === ColumnType.Note &&
          !isNewInformationDrawerOpen)
      ) {
        setIsNewInformationDrawerOpen(true);
        return;
      }

      if (!destination) {
        return;
      }

      if (
        source.droppableId === ColumnType.Outcome &&
        destination.droppableId !== ColumnType.Outcome
      ) {
        return;
      }

      if (
        destination.droppableId === ColumnType.Outcome &&
        source.droppableId !== ColumnType.Outcome &&
        source.droppableId !== ColumnType.Note
      ) {
        return;
      }

      if (source.droppableId === destination.droppableId && destination.index === source.index) {
        return;
      }
      if (destination.droppableId === ColumnType.Note && source.droppableId !== ColumnType.Note) {
        return;
      }

      const start: Column = Object.values(boardColumns).find(
        (column) => column.title === source.droppableId,
      );
      const finish: Column = Object.values(boardColumns).find(
        (column) => column.title === destination.droppableId,
      );

      if (start === finish) {
        const newEntityIds = start.entityIds.slice();
        newEntityIds.splice(source.index, 1);
        newEntityIds.splice(destination.index, 0, +draggableId);
        dispatch(changeEntityIds({ entityIds: newEntityIds, columnTitle: start.title }));
        sendEvent('dnd_order_update', 'Order update', {
          'Action ID': +draggableId,
          Destination: { status: destination.droppableId },
          Source: { status: source.droppableId },
        });
        return;
      }

      if (entityWorkspace?.permission === Permission.Viewer) {
        return;
      }

      const updateOnColumnChange = () => {
        const startEntityIds = start.entityIds.slice();
        const finishEntityIds = finish.entityIds.slice();
        startEntityIds.splice(source.index, 1);
        finishEntityIds.splice(destination.index, 0, +draggableId);
        dispatch(changeEntityIds({ entityIds: startEntityIds, columnTitle: start.title }));
        dispatch(changeEntityIds({ entityIds: finishEntityIds, columnTitle: finish.title }));
      };

      if (
        destination.droppableId !== ColumnType.Note &&
        destination.droppableId !== ColumnType.Outcome &&
        source.droppableId === ColumnType.Note
      ) {
        let noteToConvertAction = getCacheEntityById(
          apolloClient,
          +draggableId,
          EntityType.NOTE,
          filterInput,
        );

        if (
          !entityWorkspace &&
          getCacheWorkspaceById(apolloClient, noteToConvertAction?.workspaceId)?.permission ===
            Permission.Viewer
        ) {
          return;
        }

        if (noteToConvertAction) {
          const actualStartDate =
            destination.droppableId === ColumnType.Doing ||
            destination.droppableId === ColumnType.Done
              ? formatISO(startOfDay(parseISO(getNowDate())))
              : null;

          const actualEndDate =
            destination.droppableId === ColumnType.Done
              ? formatISO(startOfDay(parseISO(getNowDate())))
              : null;

          const workspaceId =
            noteToConvertAction.workspaceId === unassignedWorkspaceId
              ? defaultWorkspaceId
              : noteToConvertAction.workspaceId;

          const responce = await convertNoteToAction({
            variables: {
              workspaceId: noteToConvertAction?.workspaceId,
              noteId: noteToConvertAction.id,
              action: {
                name: noteToConvertAction.name,
                description: noteToConvertAction.description,
                updateDate: noteToConvertAction.updateDate,
                status: destination.droppableId.toUpperCase() as ActionStatus,
                actualStartDate,
                actualEndDate,
                workspaceId,
              },
            },
          });

          if (responce.data) {
            const newId = responce.data?.convertNoteToUserWorkspaceAction.id;
            const newIds = finish.entityIds.slice();
            if (newId) {
              newIds.splice(destination.index, 0, +newId);
              dispatch(
                changeEntityIds({
                  entityIds: newIds,
                  columnTitle: finish.title,
                }),
              );
            }
            setIsNewInformationDrawerOpen(true);
            sendEvent('dnd_convert_action', 'Convert note to action');
          }
        }
        return;
      }

      if (
        destination.droppableId === ColumnType.Outcome &&
        source.droppableId === ColumnType.Note
      ) {
        let noteToConvertOutcome = getCacheEntityById(
          apolloClient,
          +draggableId,
          EntityType.NOTE,
          filterInput,
        );

        if (
          !entityWorkspace &&
          getCacheWorkspaceById(apolloClient, noteToConvertOutcome?.workspaceId)?.permission ===
            Permission.Viewer
        ) {
          return;
        }

        if (noteToConvertOutcome) {
          const workspaceId =
            noteToConvertOutcome.workspaceId === unassignedWorkspaceId
              ? defaultWorkspaceId
              : noteToConvertOutcome.workspaceId;

          const responce = await convertNoteToOutcome({
            variables: {
              workspaceId: noteToConvertOutcome.workspaceId,
              noteId: noteToConvertOutcome.id,
              outcome: {
                name: noteToConvertOutcome.name,
                description: noteToConvertOutcome.description,
                status: currentOutcomeColumnStatus,
                startDate:
                  currentOutcomeColumnStatus !== OutcomeStatus.Future ? getNowDate() : null,
                endDate:
                  currentOutcomeColumnStatus === OutcomeStatus.Completed ? getNowDate() : null,
                completeDate:
                  currentOutcomeColumnStatus === OutcomeStatus.Completed ? getNowDate() : null,
                workspaceId,
              },
            },
          });

          if (responce.data) {
            const newId = responce.data?.convertNoteToUserWorkspaceOutcome.id;
            const newIds = finish.entityIds.slice();
            if (newId) {
              newIds.splice(destination.index, 0, +newId);
              dispatch(
                changeEntityIds({
                  entityIds: newIds,
                  columnTitle: finish.title,
                }),
              );
            }
            setIsNewInformationDrawerOpen(true);
            sendEvent('dnd_convert_outcome', 'Convert note to outcome');
          }
        }
        return;
      }

      if (source.droppableId === ColumnType.Done && destination.droppableId === ColumnType.Doing) {
        setActionIdMoved(+draggableId);
        updateOnColumnChange();
        if (startDateToToday !== null) {
          await updateActionStatus(
            +draggableId,
            source.droppableId.toUpperCase(),
            destination.droppableId.toUpperCase(),
            startDateToToday,
          );
          sendEvent('dnd_status_update', 'Action change status', {
            'Action ID': +draggableId,
            Destination: { status: destination.droppableId },
            Source: { status: source.droppableId },
          });
          return;
        }
        setIsStartDateConfirmModalVisible(true);
        sendEvent('dnd_status_update', 'Action change status', {
          'Action ID': +draggableId,
          Destination: { status: destination.droppableId },
          Source: { status: source.droppableId },
        });
        return;
      }
      updateOnColumnChange();
      await updateActionStatus(
        +draggableId,
        source.droppableId.toUpperCase(),
        destination.droppableId.toUpperCase(),
      );
      sendEvent('dnd_status_update', 'Action change status', {
        'Action ID': +draggableId,
        Destination: { status: destination.droppableId },
        Source: { status: source.droppableId },
      });
    },
    [
      updateActionStatus,
      startDateToToday,
      boardColumns,
      convertNoteToOutcome,
      convertNoteToAction,
      filterInput,
      currentOutcomeColumnStatus,
      isNewInformationDrawerOpen,
      noteToConvert,
      changingSpaceEntityId,
    ],
  );

  const handleToggleChangeLinkModal = useCallback((value = null) => {
    setDataChangeLinkModal(value);
  }, []);

  const firstLoadDone = useRef<boolean>(false);

  useEffect(() => {
    if (!firstLoadDone.current && settingsLoadDone && !fetchAllLoading) {
      firstLoadDone.current = true;
    }
    if (firstLoadDone.current && settingsLoadDone) {
      setIsLoading(loading || fetchAllLoading);
    }
  }, [loading, filterWorkspaces, settingsLoadDone, fetchAllLoading]);

  useEffect(() => {
    if (containerRef.current && !isLoading && !isDesktop) {
      const container = containerRef.current as HTMLDivElement;
      if (currentTutorialStep === 0) {
        const el = document.querySelector(`[data-tutorial="0"]`) as HTMLElement;
        container.scrollLeft = el.getBoundingClientRect().left;
      }
      if (currentTutorialStep === 1) {
        container.scrollLeft = 0;
      }
    }
  }, [containerRef, isLoading, currentTutorialStep]);

  useEffect(() => {
    getBmSubscription();
    getStripePrices();
  }, []);

  return (
    <>
      <DragDropContext onDragStart={onActionDragStart} onDragEnd={onActionDragEnd}>
        <StyledContainer
          data-id="board"
          data-testid="board"
          isHighlight={currentTutorialStep === 2 || currentTutorialStep === 4}
        >
          <StyledContent ref={containerRef}>
            <StyledInnerContainer>
              {isLoading ? (
                <StyledLoaderContainer>
                  <LoadingIcon />
                </StyledLoaderContainer>
              ) : (
                <>
                  <StyledBoardWrapper isHighlight={currentTutorialStep === 0}>
                    <OutcomeCardColumn
                      sourceDragStartColumn={sourceDragStartColumn}
                      isHighlight={currentTutorialStep === 1}
                      isDropDisabled={isNewInformationDrawerOpen}
                      isCombineEnabled={
                        sourceDragStartColumn !== ColumnType.Outcome &&
                        sourceDragStartColumn !== ColumnType.Note
                      }
                      isMovingDisabled={
                        sourceDragStartColumn !== ColumnType.Outcome &&
                        sourceDragStartColumn !== ColumnType.Note
                      }
                    />
                    <ActionCardColumns
                      isDropDisabled={isNewInformationDrawerOpen}
                      isCombineEnabled={sourceDragStartColumn === ColumnType.Outcome}
                      isMovingDisabled={sourceDragStartColumn === ColumnType.Outcome}
                      isDragDisabled={updateActionLoading}
                    />
                  </StyledBoardWrapper>
                </>
              )}
            </StyledInnerContainer>
          </StyledContent>
        </StyledContainer>
        {newInformationEl}
      </DragDropContext>
      <ActionBoardModals
        updateActionStatus={updateActionStatus}
        actionIdMoved={actionIdMoved}
        isStartDateConfirmModalVisible={isStartDateConfirmModalVisible}
        setIsStartDateConfirmModalVisible={setIsStartDateConfirmModalVisible}
      />
    </>
  );
});

export { ActionBoard };

