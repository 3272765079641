import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Outcome } from 'core/types/outcome';
import { UNARCHIVE_OUTCOME } from 'features/outcomes/graphql/mutations';
import { FETCH_OUTCOMES } from 'features/outcomes/graphql/queries';
import { OperationType, updatedCacheData } from 'features/utils';
import { FETCH_ACTIONS } from 'features/actions/graphql/queries';
import { mutateOutcome } from 'context/userWorkspaceContext';
import { FILTER_USER_WORKSPACES } from '../../workspaces/graphql/queries';
import { capitalize } from 'lodash-es';
import { Action } from '../../../core/types/action';
import { ColumnType } from 'apollo/stateFields/dnd/dndFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { updateEntityId } from '../../../context/dndContext/DndActions';

const useUnarchiveOutcome = (outcomeToUnarchive: Outcome | null) => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const dispatch = dndDispatch;

  return useMutation(UNARCHIVE_OUTCOME, {
    refetchQueries: [
      ...(!!filterInput.length
        ? [{ query: FILTER_USER_WORKSPACES, variables: { filters: filterInput } }]
        : []),
      { query: FETCH_ACTIONS, variables: { workspaceId: outcomeToUnarchive?.workspaceId } },
    ],
    update(cache, { data }) {
      if (outcomeToUnarchive === null) {
        return;
      }
      const { fetchUserWorkspaceOutcomes = [] } =
        cache.readQuery({
          query: FETCH_OUTCOMES,
          variables: {
            workspaceId: data?.unarchiveUserWorkspaceOutcome.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceActions = [] } =
        cache.readQuery({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: data?.unarchiveUserWorkspaceOutcome.workspaceId,
          },
        }) || {};

      cache.writeQuery({
        query: FETCH_OUTCOMES,
        data: {
          fetchUserWorkspaceOutcomes: updatedCacheData(
            fetchUserWorkspaceOutcomes,
            data?.unarchiveUserWorkspaceOutcome as Outcome,
            OperationType.CREATE,
          ),
        },
        variables: {
          workspaceId: data?.unarchiveUserWorkspaceOutcome.workspaceId,
        },
      });

      cache.writeQuery({
        query: FETCH_ACTIONS,
        data: {
          fetchUserWorkspaceActions: (data?.unarchiveUserWorkspaceOutcome.actions?.length
            ? [...fetchUserWorkspaceActions, ...data?.unarchiveUserWorkspaceOutcome.actions]
            : fetchUserWorkspaceActions) as Action[],
        },
        variables: {
          workspaceId: data?.unarchiveUserWorkspaceOutcome.workspaceId,
        },
      });

      if (data?.unarchiveUserWorkspaceOutcome) {
        if (
          !data.unarchiveUserWorkspaceOutcome.isArchived &&
          outcomeToUnarchive.isArchived &&
          data.unarchiveUserWorkspaceOutcome.actions?.length
        ) {
          data.unarchiveUserWorkspaceOutcome.actions.forEach((action) => {
            dispatch(
              updateEntityId({
                entityId: action.id,
                columnTitle: capitalize(action.status.toLowerCase()) as ColumnType,
              }),
            );
          });
          dispatch(
            updateEntityId({
              entityId: data.unarchiveUserWorkspaceOutcome.id,
              columnTitle: ColumnType.Outcome,
            }),
          );
        }

        if (
          !data.unarchiveUserWorkspaceOutcome.isArchived &&
          outcomeToUnarchive.isArchived &&
          !data.unarchiveUserWorkspaceOutcome.actions?.length
        ) {
          dispatch(
            updateEntityId({
              entityId: data.unarchiveUserWorkspaceOutcome.id,
              columnTitle: ColumnType.Outcome,
            }),
          );
        }

        mutateOutcome(
          apolloClient,
          data.unarchiveUserWorkspaceOutcome as Outcome,
          'unarchive',
          filterInput,
          outcomeToUnarchive,
        );
      }
    },
  });
};

export default useUnarchiveOutcome;

