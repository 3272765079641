import { useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../features/common/graphql/queries';
import { FETCH_WORKSPACE_INVITES_BY_IDS } from '../features/workspaces/graphql/queries';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../apollo/stateFields/userSettings/userSettingsField';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FetchWorkspaceInvitesByIdsQuery } from '__generated__/graphql';

const useWorkspaceInvites = () => {
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const { data, loading } = useQuery(FETCH_WORKSPACE_INVITES_BY_IDS, {
    fetchPolicy: 'cache-first',
    variables: {
      workspaceIds: workspacesResponse?.fetchUserWorkspacesWithInvitedCount.length
        ? workspacesResponse?.fetchUserWorkspacesWithInvitedCount.map((w) => w.id)
        : [defaultWorkspaceId],
    },
  });
  const savedWorkspacesWithCount = useRef<
    | {
        count: number;
        users: FetchWorkspaceInvitesByIdsQuery['fetchWorkspaceInvitesByIds'][number]['users'];
        workspaceId: number;
      }[]
    | null
  >(null);
  const [updatedWorkspaces, setUpdatedWorkspaces] = useState<number>(0);

  const getWorkspaceInvitesById = useCallback(
    (id: number) => {
      return savedWorkspacesWithCount?.current?.find(
        (workspaceInvite) => workspaceInvite.workspaceId === id,
      );
    },
    [updatedWorkspaces],
  );

  const getIsShared = useCallback(
    (id: number) => {
      const workspace = savedWorkspacesWithCount?.current?.find(
        (workspaceInvite) => workspaceInvite.workspaceId === id,
      );
      return !!workspace?.count && workspace?.count > 1;
    },
    [updatedWorkspaces],
  );

  useEffect(() => {
    if (!!data?.fetchWorkspaceInvitesByIds) {
      savedWorkspacesWithCount.current = data?.fetchWorkspaceInvitesByIds;
      setUpdatedWorkspaces((old) => old + 1);
    }
  }, [data?.fetchWorkspaceInvitesByIds]);

  return {
    workspacesInvites: savedWorkspacesWithCount?.current,
    loading,
    getWorkspaceInvitesById,
    getIsShared,
  };
};

export default useWorkspaceInvites;

