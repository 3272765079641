import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../../core/styles/styled-components';
import { Button } from 'antd';
import { ReactComponent as FeatureIcon } from '../../img/plan-tip.svg';
import { ReactComponent as PercentArrow } from '../../img/save-percent-arrow.svg';
import { useQuery } from '@apollo/client';
import {
  PLANS_TYPES,
  STRIPE_STATE,
  StripeValue,
} from '../../../apollo/stateFields/stripe/stripeFields';
import { getPlanPriceInfo, getUserCountByTitle } from '../../utils';
import {
  BmSubscriptionPlans,
  MockedFetchStripePrices,
  StripePriceExpanded,
  StripeSubscriptionIntervals,
} from '../../../graphql/types';
import { FETCH_STRIPE_PRICES, FETCH_STRIPE_SUBSCRIPTION } from '../../../graphql/queries';
import { format } from 'date-fns';
import { stripeMutation } from '../../../apollo/stateFields/stripe';
import {
  getOppositeDataPrice,
  getSaveAmount,
  getUpgradeDowngradeStatus,
  UpgradeDowngradeStatus,
} from '../utils';
import { ConfirmModal } from '../../../features/common/components/ConfirmModal';

const StyledCardContainer = styled.div<{
  isActive?: boolean;
  isProfessional?: boolean;
  isBusiness?: boolean;
  isForbidden?: boolean;
}>`
  position: relative;
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-dark-blue);
  margin-bottom: 20px;
  overflow: visible;

  background: linear-gradient(312.15deg, #f4fcff 0%, #ffffff 100%);
  border: 1px solid var(--color-main-light-grey);
  border-radius: 12px;

  ${({ isActive, isBusiness }) =>
    isActive &&
    !isBusiness &&
    css`
      background: linear-gradient(312.15deg, #f4fcff 0%, #ffffff 100%),
        linear-gradient(312.15deg, #f1f3f7 0%, #ffffff 100%);
      border: 1px solid var(--color-blue);
    `}
  ${({ isProfessional }) =>
    isProfessional &&
    css`
      background: linear-gradient(312.15deg, #e1f5fe 0%, #f2fbff 100%);
      border: 1px solid var(--color-main-light-grey);
    `}
  ${({ isBusiness }) =>
    isBusiness &&
    css`
      background: linear-gradient(312.15deg, #faf8ff 0%, #ffffff 100%);
      border: 1px solid var(--color-main-light-grey);
    `}
  ${({ isBusiness, isActive }) =>
    isBusiness &&
    isActive &&
    css`
      background: linear-gradient(312.15deg, #faf8ff 0%, #ffffff 100%);
      border: 1px solid #7340dd;
    `}
  ${({ isForbidden }) =>
    isForbidden &&
    css`
      background: transparent;
      border: 1px solid var(--color-main-light-grey);
    `}

  @media ${theme.device.tablet.min} {
    width: 100%;
  }

  @media ${theme.device.desktop.min} {
    width: 32%;
    //max-width: 450px;
    min-width: 350px;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const StyledCardTitle = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  @media ${theme.device.tablet.min} {
    font-size: 28px;
    line-height: 32px;
    justify-content: flex-start;
    padding: 0 28px;
    text-align: left;
  }

  @media ${theme.device.desktop.min} {
    margin-bottom: 16px;
    padding: 0 38px;
    justify-content: center;
    text-align: center;
    min-height: 64px;
  }
`;
const StyledCardSubTitle = styled.div<{ planType: PLANS_TYPES; isForbidden?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: ${({ planType, isForbidden }) =>
    isForbidden
      ? 'var(--color-grey)'
      : planType === PLANS_TYPES.Individual
      ? 'var(--color-blue)'
      : '#7340DD'};

  @media ${theme.device.tablet.min} {
    font-size: 14px;
    line-height: 18px;
    justify-content: flex-start;
    padding: 0 28px;
    text-align: left;
    margin-bottom: 0;
  }

  @media ${theme.device.desktop.min} {
    margin-bottom: 20px;
    padding: 0 38px;
    justify-content: center;
    text-align: center;
    min-height: 36px;
  }
`;
const StyledBillingInfoWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${theme.device.tablet.min} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 28px;
    margin-top: 20px;
  }

  @media ${theme.device.desktop.min} {
    padding: 0;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: center;
  }
`;
const StyledPrice = styled.div<{ withOpposite: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${theme.device.tablet.min} {
    width: 49.5%;
    justify-content: flex-start;
    flex-direction: row;

    ${({ withOpposite }) =>
      withOpposite &&
      css`
        align-items: flex-start;
        flex-direction: column;
      `}
  }

  @media ${theme.device.desktop.min} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledCurrentPrice = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
`;

const StyledCrossedPrice = styled(StyledCurrentPrice)`
  font-weight: 400;
  text-decoration-line: line-through;
  margin-right: 8px;
`;

const StyledPriceInfo = styled.div`
  margin: 6px 0 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  @media ${theme.device.tablet.min} {
    margin: 0 0 0 8px;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media ${theme.device.desktop.min} {
    margin: 6px 0 0 8px;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledAdditionalSaveInfoWrapper = styled.div<{ withOpposite?: boolean }>`
  @media ${theme.device.desktop.min} {
    ${({ withOpposite }) =>
      withOpposite &&
      css`
        min-height: 34px;
      `}
  }
`;

const StyledAdditionalSaveInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 10px;
  margin-top: 10px;
  margin-left: -15px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  background: #ffc227;
  border-radius: 4px 0 0 4px;

  @media ${theme.device.tablet.min} {
    margin-left: 0;
  }

  @media ${theme.device.desktop.min} {
    margin-left: -15px;
  }
`;

const StyledPurchase = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${theme.device.tablet.min} {
    width: 49.5%;
    justify-content: space-between;
  }

  @media ${theme.device.desktop.min} {
    width: 100%;
    justify-content: center;
  }
`;
const StyledPurchaseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 48px;
  width: 100%;
  background-color: transparent;
  border-radius: 36px;
  color: var(--color-dark-blue);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border-color: var(--color-dark-blue);

  @media ${theme.device.mobile.min} {
    margin: 20px 20px 0 20px;
  }
  @media ${theme.device.tablet.min} {
    margin: 0;
  }
  @media ${theme.device.desktop.min} {
    margin: 20px 30px 0;
  }
`;
const StyledPurchaseInfo = styled.div<{ isBusiness?: boolean }>`
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(32, 180, 243, 0.1);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  ${({ isBusiness }) =>
    isBusiness &&
    css`
      background: rgba(115, 64, 221, 0.1);
    `}

  @media ${theme.device.mobile.min} {
    margin-top: 20px;
  }
  @media ${theme.device.tablet.min} {
    margin-top: 0;
  }
  @media ${theme.device.desktop.min} {
    margin-top: 20px;
  }
`;
const StyledForbiddenInfo = styled(StyledPurchaseInfo)`
  padding: 15px 8px;
  background: rgba(164, 166, 173, 0.2);
`;
const StyledForbiddenInfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const StyledPurchaseExpiration = styled.div`
  margin-left: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
const StyledPlanTip = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px 0 54px;
  margin-top: 12px;

  @media ${theme.device.tablet.min} {
    padding: 0 28px 0 62px;
  }

  @media ${theme.device.desktop.min} {
    padding: 0 30px 0 54px;
  }
`;
const StyledFeatureIcon = styled(({ planType, isForbidden, ...rest }) => (
  <FeatureIcon {...rest} />
))<{ planType: PLANS_TYPES; isForbidden?: boolean }>`
  position: absolute;
  left: 25px;
  top: 3px;
  color: ${({ planType, isForbidden }) =>
    isForbidden
      ? 'var(--color-grey)'
      : planType === PLANS_TYPES.Individual
      ? 'var(--color-blue)'
      : '#7340DD'};
`;
const StyledUserCount = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  padding: 0 6px;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  background: #7340dd;
  color: var(--color-white);
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: center;
`;

const StyledPopularLabel = styled.div<{ isPopular?: boolean; isBestValue?: boolean }>`
  position: absolute;
  top: -10px;
  padding: 4px 8px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  background: #15c75c;
  border-radius: 4px;

  ${({ isPopular }) =>
    isPopular &&
    css`
      background: #eb1770;
    `}

  ${({ isBestValue }) =>
    isBestValue &&
    css`
      background: #15c75c;
    `}

  @media ${theme.device.tablet.min} {
    top: 16px;
    right: 16px;
  }

  @media ${theme.device.desktop.min} {
    top: -10px;
    right: unset;
  }
`;

const StyledPercentArrow = styled(PercentArrow)<{ isForbidden?: boolean }>`
  position: absolute;
  right: -25px;
  background-color: #fff;

  ${({ isForbidden }) =>
    isForbidden &&
    css`
      background-color: var(--color-main-grey);
    `}
`;

const AvailablePlanCard = ({ cardItem }: { cardItem: StripePriceExpanded }) => {
  const { amount, metadata, bmSubscriptionPlan, interval } = cardItem;
  const { features, title, description } = metadata;
  const [showModal, setShowModal] = useState<boolean>(false);
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { plansVariant, billingVariant, isSubscriptionExpired }: StripeValue = stripeData?.stripe;
  const { data: userSubscriptionData } = useQuery(FETCH_STRIPE_SUBSCRIPTION);
  const { data } = useQuery<MockedFetchStripePrices>(FETCH_STRIPE_PRICES);
  const isForTeams = plansVariant === PLANS_TYPES.Teams;
  const userCount = getUserCountByTitle(title);

  const priceInfo = getPlanPriceInfo(cardItem, billingVariant);
  const priceInUsd = amount !== 0 ? amount / 100 : 0;
  const price =
    interval === StripeSubscriptionIntervals.Year &&
    bmSubscriptionPlan !== BmSubscriptionPlans.CertificationPackageTeams &&
    bmSubscriptionPlan !== BmSubscriptionPlans.FlexibleWorkProfessionalIndividual
      ? priceInUsd / 12
      : priceInUsd;
  const { setShowStripeModal, setShowStripeType, setIsForSubscriptionChange } = stripeMutation;

  const changeStatus = useMemo(() => {
    return getUpgradeDowngradeStatus(
      userSubscriptionData?.fetchStripeSubscription?.bmSubscription,
      cardItem,
    );
  }, [userSubscriptionData?.fetchStripeSubscription?.bmSubscription, cardItem]);
  const isForbidden = changeStatus === UpgradeDowngradeStatus.Forbidden && !isSubscriptionExpired;
  // const isActive =
  //   userSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlanId ===
  //   bmSubscriptionPlanId;
  const isActive = changeStatus === UpgradeDowngradeStatus.Same && !isSubscriptionExpired;

  const isChangeable =
    changeStatus === UpgradeDowngradeStatus.Upgrade ||
    changeStatus === UpgradeDowngradeStatus.Downgrade ||
    userSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan ===
      BmSubscriptionPlans.SelfGuidedFree;

  const handlePurchase = () => {
    if (
      isChangeable &&
      !isSubscriptionExpired &&
      userSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan !==
        BmSubscriptionPlans.TrialFree
    ) {
      setShowModal(true);
    } else {
      setShowStripeType(cardItem.bmSubscriptionPlan);
      setShowStripeModal(true);
    }
  };

  const handleCheckout = () => {
    setIsForSubscriptionChange(cardItem.bmSubscriptionPlanId);
    setShowStripeType(cardItem.bmSubscriptionPlan);
    setShowModal(false);
    setShowStripeModal(true);
  };

  const oppositePrice = getOppositeDataPrice(
    data?.fetchStripePrices,
    cardItem.bmSubscriptionPlan,
    cardItem.interval,
  );

  return (
    <>
      <StyledCardContainer
        isActive={isActive}
        isProfessional={title === 'Remote Work Professional'}
        isBusiness={isForTeams}
        isForbidden={isForbidden}
      >
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardSubTitle planType={plansVariant} isForbidden={isForbidden}>
          {description}
        </StyledCardSubTitle>
        <StyledBillingInfoWrapper>
          <StyledPrice
            withOpposite={
              !!(oppositePrice && cardItem.interval === StripeSubscriptionIntervals.Year)
            }
            style={
              cardItem.bmSubscriptionPlan === BmSubscriptionPlans.EnterpriseTeams
                ? { fontWeight: '400' }
                : {}
            }
          >
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {oppositePrice && cardItem.interval === StripeSubscriptionIntervals.Year && (
                <StyledCrossedPrice>{'$' + oppositePrice}</StyledCrossedPrice>
              )}
              <StyledCurrentPrice>
                {cardItem.bmSubscriptionPlan === BmSubscriptionPlans.EnterpriseTeams
                  ? 'Price on request'
                  : '$' + price}{' '}
              </StyledCurrentPrice>
              <StyledPriceInfo>{priceInfo}</StyledPriceInfo>
            </div>
            <StyledAdditionalSaveInfoWrapper
              withOpposite={billingVariant === StripeSubscriptionIntervals.Year}
            >
              {oppositePrice && cardItem.interval === StripeSubscriptionIntervals.Year && (
                <StyledAdditionalSaveInfo>
                  Save $
                  {getSaveAmount(
                    data?.fetchStripePrices,
                    cardItem.bmSubscriptionPlan,
                    cardItem.interval,
                  )}{' '}
                  per year
                  <StyledPercentArrow isForbidden={isForbidden} />
                </StyledAdditionalSaveInfo>
              )}
            </StyledAdditionalSaveInfoWrapper>
          </StyledPrice>
          <StyledPurchase>
            {isForbidden && !isSubscriptionExpired ? (
              <StyledForbiddenInfo>
                <StyledForbiddenInfoText>
                  You are already subscribed to a more advanced plan
                </StyledForbiddenInfoText>
              </StyledForbiddenInfo>
            ) : !isActive ? (
              <StyledPurchaseButton onClick={handlePurchase}>
                {bmSubscriptionPlan === BmSubscriptionPlans.EnterpriseTeams
                  ? 'Contact Us'
                  : 'Buy Plan'}
              </StyledPurchaseButton>
            ) : (
              <StyledPurchaseInfo isBusiness={isForTeams}>
                Current Plan
                <StyledPurchaseExpiration>
                  {+userSubscriptionData!.fetchStripeSubscription?.bmSubscription?.expiration! === 0
                    ? 'Free for life'
                    : userSubscriptionData?.fetchStripeSubscription?.subscription
                        ?.isCancelAtPeriodEnd === false
                    ? `Renews on: ${format(
                        +userSubscriptionData!.fetchStripeSubscription?.bmSubscription
                          ?.expiration! * 1000,
                        'MMM dd, yyyy',
                      )}`
                    : `Active until: ${format(
                        +userSubscriptionData!.fetchStripeSubscription?.bmSubscription
                          ?.expiration! * 1000,
                        'MMM dd, yyyy',
                      )}`}
                </StyledPurchaseExpiration>
              </StyledPurchaseInfo>
            )}
          </StyledPurchase>
        </StyledBillingInfoWrapper>
        <>
          {features.map((tip, id) => {
            return (
              <StyledPlanTip key={tip.slice(0, 5) + id}>
                <StyledFeatureIcon planType={plansVariant} isForbidden={isForbidden} />
                {tip}
              </StyledPlanTip>
            );
          })}
        </>
        {userCount && <StyledUserCount>{userCount}</StyledUserCount>}
        {cardItem?.metadata?.label && (
          <StyledPopularLabel
            isPopular={cardItem?.metadata?.label === 'Most Popular'}
            isBestValue={cardItem?.metadata?.label === 'Best Value'}
          >
            {cardItem?.metadata?.label}
          </StyledPopularLabel>
        )}
      </StyledCardContainer>
      {isChangeable && (
        <ConfirmModal
          closable
          cancelText={'Cancel'}
          visible={showModal}
          title={'Change my plan?'}
          confirmText={'Go to checkout'}
          middleContent={
            <StyledDescription>
              This will replace your existing plan. You will still have access to all of your data.
            </StyledDescription>
          }
          onCancel={() => setShowModal(false)}
          onOk={handleCheckout}
        />
      )}
    </>
  );
};

export default AvailablePlanCard;

