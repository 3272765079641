import { ColumnsType } from 'antd/es/table';
import { CoursesTitle } from '__generated__/graphql';
import {
  getDefaultTypeMessage,
  getSubscriptionPlanTitle,
  getTitle,
  subscriptionPlanTitles,
} from 'features/courses/utils';
import { format } from 'date-fns';
import { getDateRangerFilterProps } from '../../../core/components/common/DynamicTable/CustomTableFilter/DateRangeFilter';
import { getTextFilterProps } from '../../../core/components/common/DynamicTable/CustomTableFilter/TextFilter';
import { ExportTableUser } from './types';
import { keyBy } from 'lodash-es';
import { ReactNode } from 'react';
import { TablePaginationConfig } from 'antd';

export const TRANSFORM_FN_BY_KEY: {
  [k in keyof ExportTableUser]?: ColumnsType<ExportTableUser>[number]['render'];
} = {
  coursesAvailableList: (course) => course && getTitle(course),
  expiration: (dateTimes) =>
    dateTimes &&
    `From ${format(dateTimes[0] * 1000, 'MMM dd, yyyy')} to ${format(
      dateTimes[1] * 1000,
      'MMM dd, yyyy',
    )}`,
  createDate: (dateTimes) =>
    dateTimes &&
    `From ${format(dateTimes[0] * 1000, 'MMM dd, yyyy')} to ${format(
      dateTimes[1] * 1000,
      'MMM dd, yyyy',
    )}`,
};

const TableCell = (props: string | { children?: ReactNode }) => {
  if (!props) return null;
  let children: ReactNode;
  if (typeof props === 'object') {
    children = props.children;
  } else {
    children = props;
  }
  return <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{children}</span>;
};

export const BASE_COLUMNS: ColumnsType<ExportTableUser> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    width: 300,
    filteredValue: null,
    sortOrder: null,
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    width: 250,
    filteredValue: null,
    sortOrder: null,
    render: TableCell,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
    width: 250,
    filteredValue: null,
    sortOrder: null,
    render: TableCell,
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    sorter: true,
    width: 200,
    filteredValue: null,
    sortOrder: null,
    render: TableCell,
    ...getTextFilterProps('domain'),
  },
  {
    title: 'Subscription Plan',
    dataIndex: 'bmSubscriptionPlan',
    key: 'bmSubscriptionPlan',
    // sorter: true,
    width: 300,
    filteredValue: null,
    sortOrder: null,
    render: (bmSubscriptionPlan: ExportTableUser['bmSubscriptionPlan']) => (
      <TableCell>{bmSubscriptionPlan && getSubscriptionPlanTitle(bmSubscriptionPlan)}</TableCell>
    ),
    filters: Object.entries(subscriptionPlanTitles).map(([key, value]) => ({
      value: key,
      text: value,
    })),
  },
  {
    title: 'Expiry Date',
    dataIndex: 'expiration',
    key: 'expiration',
    sorter: true,
    width: 200,
    filteredValue: null,
    sortOrder: null,
    render: (expiration: ExportTableUser['expiration']) => (
      <TableCell>{expiration && format(expiration * 1000, 'MMM dd, yyyy')}</TableCell>
    ),
    ...getDateRangerFilterProps('expiration'),
  },
  {
    title: 'Curriculum',
    dataIndex: 'coursesAvailableList',
    key: 'coursesAvailableList',
    // sorter: true,
    width: 300,
    filteredValue: null,
    sortOrder: null,
    render: (courses: ExportTableUser['coursesAvailableList']) => (
      <TableCell>{courses && `${getTitle(courses[0])} / ${getTitle(courses[1])}`}</TableCell>
    ),
    filters: Object.values(CoursesTitle).map((apiName) => ({
      value: apiName,
      text: getTitle(apiName),
    })),
  },
  {
    title: 'Curriculum Default Type',
    dataIndex: 'defaultType',
    key: 'defaultType',
    // sorter: true,
    width: 150,
    filteredValue: null,
    sortOrder: null,
    render: (defaultType: ExportTableUser['defaultType']) => (
      <TableCell>{getDefaultTypeMessage(defaultType!)}</TableCell>
    ),
  },
  {
    title: 'Created Date',
    dataIndex: 'createDate',
    key: 'createDate',
    sorter: true,
    width: 200,
    filteredValue: null,
    sortOrder: null,
    render: (createDate: ExportTableUser['createDate']) => (
      <TableCell>{createDate && format(createDate * 1000, 'MMM dd, yyyy')}</TableCell>
    ),
    ...getDateRangerFilterProps('createDate'),
  },
];

export const BASE_COLUMNS_BY_KEY = keyBy(BASE_COLUMNS, 'key');

export const INITIAL_COLUMN_KEYS = [
  'email',
  'firstName',
  'lastName',
  'domain',
  'bmSubscriptionPlan',
  'expiration',
  'coursesAvailableList',
];

export const DISABLED_KEYS = ['email'];

export const INITIAL_PAGINATION: TablePaginationConfig = {
  pageSize: 5,
  total: 0,
  current: 1,
  showTotal: (total) => <span>Found {total} users</span>,
  pageSizeOptions: ['5', '10', '20', '50', '100'],
};

