import { gql } from '__generated__/gql';

const WORKSPACE_FIELDS_STRING = /* GraphQL */ `
  fragment workspaceFields on Workspace {
    name
    description
    id
    permission
    isNewShared
  }
`;

export const WORKSPACE_FIELDS = gql(WORKSPACE_FIELDS_STRING);

const WORKSPACE_USER_FIELDS_STRING = /* GraphQL */ `
  fragment userFields on User {
    #    assigneeActions
    #    assigneeOutcomes
    createDate
    email
    #    externalAccount
    externalIdentity
    firstName
    id
    invitedUsersIds
    isActivated
    lastName
    name
    permission
    #    role
    updateDate
    #    workspaces
  }
`;

export const WORKSPACE_USER_FIELDS = gql(WORKSPACE_USER_FIELDS_STRING);

const USER_INVITE_FIELDS_STRING = /* GraphQL */ `
  fragment userInviteFields on FetchUserInvitesResult {
    createDate
    description
    id
    inviteFromUser {
      ...userFields
    }
    name
    permission
    updateDate
    usersCount
  }
`;

export const USER_INVITE_FIELDS = gql(USER_INVITE_FIELDS_STRING);

const WORKSPACE_USER_INVITE_FIELDS_STRING = /* GraphQL */ `
  fragment workspaceUserInviteFields on AcceptInviteResult {
    email
    externalIdentity
    firstName
    id
    isAcceptedInvite
    isActivated
    lastName
    name
    permission
    workspaceId
    workspace {
      id
      name
      description
      permission
      usersCount
      isNewShared
      createDate
      actions {
        name
        id
        status
      }
      outcomes {
        id
        name
        status
      }
      notes {
        id
        name
      }
    }
  }
`;

export const WORKSPACE_USER_INVITE_FIELDS = gql(WORKSPACE_USER_INVITE_FIELDS_STRING);

