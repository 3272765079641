import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Action } from 'core/types/action';
import { mutateAction } from 'context/userWorkspaceContext';
import { FETCH_ACTIONS } from 'features/actions/graphql/queries';
import { UPDATE_ACTION } from 'features/actions/graphql/mutations';
import {
  checkIsShownEntity,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from 'features/utils';
import { capitalize } from 'lodash-es';
import { checkActionForComplete } from '../utils';
import { ColumnType } from '../../../apollo/stateFields/dnd/dndFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { completeMutation } from '../../../apollo/stateFields/complete';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { addEntityId, deleteEntityId } from '../../../context/dndContext/DndActions';

const useUpdateMoveAction = (actionToUpdate: Action | null) => {
  const apolloClient = useApolloClient();
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;
  const dispatch = dndDispatch;

  return useMutation(UPDATE_ACTION, {
    update(cache, { data }) {
      if (actionToUpdate === null) {
        return;
      }

      const { fetchUserWorkspaceActions: oldWorkspaceActions = [] } =
        cache.readQuery({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: actionToUpdate.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceActions: newWorkspaceActions = [] } =
        cache.readQuery({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: data?.updateUserWorkspaceAction.workspaceId,
          },
        }) || {};

      let oldWorkspaceResult = oldWorkspaceActions;
      let newWorkspaceResult = newWorkspaceActions;

      if (data?.updateUserWorkspaceAction.isArchived) {
        oldWorkspaceResult = updatedCacheData(
          oldWorkspaceActions,
          data?.updateUserWorkspaceAction,
          OperationType.DELETE,
        );
      } else if (data?.updateUserWorkspaceAction.workspaceId === actionToUpdate.workspaceId) {
        oldWorkspaceResult = updatedCacheData(
          oldWorkspaceActions,
          data?.updateUserWorkspaceAction,
          OperationType.UPDATE,
        );
      } else if (data?.updateUserWorkspaceAction.workspaceId !== actionToUpdate.workspaceId) {
        oldWorkspaceResult = updatedCacheData(
          oldWorkspaceActions,
          data?.updateUserWorkspaceAction,
          OperationType.DELETE,
        );
        newWorkspaceResult = updatedCacheData(
          newWorkspaceActions,
          data?.updateUserWorkspaceAction,
          OperationType.CREATE,
        );
      }

      cache.writeQuery({
        query: FETCH_ACTIONS,
        data: {
          fetchUserWorkspaceActions: oldWorkspaceResult,
        },
        variables: {
          workspaceId: actionToUpdate.workspaceId,
        },
      });

      data?.updateUserWorkspaceAction.workspaceId !== actionToUpdate.workspaceId &&
        cache.writeQuery({
          query: FETCH_ACTIONS,
          data: {
            fetchUserWorkspaceActions: newWorkspaceResult,
          },
          variables: {
            workspaceId: data?.updateUserWorkspaceAction.workspaceId,
          },
        });

      if (data?.updateUserWorkspaceAction) {
        const operation = data.updateUserWorkspaceAction.isArchived ? 'remove' : 'update';
        if (
          operation === 'update' &&
          data.updateUserWorkspaceAction.status !== actionToUpdate.status &&
          !actionToUpdate.isArchived
        ) {
          dispatch(
            deleteEntityId({
              entityId: actionToUpdate.id,
              columnTitle: capitalize(actionToUpdate.status.toLowerCase()) as ColumnType,
            }),
          ),
            dispatch(
              addEntityId({
                entityId: data.updateUserWorkspaceAction.id,
                columnTitle: capitalize(
                  data.updateUserWorkspaceAction.status.toLowerCase(),
                ) as ColumnType,
              }),
            );
        } else if (!data.updateUserWorkspaceAction.isArchived && actionToUpdate.isArchived) {
          dispatch(
            addEntityId({
              entityId: data.updateUserWorkspaceAction.id,
              columnTitle: capitalize(
                data.updateUserWorkspaceAction.status.toLowerCase(),
              ) as ColumnType,
            }),
          );
        } else if (data.updateUserWorkspaceAction.isArchived && !actionToUpdate.isArchived) {
          dispatch(
            deleteEntityId({
              entityId: data.updateUserWorkspaceAction.id,
              columnTitle: capitalize(
                data.updateUserWorkspaceAction.status.toLowerCase(),
              ) as ColumnType,
            }),
          );
        }

        mutateAction(
          apolloClient,
          data.updateUserWorkspaceAction as Action,
          operation,
          filterInput,
          actionToUpdate,
        );
        operation === 'update' &&
          updateWorkspaceTagsData(apolloClient, data?.updateUserWorkspaceAction as Action);

        if (
          !checkIsShownEntity(data.updateUserWorkspaceAction as Action, filterInput) &&
          data.updateUserWorkspaceAction.workspaceId !== actionToUpdate.workspaceId
        ) {
          setUpdateFilterInputId({
            type: 'Action',
            workspaceId: data.updateUserWorkspaceAction.workspaceId!,
            id: data.updateUserWorkspaceAction.id,
          });
        }
        if (data?.updateUserWorkspaceAction?.outcome) {
          checkActionForComplete(
            setOutcomeToComplete,
            setOutcomeToIncomplete,
            data?.updateUserWorkspaceAction as Action,
          );
        }
      }
    },
  });
};

export default useUpdateMoveAction;

