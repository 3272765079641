import { DeletedUsersResults, DomainChangeAction } from '__generated__/graphql';
import { CoursesTitle } from 'apollo/stateFields/courses/coursesFields';
import { BmSubscriptionPlans } from 'graphql/types';

export interface UserData {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  expiration?: number | null;
  bmSubscriptionPlan?: BmSubscriptionPlans | null;
  coursesAvailableList?: CoursesTitle[] | null;
  newEmail?: string | null;
  changeEmailAction?: DomainChangeAction | null;
}

export const userDataKeys: (keyof UserData)[] = [
  'email',
  'firstName',
  'lastName',
  'expiration',
  'bmSubscriptionPlan',
  'coursesAvailableList',
  'newEmail',
  'changeEmailAction',
];

export interface UserErrorReason {
  property: keyof UserData;
  message: string;
  value: any;
}

export interface FailedUserInfo {
  data: UserData;
  reasons: UserErrorReason[];
}

export interface UploadUserResponse {
  created: UserData[];
  updated: UserData[];
  skipped: FailedUserInfo[];
  failed: FailedUserInfo[];
}

export interface DeletedUserResponse {
  deleted: DeletedUsersResults[];
  failed: FailedUserInfo[];
}
