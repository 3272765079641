import { useCallback, useEffect, useState } from 'react';
import { StyledButtonsContainer, StyledDescription, StyledTabContainer } from '../StyledComponents';
import { Collapse, Input, Select } from 'antd';
import { toast } from 'react-toastify';
import { Button } from '../../../core/components/common';
import { emailRegex, CoursesGroups } from '../../const';
import UserInfo from '../UserInfo/UserInfo';
import { useMutation } from '@apollo/client';
import {
  ADMIN_COURSES_BY_EMAIL,
  ADMIN_SUBSCRIPTION_EXP_BY_EMAIL,
} from '../../../graphql/mutations';
import AddUser from '../AddUser/AddUser';
import { CoursesGroupDefaultValue, CoursesGroupsSelectOpts } from '../utils';
import DeleteUsers from '../DeleteUsers/DeleteUsers';
import SharedSpacesManager from '../SharedSpacesManager/SharedSpacesManager';

const { Panel } = Collapse;

const AddViewEditUser = () => {
  const [changeExpirationByEmail, { loading: changeExpirationByEmailLoading }] = useMutation(
    ADMIN_SUBSCRIPTION_EXP_BY_EMAIL,
  );
  const [changeCoursesByEmail, { loading: changeCoursesByEmailLoading }] =
    useMutation(ADMIN_COURSES_BY_EMAIL);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  // First section
  const [field1, setField1] = useState('');
  const [field2, setField2] = useState(CoursesGroupDefaultValue);
  const firstSubmitDisabled = !field1.length && !emailRegex.test(field1);
  const setOopsMessage = useCallback((message?: string) => {
    setErrorMessage(message ?? 'Ooops');
  }, []);
  const setWasSuccessful = useCallback(() => {
    setIsSuccess(true);
  }, []);
  const handleFirstSectionSubmit = async () => {
    changeCoursesByEmail({
      variables: {
        values: {
          email: field1,
          coursesAvailableList: CoursesGroups[field2.value],
        },
      },
    })
      .then(() => {
        setWasSuccessful();
      })
      .catch(() => {
        setOopsMessage();
      });
  };
  const handleFirstSectionReset = () => {
    setField1('');
    setField2(CoursesGroupDefaultValue);
  };
  // Third section
  const [field5, setField5] = useState('');
  const thirdSubmitDisabled = !field5.length || !emailRegex.test(field5);

  const handleThirdSectionSubmit = () => {
    changeExpirationByEmail({
      variables: {
        values: { email: field5, expiration: Math.floor((Date.now() + 3.154e10) / 1000) },
      },
    })
      .then(() => {
        setWasSuccessful();
      })
      .catch(() => {
        setOopsMessage();
      });
  };
  const handleThirdSectionReset = () => {
    setField5('');
  };

  // Fourth section
  const [field6, setField6] = useState('');
  const [field7, setField7] = useState(CoursesGroupDefaultValue);
  const forthSubmitDisabled = !field6.length && !emailRegex.test(field6);
  const handleForthSectionSubmit = async () => {
    changeCoursesByEmail({
      variables: {
        values: {
          email: field6,
          coursesAvailableList: CoursesGroups[field7.value],
        },
      },
    })
      .then(() => {
        setWasSuccessful();
      })
      .catch(() => {
        setOopsMessage();
      });
  };
  const handleForthSectionReset = () => {
    setField6('');
    setField7(CoursesGroupDefaultValue);
  };

  // Fifth section
  const [field8, setField8] = useState('');
  const [showUserInfo, setShowUserInfo] = useState(false);
  const fifthSubmitDisabled = !field8.length && !emailRegex.test(field8);
  const handleFifthSectionSubmit = async () => {
    setShowUserInfo(true);
  };
  const handleFifthSectionReset = () => {
    setField8('');
    setShowUserInfo(false);
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (isSuccess) {
      toast.success('Successful');
      setIsSuccess(false);
    }
  }, [isSuccess]);

  return (
    <StyledTabContainer>
      <Collapse>
        <Panel header="Change (and Reset) Curriculum" key="1">
          <StyledDescription>
            Change curriculum option gives Admin possibility to enrol a user on a different
            curriculum. Admin has to chose curriculum and add user’s email. The user will start a
            new curriculum from scratch, all progress on the previous curriculum is lost.
          </StyledDescription>
          <Input
            value={field1}
            onChange={(e) => setField1(e.target.value)}
            bordered
            size="large"
            placeholder="user email"
          />
          <Select
            labelInValue
            value={field2}
            style={{ width: '100%' }}
            onChange={(option) => {
              setField2(option);
            }}
            options={CoursesGroupsSelectOpts}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleFirstSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={changeCoursesByEmailLoading}
              isDisabled={firstSubmitDisabled}
              onClick={handleFirstSectionSubmit}
              disabled={firstSubmitDisabled}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
        <Panel header="Assign to one year Free Plan" key="3">
          <StyledDescription>
            Self-Guided Free Plan is a Free 1 year plan that gives users the ability to access the
            tool for a year. Admin has to add email of user(s) to be set on a plan.
          </StyledDescription>
          <Input
            value={field5}
            onChange={(e) => setField5(e.target.value)}
            bordered
            size="large"
            placeholder="user email"
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleThirdSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={changeExpirationByEmailLoading}
              isDisabled={thirdSubmitDisabled}
              onClick={handleThirdSectionSubmit}
              disabled={thirdSubmitDisabled}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
        <Panel header="Pre-allocate curriculum" key="4">
          <StyledDescription>
            Pre-allocate curriculum option gives Admin possibility to assign a specific curriculum
            to a user. Admin has to choose curriculum and add user email. When user signs up into
            the tool, he is already enrolled in a specific curriculum.
          </StyledDescription>
          <Input
            value={field6}
            onChange={(e) => setField6(e.target.value)}
            bordered
            size="large"
            placeholder="user email"
          />
          <Select
            labelInValue
            value={field7}
            style={{ width: '100%' }}
            onChange={(option) => {
              setField7(option);
            }}
            options={CoursesGroupsSelectOpts}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleForthSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={changeCoursesByEmailLoading}
              isDisabled={forthSubmitDisabled}
              onClick={handleForthSectionSubmit}
              disabled={forthSubmitDisabled}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
        <Panel header="View and Edit user" key="5">
          <StyledDescription>
            View user profile option gives Admin possibility to view and edit user details. Admin
            can edit user's name, surname, free subscription expiry date and curriculum type.
          </StyledDescription>
          <Input
            value={field8}
            onChange={(e) => {
              setShowUserInfo(false);
              setField8(e.target.value);
            }}
            bordered
            size="large"
            placeholder="user email"
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleFifthSectionReset}>
              Reset
            </Button>
            <Button
              isDisabled={fifthSubmitDisabled}
              onClick={handleFifthSectionSubmit}
              disabled={fifthSubmitDisabled}
            >
              Search
            </Button>
          </StyledButtonsContainer>
          {field8 && showUserInfo && (
            <UserInfo
              email={field8}
              onSuccess={setWasSuccessful}
              onError={setOopsMessage}
              onEmailUpdate={setField8}
            />
          )}
        </Panel>
        <Panel header="Add user" key="6">
          <StyledDescription>Add user</StyledDescription>
          <AddUser onSuccess={setWasSuccessful} onError={setOopsMessage} />
        </Panel>
        <Panel header="Delete user(s)" key="7">
          <DeleteUsers onError={setOopsMessage} onSuccess={setWasSuccessful} />
        </Panel>
        <Panel header="Manage shared workspaces" key="8">
          <SharedSpacesManager onError={setOopsMessage} onSuccess={setWasSuccessful} />
        </Panel>
      </Collapse>
    </StyledTabContainer>
  );
};

export default AddViewEditUser;

