import { gql, makeVar } from '@apollo/client';
import { BmSubscriptionPlans, StripeSubscriptionIntervals } from '../../../graphql/types';

export enum PLANS_TYPES {
  Individual = 'Individual',
  Teams = 'Teams',
}

export interface StripeValue {
  plansVariant: PLANS_TYPES;
  billingVariant: StripeSubscriptionIntervals;
  showStripeModal: boolean | 'hide';
  showStripeType: BmSubscriptionPlans | null;
  showPurchaseInfoModal: null | 'success' | 'fail';
  isCardDataFulfilled: boolean;
  errorMessage: string;
  expiredSubscriptionText: string;
  isSubscriptionExpired: boolean;
  isSubscriptionLoading: boolean;
  isSubscriptionCanceled: boolean;
  isSubscriptionExpiredTrial: boolean | null;
  isForSubscriptionChange: string | null;
}

export const stripeInitialValue: StripeValue = {
  plansVariant: PLANS_TYPES.Individual,
  billingVariant: StripeSubscriptionIntervals.Year,
  showStripeModal: false,
  showStripeType: null,
  showPurchaseInfoModal: null,
  isCardDataFulfilled: false,
  errorMessage: '',
  expiredSubscriptionText: '',
  isSubscriptionExpired: false,
  isSubscriptionLoading: true,
  isSubscriptionCanceled: false,
  isSubscriptionExpiredTrial: null,
  isForSubscriptionChange: null,
};

export const stripeVar = makeVar<StripeValue>(stripeInitialValue);
export const STRIPE_STATE = gql`
  query Stripe {
    stripe @client
  }
`;

