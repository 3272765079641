import { Checkbox, Input } from 'antd';
import { StyledButtonsContainer, StyledDescription } from '../StyledComponents';
import { FormEvent, memo, useEffect, useState } from 'react';
import { Button, SubmitButton } from 'core/components/common';
import { useMutation } from '@apollo/client';
import { ADMIN_DELETE_USERS_BY_EMAILS } from 'graphql/mutations';
import { emailsRegexp } from 'pages/const';
import devConsole from 'core/utils/devConsole';
import UserDeleteResultsTable from '../UserDeleteResultsTable/UserDeleteResultsTable';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

interface DeleteUsersProps {
  onSuccess: () => void;
  onError: () => void;
}

const DeleteUsers = ({ onError, onSuccess }: DeleteUsersProps) => {
  const [emailsValue, setEmailsValue] = useState<string>('');
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [savePrimaryUserSharedSpace, setSavePrimaryUserSharedSpace] = useState<boolean>(false);
  const [isTestRun, setTestRun] = useState(false);
  const [wasTestRun, setWasTestRun] = useState(false);
  const [deleteUsers, { loading, data: deleteUsersResults, reset }] = useMutation(
    ADMIN_DELETE_USERS_BY_EMAILS,
  );

  const handleReset = () => {
    setEmailsValue('');
    reset();
    setWasTestRun(false);
    setSavePrimaryUserSharedSpace(false);
  };

  const handleDelete = async (e?: FormEvent) => {
    e?.preventDefault();
    setShowConfirmModal(false);
    const emails = emailsValue.split(',').map((email) => email.trim());
    try {
      const res = await deleteUsers({
        variables: {
          values: {
            emails,
            savePrimaryUserSharedSpace,
            isTestRun,
          },
        },
      });
      if (!res.data) {
        throw new Error('No data');
      }
      onSuccess();
      setWasTestRun(isTestRun);
    } catch (e) {
      devConsole.error(e);
      onError();
    } finally {
      setTestRun(false);
    }
  };

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    setShowConfirmModal(true);
  };

  useEffect(() => {
    if (isTestRun) {
      handleDelete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTestRun]);

  const isDisabled = !emailsRegexp.test(emailsValue) || loading;

  return (
    <div>
      <StyledDescription>
        Delete users by email or set of emails separated by coma. This will include workspaces this
        user is primary owner of. If it is not the case, check the box below to preserve these
        workspaces.
      </StyledDescription>
      <form onSubmit={handleSubmit}>
        <Input
          value={emailsValue}
          onChange={(e) => setEmailsValue(e.target.value)}
          bordered
          size="large"
          placeholder="user email(s)"
          name="emails"
        />
        <Checkbox
          name="savePrimaryUserSharedSpace"
          checked={savePrimaryUserSharedSpace}
          onChange={(e) => setSavePrimaryUserSharedSpace(e.target.checked)}
        >
          Save Primary user Shared Space
        </Checkbox>
        <StyledButtonsContainer>
          <Button isSecondary onClick={handleReset}>
            Reset
          </Button>
          <Button
            isSecondary
            isLoading={isTestRun && loading}
            disabled={isDisabled}
            isDisabled={isDisabled}
            onClick={() => {
              setTestRun(true);
            }}
          >
            Test Run
          </Button>
          <SubmitButton isLoading={!isTestRun && loading} disabled={isDisabled || isTestRun}>
            Submit
          </SubmitButton>
        </StyledButtonsContainer>
      </form>
      {deleteUsersResults?.deleteUsersByEmails && (
        <UserDeleteResultsTable
          dataSource={deleteUsersResults.deleteUsersByEmails}
          isLoading={loading}
          isTestRun={wasTestRun}
        />
      )}
      <ConfirmModal
        onConfirm={handleDelete}
        onCancel={() => setShowConfirmModal(false)}
        visible={showConfirmModal}
      />
    </div>
  );
};

export default memo(DeleteUsers);

