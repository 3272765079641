import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ConfirmModal } from '../../../../common/components/ConfirmModal';
import { Checkbox } from 'antd';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { CompleteOutcomeManager } from '../../../../common/components/CompleteOutcome/CompleteOutcomeManager';
import { ArchiveOutcomeManager } from '../../../../common/components/ArchiveOutcome/ArchiveOutcomeManager/ArchiveOutcomeManager';
import ChangeLinkModal from '../ChangeLinkModal/ChangeLinkModal';
import ShareWorkspaceModal from '../../../../workspaces/components/ShareWorkspace/ShareWorkspaceModal/ShareWorkspaceModal';
import TutorialModal from '../../../../../core/components/Tutorial/TutorialModal';
import LeaveWorkspaceModal from '../../../../workspaces/components/ShareWorkspace/LeaveWorkspaceModal/LeaveWorkspaceModal';
import RemoveFromWorkspaceModal from '../../../../workspaces/components/ShareWorkspace/RemoveFromWorkspaceModal/RemoveFromWorkspaceModal';
import { EditAction } from '../EditAction';
import SharedEntityInfoModal from '../../../../common/components/SharedEntityInfoModal';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { ActionStatus } from '../../../../../core/types/action';
import { useMutation, useQuery } from '@apollo/client';
import {
  COURSES_STATE,
  CoursesFields,
} from '../../../../../apollo/stateFields/courses/coursesFields';
import { coursesMutation } from '../../../../../apollo/stateFields/courses';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../apollo/stateFields/userSettings/userSettingsField';
import { userSettingsMutation } from '../../../../../apollo/stateFields/userSettings';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../../apollo/stateFields/filterInput';
import {
  MANAGE_WORKSPACES_STATE,
  ManageWorkspacesValue,
} from '../../../../../apollo/stateFields/manageWorkspaces/manageWorkspacesField';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';
import { UPDATE_SETTINGS } from '../../../../../graphql/mutations';
import ExpiredSubscriptionModal from '../../../../../pages/AvailablePlansPage/ExpiredSubscriptionModal/ExpiredSubscriptionModal';
import CoursesNotificationManager from '../../../../courses/CourseVideoNotification/CoursesNotificationManager';

const StyledConfirmModal = styled(ConfirmModal)`
  && {
    .ant-modal-body > div {
      label:last-child {
        margin-top: 20px;
      }
    }
  }
`;

const StyledFilterModal = styled(ConfirmModal)`
  && {
    .ant-modal-body > div {
      div:nth-child(2) {
        margin: 12px 0 10px;
      }
    }

    h3 {
      display: block;
      width: 250px;
    }

    h2 {
      font-weight: 700;
      line-height: 24px;
      font-size: 20px;
      font-style: normal;
    }
  }
`;

const StyledMultipleFilterModal = styled(ConfirmModal)`
  && {
    .ant-modal-body > div {
      align-items: flex-start;

      div:nth-child(2) {
        margin: 12px 0;
      }

      div:last-child {
        justify-content: flex-start;
      }
    }
    h2 {
      text-align: left;
      margin: 0;
    }
    h3 {
      text-align: left;
    }
  }
`;

const StyledCheckboxList = styled.div`
  display: block;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin: 0 0 5px 0;

    span:last-child {
      max-width: 390px;
      word-wrap: break-word;
    }

    .ant-checkbox-inner {
      background-color: #fff;

      &::after {
        border-color: var(--color-dark-blue);
      }
    }
  }
`;

export interface ActionBoardModalsProps {
  updateActionStatus: (
    id: number,
    prevStatus: string,
    newStatus: string,
    isSetToToday: boolean,
  ) => Promise<void>;
  actionIdMoved: number | null;
  isStartDateConfirmModalVisible: boolean;
  setIsStartDateConfirmModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionBoardModals = React.memo(
  ({
    updateActionStatus,
    actionIdMoved,
    isStartDateConfirmModalVisible,
    setIsStartDateConfirmModalVisible,
  }: ActionBoardModalsProps) => {
    const { data: filterData } = useQuery(FILTER_STATE);
    const { updateFilterInputId }: FilterInputValue = filterData?.filterInput;
    const { addFilterInputWorkspace, setUpdateFilterInputId } = filterMutation;
    const { data: coursesData } = useQuery(COURSES_STATE);
    const { tutorialStep }: CoursesFields = coursesData?.courses;
    const { setShowCoursesPopup } = coursesMutation;
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId, isEditing }: UserSettingsValue =
      userSettingsData?.userSettingsField;
    const { setShouldUpdateStartDate, setDefaultWorkspaceName, setIsEditing } =
      userSettingsMutation;
    const { data: manageWorkspacesData } = useQuery(MANAGE_WORKSPACES_STATE);
    const { workspaceToShare: sharedWorkspace }: ManageWorkspacesValue =
      manageWorkspacesData?.manageWorkspaces;
    const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
    const { actionEditedEntity, outcomeEditedEntity, dataChangeLinkModal }: BoardEditedEntityValue =
      boardEditedData?.boardEditedEntity;

    const { data: fetchWorkspaces } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
    const workspaces = fetchWorkspaces?.fetchUserWorkspacesWithInvitedCount || [];
    const selectedFilterWorkspaces = useMemo(() => {
      return updateFilterInputId
        ? workspaces.filter((w) => updateFilterInputId.some((input) => input.workspaceId === w.id))
        : [];
    }, [updateFilterInputId, workspaces]);
    const [updateSettings] = useMutation(UPDATE_SETTINGS);
    const [isRememberSettingSetDate, setIsRememberSettingSetDate] = useState(false);
    const [checkedFilterInputIds, setCheckedFilterInputIds] = useState<number[]>([]);

    const saveSettingSetStartDate = useCallback(
      async (option: boolean) => {
        if (isRememberSettingSetDate) {
          await updateSettings({
            variables: {
              settings: {
                setStartDateToToday: option,
              },
            },
          });
          setShouldUpdateStartDate(option);
        }
      },
      [isRememberSettingSetDate, updateSettings],
    );

    const onCloseStartDateConfirmModal = useCallback(
      async (option: boolean) => {
        await saveSettingSetStartDate(option);
        await updateActionStatus(actionIdMoved!, ActionStatus.Done, ActionStatus.Doing, option);
        setIsStartDateConfirmModalVisible(false);
      },
      [actionIdMoved, saveSettingSetStartDate, updateActionStatus],
    );

    const toggleCheckedFilterInputIds = useCallback(
      (workspaceId: number) => {
        setCheckedFilterInputIds((old) => {
          if (old.some((oldId) => oldId === workspaceId)) {
            return old.filter((oldId) => oldId !== workspaceId);
          }
          return [...old, workspaceId];
        });
      },
      [setCheckedFilterInputIds],
    );

    const toggleRememberSettingSetDate = useCallback((e: CheckboxChangeEvent) => {
      setIsRememberSettingSetDate(e.target.checked);
    }, []);

    const isSameUpdateFilter = useMemo(() => {
      return (
        updateFilterInputId?.length === 2 &&
        updateFilterInputId[0].workspaceId === updateFilterInputId[1].workspaceId &&
        updateFilterInputId[0].type !== updateFilterInputId[1].type
      );
    }, [updateFilterInputId]);

    const isFilterModalVisible = useMemo(() => {
      return (
        (selectedFilterWorkspaces?.length === 1 && !isEditing) ||
        (updateFilterInputId &&
          updateFilterInputId?.length >= 1 &&
          !isEditing &&
          isSameUpdateFilter)
      );
    }, [updateFilterInputId, isSameUpdateFilter, isEditing, selectedFilterWorkspaces]);

    // useEffect(() => {
    //   if (data?.fetchUserSettings.coursesConsts.Foundations.certificateUrl && tutorialStep === -1) {
    //     setShowCoursesPopup(true);
    //   }
    // }, []);

    useEffect(() => {
      setIsEditing(!!outcomeEditedEntity || !!actionEditedEntity);
    }, [outcomeEditedEntity, actionEditedEntity]);

    useEffect(() => {
      const defaultSpace = workspaces.find((w) => w.id === defaultWorkspaceId);
      if (defaultSpace) {
        setDefaultWorkspaceName(defaultSpace.name);
      }
    }, [workspaces, defaultWorkspaceId]);

    return (
      <>
        {isStartDateConfirmModalVisible && (
          <StyledConfirmModal
            visible={isStartDateConfirmModalVisible}
            title="Would you like to change the start date to today?"
            confirmText="Change the date"
            endContent={
              <StyledCheckbox
                checked={isRememberSettingSetDate}
                onChange={toggleRememberSettingSetDate}
              >
                Remember this setting
              </StyledCheckbox>
            }
            onCancel={() => onCloseStartDateConfirmModal(false)}
            onOk={() => onCloseStartDateConfirmModal(true)}
          />
        )}
        {isFilterModalVisible && (
          <StyledFilterModal
            closable
            zIndex={6000}
            visible={isFilterModalVisible}
            title={`Add '${
              workspaces.find((w) => w.id === updateFilterInputId![0].workspaceId)?.name || 'this'
            }' Space to the Board?`}
            subtitle={`${updateFilterInputId!.length >= 2 ? 'These' : 'This'} ${
              isSameUpdateFilter ||
              (updateFilterInputId!.some((input) => input.type === 'Outcome') &&
                updateFilterInputId!.some((input) => input.type === 'Action') &&
                !isSameUpdateFilter)
                ? 'Actions (Outcome) are'
                : `${updateFilterInputId![0].type}${
                    updateFilterInputId!.length >= 2 && !isSameUpdateFilter ? 's' : ''
                  }
                        ${updateFilterInputId!.length >= 2 ? ' are' : ' is'}`
            } in a Space not currently shown on the Board`}
            confirmText={'Add to the Board'}
            cancelText={'Cancel'}
            onCancel={() => {
              setUpdateFilterInputId(null);
              sendEvent('lifespace_cancel_selected', 'Cancel adding selected workspaces');
            }}
            onOk={() => {
              updateFilterInputId &&
                updateFilterInputId.forEach((input) => {
                  addFilterInputWorkspace(input.workspaceId);
                });
              setUpdateFilterInputId(null);
              sendEvent('lifespace_add_selected', 'Add selected workspaces');
            }}
          />
        )}
        {updateFilterInputId &&
          updateFilterInputId.length >= 2 &&
          selectedFilterWorkspaces.length >= 2 &&
          !isSameUpdateFilter &&
          !isEditing && (
            <StyledMultipleFilterModal
              closable
              visible={
                updateFilterInputId &&
                !isSameUpdateFilter &&
                updateFilterInputId.length >= 2 &&
                !isEditing
              }
              title={'Add Spaces to the Board?'}
              subtitle={`These ${
                updateFilterInputId.some((input) => input.type === 'Outcome') &&
                updateFilterInputId.some((input) => input.type === 'Action')
                  ? 'Actions (Outcome)'
                  : updateFilterInputId.some((input) => input.type === 'Outcome')
                  ? 'Outcome'
                  : 'Actions'
              } are in Spaces not currently shown on the Board`}
              confirmText={'Add to the Board'}
              cancelText={'Cancel'}
              middleContent={
                <StyledCheckboxList>
                  <div>
                    {selectedFilterWorkspaces.map((w) => {
                      return (
                        <StyledCheckbox
                          checked={checkedFilterInputIds.some((id) => w.id === id)}
                          onChange={() => {
                            toggleCheckedFilterInputIds(w.id);
                          }}
                        >
                          {w.name}
                        </StyledCheckbox>
                      );
                    })}
                  </div>
                </StyledCheckboxList>
              }
              onCancel={() => {
                setUpdateFilterInputId(null);
                setCheckedFilterInputIds([]);
                sendEvent('lifespace_cancel_selected', 'Cancel adding selected workspaces');
              }}
              onOk={() => {
                checkedFilterInputIds.forEach((id) => {
                  addFilterInputWorkspace(id);
                });
                setUpdateFilterInputId(null);
                setCheckedFilterInputIds([]);
                sendEvent('lifespace_add_selected', 'Add selected workspaces');
              }}
            />
          )}

        <CompleteOutcomeManager />
        <ArchiveOutcomeManager />
        {!!dataChangeLinkModal && <ChangeLinkModal data={dataChangeLinkModal} />}
        {sharedWorkspace && <ShareWorkspaceModal />}
        <TutorialModal />
        <LeaveWorkspaceModal />
        <RemoveFromWorkspaceModal />
        <EditAction />
        <SharedEntityInfoModal />
        <ExpiredSubscriptionModal />
        <CoursesNotificationManager />
      </>
    );
  },
);

export default ActionBoardModals;

