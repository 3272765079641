import { Table } from 'antd';
import { StyledDescription } from '../StyledComponents';
import { ColumnsType } from 'antd/es/table';
import { FailedUserInfo, UserData, UserErrorReason, userDataKeys } from './types';

interface UserErrorRow extends UserErrorReason {
  email: UserData['email'];
  property: keyof UserData;
  value: any;
  message: string;
}

const columns: ColumnsType<UserErrorRow> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Property',
    dataIndex: 'property',
    key: 'property',
    filters: userDataKeys.map((key) => ({
      text: key,
      value: key,
    })),
    onFilter: (prop, userErrorRow) => userErrorRow.property === prop,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Error Message',
    dataIndex: 'message',
    key: 'message',
  },
];

export interface UserErrorDataTableProps {
  title: string;
  userErrorDataList: FailedUserInfo[];
  isLoading?: boolean;
}

const UserErrorDataTable = ({ title, userErrorDataList, isLoading }: UserErrorDataTableProps) => {
  const dataSource = userErrorDataList.reduce(
    (acc, { data: { email }, reasons }, iErrorDataList) => {
      acc.push(
        ...reasons.map((reason, iReasonsList) => ({
          i: `${iErrorDataList}${iReasonsList}`,
          email,
          ...reason,
        })),
      );
      return acc;
    },
    [] as UserErrorRow[],
  );
  return (
    <>
      <StyledDescription>{title}</StyledDescription>
      <Table dataSource={dataSource} columns={columns} rowKey="i" loading={isLoading} />
    </>
  );
};

export default UserErrorDataTable;

