import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../../core/svg/arrow-left.svg';
import { Radio } from 'antd';
import {
  BOARD_FILTERS_STATE,
  BoardFiltersValue,
  TODO_COLUMN_FILTERS_VALUES,
  ToDoColumnFilterTypes,
} from '../../../../../apollo/stateFields/boardFilters/boardFiltersFields';
import { format, getYear, startOfDay, addDays } from 'date-fns';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_SETTINGS } from '../../../../../graphql/mutations';
import {
  GlobalStyle,
  StyledAddActionButton,
  StyledBackLink,
  StyledBurgerIcon,
  StyledCustomDate,
  StyledCustomHeader,
  StyledModal,
  StyledRadio,
  StyledRadioContent,
  StyledRadioHeader,
  StyledRangePicker,
  StyledSpace,
} from '../StyledComponents/StyledComponents';
import { boardFiltersMutation } from '../../../../../apollo/stateFields/boardFilters';

const ToDoColumnFilter = () => {
  const { data: boardFiltersData } = useQuery(BOARD_FILTERS_STATE);
  const {
    showToDoColumnFilter,
    toDoColumnFilter,
    toDoColumnStartDate,
    toDoColumnEndDate,
  }: BoardFiltersValue = boardFiltersData?.boardFilters;
  const {
    setToDoColumnStartDate,
    setToDoColumnEndDate,
    setShowToDoColumnFilter,
    setToDoColumnFilter,
  } = boardFiltersMutation;
  const [showCustomPeriod, shouldShowCustomPeriod] = useState<boolean>(false);
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const rangePickerRef = useRef<any>(null);
  const [coords, setCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [updateSettings] = useMutation(UPDATE_SETTINGS);

  const handleOpenFilter = useCallback(
    (event: any) => {
      if (filterButtonRef.current) {
        setCoords({
          x: filterButtonRef.current.getBoundingClientRect().right,
          y: filterButtonRef.current.getBoundingClientRect().top,
        });
        setShowToDoColumnFilter(true);
      }
    },
    [setShowToDoColumnFilter, setCoords, filterButtonRef.current],
  );

  const handleCloseFilter = useCallback(
    (event: any) => {
      setShowToDoColumnFilter(false);
      shouldShowCustomPeriod(false);
    },
    [setShowToDoColumnFilter, setCoords],
  );

  const renderDateFilter = () => {
    if (
      toDoColumnFilter === ToDoColumnFilterTypes.CUSTOM &&
      !!toDoColumnStartDate &&
      !!toDoColumnEndDate
    ) {
      if (getYear(toDoColumnStartDate) !== getYear(toDoColumnEndDate)) {
        return `${format(toDoColumnStartDate, 'MMM dd, yyyy')} - ${format(
          toDoColumnEndDate,
          'MMM dd, yyyy',
        )}`;
      }
      return `${format(toDoColumnStartDate, 'MMM dd')} - ${format(toDoColumnEndDate, 'MMM dd')}`;
    }

    return toDoColumnFilter;
  };

  const renderRadioGroup = () => {
    const onChange = async (e: any) => {
      setToDoColumnFilter(e.target.value as ToDoColumnFilterTypes);

      const settings =
        toDoColumnStartDate &&
        toDoColumnEndDate &&
        (e.target.value as ToDoColumnFilterTypes) === ToDoColumnFilterTypes.CUSTOM
          ? {
              toDoColumnFilter: {
                name: 'ToDo',
                filter: e.target.value,
                startDate: toDoColumnStartDate.toString(),
                endDate: toDoColumnEndDate.toString(),
              },
            }
          : {
              toDoColumnFilter: {
                name: 'ToDo',
                filter: e.target.value,
              },
            };

      await updateSettings({
        variables: {
          settings,
        },
      });
    };

    return (
      <>
        <StyledRadioHeader>To Do Filters</StyledRadioHeader>
        <Radio.Group onChange={onChange} value={toDoColumnFilter} style={{ width: '100%' }}>
          <StyledSpace direction="vertical">
            {TODO_COLUMN_FILTERS_VALUES.map((value, index) => {
              if (value === ToDoColumnFilterTypes.CUSTOM) {
                return (
                  <StyledRadio
                    value={value}
                    key={`${value}-${index}`}
                    onClick={() => shouldShowCustomPeriod(true)}
                  >
                    <StyledRadioContent>{value}</StyledRadioContent>
                    {toDoColumnStartDate &&
                      toDoColumnEndDate &&
                      toDoColumnFilter === ToDoColumnFilterTypes.CUSTOM && (
                        <StyledCustomDate>
                          {renderDateFilter()}
                          {/*  {`${format(toDoColumnStartDate, 'MMM dd')} - */}
                          {/*${format(toDoColumnEndDate, 'MMM dd')}`}*/}
                        </StyledCustomDate>
                      )}
                  </StyledRadio>
                );
              }

              return (
                <StyledRadio value={value} key={`${value}-${index}`}>
                  <StyledRadioContent>
                    {value}
                    {value === ToDoColumnFilterTypes.NEXT_7 && (
                      <span>Until {format(addDays(startOfDay(new Date()), 6), 'MMM dd')}</span>
                    )}
                    {value === ToDoColumnFilterTypes.NEXT_14 && (
                      <span>Until {format(addDays(startOfDay(new Date()), 13), 'MMM dd')}</span>
                    )}
                    {value === ToDoColumnFilterTypes.NEXT_30 && (
                      <span>Until {format(addDays(startOfDay(new Date()), 29), 'MMM dd')}</span>
                    )}
                  </StyledRadioContent>
                </StyledRadio>
              );
            })}
          </StyledSpace>
        </Radio.Group>
      </>
    );
  };

  const renderCustomPeriod = () => {
    const onChange = async (dates: any) => {
      if (!dates[0] || !dates[1]) {
        return;
      }

      setToDoColumnStartDate(dates[0].toDate());
      setToDoColumnEndDate(dates[1].toDate());

      await updateSettings({
        variables: {
          settings: {
            toDoColumnFilter: {
              name: 'ToDo',
              filter: ToDoColumnFilterTypes.CUSTOM,
              startDate: dates[0].toString(),
              endDate: dates[1].toString(),
            },
          },
        },
      });
    };

    const today = new Date();

    return (
      <>
        <GlobalStyle />
        <StyledBackLink onClick={() => shouldShowCustomPeriod(false)}>
          <LeftArrowIcon />
          <span>To Do Filters</span>
        </StyledBackLink>
        <StyledCustomHeader>Select custom period</StyledCustomHeader>
        <StyledRangePicker
          ref={rangePickerRef}
          format="MMM  DD  YYYY"
          onChange={onChange}
          placeholder={['Date1', 'Date2']}
          inputReadOnly
          defaultPickerValue={[
            toDoColumnStartDate
              ? moment(toDoColumnStartDate)
              : moment(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0)),
            toDoColumnEndDate
              ? moment(toDoColumnEndDate)
              : moment(new Date(today.getFullYear(), today.getMonth() + 1, today.getDate(), 0, 0)),
          ]}
          value={[
            toDoColumnStartDate ? moment(toDoColumnStartDate) : null,
            toDoColumnEndDate ? moment(toDoColumnEndDate) : null,
          ]}
        />
      </>
    );
  };

  useEffect(() => {
    if (
      toDoColumnFilter === ToDoColumnFilterTypes.CUSTOM &&
      (!toDoColumnStartDate || !toDoColumnEndDate)
    ) {
      setToDoColumnFilter(ToDoColumnFilterTypes.ANYTIME);
    }
  }, [showCustomPeriod]);

  return (
    <div>
      <StyledAddActionButton
        onClick={handleOpenFilter}
        size={'small'}
        shape={'round'}
        ref={filterButtonRef}
        isAnytime={toDoColumnFilter === ToDoColumnFilterTypes.ANYTIME}
      >
        <span>{renderDateFilter()}</span>
        <StyledBurgerIcon isAnytime={toDoColumnFilter === ToDoColumnFilterTypes.ANYTIME} />
      </StyledAddActionButton>
      <StyledModal
        getContainer={() => document.body}
        visible={showToDoColumnFilter}
        onCancel={handleCloseFilter}
        footer={null}
        mask={false}
        closable={true}
        style={{
          left: coords.x - 300,
          top: coords.y,
        }}
      >
        {showCustomPeriod ? renderCustomPeriod() : renderRadioGroup()}
      </StyledModal>
    </div>
  );
};

export default ToDoColumnFilter;

