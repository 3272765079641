import { FormEvent, memo, useState } from 'react';
import { StyledButtonsContainer, StyledDescription } from '../StyledComponents';
import { Input } from 'antd';
import { Button } from '../../../core/components/common';
import { domainRegex } from '../../const';
import OrgInfo from '../OrgInfo/OrgInfo';
import { SubmitButton } from 'core/components/common/Button/SubmitButton';

interface ViewEditUserProps {
  setIsSuccess: (isSuccess: boolean) => void;
  setErrorMessage: (errorMessage: string | null) => void;
}

const ViewEditOrg = ({ setIsSuccess, setErrorMessage }: ViewEditUserProps) => {
  const [domain, setDomain] = useState('');
  const [showOrgInfo, setShowOrgInfo] = useState<any>(null);
  const handleReset = () => {
    setDomain('');
    setShowOrgInfo(false);
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setShowOrgInfo(true);
  };
  const isSubmitDisabled = !domain.length || !domainRegex.test(domain);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <StyledDescription>
          <span>This allows you to view and edit all organization defaults.</span>
          <span>
            This changes will affect default settings for all individual users within the domain,
            but will not affect custom settings for those users.
          </span>
        </StyledDescription>
        <Input
          value={domain}
          onChange={(e) => {
            setShowOrgInfo(false);
            setDomain(e.target.value);
          }}
          bordered
          size="large"
          placeholder="Organization domain"
        />
        <StyledButtonsContainer>
          <Button isSecondary onClick={handleReset}>
            Reset
          </Button>
          <SubmitButton isDisabled={isSubmitDisabled}>Search</SubmitButton>
        </StyledButtonsContainer>
      </form>
      {domain && showOrgInfo && (
        <OrgInfo
          domain={domain}
          onSuccess={() => setIsSuccess(true)}
          onError={() => setErrorMessage('Ooops')}
          onDomainUpdate={setDomain}
        />
      )}
    </div>
  );
};

export default memo(ViewEditOrg);

