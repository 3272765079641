import { useMutation } from '@apollo/client';
import { ADMIN_CREATE_CORPORATE_ACC } from 'graphql/mutations';
import { CoursesGroups, domainRegex } from 'pages/const';
import { FormEvent, memo, useState } from 'react';
import { StyledButtonsContainer, StyledDescription } from '../StyledComponents';
import { Input, Select } from 'antd';
import { CoursesGroupDefaultValue, CoursesGroupsSelectOpts } from '../utils';
import { Button, ExpirationDatePicker } from 'core/components/common';
import { getTime } from 'date-fns';
import { logResponseError } from 'core/utils/error';
import { SubmitButton } from 'core/components/common/Button/SubmitButton';
import LabeledCheckbox from '../ApplyUsersCheckbox/ApplyUsersCheckbox';

interface AddOrganizationProps {
  setIsSuccess: (isSuccess: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
}
const AddOrganization = ({ setErrorMessage, setIsSuccess }: AddOrganizationProps) => {
  const [createCorporateAcc, { loading: createCorporateAccLoading }] = useMutation(
    ADMIN_CREATE_CORPORATE_ACC,
  );

  const [domainInput, setDomainInput] = useState('');
  const [coursesInput, setCoursesInput] = useState(CoursesGroupDefaultValue);
  const [expirationDateInput, setExpirationDateInput] = useState<Date | null>(null);
  const [shouldUpdateCorporateUsers, setShouldUpdateCorporateUsers] = useState(false);
  const [shouldOverrideCurriculumForCurrentUsers, setShouldOverrideCurriculumForCurrentUsers] =
    useState(false);

  const isFormDisabled = !domainInput.length || !domainRegex.test(domainInput);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const expiration = expirationDateInput && Math.floor(getTime(expirationDateInput) / 1000);
      await createCorporateAcc({
        variables: {
          values: {
            domain: domainInput,
            coursesAvailableList: CoursesGroups[coursesInput.value],
            bmSubscriptionExpiration: expiration,
          },
          options: {
            shouldUpdateCorporateUsers,
            shouldOverrideCurriculumForCurrentUsers:
              shouldUpdateCorporateUsers && shouldOverrideCurriculumForCurrentUsers,
          },
        },
      });
      setIsSuccess(true);
    } catch (e) {
      logResponseError(e, setErrorMessage);
    }
  };

  const handleReset = () => {
    setDomainInput('');
    setCoursesInput(CoursesGroupDefaultValue);
    setExpirationDateInput(null);
    setShouldOverrideCurriculumForCurrentUsers(false);
    setShouldUpdateCorporateUsers(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledDescription>
        <span>
          Use this option to create a new company domain, and assign a specific curriculum to it
        </span>
        <span>
          If you do not set an expiry date, users in this domain will default to a free trial
        </span>
        <span>
          Note: This setting will not affect existing users in this domain, only new users that have
          an e-mail address with this domain. Checking the box below will override the curriculum
          for existing users. Checking the second box will override user custom curriculum.
        </span>
      </StyledDescription>
      <Input
        value={domainInput}
        onChange={(e) => setDomainInput(e.target.value)}
        bordered
        size="large"
        placeholder="organization domain (ex: gusto.com)"
      />
      <Select
        labelInValue
        value={coursesInput}
        style={{ width: '100%' }}
        onChange={(option) => {
          setCoursesInput(option);
        }}
        options={CoursesGroupsSelectOpts}
      />
      <ExpirationDatePicker
        value={expirationDateInput}
        setExpirationDate={setExpirationDateInput}
      />
      <LabeledCheckbox
        setValue={setShouldUpdateCorporateUsers}
        value={shouldUpdateCorporateUsers}
      />
      <LabeledCheckbox
        setValue={setShouldOverrideCurriculumForCurrentUsers}
        value={shouldOverrideCurriculumForCurrentUsers}
        disabled={!shouldUpdateCorporateUsers}
      >
        Override users with custom curriculum
      </LabeledCheckbox>
      <StyledButtonsContainer>
        <Button isSecondary onClick={handleReset}>
          Reset
        </Button>
        <SubmitButton isLoading={createCorporateAccLoading} isDisabled={isFormDisabled}>
          Submit
        </SubmitButton>
      </StyledButtonsContainer>
    </form>
  );
};

export default memo(AddOrganization);

