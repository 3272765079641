import devConsole from './devConsole';

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const setCookie = (name: string, value: string, days: number, domain: string) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/;' + `domain=${domain}`;
};

export const replaceBackSlash = (value: string) => {
  if (value) {
    return value
      .split('')
      .map((char) => (char === '\\' ? '&#92;' : char))
      .join('');
  }
  return value;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts !== undefined && parts.length === 2) {
    // @ts-ignore
    return parts.pop().split(';').shift();
  }
};

export function deleteCookie(name: string) {
  document.cookie = `${name}=; path=/; domain=.billionminds.com; expires=${new Date(
    0,
  ).toUTCString()}`;
}

export const removeSpecialChars = (value: string) => {
  if (value) {
    return value.replace(/[^\w\s]/gi, '');
  }
  return value;
};

export const replaceUnderscoreToSpace = (value: string) => {
  if (value === 'Well_being') return 'Well-being';
  return value.replace(/_/g, ' ');
};

export const logger = <T extends (...args: any[]) => any>(fn: T) => {
  return (...args: Parameters<T>): ReturnType<T> => {
    devConsole.log('logger:', { args });
    const result = fn(...args);
    devConsole.log('logger:', { result });
    return result;
  };
};

