import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import closeIcon from '../../../../../../core/img/close-icon@1x.png';
import { ArrowDownIcon, CloseIcon } from '../../../../../../core/icons';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-antd';
import { Button, Divider, Drawer, Typography } from 'antd';
import { isEmpty } from 'lodash-es';
import { MouseOverCursorWrapper } from '../../../../../../core/components/MouseOverCursorWrapper';
import styled from 'styled-components';
import { theme } from '../../../../../../core/styles/styled-components';
import { ActionStatus } from '../../../../../../core/types/action';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FETCH_OUTCOMES } from '../../../../../outcomes/graphql/queries';
import { getActionInitialValuesFromNote } from '../utils';
import { ConvertToActionDrawerProps } from './types';
import { adjustActualDateAccordingStatus } from '../../../../utils';
import { isFinite, omit } from 'lodash';
import { ActionStatusComponent } from '../../common/ActionStatusComponent';
import DataRowComponent from '../../common/DataRowComponent';
import DurationRowComponent from '../../common/DurationRowComponent';
import { AddTags } from 'features/common/components/form/AddTags';
import { formatISO, parseISO, startOfDay } from 'date-fns';
import { Messages } from 'core/constants/messages';
import { TagOption } from '../../../../../common/components/form/AddTags/AddTags';
import {
  DebouncedTextAreaInput,
  DebouncedTextInput,
} from '../../../../../../core/enchancedComponents/DebouncedTextField/DebouncedTextField';
import { Permission } from '../../../../../../core/types/workspace';
import useSubscribeEntity from '../../../../../../hooks/useSubscribeEntity';
import { SUBSCRIBE_NOTE } from '../../../../../../graphql/subscriptions';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../../common/graphql/queries';
import { convertToMs, sortWorkspaces } from '../../utils';
import { Note } from '../../../../../../core/types/note';
import { sendEvent } from '../../../../../../core/integrations/sentry/events';
import { toast, ToastContent } from 'react-toastify';
import useConvertNoteToAction from '../../../../../interactions/note/useConvertNoteToAction';
import { useCheckSharedEntityMoved } from '../../../../../../hooks/useCheckSharedEntityMoved';
import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
} from '../../../../../../apollo/stateFields/error/errorFields';
import { Loader } from '../../../../../../core/components/common';
import { useLazyLoadEntity } from '../../../../../../hooks/useLazyLoadEntity';
import { errorMutation } from '../../../../../../apollo/stateFields/error';
import { FILTER_STATE, FilterInputValue } from 'apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../../apollo/stateFields/userSettings/userSettingsField';
import { boardEditedEntityMutation } from '../../../../../../apollo/stateFields/boardEditedEntity';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import { DEFAULT_ACTION_TIME_FORMAT } from '../../EditAction/utils';
import { ActionInput, NewActionInput } from '__generated__/graphql';

const { Title } = Typography;
const { Option } = Select;

const StyledTitle = styled(Title)`
  @media ${theme.device.mobile.max} {
    font-size: 20px !important;
    margin-bottom: 32px !important;
  }
`;

const StyledDrawer = styled(Drawer)`
  @media ${theme.device.tablet.max} {
    z-index: 1002;
  }

  .ant-drawer-content-wrapper {
    width: 100%;

    @media ${theme.device.desktop.min} {
      width: 324px;
      height: auto;
      top: 110px;
      bottom: 0;
    }

    @media ${theme.device.tablet.max} {
      width: 384px;
      z-index: 1002;
    }

    @media ${theme.device.mobile.max} {
      width: 100vw;
    }
  }

  .ant-drawer-content {
    border-top-left-radius: 4px;

    @media ${theme.device.tablet.max} {
      border-radius: 0;
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  h4 {
    font-size: 14px;
  }

  .ant-input,
  textarea.ant-input,
  .ant-picker {
    display: block;
  }

  .ant-input {
    line-height: 32px;
  }

  .ant-divider-horizontal {
    margin: 24px 0;

    &.mainDivider {
      margin-bottom: 0;

      @media ${theme.device.desktop.min} {
        display: none;
      }
    }
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  background: var(--color-main-grey-2);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }
`;

const StyledForm = styled(Form)`
  @media ${theme.device.desktop.min} {
    display: flex;
    min-height: 100%;
  }
`;

const StyledMainContent = styled.div`
  padding: 24px;
  width: 324px;
  background: var(--color-main-grey-2);

  @media ${theme.device.desktop.min} {
    flex: 1;
  }

  @media ${theme.device.tablet.max} {
    width: 384px;
    height: var(--app-height);
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-x: hidden;
  }

  @media ${theme.device.mobile.max} {
    width: 100vw;
    padding: 24px 16px 0 16px;
  }
`;

const StyledInputName = styled(DebouncedTextInput)`
  && {
    line-height: 40px;
    height: 40px;
  }
`;

const StyledDebouncedTextAreaInput = styled(DebouncedTextAreaInput)`
  && {
    height: 56px !important;

    @media ${theme.device.tablet.max} {
      height: 72px !important;
    }

    @media ${theme.device.mobile.max} {
      height: 72px !important;
    }
  }
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.4285em;
  color: #202635;
  line-height: 1.1428;
  font-weight: 700;
`;

export const StyledRemoveButton = styled(Button)`
  min-width: 0;
  width: 24px;
  height: 24px;
  margin: 0 8px;
  padding: 0;
  border: none;
`;

const StyledCloseButton = styled(StyledRemoveButton)`
  margin: 0;
  position: absolute;
  top: -12px;
  right: 0;

  @media ${theme.device.desktop.min} {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-grey-2);

  @media ${theme.device.tablet.max} {
    position: static;
    margin: 32px -24px 0 -24px;
    padding: 20px 24px;
    width: 384px;
  }

  @media ${theme.device.mobile.max} {
    margin: 32px -16px 0 -16px;
    padding: 20px 16px;
    width: 100vw;
  }

  .ant-btn {
    margin-right: 16px;

    @media ${theme.device.desktop.min} {
      margin-right: 0;
    }
  }

  @media ${theme.device.desktop.min} {
    padding: 10px 0;
    position: static;
    justify-content: space-between;
    background: none;
  }
`;

const FormContainer = styled.div`
  position: relative;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ConvertToActionDrawer = React.memo(({ notes }: ConvertToActionDrawerProps) => {
  const [note, setNote] = useState<Note | null>(null);
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { convertNoteToActionEntity }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
  const { showConvertNoteToActionModal } = boardEditedEntityMutation;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId, unassignedWorkspaceId }: UserSettingsValue =
    userSettingsData?.userSettingsField;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<number>(defaultWorkspaceId);
  const [convertNoteToAction, { data }] = useConvertNoteToAction(note);
  const { setErrorMessage, setEntityType } = errorMutation;
  const {
    getEntity,
    data: noteData,
    resetData: resetNoteData,
  } = useLazyLoadEntity(CUSTOM_ENTITY_TYPES.NOTE, convertNoteToActionEntity?.workspaceId);
  const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const workspaces =
    workspacesResponse?.fetchUserWorkspacesWithInvitedCount
      .slice()
      .sort((a, b) => sortWorkspaces(a, b, defaultWorkspaceId)) || [];

  const idForSubscribe = useMemo(() => {
    return convertNoteToActionEntity ? note?.id : undefined;
  }, [note, convertNoteToAction]);

  const editorPermission = useMemo(
    () =>
      workspaces.find((workspace) => workspace.id === note?.workspaceId)?.permission ===
        Permission.Editor || false,
    [workspaces, note],
  );
  const viewerPermission = useMemo(
    () =>
      workspaces.find((workspace) => workspace.id === note?.workspaceId)?.permission ===
        Permission.Viewer || false,
    [workspaces, note],
  );

  const { data: subscriptionData } = useSubscribeEntity(
    SUBSCRIBE_NOTE,
    note?.workspaceId,
    idForSubscribe,
  );
  useCheckSharedEntityMoved(
    data?.convertNoteToUserWorkspaceAction ? data?.convertNoteToUserWorkspaceAction : note,
    data?.convertNoteToUserWorkspaceAction ? CUSTOM_ENTITY_TYPES.ACTION : CUSTOM_ENTITY_TYPES.NOTE,
    currentWorkspaceId,
    undefined,
    !convertNoteToActionEntity,
  );

  useEffect(() => {
    if (note) {
      note?.workspaceId === unassignedWorkspaceId
        ? setCurrentWorkspaceId(defaultWorkspaceId)
        : setCurrentWorkspaceId(note.workspaceId!);
    }
  }, [defaultWorkspaceId, note?.workspaceId]);

  useEffect(() => {
    if (note) {
      setCurrentWorkspaceId(initWorkspaceId as number);
    } else {
      onClose();
    }
  }, [note?.workspaceId]);

  useEffect(() => {
    if (note && convertNoteToActionEntity) {
      handleGetOutcomes();
    }
  }, [currentWorkspaceId, note, convertNoteToActionEntity]);

  useEffect(() => {
    let editedNote = null;
    if (convertNoteToActionEntity) {
      editedNote = notes?.find((a) => a.id === convertNoteToActionEntity.id) || null;
      if (!editedNote) {
        if (!noteData) {
          getEntity(convertNoteToActionEntity);
        } else {
          editedNote = noteData as Note;
          setNote(editedNote);
        }
      } else {
        setNote(editedNote);
      }
    } else {
      resetNoteData();
    }
  }, [notes, convertNoteToActionEntity, noteData, currentWorkspaceId]);

  useEffect(() => {
    if (!convertNoteToActionEntity) {
      setNote(null);
    }
  }, [convertNoteToActionEntity]);

  useEffect(() => {
    if (!!subscriptionData?.subscribeToChanges.workspaceId) {
      if (!workspaces.some((w) => w.id === subscriptionData?.subscribeToChanges?.workspaceId)) {
        setErrorMessage(CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED);
        setEntityType(CUSTOM_ENTITY_TYPES.NOTE);
      }
    }
  }, [subscriptionData?.subscribeToChanges]);

  const workspaceOptions = useMemo(() => {
    if (note?.isArchived || viewerPermission || editorPermission) {
      return workspaces
        .filter((workspace) => workspace?.id !== unassignedWorkspaceId)
        .filter((workspace) => workspace?.permission !== Permission.Viewer || false)
        ?.map((workspace) => {
          return {
            label: workspace.name,
            value: workspace.id,
          };
        });
    }
    return workspaces
      .filter((workspace) => workspace?.id !== unassignedWorkspaceId)
      .filter((workspace) => workspace?.permission !== Permission.Viewer || false)
      ?.map((workspace) => {
        return {
          label: workspace.name,
          value: workspace.id,
        };
      });
  }, [workspaces, unassignedWorkspaceId, editorPermission, viewerPermission, note]);

  const [getOutcomes, { data: fetchUserWorkspaceOutcomesResponse }] = useLazyQuery(FETCH_OUTCOMES, {
    fetchPolicy: 'network-only',
  });

  const outcomes = fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes || [];
  const validationSchema = Yup.object().shape({
    name: Yup.string().max(256, 'Max length of name is 256 characters!').required(),
    status: Yup.string().required(),
    description: Yup.string().max(1000, 'Max length of description is 1000 characters!').nullable(),
    workspaceId: Yup.number().nullable(),
    outcomeId: Yup.number().nullable(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    actualStartDate: Yup.mixed().when('status', {
      is: (status: string) => status === ActionStatus.Done || status === ActionStatus.Doing,
      then: Yup.date().required(),
      otherwise: Yup.date().nullable(),
    }),
    actualEndDate: Yup.mixed().when('status', {
      is: (status: string) => status === ActionStatus.Done,
      then: Yup.date().required(),
      otherwise: Yup.date().nullable(),
    }),
    estimatedTime: Yup.number().min(0).nullable(),
    spendTime: Yup.number().min(0).nullable(),
    actionTimeFormat: Yup.string().when(['estimatedTime', 'spendTime'], {
      is: (estimatedTime: number, spendTime: number) =>
        isFinite(estimatedTime) || isFinite(spendTime),
      then: Yup.string().required(),
    }),
    inputTags: Yup.array(),
  });

  const handleGetOutcomes = () => {
    getOutcomes({
      variables: {
        workspaceId: currentWorkspaceId,
      },
    });
  };

  const onClose = () => {
    showConvertNoteToActionModal(null);
  };

  const onSubmit = useCallback(
    async (convertInput: NewActionInput) => {
      if (note) {
        try {
          await convertNoteToAction({
            variables: {
              workspaceId: note.workspaceId,
              noteId: note.id,
              action: omit(convertInput, '__typename'),
            },
            refetchQueries: [{ query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT }],
          });

          sendEvent('note-convert-to-action', 'Note convert to action', {
            'Note ID': note.id,
          });
        } catch (error) {
          console.error(error);
          toast(error as ToastContent);
        } finally {
          onClose();
        }
      }
    },
    [convertNoteToAction, note, filterInput],
  );

  const handleSubmit = useCallback(
    (values: any) => {
      const updatedValues = omit(
        {
          ...values,
          inputTags: values.inputTags.map((tagOption: any) => ({
            name: tagOption.label,
          })),
          spendTime: values?.spendTime
            ? convertToMs(
                values.spendTime,
                values?.actionTimeFormat || DEFAULT_ACTION_TIME_FORMAT,
              ) / 1000
            : null,
          estimatedTime: values?.estimatedTime
            ? convertToMs(
                values?.estimatedTime,
                values?.actionTimeFormat || DEFAULT_ACTION_TIME_FORMAT,
              ) / 1000
            : null,
          actionTimeFormat: values?.actionTimeFormat ? values.actionTimeFormat : null,

          ...(values.actualStartDate && {
            actualStartDate: formatISO(startOfDay(parseISO(values.actualStartDate))),
          }),
          ...(values.actualEndDate && {
            actualEndDate: formatISO(startOfDay(parseISO(values.actualEndDate))),
          }),
        },
        'tags',
        'durationType',
      );
      onSubmit(updatedValues as NewActionInput);
    },
    [currentWorkspaceId, onSubmit],
  );

  const initWorkspaceId =
    note?.workspaceId === unassignedWorkspaceId ? defaultWorkspaceId : note?.workspaceId;

  useEffect(() => {
    if (!convertNoteToActionEntity?.id) {
      setCurrentWorkspaceId(defaultWorkspaceId);
    }
  }, [defaultWorkspaceId]);

  return (
    <MouseOverCursorWrapper
      targetClassName="ant-drawer-mask"
      cursorImage={closeIcon}
      isOpen={!!convertNoteToActionEntity?.id}
    >
      <StyledDrawer
        destroyOnClose
        maskClosable
        width=""
        placement="right"
        closable={false}
        onClose={onClose}
        visible={!!convertNoteToActionEntity?.id}
      >
        <StyledContainer>
          {note ? (
            <Formik
              enableReinitialize
              validateOnChange={true}
              validateOnBlur={true}
              initialValues={getActionInitialValuesFromNote(note, initWorkspaceId)}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ values, isSubmitting, errors, setFieldValue, handleReset, validateForm }) => {
                useEffect(() => {
                  if (note.workspaceId === unassignedWorkspaceId) {
                    setFieldValue('workspaceId', defaultWorkspaceId || workspaceOptions[0].value);
                  } else {
                    setFieldValue('workspaceId', note.workspaceId || workspaceOptions[0].value);
                  }
                }, [note.workspaceId]);

                return (
                  <StyledForm>
                    <StyledMainContent>
                      <FormContainer>
                        <StyledCloseButton
                          ghost
                          shape="circle"
                          icon={<CloseIcon />}
                          onClick={onClose}
                        />
                        <StyledTitle level={3}>Convert to Action</StyledTitle>

                        <ActionStatusComponent
                          disabled={viewerPermission || !!note?.isArchived}
                          onChange={(value: ActionStatus) => {
                            adjustActualDateAccordingStatus({
                              status: value,
                              actualStartDate: values?.startDate?.toString(),
                              actualEndDate: values?.endDate?.toString(),
                              setActualStartDate: (value) =>
                                setFieldValue('actualStartDate', value),
                              setActualEndDate: (value) => setFieldValue('actualEndDate', value),
                            });
                            validateForm(values);
                          }}
                        />

                        <StyledLabel>Name</StyledLabel>
                        <StyledInputName
                          name="name"
                          key={`${note?.isArchived}-name-${viewerPermission}`}
                          maxLength={256}
                          autoComplete="off"
                          value={values.name ?? undefined}
                          disabled={viewerPermission || !!note?.isArchived}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('name', e?.target?.value);
                          }}
                        />
                        <StyledLabel>Description</StyledLabel>
                        <StyledDebouncedTextAreaInput
                          name="description"
                          key={`${note?.isArchived}-description-${viewerPermission}`}
                          disabled={viewerPermission || !!note?.isArchived}
                          maxLength={1000}
                          value={values.description!}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            setFieldValue('description', e?.target?.value);
                          }}
                        />

                        <Divider />
                        <StyledLabel>Space</StyledLabel>
                        {workspaceOptions && (
                          <Select
                            disabled={viewerPermission || !!note?.isArchived}
                            name="workspaceId"
                            suffixIcon={<ArrowDownIcon />}
                            placeholder="Select Space"
                            options={workspaceOptions}
                            onChange={(value) => {
                              setCurrentWorkspaceId(Number(value));
                              setFieldValue('outcomeId', null);
                            }}
                          />
                        )}
                        <StyledLabel>Link to Outcome</StyledLabel>
                        <Select
                          name="outcomeId"
                          suffixIcon={<ArrowDownIcon />}
                          notFoundContent={Messages.NO_RESULTS}
                          disabled={viewerPermission || !!note?.isArchived}
                          onClick={handleGetOutcomes}
                        >
                          {outcomes.map((outcome) => (
                            <Option value={outcome.id} key={outcome.id}>
                              {outcome.name}
                            </Option>
                          ))}
                        </Select>
                        <Divider />

                        <Title level={4}>Planned date</Title>
                        <DataRowComponent
                          startDate={{
                            name: 'startDate',
                            label: 'Start',
                            value: values?.startDate?.toString(),
                            disabled: viewerPermission || !!note?.isArchived,
                          }}
                          endDate={{
                            name: 'endDate',
                            label: 'End',
                            value: values?.endDate?.toString(),
                            disabled: viewerPermission || !!note?.isArchived,
                          }}
                          setFieldValue={setFieldValue}
                        />

                        <Title level={4}>Actual date</Title>
                        <DataRowComponent
                          startDate={{
                            name: 'actualStartDate',
                            label: 'Start',
                            value: values?.actualStartDate?.toString(),
                            disabled:
                              (values.status !== ActionStatus.Done &&
                                values.status !== ActionStatus.Doing) ||
                              viewerPermission ||
                              !!note?.isArchived,
                            hideRemoveDateButton:
                              values.status === ActionStatus.Done ||
                              values.status === ActionStatus.Doing ||
                              viewerPermission ||
                              !!note?.isArchived,
                          }}
                          endDate={{
                            name: 'actualEndDate',
                            label: 'End',
                            value: values?.actualEndDate?.toString(),
                            disabled:
                              values.status !== ActionStatus.Done ||
                              viewerPermission ||
                              !!note?.isArchived,
                            hideRemoveDateButton:
                              values.status === ActionStatus.Done ||
                              viewerPermission ||
                              !!note?.isArchived,
                          }}
                          setFieldValue={setFieldValue}
                        />
                        <Divider />
                        <Title level={4}>Planned / Actual effort</Title>
                        <DurationRowComponent
                          key={`${note?.isArchived}-effort-${viewerPermission}`}
                          setFieldValue={setFieldValue}
                          actionTimeFormat={values.actionTimeFormat}
                          error={!!errors.actionTimeFormat}
                          actualValue={values.spendTime}
                          estimatedValue={values.estimatedTime}
                          disabled={viewerPermission || !!note?.isArchived}
                          disabledSelect={
                            (!isFinite(Number(values.estimatedTime)) &&
                              !isFinite(Number(values.spendTime))) ||
                            viewerPermission ||
                            !!note?.isArchived
                          }
                        />
                        <Divider />
                        {convertNoteToActionEntity?.id && (
                          <AddTags
                            isDisabled={viewerPermission || !!note?.isArchived}
                            tags={[]}
                            workspaceId={values.workspaceId as number}
                            currentValue={values.inputTags as TagOption[]}
                          />
                        )}
                      </FormContainer>
                      <ButtonsContainer>
                        <SubmitButton
                          disabled={!isEmpty(errors) || viewerPermission || !!note?.isArchived}
                          loading={isSubmitting}
                          type="primary"
                          shape="round"
                        >
                          {note?.isArchived ? 'Incoming note is archived' : 'Create Action'}
                        </SubmitButton>
                        <Button
                          ghost
                          type="primary"
                          shape="round"
                          onClick={() => {
                            handleReset();
                            onClose();
                          }}
                        >
                          Cancel
                        </Button>
                      </ButtonsContainer>
                    </StyledMainContent>
                  </StyledForm>
                );
              }}
            </Formik>
          ) : (
            <StyledLoader />
          )}
        </StyledContainer>
      </StyledDrawer>
    </MouseOverCursorWrapper>
  );
});

export default ConvertToActionDrawer;
