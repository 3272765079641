import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import closeIcon from '../../../../../core/img/close-icon@1x.png';
import styled, { css } from 'styled-components';
import { theme } from '../../../../../core/styles/styled-components';
import { MouseOverCursorWrapper } from '../../../../../core/components/MouseOverCursorWrapper';
import { Button, Drawer, Modal, Typography } from 'antd';
import { useApolloClient, useQuery } from '@apollo/client';
import { CreateEntityFormCard } from '../../../../common/components/CreateEntityFormCard';
import { FETCH_ALL_NOTES } from '../../../graphql/queries';
import { NoteCard } from './NoteCard/NoteCard';
import useCreateNote from '../../../../interactions/note/useCreateNote';
import { Note } from '../../../../../core/types/note';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { EntityCardColumn } from '../../../../common/components';
import { Draggable, DraggableChildrenFn } from 'react-beautiful-dnd';
import { EntityName, EntityType, getCacheEntityById } from '../../../../utils';
import { useMediaQuery } from 'react-responsive';
import bodyScrollLock from '../../../../../core/utils/bodyScrollLock';
import NewInformationTabs from './NewInformationTabs';
import { sortNotes } from './utils';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';
import { ColumnType, DND_STATE, DndValue } from '../../../../../apollo/stateFields/dnd/dndFields';
import usePollDataOnActivity from '../../../../../hooks/usePollDataOnActivity';
import EditNoteModal from './EditNoteModal/EditNoteModal';
import ConvertToActionDrawer from './ConvertToActionDrawer/ConvertToActionDrawer';
import ConvertToOutcomeDrawer from './ConvertToOutcomeDrawer/ConvertToOutcomeDrawer';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../apollo/stateFields/userSettings/userSettingsField';
import { boardEditedEntityMutation } from '../../../../../apollo/stateFields/boardEditedEntity';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import {
  TUTORIAL_STATE,
  TutorialValue,
} from '../../../../../apollo/stateFields/tutorial/tutorialField';
import { UTILS_STATE, UtilsValue } from '../../../../../apollo/stateFields/utils/utilsFields';
import useDndContext from '../../../../../context/dndContext/useDndContext';
import { NewInformationProps } from './types';

const { Title } = Typography;

const StyledDrawer = styled(({ isHighlight, ...rest }) => <Drawer {...rest} />)<{
  isHighlight: boolean;
}>`
  @media ${theme.device.tablet.max} {
    top: 56px;
    transform: none !important;
  }

  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.15);

    ${({ isHighlight }) =>
      isHighlight &&
      css`
        background-color: rgba(0, 0, 0, 0.45);
      `};
  }

  .ant-drawer-content-wrapper {
    width: 100%;

    ${({ isHighlight }) =>
      isHighlight &&
      css`
        border: 1px dashed #ffffff;
      `};

    @media ${theme.device.tablet.max} {
      box-shadow: none !important;
    }

    @media ${theme.device.tablet.min} {
      width: 384px;
    }

    @media ${theme.device.desktop.min} {
      width: 382px;
      height: auto;
      top: 110px;
      bottom: 0;
    }
  }

  .ant-drawer-content {
    border-top-left-radius: 4px;
    overflow: visible;
  }

  .ant-drawer-body {
    position: relative;
    padding: 0;
    overflow: hidden;

    &:after {
      position: absolute;
      top: -12px;
      left: 55.5%;
      content: '';
      width: 25px;
      height: 25px;
      background: var(--color-main-grey-2);
      transform: rotate(45deg);
      z-index: 99999;
      opacity: 1;

      @media ${theme.device.tablet.max} {
        display: none;
      }
    }
  }

  h4 {
    font-size: 14px;
  }

  .ant-input,
  textarea.ant-input,
  .ant-picker {
    display: block;
  }

  .ant-input {
    line-height: 32px;
  }

  .ant-divider-horizontal {
    margin: 24px 0;

    &.mainDivider {
      margin-bottom: 0;

      @media ${theme.device.desktop.min} {
        display: none;
      }
    }
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  border: 1px dashed #ffffff;
`;

const StyledWrapper = styled.div`
  background: var(--color-main-grey-2);

  @media ${theme.device.desktop.min} {
    display: flex;
    height: 100%;
  }
`;

const StyledMainContent = styled.div`
  padding: 16px 6px 16px 16px;
  display: flex;
  flex-direction: column;

  @media ${theme.device.tablet.max} {
    height: var(--app-height);
  }

  @media ${theme.device.desktop.min} {
    flex: 1;
    background: var(--color-main-grey-2);
  }
`;

const StyledMainContentInner = styled.div``;

const StyledTitle = styled(Title)`
  @media ${theme.device.mobile.max} {
    font-size: 20px !important;
  }
`;

const StyledNoteCard = styled(NoteCard)`
  cursor: pointer;
  margin-right: 6px;
`;

const StyledEntityCardColumn = styled(({ showAddNoteForm, ...rest }) => (
  <EntityCardColumn {...rest} />
))<{ showAddNoteForm: boolean }>`
  background-color: initial;
  width: 100%;
  height: 100%;
  padding: 0;

  margin: 15px 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }
`;

const StyledAddActionButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: none;
  padding-left: 0;
  box-shadow: none !important;
  font-weight: 500;
  margin-bottom: 4px;

  &:after {
    display: none;
  }

  & > *:first-child {
    margin-right: 5px;
  }

  @media ${theme.device.mobile.max} {
    font-size: 12px;
  }
`;

const StyledModal = styled(({ isHighlight, ...rest }) => <Modal {...rest} />)<{
  isHighlight: boolean;
}>`
  && {
    top: 0;
    padding: 0;
    margin: 0 0 0 auto;
    width: 384px !important;

    @media ${theme.device.mobile.max} {
      margin: 0;
      width: 100% !important;
      max-width: 100%;
    }
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .ant-modal-content {
    border-top-left-radius: 4px;
    overflow: visible;
    box-shadow: none;

    @media ${theme.device.tablet.min} {
      ${({ isHighlight }) =>
        isHighlight &&
        css`
          border: 1px dashed #ffffff;
        `};
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  h4 {
    font-size: 14px;
  }

  .ant-input,
  textarea.ant-input,
  .ant-picker {
    display: block;
  }

  .ant-input {
    line-height: 32px;
  }

  .ant-divider-horizontal {
    margin: 24px 0;

    &.mainDivider {
      margin-bottom: 0;

      @media ${theme.device.desktop.min} {
        display: none;
      }
    }
  }
`;

const NewInformation = React.memo(({ clickOutside, ...restOfProps }: NewInformationProps) => {
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const apolloClient = useApolloClient();
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { currentStep: currentTutorialStep }: TutorialValue = tutorialData?.tutorial;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { unassignedWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput, updateFilterInputId: updateFilterInput }: FilterInputValue =
    filterData?.filterInput;
  const isHighlight = currentTutorialStep === 3;
  const { boardColumns } = useDndContext();
  const { noteColumn } = boardColumns;
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { isNewInformationDrawerOpen }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
  const { setIsNewInformationDrawerOpen } = boardEditedEntityMutation;
  const { data: utilsData } = useQuery(UTILS_STATE);
  const { sourceDragStartColumn }: UtilsValue = utilsData?.utils;
  const isOpen = isNewInformationDrawerOpen || isHighlight;
  const [activeTab, setActiveTab] = useState(0);

  const {
    data: notesResponse,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery(FETCH_ALL_NOTES, { fetchPolicy: 'network-only' });
  usePollDataOnActivity(startPolling, stopPolling, refetch, undefined, !isNewInformationDrawerOpen);

  const [notes, setNotes] = useState<Note[] | undefined>(notesResponse?.fetchUserWorkspacesNotes);

  const [createNote] = useCreateNote();

  const [showAddNoteForm, setShowAddNoteForm] = useState(true);

  const openedData = useRef(false);

  const onCreateNote = useCallback(
    async (name, workspaceId) => {
      try {
        await createNote({
          variables: {
            workspaceId,
            note: {
              createDate: new Date() as any,
              description: '',
              name,
              workspaceId,
            },
          },
          refetchQueries:
            workspaceId === 0 ? [{ query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT }] : [],
        });

        sendEvent('note-create', 'Note create', {
          Name: name,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [createNote],
  );

  const sortedNotes = useMemo(() => {
    return notes?.slice().sort(sortNotes);
  }, [notes, notesResponse?.fetchUserWorkspacesNotes]);

  const cardData = useMemo(() => {
    return sortedNotes?.map((n) => n.id);
  }, [sortedNotes]);

  const showedNotes = useMemo(() => {
    return sortedNotes?.filter((note) => {
      if (activeTab === 1 && note?.workspaceId === unassignedWorkspaceId) {
        return true;
      }
      if (activeTab === 2 && note?.workspaceId !== unassignedWorkspaceId) {
        return true;
      }
      return activeTab === 0;
    });
  }, [sortedNotes, activeTab]);

  //?
  useEffect(() => {
    if (updateFilterInput !== null && updateFilterInput?.length && isNewInformationDrawerOpen) {
      setIsNewInformationDrawerOpen(!isNewInformationDrawerOpen);
    }
  }, [isNewInformationDrawerOpen, updateFilterInput]);

  useEffect(() => {
    setNotes(notesResponse?.fetchUserWorkspacesNotes);
  }, [notesResponse?.fetchUserWorkspacesNotes]);

  useEffect(() => {
    if (isOpen && openedData.current) {
      setIsNewInformationDrawerOpen(false);
    }
    openedData.current = isOpen;
  }, [clickOutside, isOpen]);

  useEffect(() => {
    if (isNewInformationDrawerOpen) {
      setShowAddNoteForm(true);
    }
  }, [isNewInformationDrawerOpen]);

  const handleCloseDrawer = () => {
    setIsNewInformationDrawerOpen(false);
  };

  const handleSetActiveTab = useCallback((tab: number) => {
    setActiveTab(tab);
  }, []);

  const renderNoteCard = (noteId: number, index: number) => {
    if (sortedNotes && sortedNotes.length) {
      const entity = sortedNotes.find((note) => note.id === noteId);
      if (
        (activeTab === 1 && entity?.workspaceId !== unassignedWorkspaceId) ||
        (activeTab === 2 && entity?.workspaceId === unassignedWorkspaceId)
      ) {
        return null;
      }
      if (entity !== undefined) {
        return (
          <StyledNoteCard
            key={`${noteId}`}
            note={entity}
            isDraggable={true}
            draggablePosition={index}
          />
        );
      }
      return (
        <Draggable draggableId={`${noteId}`} index={index} key={`${noteId}`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div style={{ height: '1px', marginTop: '-1px' }} />
            </div>
          )}
        </Draggable>
      );
    }
    return null;
  };

  const renderNoteClone: DraggableChildrenFn = (provided, snapshot, rubric) => {
    useEffect(() => {
      if (snapshot.draggingOver !== ColumnType.Note && isOpen && !isMobile) {
        setIsNewInformationDrawerOpen(false);
      }
    }, [snapshot.draggingOver, isOpen]);

    const note = getCacheEntityById(
      apolloClient,
      Number(rubric.draggableId),
      EntityType.NOTE,
      filterInput,
    ) as Note;

    return (
      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
        <StyledNoteCard note={note} />
      </div>
    );
  };

  const addNoteFormEl = isOpen && (
    <div>
      {/*<StyledAddActionButton onClick={() => setShowAddNoteForm(true)} icon={<AddIcon />}>*/}
      {/*  Create New*/}
      {/*</StyledAddActionButton>*/}
      {(showAddNoteForm || isHighlight) && (
        <CreateEntityFormCard
          disabled={currentTutorialStep === 3}
          onFocus={activeTab}
          placeholder={!showedNotes?.length ? 'Add your first Incoming note' : ''}
          entityName={EntityName.NOTE}
          isShowWorkspaceSelect={activeTab === 2}
          isCancelForClear
          isDefaultWorkspace={activeTab === 2}
          onCancel={() => setShowAddNoteForm(false)}
          onSubmit={async (value) => {
            await onCreateNote(value.name, value.workspaceId);
          }}
        />
      )}
    </div>
  );

  const notesEl = notes && (
    <StyledEntityCardColumn
      isCreatePossible={false}
      entityName="Note"
      title=""
      name={noteColumn.title}
      // cardData={noteColumn.entityIds}
      cardData={cardData as number[]}
      showAddNoteForm={showAddNoteForm}
      renderCard={renderNoteCard}
      renderClone={renderNoteClone}
      onSubmit={() => {}}
    />
  );

  useEffect(() => {
    if (!isDesktop) {
      if (isOpen) {
        bodyScrollLock.enable();
      } else {
        bodyScrollLock.disable();
      }
    }
  }, [isOpen]);

  const containerEl = (
    <StyledContainer>
      <StyledWrapper>
        <StyledMainContent>
          <StyledMainContentInner data-tutorial="3">
            <StyledTitle level={3}>Incoming</StyledTitle>
            <NewInformationTabs onChange={handleSetActiveTab} activeTab={activeTab} />
            {addNoteFormEl}
          </StyledMainContentInner>
          {notesEl}
        </StyledMainContent>
      </StyledWrapper>
    </StyledContainer>
  );

  const getContent = () => {
    if (isDesktop) {
      return (
        <StyledDrawer
          maskClosable
          placement="right"
          closable={false}
          visible={isOpen}
          width=""
          {...restOfProps}
          onClose={handleCloseDrawer}
          isHighlight={isHighlight}
        >
          {containerEl}
        </StyledDrawer>
      );
    }
    return (
      <StyledModal
        getContainer={() => document.body}
        onCancel={handleCloseDrawer}
        visible={isOpen}
        closable={true}
        mask={true}
        footer={null}
        isHighlight={isHighlight}
      >
        {containerEl}
      </StyledModal>
    );
  };

  return (
    <MouseOverCursorWrapper
      targetClassName="ant-drawer-mask"
      cursorImage={closeIcon}
      isOpen={isOpen && sourceDragStartColumn !== 'Note' && currentTutorialStep !== 3}
    >
      {getContent()}
      <EditNoteModal notes={notes} />
      <ConvertToActionDrawer notes={notes} />
      <ConvertToOutcomeDrawer notes={notes} />
    </MouseOverCursorWrapper>
  );
});

export default NewInformation;

