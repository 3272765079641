import { DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { useCallback, useMemo, useState } from 'react';
import CustomTableFilter, { getCustomTableFilterProps } from './CustomTableFilter';
import { ColumnsType } from 'antd/es/table';
import { getValueFromObjectWithKeys } from '../../../../../pages/AdminPanel/utils';
import { RangePickerProps } from 'antd/es/date-picker';

const { RangePicker } = DatePicker;

const DateRangeFilter = (filterDropDownProps: FilterDropdownProps) => {
  const { setSelectedKeys } = filterDropDownProps;
  const [start, setStart] = useState<undefined | moment.Moment>(undefined);
  const [end, setEnd] = useState<undefined | moment.Moment>(undefined);

  const handleReset: React.FormEventHandler<HTMLFormElement> = useCallback((e) => {
    e.preventDefault();
    setStart(undefined);
    setEnd(undefined);
  }, []);

  const handleChange: RangePickerProps['onChange'] = useCallback(
    (e) => {
      const startMoment = e?.[0];
      if (!startMoment) {
        setStart(undefined);
        setEnd(undefined);
        setSelectedKeys && setSelectedKeys([]);
      } else {
        const start = startMoment.unix();
        const endMoment = e?.[1];
        const end = endMoment?.unix();
        setStart(startMoment);
        setEnd(endMoment ?? startMoment);
        setSelectedKeys && setSelectedKeys([[start, end ?? start] as any]);
      }
    },
    [setSelectedKeys],
  );

  const rangePickerValue: RangePickerProps['value'] = useMemo(() => [start!, end!], [start, end]);

  return (
    <CustomTableFilter onReset={handleReset} {...filterDropDownProps}>
      <RangePicker
        value={rangePickerValue}
        onChange={handleChange}
        size="middle"
        style={{ width: '100%' }}
      />
    </CustomTableFilter>
  );
};

export const getDateRangerFilterProps = (
  ...dataIndexes: string[]
): Pick<ColumnsType<object>[number], 'filterDropdown' | 'filterIcon' | 'onFilter'> => ({
  ...getCustomTableFilterProps(),
  filterDropdown: DateRangeFilter,
  onFilter: (props: any, record: object) => {
    if (!props) return true;
    const [start, end] = props;
    if (!start) return true;
    const value: number | string | Date = getValueFromObjectWithKeys(record, dataIndexes);
    let dateTime: number;
    if (typeof value === 'number') {
      dateTime = value;
    } else if (typeof value === 'string') {
      dateTime = new Date(value).getTime();
    } else if (value instanceof Date) {
      dateTime = value.getTime();
    } else {
      return false;
    }
    return dateTime >= start && dateTime <= end;
  },
});

export default DateRangeFilter;

