import { gql } from '__generated__/gql';
import '../features/workspaces/graphql/fragments';
import '../features/actions/graphql/fragments';
import '../features/outcomes/graphql/fragments';
import '../features/actions/graphql/fragments';

const SUBSCRIBE_WORKSPACE_STRING = /* GraphQL */ `
  subscription SubscribeWorkspace($triggerId: Int!) {
    subscribeToChanges(triggerId: $triggerId) {
      ... on Workspace {
        ...workspaceFields
      }
    }
  }
`;

export const SUBSCRIBE_WORKSPACE = gql(SUBSCRIBE_WORKSPACE_STRING);

const SUBSCRIBE_ACTION_STRING = /* GraphQL */ `
  subscription SubscribeAction($triggerId: Int!) {
    subscribeToChanges(triggerId: $triggerId) {
      ... on Action {
        ...actionFields
      }
      ... on ActionHistoricOutcome {
        action {
          ...actionFields
        }
        historicOutcome {
          ...outcomeFields
        }
      }
    }
  }
`;

export const SUBSCRIBE_ACTION = gql(SUBSCRIBE_ACTION_STRING);

const SUBSCRIBE_OUTCOME_STRING = /* GraphQL */ `
  subscription SubscribeOutcome($triggerId: Int!) {
    subscribeToChanges(triggerId: $triggerId) {
      ... on Outcome {
        ...outcomeFields
      }
    }
  }
`;

export const SUBSCRIBE_OUTCOME = gql(SUBSCRIBE_OUTCOME_STRING);

const SUBSCRIBE_NOTE_STRING = /* GraphQL */ `
  subscription SubscribeNote($triggerId: Int!) {
    subscribeToChanges(triggerId: $triggerId) {
      ... on Note {
        ...noteFields
      }
    }
  }
`;

export const SUBSCRIBE_NOTE = gql(SUBSCRIBE_NOTE_STRING);

