import React, { useCallback, useState } from 'react';
import { DefaultWorkSpaceSelectorProps } from './types';
import { Select as SelectAntd, Select } from 'antd';
import { ArrowDownIcon } from '../../../../core/icons';
import styled from 'styled-components';
import { StyledButton } from '../CompleteOutcome/StyledComponents';
import { useMutation } from '@apollo/client';
import { UPDATE_SETTINGS } from '../../../../graphql/mutations';
import { userSettingsMutation } from '../../../../apollo/stateFields/userSettings';

const { Option } = SelectAntd;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  margin: 16px 0 0 0;
  max-width: 267px;
  text-align: left;
`;

const StyledSubmitButton = styled(StyledButton)`
  margin-top: 24px;
  white-space: nowrap;
`;

const DefaultWorkSpaceSelector = ({
  onClose,
  filteredWorkspaces,
}: DefaultWorkSpaceSelectorProps) => {
  const [newDefaultWorkspaceId, setNewDefaultWorkspaceId] = useState<number>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { setDefaultWorkspaceId } = userSettingsMutation;
  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS);

  const handleChange = (value: any) => {
    setNewDefaultWorkspaceId(+value);
  };

  const handleChangeDefaultSpace = async () => {
    if (newDefaultWorkspaceId) {
      setDisabled(true);
      setDefaultWorkspaceId(newDefaultWorkspaceId);
      await updateSettings({
        variables: {
          settings: {
            defaultWorkspaceId: newDefaultWorkspaceId,
          },
        },
      });
      onClose(newDefaultWorkspaceId);
    }
  };

  const getOptions = useCallback(() => {
    const options = filteredWorkspaces.map((w) => {
      return {
        value: w.id,
        label: w.name,
      };
    });
    return options.map((option, index) => {
      return (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      );
    });
  }, [filteredWorkspaces]);

  return (
    <StyledContainer>
      <StyledSelect
        disabled={false}
        value={newDefaultWorkspaceId}
        suffixIcon={<ArrowDownIcon />}
        placeholder="Select another default Space"
        onChange={handleChange}
      >
        {getOptions()}
      </StyledSelect>
      <StyledSubmitButton
        isDisabled={!newDefaultWorkspaceId || disabled || loading}
        onClick={handleChangeDefaultSpace}
      >
        Set as default
      </StyledSubmitButton>
    </StyledContainer>
  );
};

export default DefaultWorkSpaceSelector;

