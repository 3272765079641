import { gql } from '__generated__';

const TAG_FIELDS_STRING = /* GraphQL */ `
  fragment tagFields on Tag {
    id
    name
    workspaceId
    createDate
    updateDate
    actionId
  }
`;

export const TAG_FIELDS = gql(TAG_FIELDS_STRING);
