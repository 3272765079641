import { gql } from '__generated__/gql';

const DND_COLUMN_FIELDS_STRING = /* GraphQL */ `
  fragment dndColumnFields on BoardColumn {
    id
    title
    entityIds
  }
`;

export const DND_COLUMN_FIELDS = gql(DND_COLUMN_FIELDS_STRING);

const COURSES_CONSTS_FIELDS_STRING = /* GraphQL */ `
  fragment coursesConstsFields on CoursesConstsArgs {
    title
    certificateUrl
    isCourseAvailable
    videos {
      duration
      id
      isAvailable
      name
      section
      url
    }
  }
`;

export const COURSES_CONSTS_FIELDS = gql(COURSES_CONSTS_FIELDS_STRING);

const COURSES_VARS_FIELDS_STRING = /* GraphQL */ `
  fragment coursesVarsFields on CoursesVarsArgs {
    dateLastWatched
    lastWatchedId
    watchedVideoIds
  }
`;

export const COURSES_VARS_FIELDS = gql(COURSES_VARS_FIELDS_STRING);

const COURSES_VAR_STRING = /* GraphQL */ `
  fragment coursesVarFields on CoursesVars {
    Foundations {
      ...coursesVarsFields
    }
    MasteringPersonalEffectiveness {
      ...coursesVarsFields
    }
    Fundamentals {
      ...coursesVarsFields
    }
    RemoteWorkProfessional {
      ...coursesVarsFields
    }
    FundamentalsManager {
      ...coursesVarsFields
    }
    RemoteWorkProfessionalManager {
      ...coursesVarsFields
    }
  }
`;

export const COURSES_VAR = gql(COURSES_VAR_STRING);

const COURSES_CONST_STRING = /* GraphQL */ `
  fragment coursesConstFields on CoursesConsts {
    Foundations {
      ...coursesConstsFields
    }
    MasteringPersonalEffectiveness {
      ...coursesConstsFields
    }
    Fundamentals {
      ...coursesConstsFields
    }
    RemoteWorkProfessional {
      ...coursesConstsFields
    }
    FundamentalsManager {
      ...coursesConstsFields
    }
    RemoteWorkProfessionalManager {
      ...coursesConstsFields
    }
  }
`;

export const COURSES_CONST = gql(COURSES_CONST_STRING);

