import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, omit } from 'lodash';
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Button, Divider, Drawer, Select as SelectAntd, Typography } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form } from 'formik-antd';
import * as Yup from 'yup';
import { ArrowDownIcon, CloseIcon } from 'core/icons';
import { theme } from 'core/styles/styled-components';
import { Outcome, OutcomeInput, OutcomeStatus } from 'core/types/outcome';
import { StatusSelect } from 'core/components/form/StatusSelect';
import { ReactComponent as DoneIcon } from 'core/svg/outcome/done.svg';
import { ReactComponent as CurrentIcon } from 'core/svg/outcome/current.svg';
import closeIcon from 'core/img/close-icon@1x.png';
import { ReactComponent as AddIcon } from 'core/svg/add.svg';
import { ReactComponent as FutureIcon } from 'core/svg/outcome/future.svg';
import { MouseOverCursorWrapper } from 'core/components/MouseOverCursorWrapper';
import {
  FETCH_USER_WORKSPACE,
  FETCH_USER_WORKSPACE_TAGS,
  FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
} from 'features/common/graphql/queries';
import { CreateEntityFormCard } from 'features/common/components/CreateEntityFormCard';
import { AddTags } from 'features/common/components/form/AddTags';
import { EditOutcomeContentProps, EditOutcomeProps } from './types';
import {
  adjustDateAccordingStatus,
  adjustStatusAccordingStartDate,
  sortActionCardsByDateAndName,
} from '../../../utils';
import { getActionOptions, getInitialValues } from './utils';
import DataRowComponent from '../common/DataRowComponent';
import { FETCH_ACTIONS } from '../../../graphql/queries';
import { toast, ToastContent } from 'react-toastify';
import { Action, ActionStatus } from '../../../../../core/types/action';
import { sortWorkspaces } from '../utils';
import { ActionCard } from '../ActionCard';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { formatISO, parseISO, startOfDay } from 'date-fns';
import { Messages } from 'core/constants/messages';
import { useMediaQuery } from 'react-responsive';
import { TagOption } from '../../../../common/components/form/AddTags/AddTags';
import {
  DebouncedTextAreaInput,
  DebouncedTextInput,
} from '../../../../../core/enchancedComponents/DebouncedTextField/DebouncedTextField';
import { Permission, Workspace } from '../../../../../core/types/workspace';
import { NOT_ASSIGNED_WORKSPACE_NAME } from '../../../../../core/constants/others';
import { Loader } from '../../../../../core/components/common';
import { Select } from '../../../../../core/components/common/Select';
import useCreateAction from '../../../../interactions/action/useCreateAction';
import { SUBSCRIBE_OUTCOME } from '../../../../../graphql/subscriptions';
import useSubscribeEntity from '../../../../../hooks/useSubscribeEntity';
import { EntityType, getCacheEntityById } from '../../../../utils';
import { updateOutcomeByStatus } from '../../../../../core/utils/outcome';
import { FILTER_USER_WORKSPACES } from '../../../../workspaces/graphql/queries';
import useUpdateOutcome from '../../../../interactions/outcome/useUpdateOutcome';
import useMoveOutcome from '../../../../interactions/outcome/useMoveOutcome';
import { getUpdateFuncForArchive } from '../../../../interactions/outcome/getUpdateFuncForArchive';
import { ARCHIVE_OUTCOME } from '../../../../outcomes/graphql/mutations';
import useUnarchiveOutcome from '../../../../interactions/outcome/useUnarchiveOutcome';
import { useCheckSharedEntityMoved } from '../../../../../hooks/useCheckSharedEntityMoved';
import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
  ERROR_STATE,
  ErrorValue,
} from '../../../../../apollo/stateFields/error/errorFields';
import { useLazyLoadEntity } from '../../../../../hooks/useLazyLoadEntity';
import { setTimeoutPromise } from '../../../../../core/utils/promiseTimeout';
import { useUserWorkspaceContext } from '../../../../../context/userWorkspaceContext';
import { Tag } from '../../../../../core/types/tag';
import { errorMutation } from '../../../../../apollo/stateFields/error';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../apollo/stateFields/userSettings/userSettingsField';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import { boardEditedEntityMutation } from '../../../../../apollo/stateFields/boardEditedEntity';
import { dndDispatch } from '../../../../../context/dndContext/dndContext';

const { Title } = Typography;
const { Option } = SelectAntd;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 100%;
    @media ${theme.device.mobile.min} {
      width: 100%;
    }
    @media ${theme.device.tablet.min} {
      width: 384px;
    }
    @media ${theme.device.desktop.min} {
      width: 847px;
      height: auto;
      top: 110px;
      bottom: 0;
    }
  }
  .ant-drawer-content {
    border-top-left-radius: 4px;
  }
  .ant-drawer-body {
    padding: 0;
  }
  h4 {
    font-size: 14px;
  }
  .ant-input,
  textarea.ant-input,
  .ant-picker {
    display: block;
  }
  .ant-input {
    line-height: 32px;
  }
  .ant-divider-horizontal {
    margin: 24px 0;
    &.mainDivider {
      margin-bottom: 0;
      @media ${theme.device.desktop.min} {
        display: none;
      }
    }
  }
`;
const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  background: var(--color-main-grey-2);
`;
const StyledForm = styled(Form)`
  background: var(--color-main-grey-2);
  height: 100%;

  @media ${theme.device.desktop.min} {
    background: var(--color-dark-grey);

    display: flex;
    min-height: 100%;
  }
`;
const StyledMainContent = styled.div`
  padding: 32px 24px 0;
  overflow: auto;
  @media ${theme.device.desktop.min} {
    flex: 1;
    padding: 32px 40px 32px 32px;
    background: var(--color-main-grey-2);
  }
`;
const StyledSideContent = styled.div`
  padding: 0 24px 100px;
  position: relative;
  min-height: calc(100% - 345px);

  @media ${theme.device.mobile.min} {
    background-color: var(--color-main-grey-2);
  }

  @media ${theme.device.desktop.min} {
    background-color: transparent;
    width: 320px;
    padding: 24px 24px 24px 28px;
  }
`;
const StyledArchivedCheckbox = styled(Checkbox)`
  margin: 0 25px;
`;
const StyledInputName = styled(DebouncedTextInput)`
  && {
    line-height: 40px;
    height: 40px;
  }
`;
const StyledDebouncedTextAreaInput = styled(DebouncedTextAreaInput)`
  && {
    height: 56px;

    @media ${theme.device.tablet.max} {
      height: 72px;
    }

    @media ${theme.device.mobile.max} {
      height: 72px;
    }
  }
`;
export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.4285em;
  color: #202635;
  line-height: 1.1428;
`;
export const StyledRemoveButton = styled(Button)`
  min-width: 0;
  width: 26px;
  height: 26px;
  margin: 0 8px;
  padding: 0;
  border-color: transparent;
`;
const StyledCloseButton = styled(StyledRemoveButton)`
  margin: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  @media ${theme.device.tablet} {
    top: 20px;
    right: 20px;
  }
  @media ${theme.device.desktop.min} {
    display: none;
  }
`;
const StyledActionSearchContainer = styled.div``;
const StyledSelect = styled(Select)`
  && {
    .ant-select-item.ant-select-item-option {
      background-color: var(--color-white);

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
`;
const StyledAddActionContainer = styled.div``;
const StyledControlBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;

  ${StyledAddActionContainer} {
    margin-bottom: 10px;
  }
  @media ${theme.device.desktop.min} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${StyledActionSearchContainer},
    ${StyledAddActionContainer} {
      flex: 1;
      margin: 0;
    }
    .ant-select {
      width: 455px;
    }
  }
`;
const StyledSelectActionButton = styled(Button)<{ disabled?: boolean }>`
  padding: 0;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
  &:hover,
  &:focus {
    background: transparent;
  }
  &:hover,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    border-color: transparent;
    background: transparent;
  }

  svg path {
    fill: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
  }
`;
const StyledBoldText = styled.span`
  margin: 0 5px;
  font-weight: 700;
`;
const StyledArrowDownIcon = styled(ArrowDownIcon)`
  margin-left: 5px;
`;
const StyledLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const StyledAddActionButton = styled(Button)<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  & > *:first-child {
    margin-right: 5px;
  }

  svg path:nth-child(2),
  svg path:nth-child(3) {
    stroke: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
  }
`;
const StyledActionList = styled.div`
  margin-top: 30px;
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EditOutcomeContent = React.memo(
  ({ outcomes, onClose, isLoading, setIsLoading }: EditOutcomeContentProps) => {
    const { data } = useQuery(ERROR_STATE);
    const { errorMessage }: ErrorValue = data?.error;
    const { setErrorMessage, setErrorWorkspaces, setEntityType } = errorMutation;
    const { actions } = useUserWorkspaceContext();
    const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
    const { updateOutcomeWithActionEntity, outcomeEditedEntity }: BoardEditedEntityValue =
      boardEditedData?.boardEditedEntity;
    const { showEditOutcomeModal, setUpdateOutcomeWithActionEntity } = boardEditedEntityMutation;
    const { showArchiveOutcomeModal } = boardEditedEntityMutation;
    const [formTouched, setFormTouched] = useState<boolean>(false);
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [outcome, setOutcome] = useState<Outcome | null>(outcomeEditedEntity);
    const apolloClient = useApolloClient();
    const dispatch = dndDispatch;
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { data: filterData } = useQuery(FILTER_STATE);
    const { filterInput }: FilterInputValue = filterData?.filterInput;
    const { setUpdateFilterInputId } = filterMutation;
    const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
    const [updateOutcome, { loading: updateLoading }] = useUpdateOutcome(outcome);
    const [moveOutcome, { loading: moveLoading }] = useMoveOutcome(outcome);
    const [unarchiveOutcomeMutation] = useUnarchiveOutcome(outcome);
    const [archiveOutcomeMutation] = useMutation(ARCHIVE_OUTCOME);
    const [showForm, setShowForm] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [currentWorkspaceId, setCurrentWorkspaceId] = useState<number>(
      outcomeEditedEntity?.workspaceId || outcome?.workspaceId || defaultWorkspaceId,
    );
    const [archiveCheckboxState, setArchiveCheckboxState] = useState(false);
    const {
      getEntity,
      data: outcomeData,
      resetData: resetOutcomeData,
      loading,
    } = useLazyLoadEntity(CUSTOM_ENTITY_TYPES.OUTCOME, outcome?.workspaceId);
    const prevWorkspace = useRef<Workspace | null>(null);
    const mutationLoading = useMemo(() => {
      return updateLoading || moveLoading || false;
    }, [updateLoading, moveLoading]);

    const outcomeRef = useRef<Outcome | null>(null);

    const initialValues = useMemo(() => getInitialValues(outcome), [outcome]);
    const { data: subscriptionData, setData: setSubscriptionData } = useSubscribeEntity(
      SUBSCRIBE_OUTCOME,
      outcome?.workspaceId,
      outcome?.id,
    );

    const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
    const [getActions, { data: workspaceActionResponse, loading: actionLoading }] = useLazyQuery(
      FETCH_ACTIONS,
      {
        fetchPolicy: 'network-only',
      },
    );

    const [getWorkspace] = useLazyQuery(FETCH_USER_WORKSPACE, {
      fetchPolicy: 'network-only',
    });

    const linkedActionsArray = useMemo(() => {
      return (
        outcome?.actions
          ?.slice()
          .map((action) => {
            let entity = getCacheEntityById(
              apolloClient,
              action.id,
              EntityType.ACTION,
              filterInput,
            ) as Action;
            if (!entity) {
              const findedAction = workspaceActionResponse?.fetchUserWorkspaceActions.find(
                (a) => a.id === action.id,
              );
              entity = findedAction as unknown as Action;
            }
            return entity;
          })
          .filter((action) => action !== undefined) || []
      );
    }, [outcome?.actions, filterInput, workspaceActionResponse?.fetchUserWorkspaceActions]);

    const workspaceActions = useMemo(() => {
      return workspaceActionResponse?.fetchUserWorkspaceActions || [];
    }, [outcome, workspaceActionResponse?.fetchUserWorkspaceActions]);

    const [createAction] = useCreateAction();
    const workspaces =
      workspacesResponse?.fetchUserWorkspacesWithInvitedCount
        .slice()
        .sort((a, b) => sortWorkspaces(a, b, defaultWorkspaceId)) || [];

    const workspace = useMemo(
      () => workspaces.find((workspace) => workspace.id === outcome?.workspaceId),
      [workspaces, outcome],
    );
    const viewerPermission = useMemo(
      () => workspace?.permission === Permission.Viewer || false,
      [workspace],
    );
    const editorPermission = useMemo(
      () => workspace?.permission === Permission.Editor || false,
      [workspace],
    );
    const isCurrentWorkspaceShared = useMemo(() => {
      const space = workspaces.find((workspace) => workspace.id === outcome?.workspaceId);
      return (space?.usersCount && space?.usersCount >= 2) || false;
    }, [workspaces, outcome?.workspaceId]);
    const actionOptions = useMemo(() => {
      return getActionOptions(workspaceActions as Action[], linkedActionsArray) || [];
    }, [workspaceActions, linkedActionsArray, outcome]);

    const mainContainerRef = useRef(null);

    const onSaveOutcomeOnUpdateAction = useCallback(
      (outcomeToUpdate: { id: number; actions: Action[]; tags: Tag[]; workspaceId: number }) => {
        const variables = {
          outcomeValues: {
            id: outcomeToUpdate.id,
            actionIds: outcomeToUpdate.actions?.map((action) => action.id),
            tagIds: outcomeToUpdate.tags.map((tag) => tag.id),
          },
          workspaceId: outcomeToUpdate.workspaceId,
        };
        return updateOutcome({
          variables,
        });
      },
      [updateOutcome, apolloClient, filterInput],
    );

    const onSubmit = useCallback(
      async (outcomeData: OutcomeInput) => {
        if (outcome) {
          try {
            const outcomeValues = {
              ...outcomeData,
              ...updateOutcomeByStatus(
                outcomeData as Outcome,
                outcome.status as OutcomeStatus,
                outcomeData.status as OutcomeStatus,
              ),
            };
            const variables =
              outcome.workspaceId === outcomeData.workspaceId
                ? { outcomeValues, workspaceId: outcome.workspaceId }
                : {
                    outcomeValues: {
                      actionIds: outcomeValues.actionIds,
                      inputTags: outcomeValues.inputTags,
                      id: outcomeValues.id,
                      workspaceId: outcomeValues.workspaceId,
                    },
                    workspaceId: outcome.workspaceId,
                    targetWorkspaceId: outcomeValues.workspaceId,
                  };
            const refetchQueries = [
              ...(!outcomeValues.inputTags?.length &&
              outcomeValues.inputTags?.length !== outcome.tags?.length
                ? []
                : !!filterInput.length
                ? [{ query: FILTER_USER_WORKSPACES, variables: { filters: filterInput } }]
                : []),
              ...(outcomeValues.workspaceId !== outcome.workspaceId
                ? [
                    {
                      query: FETCH_USER_WORKSPACE_TAGS,
                      variables: { workspaceId: outcomeValues.workspaceId },
                    },
                  ]
                : []),
            ];
            if (outcome.workspaceId === outcomeData.workspaceId) {
              return updateOutcome({
                variables,
                refetchQueries,
              }).then((res) => {
                res.data?.updateUserWorkspaceOutcome &&
                  setOutcome(res.data?.updateUserWorkspaceOutcome as Outcome);
                // outcomeEditedEntity && !isCurrentWorkspaceShared && getEntity(outcomeEditedEntity);
                outcomeEditedEntity &&
                  isCurrentWorkspaceShared &&
                  handleGetActions(res.data?.updateUserWorkspaceOutcome.workspaceId);
                sendEvent('outcome-edit', 'Submit outcome edit', {
                  'Outcome ID': outcome?.id,
                });
                return res;
              });
            } else {
              return moveOutcome({
                variables,
                refetchQueries,
              }).then((res) => {
                const outcome = res.data?.moveUserWorkspaceOutcome as Outcome;
                outcome && setOutcome(outcome);
                sendEvent('outcome-move', 'Submit outcome edit', {
                  'Outcome ID': outcome?.id,
                });
                if (outcomeEditedEntity && !errorMessage && outcome) {
                  showEditOutcomeModal(outcome);
                  if (
                    workspacesResponse?.fetchUserWorkspacesWithInvitedCount &&
                    workspacesResponse.fetchUserWorkspacesWithInvitedCount.some(
                      (w) => w.id === outcome?.workspaceId,
                    )
                  ) {
                    handleGetActions(outcome?.workspaceId);
                  }
                }
                return res;
              });
            }
          } catch (error) {
            console.error(error);
            toast(error as ToastContent);
          }
        }
      },
      [updateOutcome, outcome, apolloClient, filterInput],
    );

    const onActionCreateSubmit = useCallback(
      async (name) => {
        if (outcome) {
          try {
            // const currentSpaceFilterInput = filterInput.find(
            //   (w) => w.workspaceId === outcome?.workspaceId,
            // );
            // const filterInputSpaceTags = currentSpaceFilterInput
            //   ? currentSpaceFilterInput.tagIds
            //   : undefined;
            const actionValues = {
              name,
              workspaceId: outcome?.workspaceId,
              status: ActionStatus.Backlog,
              outcomeId: outcome.id,
              // tagIds: filterInputSpaceTags,
            };
            const createActionResp = await createAction({
              variables: { actionValues, workspaceId: outcome?.workspaceId },
            });
            sendEvent('action-create', 'Action create', {
              Name: name,
              Status: status,
            });
            return createActionResp;
          } catch (error) {
            console.error(error);
            toast(error as ToastContent);
          }
        }
      },
      [createAction, apolloClient, defaultWorkspaceId, filterInput, outcome],
    );

    const onSave = useCallback(
      async (values: any, linkedActionIds?: number[]) => {
        const updatedValues = omit(
          {
            ...values,
            inputTags: values.inputTags.map((tagOption: any) => ({
              name: tagOption.label,
            })),
            ...(values.startDate && {
              startDate: formatISO(startOfDay(parseISO(values.startDate))),
            }),
            ...(values.endDate && {
              endDate: formatISO(startOfDay(parseISO(values.endDate))),
            }),
            actionIds: linkedActionIds || linkedActionsArray?.slice().map((action) => action?.id),
            workspaceId: values.workspaceId,
          },
          'isAllActionsDone',
          'actions',
          'tags',
          '__typename',
          'isArchived',
        );

        return onSubmit(updatedValues as OutcomeInput);
      },
      [
        linkedActionsArray,
        outcomeData,
        workspaceActionResponse?.fetchUserWorkspaceActions,
        isLoading,
        onSubmit,
      ],
    );

    const onLinkExistedActionSubmit = useCallback(
      async (value) => {
        const linkAction = workspaceActionResponse?.fetchUserWorkspaceActions.find(
          (item) => item.id === value,
        );
        const isLinked = !!linkedActionsArray.find((item) => item.id === linkAction?.id);
        if (linkAction && !isLinked) {
          await onSave(
            initialValues,
            [...linkedActionsArray, linkAction].map((action) => action.id),
          );
        }

        toggleActionSearchOpenStatus(false);
      },
      [
        workspaceActionResponse?.fetchUserWorkspaceActions,
        linkedActionsArray,
        initialValues,
        onSave,
      ],
    );

    const onUnselectLinkedAction = useCallback(
      async (id: number) => {
        await onSave(
          initialValues,
          linkedActionsArray.filter((action) => action.id !== id).map((action) => action.id),
        );
      },
      [onSave, linkedActionsArray, initialValues],
    );

    const workspaceOptions = useMemo(() => {
      return workspaces
        .filter((workspace) => workspace.name !== NOT_ASSIGNED_WORKSPACE_NAME)
        .filter(
          (workspace) =>
            workspace?.permission !== Permission.Viewer ||
            outcome?.workspaceId === workspace.id ||
            false,
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((workspace) => ({
          label: workspace.name,
          value: workspace.id,
        }));
    }, [workspaces]);

    const toggleActionSearchOpenStatus = useCallback(
      (newOpenStatus: boolean = !showSearch) => {
        setShowSearch(newOpenStatus);
      },
      [showSearch],
    );

    const dataForArchive = useMemo(() => {
      return { filterInput, apolloClient, dispatch };
    }, [filterInput, apolloClient]);

    const archiveOutcome = useCallback(
      async (isArchivedWithActions) => {
        if (outcome) {
          await archiveOutcomeMutation({
            update: getUpdateFuncForArchive(isArchivedWithActions, outcome, dataForArchive),
            variables: {
              outcomeId: outcome.id,
              fullArchive: isArchivedWithActions,
              workspaceId: outcome.workspaceId!,
            },
          });

          sendEvent('outcome-archive', 'Outcome archive', {
            'Outcome ID': outcome.id,
          });
        }
      },
      [archiveOutcomeMutation, outcome, apolloClient, filterInput],
    );

    const onArchive = useCallback(
      (outcome: Outcome) => {
        if (!outcome.actions?.length) {
          archiveOutcome(false);
          showEditOutcomeModal(null);
          return;
        }
        showArchiveOutcomeModal(outcome);
      },
      [archiveOutcome, outcome],
    );

    const unarchiveOutcome = useCallback(async () => {
      if (outcome) {
        await unarchiveOutcomeMutation({
          variables: {
            outcomeId: outcome.id,
            workspaceId: outcome.workspaceId!,
          },
        });

        sendEvent('outcome-unarchive', 'Outcome unarchive', {
          'Outcome ID': outcome.id,
        });
      }
    }, [unarchiveOutcomeMutation, outcome, filterInput, apolloClient]);

    const setUnarchiveCheckboxState = () => {
      setArchiveCheckboxState(false);
      onClose();
      unarchiveOutcome();
    };

    const sendChangeEvent = (element: string) => {
      sendEvent('outcome-edit', 'Outcome edit', {
        'Outcome ID': outcome?.id,
        Element: element,
      });
    };

    const handleGetActions = useCallback(
      (workspaceId?: number | null) => {
        if (outcome?.workspaceId && !mutationLoading) {
          return getActions({ variables: { workspaceId: workspaceId || outcome?.workspaceId } });
        }
      },
      [outcome?.workspaceId, mutationLoading],
    );

    const validationSchema = Yup.object().shape({
      name: Yup.string().max(256, 'Max length of name is 256 characters!').required(),
      status: Yup.string().required(),
      description: Yup.string()
        .max(1000, 'Max length of description is 1000 characters!')
        .nullable(),
      workspaceId: Yup.number().nullable(),
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
      inputTags: Yup.array(),
    });

    useCheckSharedEntityMoved(
      outcome,
      CUSTOM_ENTITY_TYPES.OUTCOME,
      outcome?.workspaceId,
      isLoading,
    );

    useEffect(() => {
      if (mutationLoading) {
        setIsLoading(true);
      } else {
        setTimeoutPromise(100).then(() => {
          setIsLoading(false);
        });
      }
    }, [mutationLoading]);

    useEffect(() => {
      if (!!updateOutcomeWithActionEntity && outcome) {
        setSubscriptionData(undefined);
        getEntity(outcome);

        const action = updateOutcomeWithActionEntity;
        if (!!action && action.outcome) {
          onSaveOutcomeOnUpdateAction({
            id: action.outcome.id,
            actions: [...(outcome?.actions?.filter((a) => a.id !== action.id) || []), action],
            tags: outcome?.tags?.length ? outcome?.tags : [],
            workspaceId: action.workspaceId!,
          }).then(() => {
            setUpdateOutcomeWithActionEntity(null);
          });
        }
      }
    }, [updateOutcomeWithActionEntity]);

    useEffect(() => {
      if (outcome) {
        outcomeRef.current = outcome;
        const workspace = workspaces.find((workspace) => workspace.id === outcome?.workspaceId);

        if (!workspace) {
          if (prevWorkspace.current?.id) {
            getWorkspace({
              variables: {
                workspaceId: prevWorkspace.current?.id,
              },
            }).then((res) => {
              if (!res.data?.fetchUserWorkspace.id) {
                setErrorMessage(CUSTOM_ERROR_MESSAGE.NO_RELATION);
                setErrorWorkspaces([
                  {
                    id: prevWorkspace.current?.id!,
                    permission: Permission.Viewer,
                    type: 'Workspace',
                    name: prevWorkspace.current?.name,
                  },
                ]);
              }
            });
          }
        } else {
          prevWorkspace.current = workspace;
        }
      }
    }, [workspaces, outcome]);

    useEffect(() => {
      if (outcome?.workspaceId && !isLoading) {
        setCurrentWorkspaceId(outcome.workspaceId);

        if (outcomeEditedEntity && outcome.workspaceId !== outcomeEditedEntity?.workspaceId) {
          showEditOutcomeModal({
            ...outcome,
            workspaceId: outcome.workspaceId,
          });
        }
      }
    }, [outcome?.workspaceId]);

    useEffect(() => {
      if (outcome) {
        setArchiveCheckboxState(!!outcome?.isArchived);
      }
    }, [outcome?.isArchived]);

    useEffect(() => {
      if (!!subscriptionData?.subscribeToChanges) {
        if (workspaces.some((w) => w.id === subscriptionData?.subscribeToChanges?.workspaceId)) {
          setOutcome(subscriptionData?.subscribeToChanges);
        } else {
          setErrorMessage(CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED);
          setEntityType(CUSTOM_ENTITY_TYPES.OUTCOME);
        }
      }
    }, [subscriptionData?.subscribeToChanges]);

    useEffect(() => {
      let editedOutcome = null;
      const boardOutcome = outcomes?.find((o) => o.id === outcome?.id);

      if (outcomeEditedEntity) {
        if (!boardOutcome && !outcomeData && !outcome) {
          getEntity({ ...outcomeEditedEntity, workspaceId: currentWorkspaceId });
        } else {
          if (!!boardOutcome) {
            editedOutcome = boardOutcome as Outcome;
          } else if (subscriptionData?.subscribeToChanges) {
            editedOutcome = subscriptionData?.subscribeToChanges as Outcome;
          } else if (!!outcomeData) {
            editedOutcome = outcomeData as Outcome;
          } else if (outcome) {
            editedOutcome = outcome;
          } else {
            // getEntity({
            //   ...outcomeEditedEntity,
            //   workspaceId: outcome?.workspaceId || currentWorkspaceId,
            // });
          }
        }
      } else {
        resetOutcomeData();
      }
      setOutcome(editedOutcome);
    }, [outcomeData, outcomes]);

    useEffect(() => {
      if (outcomeEditedEntity) {
        if (outcome && !isLoading && !loading && !formSubmitting) {
          getEntity({ ...outcome, workspaceId: currentWorkspaceId || outcome.workspaceId });
        }
      } else {
        resetOutcomeData();
      }
    }, [outcomes.length]);

    useEffect(() => {
      if (outcomeEditedEntity && !isLoading && !formSubmitting && !actionLoading) {
        handleGetActions(outcome?.workspaceId);
      }
    }, [actions]);

    useEffect(() => {
      if (outcome && !isLoading && !formSubmitting && !actionLoading) {
        handleGetActions(outcome.workspaceId);
      }
    }, [outcome?.workspaceId, outcome?.actions]);

    const LinkedActionsComponent = useMemo(() => {
      return (
        <>
          <StyledControlBar>
            {!showSearch && (
              <StyledAddActionContainer>
                {!archiveCheckboxState && !viewerPermission && showForm ? (
                  <CreateEntityFormCard
                    isShowWorkspaceSelect={false}
                    entityName="Action"
                    initWorkspaceId={outcome?.workspaceId}
                    onCancel={() => setShowForm(false)}
                    onSubmit={async ({ name }) => {
                      setSubscriptionData(undefined);
                      const resp = await onActionCreateSubmit(name);
                      const action = resp?.data?.createUserWorkspaceAction;
                      if (!!action && action.outcome) {
                        await onSaveOutcomeOnUpdateAction({
                          id: action.outcome.id,
                          actions: [
                            ...(outcome?.actions?.filter((a) => a.id !== action.id) || []),
                            action,
                          ] as Action[],
                          tags: outcome?.tags?.length ? outcome?.tags : [],
                          workspaceId: action.workspaceId!,
                        });
                      }
                      outcome && getEntity(outcome);
                      sendChangeEvent('add action');
                      setShowForm(false);
                    }}
                  />
                ) : (
                  <StyledAddActionButton
                    disabled={archiveCheckboxState || viewerPermission}
                    onClick={() => setShowForm(!showForm)}
                    shape="round"
                    icon={<AddIcon />}
                  >
                    Create New Action
                  </StyledAddActionButton>
                )}
              </StyledAddActionContainer>
            )}
            {!showForm && (
              <StyledActionSearchContainer onClick={(e) => e.stopPropagation()}>
                {showSearch ? (
                  <SelectAntd
                    onClick={() => handleGetActions(outcome?.workspaceId)}
                    dropdownAlign={{ overflow: { adjustY: 0 } }}
                    showSearch
                    notFoundContent={actionLoading ? Messages.LOADING : Messages.NO_RESULTS}
                    placeholder="Select Action"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={async (value) => {
                      await onLinkExistedActionSubmit(value);
                      // outcome && getEntity(outcome);
                    }}
                  >
                    {actionOptions.map((option, index) => {
                      return (
                        <Option key={index} value={option.value}>
                          {option.label}
                        </Option>
                      );
                    })}
                  </SelectAntd>
                ) : (
                  <StyledSelectActionButton
                    disabled={archiveCheckboxState || viewerPermission}
                    onClick={() => toggleActionSearchOpenStatus(true)}
                  >
                    or <StyledBoldText>Select Action</StyledBoldText> from Space
                    <StyledArrowDownIcon />
                  </StyledSelectActionButton>
                )}
              </StyledActionSearchContainer>
            )}
          </StyledControlBar>

          {isDesktop && <Divider className="mainDivider" />}
          {!archiveCheckboxState && (
            <StyledActionList>
              {actionLoading && !!linkedActionsArray.length ? (
                <StyledLoaderWrapper
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                >
                  <Loader />
                </StyledLoaderWrapper>
              ) : linkedActionsArray ? (
                linkedActionsArray
                  .slice()
                  .sort(sortActionCardsByDateAndName)
                  .map((action, index) => {
                    if (action) {
                      return (
                        <ActionCard
                          key={`${action.id}-${index}`}
                          isNested
                          onUnselect={() => onUnselectLinkedAction(action.id)}
                          showStatusSelect
                          draggablePosition={index}
                          isDraggable={false}
                          action={action}
                          isEditOutcomeList={true}
                        />
                      );
                    }
                    return null;
                  })
              ) : null}
            </StyledActionList>
          )}
        </>
      );
    }, [
      archiveCheckboxState,
      showForm,
      outcome?.workspaceId,
      showSearch,
      linkedActionsArray,
      onUnselectLinkedAction,
      onLinkExistedActionSubmit,
      onSaveOutcomeOnUpdateAction,
      actionOptions,
      actionLoading,
      outcome?.actions?.length,
    ]);

    if (!workspace && outcome) {
      return null;
    }

    return outcome ? (
      <Formik
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          return onSave(values);
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          handleReset,
          submitForm,
          isValid,
          touched,
        }) => {
          const handleSubmitForm = async (): Promise<any> => {
            if (isValid) {
              setTimeout(() => {
                return submitForm();
              }, 200);
            }
          };
          useEffect(() => {
            if (!formTouched && !isEmpty(touched)) {
              setFormTouched(true);
            }
          }, [touched]);

          useEffect(() => {
            setFormSubmitting(isSubmitting);
          }, [isSubmitting]);

          return (
            <StyledForm onClick={() => toggleActionSearchOpenStatus(false)}>
              <StyledMainContent ref={mainContainerRef}>
                <Title level={3}>{viewerPermission ? `View Outcome` : `Edit Outcome`}</Title>

                <StatusSelect
                  disabled={archiveCheckboxState || viewerPermission || isLoading}
                  name="status"
                  onChange={async (e) => {
                    adjustDateAccordingStatus({
                      status: e,
                      startDate: values.startDate,
                      endDate: values.endDate,
                      setStartDate: (value) => setFieldValue('startDate', value),
                      setEndDate: (value) => setFieldValue('endDate', value),
                    });
                    await handleSubmitForm();
                  }}
                  options={[
                    {
                      label: 'Current',
                      value: OutcomeStatus.Current,
                      icon: <DoneIcon />,
                    },
                    {
                      label: 'Future',
                      value: OutcomeStatus.Future,
                      icon: <CurrentIcon color="#FFC227" />,
                    },
                    {
                      label: 'Completed',
                      value: OutcomeStatus.Completed,
                      icon: <FutureIcon color="#3CBA00" />,
                    },
                  ]}
                />

                <StyledArchivedCheckbox
                  disabled={viewerPermission || isLoading}
                  name="isArchived"
                  checked={archiveCheckboxState}
                  onChange={() => {
                    if (archiveCheckboxState) {
                      setUnarchiveCheckboxState();
                    } else {
                      sendChangeEvent('isArchived');
                      if (!outcome.actions?.length) {
                        onArchive(outcome);
                      } else if (!!outcome.actions?.length) {
                        showArchiveOutcomeModal(archiveCheckboxState ? null : outcome);
                      }
                    }
                  }}
                >
                  Archived
                </StyledArchivedCheckbox>
                <StyledLabel>Name</StyledLabel>
                <StyledInputName
                  key={`${archiveCheckboxState}${viewerPermission}${isLoading}name`}
                  disabled={archiveCheckboxState || viewerPermission || isLoading}
                  name="name"
                  maxLength={256}
                  autoComplete="off"
                  value={values.name ?? undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('name', e?.target?.value);
                  }}
                  onBlur={handleSubmitForm}
                />
                <StyledLabel>Description</StyledLabel>
                <StyledDebouncedTextAreaInput
                  key={`${archiveCheckboxState}${viewerPermission}${isLoading}description`}
                  disabled={archiveCheckboxState || viewerPermission || isLoading}
                  name="description"
                  maxLength={1000}
                  value={values.description ?? undefined}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    setFieldValue('description', e?.target?.value);
                  }}
                  onBlur={handleSubmitForm}
                />
                {isDesktop && LinkedActionsComponent}
              </StyledMainContent>

              {!isDesktop && <Divider />}

              <StyledSideContent>
                <StyledLabel>Space</StyledLabel>
                <StyledSelect
                  disabled={archiveCheckboxState || viewerPermission || isLoading}
                  name="workspaceId"
                  suffixIcon={<ArrowDownIcon />}
                  placeholder="Select Space"
                  options={workspaceOptions}
                  value={values.workspaceId}
                  onSelect={async (id: number) => {
                    setFieldValue('workspaceId', id);
                    setCurrentWorkspaceId(id);
                    if (filterInput.some((input) => input.workspaceId === id)) {
                      setUpdateFilterInputId({
                        type: 'Outcome',
                        workspaceId: id,
                        id: outcome.id,
                      });
                    }
                    await handleSubmitForm();
                  }}
                />

                <Divider />

                <Title level={4}>When</Title>
                <DataRowComponent
                  startDate={{
                    name: 'startDate',
                    label: 'Start',
                    value: values?.startDate?.toString(),
                    disabled: archiveCheckboxState || viewerPermission || isLoading,
                    onChange: async (value) => {
                      adjustStatusAccordingStartDate({
                        startDate: value?.toString(),
                        currentStatus: values?.status,
                        setStatus: (value) => setFieldValue('status', value),
                      });
                      await handleSubmitForm();
                    },
                  }}
                  endDate={{
                    name: 'endDate',
                    label: 'End',
                    value: values?.endDate?.toString(),
                    disabled: archiveCheckboxState || viewerPermission || isLoading,
                    onChange: handleSubmitForm,
                  }}
                  setFieldValue={setFieldValue}
                />

                <Divider />

                {outcome?.workspaceId && (
                  <AddTags
                    tags={outcome.tags || []}
                    workspaceId={outcome?.workspaceId}
                    isDisabled={archiveCheckboxState || viewerPermission || isLoading || false}
                    onChange={handleSubmitForm}
                    currentValue={values.inputTags as TagOption[]}
                  />
                )}

                {!isDesktop && LinkedActionsComponent}
              </StyledSideContent>
            </StyledForm>
          );
        }}
      </Formik>
    ) : (
      <StyledLoader />
    );
  },
);

const EditOutcome = React.memo(({ outcomes }: EditOutcomeProps) => {
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { outcomeEditedEntity }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
  const { showEditOutcomeModal } = boardEditedEntityMutation;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClose = useCallback(() => {
    !isLoading && showEditOutcomeModal(null);
  }, [isLoading]);

  return (
    <MouseOverCursorWrapper
      targetClassName="ant-drawer-mask"
      cursorImage={closeIcon}
      isOpen={!!outcomeEditedEntity?.id}
    >
      <StyledDrawer
        destroyOnClose
        maskClosable
        width=""
        placement="right"
        closable={false}
        onClose={onClose}
        visible={!!outcomeEditedEntity?.id}
        // zIndex={2000}
      >
        <StyledContainer>
          <StyledCloseButton ghost shape="circle" icon={<CloseIcon />} onClick={onClose} />
          {outcomeEditedEntity?.id && (
            <EditOutcomeContent
              outcomes={outcomes}
              onClose={onClose}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </StyledContainer>
      </StyledDrawer>
    </MouseOverCursorWrapper>
  );
});

export { EditOutcome };
