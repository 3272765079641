import React, { memo } from 'react';
import SharedSpacesTable from './SharedSpacesTable';
import { useMutation } from '@apollo/client';
import { FetchSharedWorkspacesQuery, Permission, Workspace } from '__generated__/graphql';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { FormEvent, useCallback, useState } from 'react';
import { StyledButtonContainer, StyledModal, StyledSubmitButton } from '../StyleModalComponents';
import UserInfo from '../UserInfo/UserInfo';
import { ADMIN_ASSIGN_PRIMARY_OWNER, ADMIN_DELETE_USERS_BY_EMAILS } from 'graphql/mutations';
import UserDeleteResultsTable from '../UserDeleteResultsTable/UserDeleteResultsTable';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import devConsole from 'core/utils/devConsole';
import { Member } from './consts';

const StyledBiggerModal = styled(StyledModal)`
  width: fit-content !important;
  max-width: min(95vw, 1000px);
  /* overflow: scroll; */
  max-height: 60vh;
  /* overflow: hidden; */
`;

const StyledModalContent = styled.div`
  overflow: auto;
  width: fit-content;
  height: 100%;
  max-height: 60vh;
  max-width: min(95vw, 1000px);
  padding: 16px;
`;

interface ISharedSpacesTablesProps {
  dataSource: FetchSharedWorkspacesQuery['fetchSharedWorkspaces'];
  isLoading?: boolean;
  onSuccess: () => void;
  onError: () => void;
  onRefetch: (additionalEmailOrId?: string | number) => Promise<void>;
}

const SharedSpacesTables = ({
  dataSource,
  isLoading,
  onError,
  onSuccess,
  onRefetch,
}: ISharedSpacesTablesProps) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [savePrimaryUserSharedSpace, setSavePrimaryUserSharedSpace] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [deleteUser, { loading: deleteUserLoading, data: deleteUsersResults }] = useMutation(
    ADMIN_DELETE_USERS_BY_EMAILS,
  );
  const [selectedUser, setSelectedUser] = useState<Member>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<Pick<Workspace, 'id'>>();
  const [assignPrimaryOwner] = useMutation(ADMIN_ASSIGN_PRIMARY_OWNER);
  const handleCloseEditModal = useCallback(() => setShowEditModal(false), []);
  const handleOpenEditModal = useCallback((workspace: Pick<Workspace, 'id'>, user: Member) => {
    setShowEditModal(true);
    setSelectedUser(user);
    setSelectedWorkspace(workspace);
  }, []);
  const handleCloseConfirmModal = useCallback(() => setShowConfirmModal(false), []);
  const handleOpenDeleteModal = useCallback((workspace: Pick<Workspace, 'id'>, user: Member) => {
    setShowConfirmModal(true);
    setSelectedUser(user);
    setSelectedWorkspace(workspace);
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    if (!selectedWorkspace) {
      devConsole.warn('No selected workspace');
      return;
    }
    setShowDeleteModal(false);
    onRefetch(selectedWorkspace.id);
  }, [onRefetch, selectedWorkspace]);

  const handleDelete = async (e?: FormEvent) => {
    e?.preventDefault();

    try {
      if (!selectedUser) {
        devConsole.warn('No selected user');
        return;
      }
      const res = await deleteUser({
        variables: {
          values: {
            emails: [selectedUser.email],
            savePrimaryUserSharedSpace,
          },
        },
      });
      if (!res.data?.deleteUsersByEmails) {
        throw new Error('Failed to delete user');
      }
      onSuccess();
    } catch (e) {
      devConsole.error(e);
      onError();
      return;
    } finally {
      setShowConfirmModal(false);
    }
    setShowDeleteModal(true);
  };

  const handleAssignPrimaryOwner = useCallback(
    async (workspace: Pick<Workspace, 'id'>, user: Member) => {
      try {
        const res = await assignPrimaryOwner({
          variables: {
            workspaceId: workspace.id,
            newPrimaryOwnerEmail: user.email,
          },
        });
        if (!res.data?.assignPrimaryOwner) {
          throw new Error('Failed to assign primary owner');
        }
        onSuccess();
        onRefetch(workspace.id);
      } catch (e) {
        devConsole.error(e);
        onError();
      }
    },
    [assignPrimaryOwner, onError, onRefetch, onSuccess],
  );
  return (
    <>
      {dataSource.map((workspace) => (
        <SharedSpacesTable
          dataSource={workspace}
          isLoading={isLoading}
          key={workspace.id}
          onAssignPrimaryOwner={handleAssignPrimaryOwner}
          // onAssignPrimaryOwner={() => {
          //   console.log('hello');
          // }}
          onDelete={handleOpenDeleteModal}
          onViewEdit={handleOpenEditModal}
        />
      ))}
      <StyledBiggerModal
        destroyOnClose
        getContainer={() => document.body}
        visible={showEditModal}
        onCancel={handleCloseEditModal}
        footer={null}
        mask={false}
        closable={true}
      >
        <StyledModalContent>
          {selectedUser && (
            <UserInfo
              email={selectedUser.email}
              onSuccess={onSuccess}
              onError={onError}
              onEmailUpdate={(newEmail) => {
                onRefetch(
                  selectedUser.permission === Permission.PrimaryOwner ? newEmail : undefined,
                );
              }}
            />
          )}
        </StyledModalContent>
        <StyledButtonContainer>
          <StyledSubmitButton onClick={handleCloseEditModal}>OK</StyledSubmitButton>
        </StyledButtonContainer>
      </StyledBiggerModal>
      <StyledBiggerModal
        destroyOnClose
        getContainer={() => document.body}
        visible={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        footer={null}
        mask={false}
        closable={true}
      >
        <StyledModalContent>
          {deleteUsersResults?.deleteUsersByEmails && (
            <UserDeleteResultsTable
              dataSource={deleteUsersResults.deleteUsersByEmails}
              isLoading={deleteUserLoading}
            />
          )}
        </StyledModalContent>
        <StyledButtonContainer>
          <StyledSubmitButton onClick={handleCloseDeleteModal}>OK</StyledSubmitButton>
        </StyledButtonContainer>
      </StyledBiggerModal>
      <ConfirmModal
        onConfirm={handleDelete}
        onCancel={handleCloseConfirmModal}
        visible={showConfirmModal}
        loading={deleteUserLoading}
      >
        <Checkbox
          name="savePrimaryUserSharedSpace"
          checked={savePrimaryUserSharedSpace}
          onChange={(e) => setSavePrimaryUserSharedSpace(e.target.checked)}
        >
          Save Primary user Shared Space
        </Checkbox>
      </ConfirmModal>
    </>
  );
};

export default memo(SharedSpacesTables) as typeof SharedSpacesTables;

