import { ApolloClient } from '@apollo/client';
import { Action } from '../../core/types/action';
import { Outcome } from '../../core/types/outcome';
import { Note } from '../../core/types/note';
import { FILTER_USER_WORKSPACES } from '../workspaces/graphql/queries';
import { FETCH_ALL_NOTES } from '../actions/graphql/queries';
import { EntityType, Filter } from './index';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../common/graphql/queries';
import { Workspace } from '../../core/types/workspace';

export const getCacheEntityById = (
  client: ApolloClient<any>,
  entityId: number,
  type: EntityType,
  filterInput: Filter[],
): Action | Outcome | Note | null => {
  let result = null;
  client
    .readQuery({
      query: FILTER_USER_WORKSPACES,
      variables: {
        filters: filterInput,
      },
    })
    ?.filterUserWorkspaces.forEach((workspace) => {
      switch (type) {
        case EntityType.ACTION:
          workspace.actions?.forEach((action) => {
            if (action.id === entityId) {
              result = action;
            }
          });
          return;
        case EntityType.OUTCOME:
          workspace.outcomes?.forEach((outcome) => {
            if (outcome.id === entityId) {
              result = outcome;
            }
          });
          return;
      }
    });
  if (type === EntityType.NOTE) {
    client.readQuery({ query: FETCH_ALL_NOTES })?.fetchUserWorkspacesNotes.forEach((note) => {
      if (note.id === entityId) {
        result = note;
      }
    });
  }
  if (type === EntityType.WORKSPACE) {
    client
      .readQuery({
        query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
      })
      ?.fetchUserWorkspacesWithInvitedCount.forEach((workspace) => {
        if (workspace.id === entityId) {
          result = workspace;
        }
      });
  }

  return result;
};

export const getCacheWorkspaceByEntityId = (
  client: ApolloClient<any>,
  entityId: number,
  filterInput: Filter[],
): Workspace | null => {
  let result: Workspace | null = null;
  let workspaceIdFromNote: number | null | undefined = null;

  client.readQuery({ query: FETCH_ALL_NOTES })?.fetchUserWorkspacesNotes.forEach((note) => {
    if (note.id === entityId) {
      workspaceIdFromNote = note.workspaceId;
    }
  });

  client
    .readQuery({
      query: FILTER_USER_WORKSPACES,
      variables: {
        filters: filterInput,
      },
    })
    ?.filterUserWorkspaces.forEach((workspace) => {
      workspace.id === workspaceIdFromNote && (result = workspace as Workspace);
      !result &&
        workspace.actions?.forEach((action) => {
          if (action.id === entityId) {
            result = workspace as Workspace;
          }
        });
      !result &&
        workspace.outcomes?.forEach((outcome) => {
          if (outcome.id === entityId) {
            result = workspace as Workspace;
          }
        });
    });

  return result;
};

export const getCacheWorkspaceById = (
  client: ApolloClient<any>,
  workspaceId?: number | null,
): Workspace | null => {
  let result: Workspace | null = null;

  result =
    client
      .readQuery({
        query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
      })
      ?.fetchUserWorkspacesWithInvitedCount.find((w) => {
        if (w.id === workspaceId) {
          return true;
        }
      }) || null;
  return result;
};

export const getCacheFilterWorkspaceById = (
  client: ApolloClient<any>,
  filters: Filter[],
  workspaceId?: number,
): Workspace | null => {
  let result: Workspace | null = null;

  result = (client
    .readQuery({ query: FILTER_USER_WORKSPACES, variables: { filters } })
    ?.filterUserWorkspaces.find((w) => {
      if (w.id === workspaceId) {
        return true;
      }
    }) || null) as Workspace | null;
  return result;
};

