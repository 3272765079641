import { Action, ActionInput } from 'core/types/action';
import { ActionTimeFormat } from '../types';
import { convertFromMs } from '../utils';

export const DEFAULT_ACTION_TIME_FORMAT: ActionTimeFormat = ActionTimeFormat.Minutes;

export function getInitialValues(action: Action | null): Partial<ActionInput> {
  if (action === null) {
    return {};
  }

  return {
    ...action,
    inputTags:
      action.tags?.map((tag) => {
        return { label: tag.name, name: tag.name, value: tag.id };
      }) || [],
    outcomeId: action.outcome?.id,
    estimatedTime: action?.estimatedTime
      ? convertFromMs(
          action?.estimatedTime * 1000,
          action?.actionTimeFormat || DEFAULT_ACTION_TIME_FORMAT,
        )
      : null,
    spendTime: action?.spendTime
      ? convertFromMs(
          action?.spendTime * 1000,
          action?.actionTimeFormat || DEFAULT_ACTION_TIME_FORMAT,
        )
      : null,
    actionTimeFormat: action?.actionTimeFormat
      ? action?.actionTimeFormat
      : DEFAULT_ACTION_TIME_FORMAT,
  };
}

