import { StyledButtonsContainer, StyledDescription } from '../StyledComponents';
import { Upload, UploadProps } from 'antd';
import { Button } from '../../../core/components/common';
import { UploadOutlined } from '@ant-design/icons';
import { API_REST_UPLOAD_USERS_URL } from '../../../core/integrations/api/config';
import { useAuth } from '../../../context/authContext';
import axios from 'axios';
import devConsole from 'core/utils/devConsole';
import { useState } from 'react';
import LabeledCheckbox from '../ApplyUsersCheckbox/ApplyUsersCheckbox';
import UserDataTable, { UserDataTableType } from '../UserDataTable/UserDataTable';
import UserErrorDataTable from '../UserErrorDataTable/UserErrorDataTable';
import CsvUploadInstructions from '../CsvUploadInstructions/CsvUpsertInstructions';
import { UploadFile } from 'antd/es/upload/interface';
import { FailedUserInfo, UploadUserResponse, UserData } from '../UserErrorDataTable/types';

interface UpsertUsersCsvProps {
  setIsSuccess: (isSuccess: boolean) => void;
  setErrorMessage: (errorMessage: string | null) => void;
}

enum UploadAction {
  Upsert = 'upsert',
  Create = 'create',
  Update = 'update',
  None = 'none',
}

const getDataTableTitle = (
  uploadAction: UploadAction,
  uploadTitleName: keyof UploadUserResponse,
): string => {
  if (uploadAction === UploadAction.None) {
    if (uploadTitleName === 'failed') {
      return `Users who would fail`;
    }
    return `Users who would be ${uploadTitleName}`;
  }
  switch (uploadTitleName) {
    case 'created':
    case 'updated':
      return `Users ${uploadTitleName} successfully`;
    case 'failed':
    case 'skipped':
      return `Users ${uploadTitleName}`;
  }
  devConsole.log('Unknown Upload Title Name');
};

const UpsertUsersCsv = ({ setIsSuccess, setErrorMessage }: UpsertUsersCsvProps) => {
  const [shouldCreate, setShouldCreate] = useState<boolean>(false);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [createdList, setCreatedList] = useState<UserData[]>([]);
  const [updatedList, setUpdatedList] = useState<UserData[]>([]);
  const [skippedList, setSkippedList] = useState<FailedUserInfo[]>([]);
  const [failedList, setFailedList] = useState<FailedUserInfo[]>([]);
  const [uploadedFile, setUploadedFile] = useState<UploadFile | undefined>();
  const [uploadedAction, setUploadedAction] = useState<UploadAction>(UploadAction.None);
  const [isLoading, setIsLoading] = useState(false);

  let uploadAction: UploadAction;
  if (shouldCreate) {
    uploadAction = shouldUpdate ? UploadAction.Upsert : UploadAction.Create;
  } else {
    uploadAction = shouldUpdate ? UploadAction.Update : UploadAction.None;
  }

  const { idToken } = useAuth();
  const props: UploadProps = {
    action: API_REST_UPLOAD_USERS_URL + (uploadAction && '-') + uploadAction,
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${idToken}`,
      Accept: '*/*',
    },
    showUploadList: {
      showRemoveIcon: false,
      showPreviewIcon: true,
    },
    customRequest: (options: any) => {
      const data = new FormData();
      data.append('file', options.file);
      const config = {
        headers: {
          authorization: `Bearer ${idToken}`,
          'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        },
      };
      axios
        .post<UploadUserResponse>(options.action, data, config)
        .then(({ data }) => {
          options.onSuccess(data, options.file);
          const { created, failed, skipped, updated } = data;
          setCreatedList(created);
          setSkippedList(skipped);
          setUpdatedList(updated);
          setFailedList(failed);
          setIsSuccess(true);
          setUploadedFile(options.file);
          setUploadedAction(uploadAction);
        })
        .catch((err: Error) => {
          setErrorMessage('Oops');
          devConsole.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        devConsole.info(info.file, info.fileList);
      } else {
        setIsLoading(true);
      }
      if (info.file.status === 'done') {
        setIsSuccess(true);
        setUploadedFile(info.file);
        setUploadedAction(uploadAction);
      } else if (info.file.status === 'error') {
        setErrorMessage('Ooops');
      }
    },
    fileList: uploadedFile ? [uploadedFile] : [],
    accept: '.xlsx, .xls, .csv',
  };

  return (
    <>
      <StyledDescription>Add/Update users by csv file.</StyledDescription>
      <CsvUploadInstructions onSuccess={setIsSuccess} onError={setErrorMessage} />
      <LabeledCheckbox setValue={setShouldCreate} value={shouldCreate}>
        Create New Users
      </LabeledCheckbox>
      <LabeledCheckbox setValue={setShouldUpdate} value={shouldUpdate}>
        Update Existing Users
      </LabeledCheckbox>
      Not selecting any checkbox will perform a test run
      <StyledButtonsContainer>
        <Upload {...props}>
          <Button
            icon={<UploadOutlined />}
            isLoading={isLoading}
            disabled={isLoading}
            isDisabled={isLoading}
          >
            Click to Upload
          </Button>
        </Upload>
      </StyledButtonsContainer>
      {createdList.length > 0 && (
        <UserDataTable
          type={UserDataTableType.Create}
          userDataList={createdList}
          isLoading={isLoading}
          title={getDataTableTitle(uploadedAction, 'created')}
        />
      )}
      {updatedList.length > 0 && (
        <UserDataTable
          type={UserDataTableType.Update}
          userDataList={updatedList}
          isLoading={isLoading}
          title={getDataTableTitle(uploadedAction, 'updated')}
        />
      )}
      {skippedList.length > 0 && (
        <UserErrorDataTable
          userErrorDataList={skippedList}
          isLoading={isLoading}
          title={getDataTableTitle(uploadedAction, 'skipped')}
        />
      )}
      {failedList.length > 0 && (
        <UserErrorDataTable
          userErrorDataList={failedList}
          isLoading={isLoading}
          title={getDataTableTitle(uploadedAction, 'failed')}
        />
      )}
    </>
  );
};

export default UpsertUsersCsv;

