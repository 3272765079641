import { gql } from '__generated__/gql';
import '../../common/graphql/fragments';

const OUTCOME_FIELDS_STRING = /* GraphQL */ `
  fragment outcomeFields on Outcome {
    id
    name
    status
    description
    startDate
    endDate
    updateDate
    completeDate
    workspaceId
    isArchived
    createDate
    actions {
      id
      # createDate
      # name
      # status
    }
    tags {
      ...tagFields
    }
  }
`;

export const OUTCOME_FIELDS = gql(OUTCOME_FIELDS_STRING);

export const OUTCOME_FIELDS_UNARCHIVE_STRING = /* GraphQL */ `
  fragment outcomeFieldsUnarchive on Outcome {
    id
    name
    # createDate
    status
    description
    startDate
    endDate
    updateDate
    completeDate
    workspaceId
    isArchived
    actions {
      id
      # createDate
      actualEndDate
      actualPoints
      estimatedPoints
      actualStartDate
      assigneeId
      description
      endDate
      name
      outcome {
        id
        name
        # status
        # createDate
      }
      startDate
      status
      tags {
        ...tagFields
      }
      updateDate
      workspaceId
      isArchived
    }
    tags {
      name
      id
    }
  }
`;

export const OUTCOME_FIELDS_UNARCHIVE = gql(OUTCOME_FIELDS_UNARCHIVE_STRING);

