import axios, { AxiosRequestConfig } from 'axios';
import { API_GRAPHQL_URL } from 'core/integrations/api/config';

const axiosInstance = axios.create({
  baseURL: API_GRAPHQL_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

interface AxiosGqlOptions<Variables> {
  tokenId?: string;
  variables?: Variables;
  admin?: string;
}

interface AxiosGqlPayload<Variables> {
  query: string;
  variables?: Variables;
}

type GraphQLResponse<Data = any> = {
  data: Data;
  errors: {
    message: string;
    code: string;
  }[];
};

export const axiosGql = <T = any, Variables extends Record<string, any> = Record<string, any>>(
  query: string,
  { tokenId, variables, admin }: AxiosGqlOptions<Variables>,
) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: tokenId,
    admin,
  };

  const data: AxiosGqlPayload<Variables> = { query, variables };

  return axiosInstance.post<GraphQLResponse<T>>('', data, {
    headers,
  });
};

