import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OutcomeArchiveModal } from '../OutcomeArchiveModal';
import { Outcome } from '../../../../../core/types/outcome';
import { useLazyLoadEntity } from '../../../../../hooks/useLazyLoadEntity';
import { CUSTOM_ENTITY_TYPES } from '../../../../../apollo/stateFields/error/errorFields';
import { useUserWorkspaceContext } from '../../../../../context/userWorkspaceContext';
import { getUpdateFuncForArchive } from '../../../../interactions/outcome/getUpdateFuncForArchive';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { ARCHIVE_OUTCOME } from '../../../../outcomes/graphql/mutations';
import { ArchiveOptions } from '../OutcomeArchiveModal/types';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../../apollo/stateFields/filterInput';
import { boardEditedEntityMutation } from '../../../../../apollo/stateFields/boardEditedEntity';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import { dndDispatch } from '../../../../../context/dndContext/dndContext';

const ArchiveOutcomeManager = React.memo(() => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;
  const dispatch = dndDispatch;
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { outcomeArchiveEditedEntity }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
  const { showArchiveOutcomeModal, showEditOutcomeModal } = boardEditedEntityMutation;
  const [outcome, setOutcome] = useState<Outcome | null>(null);
  const { outcomes } = useUserWorkspaceContext();
  const [archiveOutcomeMutation] = useMutation(ARCHIVE_OUTCOME);
  const {
    getEntity,
    data: outcomeData,
    resetData: resetOutcomeData,
    loading,
  } = useLazyLoadEntity(CUSTOM_ENTITY_TYPES.OUTCOME, outcomeArchiveEditedEntity?.workspaceId);

  const dataForArchive = useMemo(() => {
    return { filterInput, apolloClient, dispatch };
  }, [filterInput, apolloClient]);

  const archiveOutcome = useCallback(
    async (isArchivedWithActions) => {
      if (outcome) {
        await archiveOutcomeMutation({
          update: getUpdateFuncForArchive(isArchivedWithActions, outcome, dataForArchive),
          variables: {
            outcomeId: outcome.id,
            fullArchive: isArchivedWithActions,
            workspaceId: outcome.workspaceId!,
          },
        });

        sendEvent('outcome-archive', 'Outcome archive', {
          'Outcome ID': outcome.id,
        });
      }
    },
    [archiveOutcomeMutation, outcome, apolloClient, filterInput],
  );

  const confirmArhciveOutcome = useCallback(
    async (option: ArchiveOptions) => {
      setUpdateFilterInputId(null);
      showArchiveOutcomeModal(null);
      showEditOutcomeModal(null);

      if (option === ArchiveOptions.WITH_ACTIONS) {
        await archiveOutcome(true);
        return;
      }
      await archiveOutcome(false);
      return;
    },
    [outcome, archiveOutcome],
  );

  useEffect(() => {
    let editedOutcome = null;
    if (outcomeArchiveEditedEntity) {
      editedOutcome = outcomes?.find((o) => o.id === outcomeArchiveEditedEntity.id) || null;
      if (!editedOutcome) {
        if (
          !outcomeData ||
          (outcomeData.workspaceId !== outcomeArchiveEditedEntity.workspaceId && !loading)
        ) {
          outcomeArchiveEditedEntity.workspaceId && getEntity(outcomeArchiveEditedEntity);
        } else {
          editedOutcome = outcomeData as Outcome;
          setOutcome(editedOutcome);
        }
      } else {
        setOutcome(editedOutcome);
      }
    } else {
      resetOutcomeData();
    }
  }, [outcomeData, outcomes, outcomeArchiveEditedEntity]);

  return (
    <>
      {outcome ? (
        <OutcomeArchiveModal
          destroyOnClose
          visible={!!outcomeArchiveEditedEntity?.id}
          count={outcome?.actions?.length!}
          onCancel={() => showArchiveOutcomeModal(null)}
          onConfirm={confirmArhciveOutcome}
        />
      ) : null}
    </>
  );
});

export { ArchiveOutcomeManager };

