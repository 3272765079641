import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import Highlighter from 'react-highlight-words';
import { Outcome, OutcomeStatus } from 'core/types/outcome';
import { Permission, Workspace } from 'core/types/workspace';
import { ReactComponent as LockIcon } from 'core/svg/lock.svg';
import { ReactComponent as ArrowRight } from 'core/svg/arrow-right.svg';
import { formatDate } from 'core/utils/date';
import { EntityCard } from 'features/common/components';
import {
  FETCH_USER_WORKSPACE_TAGS,
  FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
} from 'features/common/graphql/queries';
import { ReactComponent as StatusCurrentIcon } from './svg/current.svg';
import { ReactComponent as StatusCompletedIcon } from './svg/completed.svg';
import { ReactComponent as StatusFutureIcon } from './svg/future.svg';
import { OutcomeCardProps } from './types';
import {
  CONTEXT_MENU_OPTION_TO_STATUS,
  CONTEXT_MENU_OPTIONS_BY_STATUS,
} from '../OutcomeCardColumn/constants';
import { ContextMenuOptions } from '../OutcomeCardColumn/types';
import { updateOutcomeByStatus } from '../../../../core/utils/outcome';
import { omit } from 'lodash-es';
import { ConfirmModal } from '../../../common/components/ConfirmModal';
import { toast, ToastContent } from 'react-toastify';
import { ContextMenuOption } from '../../../../core/components/common/Card/types';
import WorkspaceSelectMenu from '../../../common/components/WorkspaceSelectMenu/WorkspaceSelectMenu';
import { sendEvent } from '../../../../core/integrations/sentry/events';
import { getUpdateFuncForArchive } from '../../../interactions/outcome/getUpdateFuncForArchive';
import { ARCHIVE_OUTCOME } from '../../graphql/mutations';
import { sortWorkspaces } from '../../../actions/components/ActionBoard/utils';
import { FETCH_ACTIONS } from '../../../actions/graphql/queries';
import { FILTER_USER_WORKSPACES } from '../../../workspaces/graphql/queries';
import { EntityType } from '../../../utils';
import { theme } from '../../../../core/styles/styled-components';
import { Tags } from '../../../actions/components/ActionBoard/ActionCard/Tags';
import { SharedPeopleIcon } from '../../../workspaces/components/ShareWorkspace/SharedPeopleIcon/SharedPeopleIcon';
import { getTrimmedString } from '../../../actions/components/ActionBoard/ActionCard/utils';
import useMoveOutcome from '../../../interactions/outcome/useMoveOutcome';
import useUpdateOutcome from '../../../interactions/outcome/useUpdateOutcome';
import useUnarchiveOutcome from '../../../interactions/outcome/useUnarchiveOutcome';
import { ERROR_STATE, ErrorValue } from '../../../../apollo/stateFields/error/errorFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import { boardEditedEntityMutation } from '../../../../apollo/stateFields/boardEditedEntity';
import { dndDispatch } from '../../../../context/dndContext/dndContext';
import useWorkspaceInvites from '../../../../hooks/useWorkspaceInvites';
import {
  HIGHLIGHTED_ENTITIES,
  HighlightedEntitiesValue,
} from '../../../../apollo/stateFields/highlightedEnntities/highlightedEntityField';

const StyledArrowRight = styled(ArrowRight)`
  margin: 0 4px !important;
`;

const StyledSharedPeopleIcon = styled(SharedPeopleIcon)`
  margin-right: 5px;
`;

const StyledIconBlock = styled.div`
  position: relative;
  margin: -2px 8px 0 0;
`;

const StyledLockIcon = styled((props) => <LockIcon {...props} />)`
  width: 9px;
  height: 9px;
  position: absolute;
  bottom: 10px;
  right: 5px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledOutcomeWorkspaceWrapper = styled.div`
  font-size: 9px;
  display: flex;
  align-items: center;
  line-height: 1;
`;

const StyledOutcomeWorkspace = styled.span<{ isShared?: boolean; isSimple?: boolean }>`
  margin-right: 4px;
  padding: 3px 3px;
  background-color: ${({ isShared }) =>
    isShared ? 'rgba(255, 194, 39, 0.4)' : 'rgba(177, 190, 249, 0.4)'};
  color: ${({ isShared }) => (isShared ? '#4A3602' : '#102DBC')};
  border-radius: 2px;
  font-size: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isSimple }) =>
    isSimple &&
    css`
      white-space: nowrap;
      max-width: 130px !important;
      overflow: hidden;
      text-overflow: ellipsis;

      @media ${theme.device.mobile.max} {
        max-width: 58px !important;
      }
    `};

  @media ${theme.device.mobile.min} {
    max-width: 140px;
  }

  @media ${theme.device.tablet.min} {
    max-width: 190px;
  }

  @media ${theme.device.desktop.min} {
    max-width: 140px;
  }

  @media ${theme.device.largeDesktop.min} {
    max-width: 190px;
  }
`;

const StyledOutcomeName = styled.span<{ isSimple?: boolean; isEllipsis?: boolean }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);
  display: -webkit-box;
  -webkit-line-clamp: 3; // add scroll
  -webkit-box-orient: vertical;

  @media ${theme.device.desktop.min} {
    max-width: 275px;
  }

  ${({ isSimple }) =>
    isSimple &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    `};
`;

const StyledDates = styled.div`
  color: var(--color-dark-blue);
  font-size: 9px;
  line-height: 1;
  margin: 7px 0 0 21px;
`;

const StyledEntityCard = styled(({ disabled, isSimple, isTransparent, ...props }) => (
  <EntityCard {...props} />
))<{
  isTransparent: boolean | null;
  disabled?: boolean;
  isSimple?: boolean;
}>`
  transition: all ease-in-out 200ms;

  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledDates} {
        color: var(--color-grey);
      }
    `}
  ${({ isSimple }) =>
    isSimple &&
    css`
      max-width: 180px;
      width: 100%;
      min-height: 56px;
      pointer-events: none;
    `}

  @media ${theme.device.desktop.min} {
    ${({ isTransparent }) =>
      isTransparent &&
      css`
        opacity: 0.4;
      `}
  }
`;

const StyledWorkspaceSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledConfirmModal = styled((props) => <ConfirmModal {...props} />)`
  && {
    .ant-modal-body > div {
      div:last-child {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
  }
`;

const OUTCOME_ICON_MAP = {
  [OutcomeStatus.Completed]: StatusCompletedIcon,
  [OutcomeStatus.Current]: StatusCurrentIcon,
  [OutcomeStatus.Future]: StatusFutureIcon,
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  width: 100%;
`;

const StyledTagsWrapper = styled.div`
  display: flex;
  width: calc(100% - 1rem);
  overflow: hidden;
`;

const OutcomeCard = React.memo(
  ({ outcome, highlightText, isNested, isSimple = false, ...restOfProps }: OutcomeCardProps) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(ERROR_STATE);
    const { errorWorkspaces }: ErrorValue = data?.error;
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { data: filterData } = useQuery(FILTER_STATE);
    const { filterInput }: FilterInputValue = filterData?.filterInput;
    const { data: highlightData } = useQuery(HIGHLIGHTED_ENTITIES);
    const { hoveredOutcomeId }: HighlightedEntitiesValue = highlightData?.highlightedEntities;
    const dispatch = dndDispatch;
    const { showEditOutcomeModal, showArchiveOutcomeModal } = boardEditedEntityMutation;
    const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
    const workspaces =
      workspacesResponse?.fetchUserWorkspacesWithInvitedCount
        ?.slice()
        ?.sort((a: Workspace, b: Workspace) => sortWorkspaces(a, b, defaultWorkspaceId)) || [];
    const currentWorkspace = workspaces.find(
      (workspace: Workspace) => workspace.id === outcome?.workspaceId,
    );

    const { loading, getWorkspaceInvitesById } = useWorkspaceInvites();
    const [moveOutcome, { loading: loadingMoveOutcome }] = useMoveOutcome(outcome);
    const [updateOutcome] = useUpdateOutcome(outcome);
    const [archiveOutcomeMutation] = useMutation(ARCHIVE_OUTCOME);
    const [unarchiveOutcomeMutation] = useUnarchiveOutcome(outcome);

    const sharedUsersCount = getWorkspaceInvitesById(outcome?.workspaceId!)?.count || 0;
    const isShared = sharedUsersCount > 1;
    const viewerPermission = useMemo(
      () => currentWorkspace?.permission === Permission.Viewer || false,
      [currentWorkspace, outcome],
    );
    const editorPermission = useMemo(
      () => currentWorkspace?.permission === Permission.Editor || false,
      [currentWorkspace, outcome],
    );
    const { name, id, startDate, endDate, status, isArchived } = outcome;
    const OutcomeIcon = OUTCOME_ICON_MAP[status || OutcomeStatus.Current];

    const setOutcomeToUpdate = (outcomeToUpdate: Outcome, status: OutcomeStatus) => {
      let outcomeValues = updateOutcomeByStatus(
        outcomeToUpdate,
        outcome.status as OutcomeStatus,
        status,
      ) as Outcome & {
        actionIds: number[];
        tagIds: number[];
      };
      const actionIds = outcomeValues.actions?.map((action) => action.id) || [];
      const tagIds = outcomeValues.tags?.map((tag) => tag.id) || [];
      outcomeValues = omit(outcomeValues, '__typename', 'actions', 'tags', 'isArchived');
      outcomeValues.actionIds = actionIds;
      outcomeValues.tagIds = tagIds;
      updateOutcome({
        variables: { outcomeValues, workspaceId: outcomeValues.workspaceId },
      });
      sendEvent('outcome-edit', 'Outcome edit', {
        'Outcome ID': outcome?.id,
      });
    };
    const workspaceName = currentWorkspace?.name
      ? getTrimmedString(currentWorkspace?.name, 41)
      : !!errorWorkspaces?.find((w) => Number(w.id) === outcome?.workspaceId)?.name
      ? getTrimmedString(
          errorWorkspaces?.find((w) => Number(w.id) === outcome?.workspaceId)?.name,
          41,
        )
      : '';
    const dataForArchive = useMemo(() => {
      return { filterInput, apolloClient, dispatch };
    }, [filterInput, apolloClient]);

    const archiveOutcome = useCallback(
      async (isArchivedWithActions) => {
        await archiveOutcomeMutation({
          update: getUpdateFuncForArchive(isArchivedWithActions, outcome, dataForArchive),
          variables: {
            outcomeId: outcome.id,
            fullArchive: isArchivedWithActions,
            workspaceId: outcome?.workspaceId!,
          },
        });

        sendEvent('outcome-archive', 'Outcome archive', {
          'Outcome ID': outcome.id,
        });
      },
      [archiveOutcomeMutation, outcome, apolloClient, filterInput],
    );

    const unarchiveOutcome = useCallback(async () => {
      await unarchiveOutcomeMutation({
        variables: {
          outcomeId: outcome.id,
          workspaceId: outcome?.workspaceId!,
        },
      });

      sendEvent('outcome-unarchive', 'Outcome unarchive', {
        'Outcome ID': outcome.id,
      });
    }, [unarchiveOutcomeMutation, outcome, filterInput, apolloClient]);

    const onOutcomeWorkspaceChange = useCallback(
      async (option, oldWorkspaceId = -1) => {
        const newWorkspaceId = option.value;
        const refetchQueries = outcome.actions?.length
          ? [
              { query: FETCH_ACTIONS, variables: { workspaceId: newWorkspaceId } },
              {
                query: FETCH_USER_WORKSPACE_TAGS,
                variables: { workspaceId: newWorkspaceId },
              },
              ...(!!filterInput.length
                ? [{ query: FILTER_USER_WORKSPACES, variables: { filters: filterInput } }]
                : []),
            ]
          : [{ query: FETCH_ACTIONS, variables: { workspaceId: newWorkspaceId } }];
        try {
          await moveOutcome({
            variables: {
              outcomeValues: {
                id: outcome.id,
                workspaceId: oldWorkspaceId,
                actionIds: outcome.actions?.length
                  ? [...outcome.actions?.map((action) => action.id)]
                  : [],
                inputTags: outcome.tags?.length
                  ? outcome.tags?.map((tag) => ({ name: tag.name }))
                  : [],
              },
              workspaceId: oldWorkspaceId,
              targetWorkspaceId: newWorkspaceId,
            },
            refetchQueries,
          });
        } catch (error) {
          console.error(error);
          toast(error as ToastContent);
        }
      },
      [outcome, moveOutcome, apolloClient, filterInput],
    );

    const handleContextMenuOptionClick = (option: ContextMenuOption) => {
      const label = option.label;

      switch (label) {
        case ContextMenuOptions.EDIT_OUTCOME.label:
          showEditOutcomeModal(outcome);
          break;
        case ContextMenuOptions.VIEW_OUTCOME.label:
          showEditOutcomeModal(outcome);
          break;
        case ContextMenuOptions.DELETE.label:
          break;
        case ContextMenuOptions.COMPLETE_OUTCOME.label:
        case ContextMenuOptions.MAKE_FUTURE.label:
        case ContextMenuOptions.MAKE_CURRENT.label:
          setOutcomeToUpdate(outcome, CONTEXT_MENU_OPTION_TO_STATUS[label]);
          break;
        case ContextMenuOptions.ARCHIVE.label:
          if (!outcome.actions?.length) {
            archiveOutcome(false);
            break;
          }
          showArchiveOutcomeModal(outcome);
          break;
        case ContextMenuOptions.UNARCHIVE.label:
          unarchiveOutcome();
          break;
      }
    };
    const icon = (
      <StyledIconBlock>
        <OutcomeIcon />
        {isArchived && <StyledLockIcon />}
      </StyledIconBlock>
    );

    const contextMenuOptions = viewerPermission
      ? CONTEXT_MENU_OPTIONS_BY_STATUS.FOR_VIEWER
      : !isSimple &&
        (isArchived
          ? CONTEXT_MENU_OPTIONS_BY_STATUS.ARCHIVED
          : CONTEXT_MENU_OPTIONS_BY_STATUS[status!]);

    const datesEl = !isSimple && (
      <StyledDates>
        {startDate && <span>{formatDate(startDate)}</span>}
        {(startDate || endDate) && <StyledArrowRight />}
        {endDate && <span>{formatDate(endDate)}</span>}
      </StyledDates>
    );

    // useEffect(() => {
    //   if (loadingMoveOutcome) {
    //     dispatch(setChangingSpaceEntityId(outcome.id));
    //   } else {
    //     dispatch(setChangingSpaceEntityId(null));
    //   }
    // }, [loadingMoveOutcome]);

    return (
      <StyledEntityCard
        isTransparent={!!hoveredOutcomeId && hoveredOutcomeId !== id}
        disabled={loadingMoveOutcome}
        draggableId={'' + id}
        icon={icon}
        entityName={EntityType.OUTCOME}
        entity={outcome}
        isSimple={isSimple}
        isViewOnly={viewerPermission}
        title={
          <StyledTitleContainer>
            <StyledOutcomeName isSimple={isSimple} isEllipsis={name.length > 70}>
              {highlightText ? (
                <Highlighter
                  textToHighlight={name}
                  searchWords={[highlightText]}
                  highlightStyle={{
                    padding: 0,
                    backgroundColor: '#aff8db',
                    borderRadius: '2px',
                  }}
                />
              ) : (
                name
              )}
            </StyledOutcomeName>
            {workspaceName && (
              <StyledContainer>
                <StyledOutcomeWorkspaceWrapper className="col--fit">
                  {isNested || viewerPermission ? (
                    <StyledWorkspaceSelectContainer>
                      <StyledOutcomeWorkspace isShared={isShared} isSimple={isSimple}>
                        {workspaceName}
                      </StyledOutcomeWorkspace>
                    </StyledWorkspaceSelectContainer>
                  ) : (
                    <WorkspaceSelectMenu
                      isMultiSorted
                      disabled={loadingMoveOutcome}
                      currentWorkspaceId={outcome?.workspaceId}
                      onContextMenuOptionClick={(option) => {
                        if (option?.value === outcome?.workspaceId) {
                          return;
                        }
                        onOutcomeWorkspaceChange(option, outcome?.workspaceId);
                        sendEvent('outcome-move', 'Update workspace of outcome');
                      }}
                    >
                      <StyledOutcomeWorkspace isShared={isShared} isSimple={isSimple}>
                        {workspaceName}
                      </StyledOutcomeWorkspace>
                    </WorkspaceSelectMenu>
                  )}
                  {isShared && !isSimple && <StyledSharedPeopleIcon count={sharedUsersCount} />}
                </StyledOutcomeWorkspaceWrapper>
                <StyledTagsWrapper className="col--fill">
                  <Tags tags={outcome.tags!} loadingOtherComponent={loading} />
                </StyledTagsWrapper>
              </StyledContainer>
            )}
          </StyledTitleContainer>
        }
        onDoubleClick={() => showEditOutcomeModal(outcome)}
        contextMenuOptions={contextMenuOptions}
        onContextMenuOptionClick={handleContextMenuOptionClick}
        highlightedContextMenuOptions={[
          ...Object.values(ContextMenuOptions).filter(
            (opt) => opt.label !== ContextMenuOptions.DELETE.label,
          ),
        ]}
        {...restOfProps}
      >
        {datesEl}
      </StyledEntityCard>
    );
  },
);

export { OutcomeCard };
