import { gql, makeVar } from '@apollo/client';
import { CoursesConstsArgsVideoValues, CoursesVideosSections } from '../../../graphql/types';
import { CoursesTitle } from '__generated__/graphql';

export { CoursesTitle } from '__generated__/graphql';

export type FirstCourseTitle =
  | CoursesTitle.Fundamentals
  | CoursesTitle.Foundations
  | CoursesTitle.FundamentalsManager;
export type SecondCourseTitle =
  | CoursesTitle.MasteringPersonalEffectiveness
  | CoursesTitle.RemoteWorkProfessional
  | CoursesTitle.RemoteWorkProfessionalManager;

export type CourseOperation = 'remove' | 'add';

export interface ActiveVideoInfo {
  video: CoursesConstsArgsVideoValues;
  courseTitle: string;
}

export interface CoursesFields {
  showCoursesPopup: boolean;
  activeVideo: ActiveVideoInfo | null;
  canceledNotificationVideo: ActiveVideoInfo | null;
  timezone: string;
  tutorialStep: number;
  tipsSection: null | CoursesVideosSections;
  expandCards: CoursesTitle[];
  expandSections: CoursesVideosSections[];
  firstCourseTitle: CoursesTitle;
  secondCourseTitle: CoursesTitle;
}

export const coursesInitialValue: CoursesFields = {
  showCoursesPopup: false,
  activeVideo: null,
  canceledNotificationVideo: null,
  timezone: '',
  tutorialStep: -1,
  tipsSection: null,
  expandCards: [],
  expandSections: [],
  firstCourseTitle: CoursesTitle.Foundations,
  secondCourseTitle: CoursesTitle.MasteringPersonalEffectiveness,
};

export const coursesVar = makeVar<CoursesFields>(coursesInitialValue);
export const COURSES_STATE = gql`
  query Courses {
    courses @client
  }
`;

