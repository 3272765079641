import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Input, Radio, Space } from 'antd';
import {
  BmSubscriptionPlans,
  MockedFetchStripePrices,
  StripePrice,
  StripePriceExpanded,
  StripeSubscriptionIntervals,
} from '../../../graphql/types';
import StripeDetails from './StripeDetails';
import {
  PLANS_TYPES,
  STRIPE_STATE,
  StripeValue,
} from '../../../apollo/stateFields/stripe/stripeFields';
import { getDiscount } from '../../utils';
import styled from 'styled-components';
import { theme } from '../../../core/styles/styled-components';
import { ReactComponent as CodeAcceptedIcon } from '../../img/code-accepted.svg';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FETCH_STRIPE_PRICES,
  FETCH_STRIPE_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_CHANGE_DISCOUNT,
  VALIDATE_STRIPE_PROMO_CODE,
} from '../../../graphql/queries';
import { RadioChangeEvent } from 'antd/es';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  CANCEL_STRIPE_SUBSCRIPTION,
  CHANGE_STRIPE_SUBSCRIPTION,
  CREATE_STRIPE_SUBSCRIPTION,
} from '../../../graphql/mutations';
import { stripeMutation } from '../../../apollo/stateFields/stripe';
import { getErrorMessage } from '../stripe-decline-messages';
import { addMonths, addYears, format } from 'date-fns';

const StyledFormContainer = styled.form`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  background: var(--color-white);
  color: var(--color-dark-blue);

  @media ${theme.device.mobile.max} {
    flex-direction: column;
  }
`;

const StyledBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 100;
  opacity: 0.5;
`;

const StyledDetailsContainer = styled.div`
  height: 100%;
  width: 55%;
  padding: 59px;

  @media ${theme.device.mobile.max} {
    width: 100%;
    padding: 28px 16px;
    height: auto;
  }
`;

const StyledSummaryContainer = styled.div`
  height: 100%;
  width: 45%;
  background-color: #f1f3f7;
  padding: 59px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${theme.device.mobile.max} {
    width: 100%;
    padding: 28px 16px;
  }
`;

const StyledDetailsTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 40px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  margin-bottom: 40px;
  font-weight: 700;
`;

const StyledRadioInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledRadioInnerInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
`;

const StyledSaveInfo = styled.div`
  padding: 4px 10px;
  background-color: #ffc227;
  border-radius: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 12px;
`;

const StyledFieldTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledCouponContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInputWrapper = styled.div`
  position: relative;
  width: 70%;
`;

const StyledCouponInfo = styled.div<{ isSuccess?: boolean; isError?: boolean }>`
  position: absolute;
  bottom: -20px;
  left: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: ${({ isError }) => (isError ? 'var(--color-main-error-red)' : '#3CBA00')};
  color: ${({ isSuccess }) => (isSuccess ? '#3CBA00' : 'var(--color-main-error-red)')};
`;

const StyledInput = styled(Input)<{ isInvalid: boolean }>`
  && {
    width: 100%;
    line-height: 40px;
    height: 40px;
    border: 1px solid var(--color-main-light-grey);
    margin-bottom: 0;

    border: ${({ isInvalid }) =>
      isInvalid
        ? '1px solid var(--color-main-error-red);'
        : '1px solid var(--color-main-light-grey)'};

    @media ${theme.device.mobile.max} {
      font-size: 16px !important;
    }
  }
`;

const StyledCouponButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 32px;
  width: 25%;
  background-color: transparent;
  border-radius: 36px;
  color: var(--color-dark-blue);
  font-weight: 400;
`;

const StyledCodeAcceptedIcon = styled(CodeAcceptedIcon)`
  position: absolute;
  right: 12px;
  top: 8px;
`;

const StyledSummaryTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-dark-blue);
  margin: 12px 0 20px;
  opacity: 0.2;
`;

const StyledTopContainer = styled.div`
  @media ${theme.device.mobile.max} {
    margin-bottom: 40px;
  }
`;

const StyledBottomContainer = styled.div``;
const StyledPlanType = styled.div<{ planType: PLANS_TYPES }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: ${({ planType }) =>
    planType === PLANS_TYPES.Individual ? 'var(--color-blue)' : '#7340DD'};

  @media ${theme.device.tablet.min} {
    font-size: 14px;
    line-height: 18px;
  }
`;
const StyledPlanBilling = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;
const StyledPayInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
`;
const StyledMonthInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
`;
const StyledPriceRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;
const StyledPlanPriceBold = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
const StyledBilledNowBold = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
`;
const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin-bottom: 8px;
    margin-left: 0;

    .ant-checkbox-inner {
      background-color: #fff;

      &::after {
        border-color: var(--color-dark-blue);
      }
    }

    .ant-checkbox + span {
      padding-top: 12px;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const StyledOutLink = styled.a`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  color: var(--color-dark-blue);
`;

const StyledPurchaseButton = styled(StyledCouponButton)`
  position: relative;
  z-index: 101;
  height: 48px;
  width: 100%;
  background-color: var(--color-dark-blue);
  color: var(--color-white);
  font-weight: 700;
  margin-top: 40px;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
  }
`;

const StyledSubscriptionChangeInfo = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
`;

const StripeForm = ({ startPeriod }: { startPeriod: StripeSubscriptionIntervals }) => {
  const { data: stripePlansData } = useQuery<MockedFetchStripePrices>(FETCH_STRIPE_PRICES);
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { setShowPurchaseInfoModal, setShowStripeType, setShowStripeModal, setErrorMessage } =
    stripeMutation;
  const {
    showStripeType,
    isForSubscriptionChange,
    isCardDataFulfilled,
    showStripeModal,
  }: StripeValue = stripeData?.stripe;
  const plans = useMemo(
    () =>
      stripePlansData?.fetchStripePrices?.filter(
        (plan) => plan.bmSubscriptionPlan === showStripeType,
      ),
    [stripePlansData?.fetchStripePrices, showStripeType],
  );

  const [currentPlan, setCurrentPlan] = useState<StripePriceExpanded | null>(null);
  const [billingPeriod, setBillingPeriod] = useState<StripeSubscriptionIntervals>(startPeriod);
  const [coupon, setCoupon] = useState<string>('');
  const [couponError, setCouponError] = useState<boolean>(false);
  const [couponSuccess, setCouponSuccess] = useState<boolean>(false);

  const [isRecurring, setIsRecurring] = useState<boolean>(true);
  const [isAcceptPolicy, setIsAcceptPolicy] = useState<boolean>(true);
  const priceInUsd =
    currentPlan?.amount && currentPlan?.amount !== 0 ? currentPlan?.amount / 100 : 0;
  const isYearPlan =
    currentPlan &&
    currentPlan.interval === StripeSubscriptionIntervals.Year &&
    currentPlan.bmSubscriptionPlan !== BmSubscriptionPlans.CertificationPackageTeams &&
    currentPlan.bmSubscriptionPlan !== BmSubscriptionPlans.FlexibleWorkProfessionalIndividual;
  const isOnePaymentPlan =
    currentPlan &&
    (currentPlan.bmSubscriptionPlan === BmSubscriptionPlans.CertificationPackageTeams ||
      currentPlan.bmSubscriptionPlan === BmSubscriptionPlans.FlexibleWorkProfessionalIndividual);
  const price = isYearPlan ? priceInUsd / 12 : priceInUsd;
  const monthlyPlan = plans?.find((plan) => plan.interval === StripeSubscriptionIntervals.Month);
  const yearPlan = plans?.find((plan) => plan.interval === StripeSubscriptionIntervals.Year);
  const savePercent =
    monthlyPlan && yearPlan ? (monthlyPlan?.amount * 12 - yearPlan.amount) / 100 : 0;

  const [getChangeDiscount, { data: changeDiscountData, loading: changeDiscountLoading }] =
    useLazyQuery(FETCH_SUBSCRIPTION_CHANGE_DISCOUNT);
  const [validateCoupon, { data: validateResponse, error: validateError }] = useLazyQuery(
    VALIDATE_STRIPE_PROMO_CODE,
  );
  const [createStripeSubscription] = useMutation(CREATE_STRIPE_SUBSCRIPTION);
  const [changeStripeSubscription] = useMutation(CHANGE_STRIPE_SUBSCRIPTION);
  const [cancelStripeSubscription] = useMutation(CANCEL_STRIPE_SUBSCRIPTION);
  const [fetchStripeSubscription, { data: stripeSubscriptionData }] =
    useLazyQuery(FETCH_STRIPE_SUBSCRIPTION);

  const handleChangeBillingPeriod = (e: RadioChangeEvent) => {
    setBillingPeriod(e.target.value);
  };

  const handleValidateCoupon = async () => {
    const response = await validateCoupon({
      variables: {
        promoCodeId: coupon,
        bmSubscriptionPlanId: currentPlan?.bmSubscriptionPlanId!,
        bmSubscriptionProductId: currentPlan?.bmSubscriptionProductId!,
      },
    });
    response.data?.validateStripePromoCode === null && setCouponError(true);
    !!response.data?.validateStripePromoCode && setCouponSuccess(true);
  };

  const resetForm = () => {
    setCoupon('');
    setCouponError(false);
    setCouponSuccess(false);
  };

  useEffect(() => {
    if (isForSubscriptionChange && !!currentPlan?.bmSubscriptionPlanId) {
      getChangeDiscount({
        variables: { newBmSubscriptionPlanId: currentPlan?.bmSubscriptionPlanId },
      });
    }
  }, [isForSubscriptionChange, billingPeriod, currentPlan?.bmSubscriptionPlanId]);

  useEffect(() => {
    if (plans) {
      setCurrentPlan(plans?.find(((plan) => plan.interval === startPeriod) || plans[0]) ?? null);
    }
  }, [plans]);

  useEffect(() => {
    if (plans) {
      const newPlan = plans.find((plan) => plan.interval === billingPeriod);
      newPlan && setCurrentPlan(newPlan);
    }
  }, [billingPeriod]);

  useEffect(() => {
    setBillingPeriod(startPeriod);
  }, [startPeriod]);

  useEffect(() => {
    resetForm();
  }, [showStripeModal]);

  const stripe = useStripe();
  const elements = useElements();
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  // const [clientSecret, setClientSecret] = useState<string>('');
  // const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>();

  if (!stripe || !elements) {
    return null;
  }
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setPaymentLoading(true);

    const variables = {
      bmSubscriptionPlanId: currentPlan?.bmSubscriptionPlanId!,
      newBmSubscriptionPlanId: currentPlan?.bmSubscriptionPlanId!,
      isCancelAtPeriodEnd: isRecurring ? undefined : true,
      promoCodeId:
        couponSuccess && !!validateResponse?.validateStripePromoCode?.id
          ? validateResponse?.validateStripePromoCode?.id
          : undefined,
    };
    let respClientSecret;

    if (!isForSubscriptionChange) {
      let resp = await createStripeSubscription({ variables });
      if (!resp.data?.createStripeSubscription) {
        const oldSubscriptionResponse = await fetchStripeSubscription();
        const subscriptionId =
          oldSubscriptionResponse.data?.fetchStripeSubscription?.bmSubscription
            ?.externalUnfinishedSubscriptionId!;
        await cancelStripeSubscription({ variables: { subscriptionId } });
        resp = await createStripeSubscription({ variables });
      }
      respClientSecret = resp?.data?.createStripeSubscription?.clientSecret;
    } else {
      let resp = await changeStripeSubscription({ variables });
      respClientSecret = resp?.data?.changeStripeSubscription?.clientSecret;
    }
    // respClientSecret === null if discount more or equal the price

    if (!respClientSecret && respClientSecret !== null) {
      setPaymentLoading(false);
      setShowStripeModal('hide');
      setShowPurchaseInfoModal('fail');
      return;
    }

    if (respClientSecret !== null) {
      let { error, paymentIntent } = await stripe.confirmCardPayment(respClientSecret!, {
        payment_method: {
          card: elements.getElement(CardNumberElement)!,
          billing_details: {
            name: 'Test',
          },
        },
      });
      console.log('error: ', error, 'paymentIntent: ', paymentIntent);
      if (error?.decline_code || error?.message) {
        setErrorMessage(getErrorMessage(error));
      }

      await fetchStripeSubscription();
      setPaymentLoading(false);

      if (error) {
        setIsRecurring(true);
        setShowPurchaseInfoModal('fail');
        setShowStripeModal('hide');
      } else {
        setShowStripeModal(false);
        setShowPurchaseInfoModal('success');
        setShowStripeType(null);
      }
      return;
    }

    await fetchStripeSubscription();
    setPaymentLoading(false);
    setShowStripeModal(false);
    setShowPurchaseInfoModal('success');
    setShowStripeType(null);
  };

  const calculatedPrice = (
    (isYearPlan ? price * 12 : price) -
    ((couponSuccess &&
      (getDiscount(isYearPlan ? price * 12 : price, validateResponse?.validateStripePromoCode) ||
        0)) ||
      0) -
    ((!!changeDiscountData?.fetchSubscriptionChangeDiscount.discount &&
      (changeDiscountData.fetchSubscriptionChangeDiscount.discount / 100 || 0)) ||
      0)
  ).toFixed(2);

  const nextPaymentDate = isYearPlan
    ? format(addYears(new Date(), 1), 'MMMM dd, yyyy')
    : format(addMonths(new Date(), 1), 'MMMM dd, yyyy');

  const disableCoupon = !!isForSubscriptionChange
    ? !!changeDiscountData?.fetchSubscriptionChangeDiscount.discount ||
      couponSuccess ||
      couponError ||
      !coupon.length
    : couponSuccess || couponError || !coupon.length;

  return (
    <StyledFormContainer onSubmit={handleFormSubmit}>
      {paymentLoading && <StyledBackdrop />}
      {currentPlan ? (
        <>
          <StyledDetailsContainer>
            <StyledDetailsTitle>Payment details</StyledDetailsTitle>
            {!isOnePaymentPlan && plans?.length && plans?.length === 2 && (
              <>
                <StyledFieldTitle>Billing period</StyledFieldTitle>
                <StyledRadioGroup onChange={handleChangeBillingPeriod} value={billingPeriod}>
                  <Space direction="vertical">
                    <Radio value={StripeSubscriptionIntervals.Year}>
                      <StyledRadioInner>
                        Yearly
                        {yearPlan && (
                          <StyledRadioInnerInfo>
                            ${(yearPlan.amount / 100 / 12).toFixed(2)}/month
                          </StyledRadioInnerInfo>
                        )}
                        {!!savePercent && (
                          <StyledSaveInfo>save ${Math.floor(savePercent)}</StyledSaveInfo>
                        )}
                      </StyledRadioInner>
                    </Radio>
                    <Radio value={StripeSubscriptionIntervals.Month}>
                      <StyledRadioInner>
                        Monthly
                        {monthlyPlan && (
                          <StyledRadioInnerInfo>
                            ${(monthlyPlan.amount / 100).toFixed(2)}/month
                          </StyledRadioInnerInfo>
                        )}
                      </StyledRadioInner>
                    </Radio>
                  </Space>
                </StyledRadioGroup>
              </>
            )}
            <StyledFieldTitle>Card details</StyledFieldTitle>
            <StripeDetails key={`${showStripeModal}`} />
            <StyledFieldTitle>Coupon</StyledFieldTitle>
            <StyledCouponContainer>
              <StyledInputWrapper>
                <StyledInput
                  isInvalid={couponError}
                  name="coupon"
                  maxLength={256}
                  autoComplete="off"
                  value={coupon}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    couponError && setCouponError(false);
                    couponSuccess && setCouponSuccess(false);
                    setCoupon(e.target.value);
                  }}
                />
                {couponError && (
                  <StyledCouponInfo isError={couponError}>Coupon not found</StyledCouponInfo>
                )}
                {couponSuccess && (
                  <StyledCouponInfo isSuccess={couponSuccess}>
                    {validateResponse?.validateStripePromoCode?.coupon.percentOff
                      ? `${validateResponse?.validateStripePromoCode?.coupon.percentOff}% `
                      : `$${
                          (validateResponse?.validateStripePromoCode?.coupon.amountOff || 0) / 100
                        } `}
                    discount activated!
                  </StyledCouponInfo>
                )}
                {couponSuccess && <StyledCodeAcceptedIcon />}
              </StyledInputWrapper>
              <StyledCouponButton disabled={disableCoupon} onClick={handleValidateCoupon}>
                Apply
              </StyledCouponButton>
            </StyledCouponContainer>
          </StyledDetailsContainer>
          <StyledSummaryContainer>
            <StyledTopContainer>
              <StyledSummaryTitle>Summary</StyledSummaryTitle>
              <StyledDivider />
              <StyledPlanType
                planType={
                  currentPlan.bmSubscriptionPlan.includes(PLANS_TYPES.Individual)
                    ? PLANS_TYPES.Individual
                    : PLANS_TYPES.Teams
                }
              >
                {currentPlan.bmSubscriptionPlan.includes(PLANS_TYPES.Individual)
                  ? 'Individual'
                  : 'Business'}
              </StyledPlanType>
              <StyledFieldTitle>{currentPlan.metadata.title}</StyledFieldTitle>
              <StyledPlanBilling>
                {isOnePaymentPlan
                  ? 'One time payment'
                  : currentPlan.interval === StripeSubscriptionIntervals.Month
                  ? 'Monthly plan'
                  : 'Yearly plan'}
              </StyledPlanBilling>
              <StyledPayInfo>
                ${price.toFixed(2)}
                {!isOnePaymentPlan && (
                  <StyledMonthInfo>
                    &times;
                    {isYearPlan ? ' 12 months' : ' 1 month'}
                  </StyledMonthInfo>
                )}
              </StyledPayInfo>
            </StyledTopContainer>
            <StyledBottomContainer>
              <StyledPriceRow>
                {currentPlan.bmSubscriptionPlan ===
                BmSubscriptionPlans.FlexibleWorkProfessionalIndividual
                  ? 'Yearly plan price: '
                  : isYearPlan
                  ? 'Yearly plan price: '
                  : 'Monthly plan price: '}
                <StyledPlanPriceBold>
                  ${(isYearPlan ? price * 12 : price).toFixed(2)}
                </StyledPlanPriceBold>
              </StyledPriceRow>
              {couponSuccess &&
                !!getDiscount(
                  isYearPlan ? price * 12 : price,
                  validateResponse?.validateStripePromoCode,
                ) && (
                  <StyledPriceRow style={{ marginTop: '12px' }}>
                    Coupon code discount:{' '}
                    <StyledPlanPriceBold>
                      -$
                      {!!getDiscount(
                        isYearPlan ? price * 12 : price,
                        validateResponse?.validateStripePromoCode,
                      ) &&
                        getDiscount(
                          isYearPlan ? price * 12 : price,
                          validateResponse?.validateStripePromoCode,
                        )!.toFixed(2)}
                    </StyledPlanPriceBold>
                  </StyledPriceRow>
                )}
              {isForSubscriptionChange &&
                !changeDiscountLoading &&
                !!changeDiscountData?.fetchSubscriptionChangeDiscount?.discount && (
                  <StyledPriceRow style={{ marginTop: '12px' }}>
                    Existing plan discount:{' '}
                    <StyledPlanPriceBold>
                      -${changeDiscountData.fetchSubscriptionChangeDiscount.discount / 100}
                    </StyledPlanPriceBold>
                  </StyledPriceRow>
                )}
              <StyledPriceRow style={{ marginBottom: '30px' }}>
                Billed now:{' '}
                <StyledBilledNowBold>
                  ${+calculatedPrice >= 0 ? calculatedPrice : 0}
                </StyledBilledNowBold>
              </StyledPriceRow>
              <StyledCheckboxWrapper>
                {!isOnePaymentPlan && (
                  <StyledCheckbox
                    name="recurring"
                    checked={isRecurring}
                    onChange={() => setIsRecurring(!isRecurring)}
                  >
                    {`Recurring payment (next payment due: ${nextPaymentDate})`}
                  </StyledCheckbox>
                )}
                <StyledCheckbox
                  name="policy"
                  checked={isAcceptPolicy}
                  onChange={() => setIsAcceptPolicy(!isAcceptPolicy)}
                >
                  I accept the BillionMinds{' '}
                  <StyledOutLink href="https://billionminds.com/terms-and-privacy/" target="_blank">
                    Terms and Privacy Policy
                  </StyledOutLink>
                </StyledCheckbox>
              </StyledCheckboxWrapper>
              <StyledPurchaseButton
                disabled={!isAcceptPolicy || !isCardDataFulfilled}
                htmlType={'submit'}
                loading={paymentLoading}
              >
                Purchase
              </StyledPurchaseButton>
              {isForSubscriptionChange && (
                <StyledSubscriptionChangeInfo>
                  After purchasing your current subscription will be canceled
                </StyledSubscriptionChangeInfo>
              )}
            </StyledBottomContainer>
          </StyledSummaryContainer>
        </>
      ) : null}
    </StyledFormContainer>
  );
};

export default StripeForm;

