import React, { useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../../../core/styles/styled-components';
import { ReactComponent as HelpIcon } from '../../../../../core/svg/common/help.svg';
import { useMediaQuery } from 'react-responsive';
import SharedPeopleSelector from '../SharedPeopleSelector/SharedPeopleSelector';
import { CONTEXT_MENU_OPTIONS_BY_ROLES, UserMenuRoles } from '../types';
import SharedRoleSelector from '../SharedRoleSelector/SharedRoleSelector';
import SharedUserRoleItem from '../SharedUserRoleItem/SharedUserRoleItem';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER_INVITES } from '../../../graphql/mutations';
import { FETCH_WORKSPACE_INVITES_BY_IDS, SEARCH_USER_INVITES } from '../../../graphql/queries';
import { getMenuOptionsByPermission, sortUsersByPermission } from '../utils';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';
import { AUTH_STATE, AuthValue } from '../../../../../apollo/stateFields/auth/authFields';
import {
  MANAGE_WORKSPACES_STATE,
  ManageWorkspacesValue,
} from '../../../../../apollo/stateFields/manageWorkspaces/manageWorkspacesField';
import { manageWorkspacesMutation } from '../../../../../apollo/stateFields/manageWorkspaces';
import useWorkspaceInvites from '../../../../../hooks/useWorkspaceInvites';
import { STRIPE_STATE, StripeValue } from '../../../../../apollo/stateFields/stripe/stripeFields';
import ExpiredTooltip from '../../../../../core/components/common/ExpiredTooltip/ExpiredTooltip';
import { USER_PLANS_PATH } from '../../../../../core/constants/routePaths';
import { Link } from 'react-router-dom';
import { AcceptInviteResult, Permission } from '__generated__/graphql';

export const StyledModal = styled(Modal)`
  width: 640px !important;
  .ant-modal-content {
    border-radius: 3px;
    background-color: var(--color-main-grey-2);
  }
  .ant-modal-body {
    max-height: 696px;
    min-height: 282px;
    width: 640px;
    margin: auto;
    height: 100%;
    padding: 32px;

    display: flex;
    flex-direction: column;
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.15);
  }

  @media ${theme.device.mobile.max} {
    && {
      max-height: 100vh !important;
      max-width: 100vh !important;
      width: 100vw !important;
      height: 100vh !important;
      top: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .ant-modal-content {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
    .ant-modal-body {
      max-height: 100%;
      width: 100%;
      height: 100%;
      margin: 0;
      overflow-y: hidden;
      padding: 16px;
    }
    .ant-modal-wrap {
      overflow: hidden !important;
    }
  }
`;

export const StyledInnerModal = styled(Modal)`
  width: 312px !important;
  .ant-modal-content {
    height: 100%;
    width: 100%;
    margin: auto;

    border-radius: 3px;
    background-color: var(--color-main-grey-2);
  }
  .ant-modal-body {
    padding: 24px;
    height: 100%;
  }

  @media ${theme.device.tablet.min} {
    width: 480px !important;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledInfoWrapper = styled.div`
  position: relative;
  z-index: 1111;
`;

const StyledInfoDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 9px;
    margin: 0;
  }
`;

const StyledHelpIcon = styled(HelpIcon)`
  width: 18px;
  height: 18px;

  color: var(--color-grey);
`;

const StyledInfoHoverModal = styled.div<{ coords: { x: number; y: number } }>`
  position: fixed;
  width: 480px;
  padding: 24px;

  background-color: var(--color-main-grey-2);
  box-shadow: 0px 1px 32px rgba(32, 38, 53, 0.2);
  border-radius: 3px;

  ${({ coords }) => `left: ${coords.x};`}
  ${({ coords }) => `top: ${coords.y};`}
`;

const StyledParagraf = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledInfoContent = styled.div`
  font-size: 14px;
  font-size: 14px;
`;

const StyledInfoButton = styled(Button)`
  margin-left: 10px;
`;

const StyledInfoMobileButton = styled(Button)`
  margin-bottom: 0;
  margin-left: 0;
`;

const StyledBoldText = styled.h4`
  margin-bottom: 4px;
  color: var(--color-dark-blue);
  font-weight: 700;
`;

const StyledText = styled.p`
  margin: 0;
  color: var(--color-dark-blue);
  font-weight: 400;
`;

const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0;

  @media ${theme.device.mobile.max} {
    font-size: 20px;
  }
`;

const StyledControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media ${theme.device.mobile.max} {
    flex-wrap: wrap;
  }
`;

const StyledSharedPeopleSelector = styled(SharedPeopleSelector)`
  @media ${theme.device.mobile.max} {
    width: 65%;
  }
`;

const StyledRoleSelector = styled(SharedRoleSelector)`
  width: 151px;
  border-radius: 2px;
  margin-left: 4px;
  margin-bottom: 16px;

  @media ${theme.device.mobile.max} {
    width: 33%;
  }

  && {
    background: var(--color-white);

    &:hover,
    &:active,
    &:focus {
      background: var(--color-white);
    }
  }
`;

const StyledAddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 8px 15px;
  margin-bottom: 16px;
  margin-left: 12px;
  height: 40px;
  border: none;
  background-color: var(--color-dark-blue);
  color: var(--color-white);

  &:hover,
  &:focus {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
  }

  @media ${theme.device.mobile.max} {
    margin: 0;
    height: 32px;
  }
`;

const StyledUsersList = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  max-height: 342px;
  padding-right: 8px;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }

  @media ${theme.device.mobile.max} {
    max-height: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin: 10px 0 5px 0;

    span:last-child {
      max-width: 390px;
      word-wrap: break-word;
      color: var(--color-dark-blue);
    }

    .ant-checkbox-inner {
      background-color: #fff;

      &::after {
        border-color: var(--color-dark-blue);
      }
    }
    @media ${theme.device.mobile.max} {
      margin: 15px 0;
    }
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: var(--color-white);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  &:focus,
  &:hover,
  &:active {
    color: var(--color-white);
    opacity: 0.8;
  }
`;

const RolesInfo = () => {
  const renderParagraph = (title: string, text: string) => {
    return (
      <StyledParagraf>
        <StyledBoldText>{title}</StyledBoldText>
        <StyledText>{text}</StyledText>
      </StyledParagraf>
    );
  };

  return (
    <StyledInfoContent>
      {renderParagraph(
        'Primary Owner',
        'Space creator. Can manage all roles and permissions for this Space.',
      )}
      {/*{renderParagraph(*/}
      {/*  'SO',*/}
      {/*  'Can manage all roles and permissions for this Space, except Primary Owner. ',*/}
      {/*)}*/}
      {renderParagraph(
        'Editor',
        'Can create, edit and archive Actions and Outcomes in this Space. Can add new users, except Primary and Secondary Owners. ',
      )}
      {/*{renderParagraph('Cont', 'Can create and edit Actions and Outcomes in this Space.')}*/}
      {/*{renderParagraph('Mover', 'Can change the status of Actions and Outcomes in this Space.')}*/}
      {renderParagraph('Viewer', 'Can view Actions and Outcomes in this Space.')}
    </StyledInfoContent>
  );
};

const ShareWorkspaceModal = () => {
  const { data } = useQuery(AUTH_STATE);
  const { authUser }: AuthValue = data?.auth;
  const { data: manageWorkspacesData } = useQuery(MANAGE_WORKSPACES_STATE);
  const { workspaceToShare: sharedWorkspace }: ManageWorkspacesValue =
    manageWorkspacesData?.manageWorkspaces;
  const { setSharedWorkspace, setLeaveWorkspace } = manageWorkspacesMutation;
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired, expiredSubscriptionText }: StripeValue = stripeData?.stripe;
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isSearchValid, setIsSearchValid] = useState<boolean>(false);
  const [coord, setCoord] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [checked, setChecked] = useState<boolean>(false);
  const [submitCount, setSubmitCount] = useState<number>(0);
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const [addedUserEmails, setAddedUserEmails] = useState<string[]>([]);
  const [addedUserRole, setAddedUserRole] = useState<string>(UserMenuRoles.EDITOR);
  const { getWorkspaceInvitesById } = useWorkspaceInvites();
  const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];

  if (!sharedWorkspace) {
    return null;
  }
  const [createInvites, { loading }] = useMutation(CREATE_USER_INVITES);

  const isDisabled =
    (getWorkspaceInvitesById(sharedWorkspace.id)?.users?.find(
      (userInvite) => userInvite?.externalIdentity === authUser?.uid,
    )?.permission as unknown as UserMenuRoles) === UserMenuRoles.VIEWER;

  const handleSubmit = async () => {
    await createInvites({
      variables: {
        emails: addedUserEmails,
        permission: addedUserRole as Permission,
        workspaceId: sharedWorkspace.id,
      },
      refetchQueries: [
        {
          query: FETCH_WORKSPACE_INVITES_BY_IDS,
          variables: {
            workspaceIds: workspaces.map((w) => w.id),
          },
        },
        { query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT },
        {
          query: SEARCH_USER_INVITES,
          variables: {
            query: '',
          },
        },
      ],
    });
    setAddedUserEmails([]);
    setSubmitCount((old) => old + 1);
  };

  return (
    <>
      <StyledModal
        mask
        getContainer={false}
        visible={!!sharedWorkspace}
        onCancel={() => {
          setSharedWorkspace(null);
        }}
        footer={null}
        closable={true}
      >
        {isMobile && (
          <StyledInfoDescription>
            <StyledInfoMobileButton
              shape="circle"
              type="text"
              size={'small'}
              icon={<StyledHelpIcon />}
              onClick={() => {
                !isDesktop && setShowInfo(true);
              }}
            />
            <p>About all roles</p>
          </StyledInfoDescription>
        )}
        <StyledHeader>
          <StyledTitle>Share {sharedWorkspace.name}</StyledTitle>
          <StyledInfoWrapper
            onMouseEnter={(event: any) => {
              setCoord({
                x: event.target.getBoundingClientRect().x,
                y: event.target.getBoundingClientRect().y,
              });
              return isDesktop && setShowInfo(true);
            }}
            onMouseLeave={() => isDesktop && setShowInfo(false)}
          >
            {!isMobile && (
              <StyledInfoButton
                shape="circle"
                type="text"
                icon={<HelpIcon />}
                onClick={() => {
                  !isDesktop && setShowInfo(true);
                }}
              />
            )}
            {showInfo && isDesktop && (
              <StyledInfoHoverModal coords={coord}>
                <RolesInfo />
              </StyledInfoHoverModal>
            )}
          </StyledInfoWrapper>
        </StyledHeader>

        <StyledControls>
          <StyledSharedPeopleSelector
            key={`${submitCount}`}
            disabled={isDisabled}
            validate={(value) => setIsValid(value)}
            setIsSearchValid={(value) => setIsSearchValid(value)}
            addedUsers={getWorkspaceInvitesById(sharedWorkspace.id)?.users as AcceptInviteResult[]}
            initialValue={addedUserEmails}
            onEmailSelect={(value) => {
              setAddedUserEmails(value);
            }}
          />

          <StyledRoleSelector
            disabled={isDisabled}
            onSelect={(value) => {
              setAddedUserRole(value.key);
            }}
            role={addedUserRole}
            roles={CONTEXT_MENU_OPTIONS_BY_ROLES.FOR_WORKSPACE}
          />
          <ExpiredTooltip
            position={{
              top: '-65px',
              left: '-330px',
            }}
            placement={'topRight'}
            width={'330px'}
            withoutArrow={true}
            visible={isSubscriptionExpired}
            title={
              <>
                You cannot add new users to a space because your {expiredSubscriptionText}. To
                enable this, please{' '}
                <StyledLink to={USER_PLANS_PATH}>select a paid plan </StyledLink>
              </>
            }
          >
            <StyledAddButton
              disabled={
                isSubscriptionExpired ||
                (isSearchValid ? isDisabled || loading : !isValid || isDisabled || loading)
              }
              shape={'round'}
              onClick={handleSubmit}
            >
              Add to Space
            </StyledAddButton>
          </ExpiredTooltip>
        </StyledControls>
        {/*{isMobile && (*/}
        {/*  <StyledCheckbox checked={checked} onChange={() => setChecked(!checked)}>*/}
        {/*    Allow to duplicate Space to other users*/}
        {/*  </StyledCheckbox>*/}
        {/*)}*/}
        {getWorkspaceInvitesById(sharedWorkspace.id)?.users && (
          <StyledUsersList>
            {sortUsersByPermission(
              getWorkspaceInvitesById(sharedWorkspace.id)?.users?.slice() as AcceptInviteResult[],
            )?.map((user) => {
              const isCurrent = authUser?.uid === user.externalIdentity;
              const roles = getMenuOptionsByPermission(
                user.permission!,
                sharedWorkspace.permission!,
                isCurrent,
              );
              return (
                <SharedUserRoleItem
                  key={user.email}
                  user={user}
                  roles={roles}
                  handleSpaceLeave={() => {
                    setLeaveWorkspace(sharedWorkspace);
                  }}
                />
              );
            })}
          </StyledUsersList>
        )}
        {/*{!isMobile && (*/}
        {/*  <StyledCheckbox checked={checked} onChange={() => setChecked(!checked)}>*/}
        {/*    Allow to duplicate Space to other users*/}
        {/*  </StyledCheckbox>*/}
        {/*)}*/}
      </StyledModal>
      <StyledInnerModal
        mask
        getContainer={false}
        visible={!isDesktop && showInfo}
        onCancel={() => {
          setShowInfo(false);
        }}
        footer={null}
        closable={true}
        style={{ top: '15%' }}
      >
        <RolesInfo />
      </StyledInnerModal>
    </>
  );
};

export default ShareWorkspaceModal;

