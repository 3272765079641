import { ENTITY_NAME_BY_OPERATION_NAME, OPERATION_NAME } from './types';
import { CUSTOM_ENTITY_TYPES } from './stateFields/error/errorFields';
import devConsole from 'core/utils/devConsole';

export const isOperationRegistered = (operationName: string): operationName is OPERATION_NAME => {
  return operationName in OPERATION_NAME;
};

export const getEntityByOperationName = (operationName: string) => {
  if (!isOperationRegistered(operationName)) {
    devConsole.log(`${operationName} is not an Operation registered`);
    return;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.OUTCOME.has(operationName)) {
    return CUSTOM_ENTITY_TYPES.OUTCOME;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.ACTION.has(operationName)) {
    return CUSTOM_ENTITY_TYPES.ACTION;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.NOTE.has(operationName)) {
    return CUSTOM_ENTITY_TYPES.NOTE;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.WORKSPACE.has(operationName)) {
    return CUSTOM_ENTITY_TYPES.WORKSPACE;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.USER.has(operationName)) {
    return CUSTOM_ENTITY_TYPES.USER;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.CORPORATE_ACCOUNT.has(operationName)) {
    return CUSTOM_ENTITY_TYPES.CORPORATE_ACCOUNT;
  }
  devConsole.log(`${operationName} is not an Operation with a registered Entity`);
};

export const isAdminOperationByEntity = (
  entityType: CUSTOM_ENTITY_TYPES | null | undefined,
): entityType is CUSTOM_ENTITY_TYPES.USER | CUSTOM_ENTITY_TYPES.CORPORATE_ACCOUNT => {
  return (
    entityType === CUSTOM_ENTITY_TYPES.USER || entityType === CUSTOM_ENTITY_TYPES.CORPORATE_ACCOUNT
  );
};

export const isAdminOperation = (operationName: string): boolean => {
  const entityType = getEntityByOperationName(operationName);
  return isAdminOperationByEntity(entityType);
};

