import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FETCH_ACTIONS, FETCH_ALL_NOTES, FETCH_NOTES } from '../../actions/graphql/queries';
import { CONVERT_NOTE_TO_ACTION } from '../../actions/graphql/mutations';
import { Note } from '../../../core/types/note';
import {
  checkIsShownEntity,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from '../../utils';
import { mutateAction } from '../../../context/userWorkspaceContext';
import { capitalize } from 'lodash-es';
import { checkActionForComplete } from '../utils';
import { ColumnType } from '../../../apollo/stateFields/dnd/dndFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { completeMutation } from '../../../apollo/stateFields/complete';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { addEntityId, deleteEntityId } from '../../../context/dndContext/DndActions';
import { Action } from '__generated__/graphql';

const useConvertNoteToAction = (noteToConvertAction?: Note | null) => {
  const apolloClient = useApolloClient();
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;
  const dispatch = dndDispatch;

  return useMutation(CONVERT_NOTE_TO_ACTION, {
    update(cache, { data }) {
      if (noteToConvertAction === null) {
        return;
      }

      const { fetchUserWorkspacesNotes = [] } =
        cache.readQuery({
          query: FETCH_ALL_NOTES,
        }) || {};

      const { fetchUserWorkspaceNotes = [] } =
        cache.readQuery({
          query: FETCH_NOTES,
          variables: {
            workspaceId: noteToConvertAction?.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceActions = [] } =
        cache.readQuery({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: data?.convertNoteToUserWorkspaceAction.workspaceId,
          },
        }) || {};

      cache.writeQuery({
        query: FETCH_ALL_NOTES,
        data: {
          fetchUserWorkspacesNotes: updatedCacheData(
            fetchUserWorkspacesNotes,
            noteToConvertAction,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery({
        query: FETCH_NOTES,
        variables: {
          workspaceId: noteToConvertAction?.workspaceId,
        },
        data: {
          fetchUserWorkspaceNotes: updatedCacheData(
            fetchUserWorkspaceNotes,
            noteToConvertAction,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: data?.convertNoteToUserWorkspaceAction.workspaceId,
        },
        data: {
          fetchUserWorkspaceActions: updatedCacheData(
            fetchUserWorkspaceActions,
            data?.convertNoteToUserWorkspaceAction,
            OperationType.CREATE,
          ),
        },
      });
      if (data?.convertNoteToUserWorkspaceAction) {
        noteToConvertAction &&
          dispatch(
            deleteEntityId({
              entityId: noteToConvertAction.id,
              columnTitle: ColumnType.Note,
            }),
          );

        dispatch(
          addEntityId({
            entityId: data?.convertNoteToUserWorkspaceAction.id,
            columnTitle: capitalize(
              data.convertNoteToUserWorkspaceAction.status.toLowerCase(),
            ) as ColumnType,
          }),
        );
        mutateAction(
          apolloClient,
          data.convertNoteToUserWorkspaceAction as Action,
          'create',
          filterInput,
        );

        data.convertNoteToUserWorkspaceAction?.tags?.length &&
          updateWorkspaceTagsData(apolloClient, data.convertNoteToUserWorkspaceAction as Action);

        if (!checkIsShownEntity(data.convertNoteToUserWorkspaceAction as Action, filterInput)) {
          setUpdateFilterInputId({
            type: 'Action',
            workspaceId: data.convertNoteToUserWorkspaceAction.workspaceId!,
            id: data.convertNoteToUserWorkspaceAction.id,
          });
        }
        if (data?.convertNoteToUserWorkspaceAction?.outcome) {
          checkActionForComplete(
            setOutcomeToComplete,
            setOutcomeToIncomplete,
            data.convertNoteToUserWorkspaceAction as Action,
          );
        }
      }
    },
  });
};

export default useConvertNoteToAction;

