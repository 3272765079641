import { getMonth, getYear, isValid, parseISO } from 'date-fns';
import { orderBy } from 'lodash';

import { Outcome, OutcomeStatus } from 'core/types/outcome';
import { Note } from '../../core/types/note';

export const getSortedOutcomes = (outcomes: Outcome[]) =>
  orderBy(
    outcomes,
    [
      (outcome) => isValid(parseISO(String(outcome.startDate))),
      (outcome) => parseISO(String(outcome.startDate)),
      (outcome) => outcome.name,
    ],
    ['desc', 'asc', 'asc'],
  );

export const getOutcomesByStatus = (outcomes: Outcome[], status: OutcomeStatus) =>
  outcomes.filter((outcome) => outcome.status === status);

export const getOutcomesByCompleteDate = (outcomes: Outcome[], date: any) => {
  return outcomes.filter((outcome) => {
    // if (!outcome.completeDate) {
    //   return false;
    // }
    if (!outcome.endDate) {
      return false;
    }

    return (
      // (getMonth(parseISO(outcome.completeDate)) === Number(date.format('M')) - 1 &&
      //   getYear(parseISO(outcome.completeDate)) === Number(date.format('YYYY'))) ||
      outcome.endDate &&
      getMonth(parseISO(outcome.endDate)) === Number(date.format('M')) - 1 &&
      getYear(parseISO(outcome.endDate)) === Number(date.format('YYYY'))
    );
  });
};

export const sortOutcomesByUpdateDate = (a: Outcome, b: Outcome): number => {
  if (a.updateDate && b.updateDate) {
    if (a.updateDate > b.updateDate) {
      return -1;
    } else if (a.updateDate < b.updateDate) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }
  return a.name.localeCompare(b.name);
};

