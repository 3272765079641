import { ReactComponent as UrgentImportantIcon } from './svg/urgent-important-1.svg';
import { ReactComponent as ImportantIcon } from './svg/important.svg';
import { ReactComponent as UrgentIcon } from './svg/urgent.svg';
import { ReactComponent as NotDefinedIcon } from './svg/not-definrd.svg';
import { ReactComponent as ContinueIcon } from './svg/continue.svg';
import { ReactComponent as PauseIcon } from './svg/pause.svg';
import { ReactComponent as CancelIcon } from './svg/cancel.svg';
import { ReactComponent as FinishIcon } from './svg/finish.svg';
import { ReactComponent as SettingsIcon } from './svg/settings.svg';
import { Action, ActionPriority, ActionStatus } from '../../../../../core/types/action';
import { FocusTimer, FocusTimerStatus } from './FocusTimer/types';
import { getSpendTime } from './FocusTimer/utils';
import { FocusTimerType } from './FocusTimer/FocusSettingsModal';

export enum PriorityTitle {
  IMPORTANT = 'Important',
  IMPORTANT_URGENT = 'Important & Urgent',
  URGENT = 'Urgent',
  NOT_DEFINED = 'Not defined',
}

export const ContextMenuOptions = {
  EDIT_ACTION: { label: 'Edit' },
  VIEW_ACTION: { label: 'View Action' },
  DUPLICATE_ACTION: { label: 'Duplicate' },
  ARCHIVE_ACTION: { label: 'Archive' },
  UNARCHIVE_ACTION: { label: 'Unarchive' },
  DELETE_ACTION: { label: 'Delete' },
  REMOVE_ACTION: { label: 'Remove' },
  DIVIDER: { label: 'Divider' },
  START_FOCUS_TIMER: { label: 'Start focus period', icon: ContinueIcon, disabled: false },
  PAUSE_FOCUS_TIMER: { label: 'Pause', icon: PauseIcon, disabled: false },
  CONTINUE_FOCUS_TIMER: { label: 'Continue', icon: ContinueIcon, disabled: false },
  CANCEL_FOCUS_TIMER: { label: 'Cancel', icon: CancelIcon, disabled: false },
  FINISH_FOCUS_TIMER: { label: 'Finish and save', icon: FinishIcon, disabled: false },
  SETTINGS_FOCUS_TIMER: { label: 'Focus settings', icon: SettingsIcon, disabled: false },
  SET_PRIORITY: {
    label: 'Set Priority',
    children: [
      {
        key: ActionPriority.ImportantUrgent,
        label: PriorityTitle.IMPORTANT_URGENT,
        icon: UrgentImportantIcon,
      },
      {
        key: ActionPriority.Urgent,
        label: PriorityTitle.URGENT,
        icon: UrgentIcon,
      },
      {
        key: ActionPriority.Important,
        label: PriorityTitle.IMPORTANT,
        icon: ImportantIcon,
      },
      {
        key: 'Not_Defined',
        label: PriorityTitle.NOT_DEFINED,
        icon: NotDefinedIcon,
      },
    ],
  },
};

export const ContextSubMenuOptions = {
  IMPORTANT_URGENT: {
    label: PriorityTitle.IMPORTANT_URGENT,
    icon: UrgentImportantIcon,
  },
  URGENT: {
    label: PriorityTitle.URGENT,
    icon: UrgentIcon,
  },
  IMPORTANT: {
    label: PriorityTitle.IMPORTANT,
    icon: ImportantIcon,
  },
  NOT_DEFINED: {
    label: PriorityTitle.NOT_DEFINED,
    icon: NotDefinedIcon,
  },
};

export const CONTEXT_MENU_OPTIONS_BY_STATE = {
  ARCHIVED: [ContextMenuOptions.EDIT_ACTION, ContextMenuOptions.UNARCHIVE_ACTION],
  FOR_VIEWER: [ContextMenuOptions.VIEW_ACTION],
  NOT_ARCHIVED: [
    ContextMenuOptions.EDIT_ACTION,
    ContextMenuOptions.DUPLICATE_ACTION,
    ContextMenuOptions.ARCHIVE_ACTION,
  ],
  NOT_ARCHIVED_WITH_PRIORITY: [
    ContextMenuOptions.EDIT_ACTION,
    ContextMenuOptions.DUPLICATE_ACTION,
    ContextMenuOptions.SET_PRIORITY,
    ContextMenuOptions.ARCHIVE_ACTION,
  ],
  NOT_ARCHIVED_NESTED: [
    ContextMenuOptions.EDIT_ACTION,
    ContextMenuOptions.DUPLICATE_ACTION,
    ContextMenuOptions.ARCHIVE_ACTION,
    ContextMenuOptions.REMOVE_ACTION,
  ],
};

export const CONTEXT_SUBMENU_OPTIONS = {
  PRIORITY: [
    ContextSubMenuOptions.IMPORTANT_URGENT,
    ContextSubMenuOptions.URGENT,
    ContextSubMenuOptions.IMPORTANT,
    ContextSubMenuOptions.NOT_DEFINED,
  ],
};

export const getFocusTimerMenu = (
  action: Action,
  currentFocusTimer: FocusTimer | null | undefined,
  focusTimerType?: FocusTimerType | null,
) => {
  if (action.status !== ActionStatus.Doing) {
    return [];
  }
  const maxOpenTimer = 8.64e7;

  if (!!currentFocusTimer) {
    if (action.id !== currentFocusTimer.actionId) {
      return [
        ContextMenuOptions.DIVIDER,
        { ...ContextMenuOptions.START_FOCUS_TIMER, disabled: true },
        { ...ContextMenuOptions.SETTINGS_FOCUS_TIMER, disabled: true },
      ];
    } else {
      const fixedPeriod = currentFocusTimer.fixedPeriodSelected
        ? currentFocusTimer.fixedPeriodSelected * 1000
        : 1.8e6;
      const spendTime = getSpendTime(
        currentFocusTimer.startTime,
        currentFocusTimer.resumeTime,
        currentFocusTimer.fixedPeriodSelected,
        currentFocusTimer.spendTime,
        currentFocusTimer.status,
        focusTimerType,
      );

      if (currentFocusTimer.status === FocusTimerStatus.Active) {
        if (focusTimerType && focusTimerType === FocusTimerType.Open && spendTime < maxOpenTimer) {
          return [
            ContextMenuOptions.DIVIDER,
            ContextMenuOptions.PAUSE_FOCUS_TIMER,
            ContextMenuOptions.CANCEL_FOCUS_TIMER,
            { ...ContextMenuOptions.SETTINGS_FOCUS_TIMER, disabled: true },
          ];
        } else if (spendTime >= fixedPeriod) {
          return [
            ContextMenuOptions.DIVIDER,
            ContextMenuOptions.FINISH_FOCUS_TIMER,
            ContextMenuOptions.CANCEL_FOCUS_TIMER,
            { ...ContextMenuOptions.SETTINGS_FOCUS_TIMER, disabled: true },
          ];
        }
        return [
          ContextMenuOptions.DIVIDER,
          ContextMenuOptions.PAUSE_FOCUS_TIMER,
          ContextMenuOptions.CANCEL_FOCUS_TIMER,
          { ...ContextMenuOptions.SETTINGS_FOCUS_TIMER, disabled: true },
        ];
      } else {
        return [
          ContextMenuOptions.DIVIDER,
          ContextMenuOptions.CONTINUE_FOCUS_TIMER,
          ContextMenuOptions.FINISH_FOCUS_TIMER,
          ContextMenuOptions.CANCEL_FOCUS_TIMER,
          { ...ContextMenuOptions.SETTINGS_FOCUS_TIMER, disabled: true },
        ];
      }
    }
  } else {
    return [
      ContextMenuOptions.DIVIDER,
      ContextMenuOptions.START_FOCUS_TIMER,
      ContextMenuOptions.SETTINGS_FOCUS_TIMER,
    ];
  }

  return [];
};

