import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../svg/close.svg';
import { useMediaQuery } from 'react-responsive';
import theme from '../../styles/styled-components/theme';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_SETTINGS } from '../../../graphql/mutations';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { TUTORIAL_STATE, TutorialValue } from '../../../apollo/stateFields/tutorial/tutorialField';
import { tutorialMutation } from '../../../apollo/stateFields/tutorial';
import { useUserWorkspaceContext } from '../../../context/userWorkspaceContext';

const StyledModal = styled(({ currentStep, isOpacity, ...rest }) => <Modal {...rest} />)<{
  currentStep: number;
  isOpacity: boolean;
}>`
  z-index: 2500;
  max-width: 360px;
  width: calc(100vw - 16px) !important;
  margin: 0;
  padding: 0;
  pointer-events: auto;
  display: none;

  ${({ isOpacity }) =>
    !isOpacity &&
    css`
      display: block;
    `}
  
  @media ${theme.device.mobile.max} {
    ${({ currentStep }) =>
      currentStep !== 4 &&
      currentStep !== 5 &&
      css`
        margin: 0 auto;
        left: auto !important;
      `}
  }}
  
  & .ant-modal-body {
    padding: 20px;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #ffffff transparent;

      ${({ currentStep }) => {
        if (currentStep === 3) {
          return css`
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent #ffffff;
            left: auto;
            right: -10px;
            top: 90px;
          `;
        }
        if (currentStep === 5) {
          return css`
            right: 20px;
            left: auto;
          `;
        }
      }}

      @media ${theme.device.mobile.max} {
        ${({ currentStep }) => {
          if (currentStep === 3) {
            return css`
              top: -10px;
              left: 20px;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent #ffffff transparent;
            `;
          }
        }}
  }
`;

const StyledTitle = styled.h4`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
`;

const StyledDescription = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 24px;
`;

const StyledBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCountSteps = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #a7a8ae;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
`;

const modalContent = [
  {
    title: 'Actions',
    description:
      'Actions are the things you do every day. As you perform actions, move them from left to right in this view.',
  },
  {
    title: 'Outcomes',
    description:
      'Outcomes are "why" you perform actions. Add the outcome directly here, and double tap it to add more detail. You can create an action directly from an outcome, or connect an action to an outcome by drag and drop.',
  },
  {
    title: 'Spaces',
    description:
      'For us to excel in the different parts of our lives, we must create space to do so. BillionMinds allows you to see all of your spaces at once, or just the right ones at the right time.',
  },
  {
    title: 'Incoming',
    description:
      'The best laid plans are subject to change. Incoming is a universal in-tray where you can collect, triage and adapt to new information. Enter it quickly in here, and figure out what to do with it later.',
  },
  {
    title: 'Skills Library',
    description:
      'Click here to access previously viewed videos, download workbooks, discover tips and tricks and to get certified as highly effective.',
  },
  {
    title: 'First Steps always here',
    description: 'You can always go through the training again. Or you can close this menu item.',
  },
];

const TutorialModal = React.memo(() => {
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { currentStep, completedSteps }: TutorialValue = tutorialData?.tutorial;
  const { setCurrentStep, setCompletedStep } = tutorialMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const [visible, setVisible] = useState(false);
  const [isOpacity, setOpacity] = useState(true);
  const [zIndex, setZIndex] = useState(2500);
  const [positionContainer, setPositionContainer] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const { loading } = useUserWorkspaceContext();

  useEffect(() => {
    if (currentStep > -1) {
      setVisible(true);
    }

    if (currentStep === 1) {
      setTimeout(() => {
        setZIndex(3000);
      }, 0);
    }
  }, [currentStep]);

  useEffect(() => {
    let delay = 500;

    if (currentStep === 5) {
      delay = 1000;
    }

    setTimeout(() => {
      const el = document.querySelector(`[data-tutorial="${currentStep}"]`) as HTMLElement;
      if (el) {
        const rect = el.getBoundingClientRect();
        setPositionContainer({
          top: rect.top,
          left: rect.left,
          right: rect.right,
          bottom: rect.bottom,
        });
        setOpacity(false);
      }
    }, delay);
  }, [currentStep, visible, loading]);

  const getModalPosition = () => {
    switch (currentStep) {
      case 0:
        if (isDesktop) {
          return {
            top: positionContainer.bottom + 10,
            left: positionContainer.left + 270,
          };
        }
        return {
          top: positionContainer.bottom + 10,
          left: positionContainer.left,
        };
      case 1:
        if (isDesktop) {
          return {
            top: positionContainer.bottom + 10,
            left: positionContainer.left + 30,
          };
        }
        return {
          top: positionContainer.bottom + 10,
          left: positionContainer.left,
        };
      case 2:
        if (isDesktop) {
          return {
            top: positionContainer.bottom + 20,
            left: positionContainer.left - 15,
          };
        }
        return {
          top: positionContainer.bottom + 10,
          left: positionContainer.left + 65,
        };
      case 3:
        if (!isMobile) {
          return {
            top: positionContainer.bottom - 180,
            right: -positionContainer.left + 388,
            left: 'auto',
          };
        }
        return {
          top: 260,
        };
      case 4:
        if (isMobile) {
          return {
            top: positionContainer.bottom + 25,
            left: positionContainer.left - 10,
          };
        }
        return {
          top: positionContainer.bottom + 25,
          left: positionContainer.left - 10,
        };
      // case 5:
      //   if (isDesktop) {
      //     return {
      //       top: positionContainer.bottom + 20,
      //       left: positionContainer.right - 60,
      //     };
      //   }
      //   return {
      //     top: positionContainer.bottom + 20,
      //     left: positionContainer.right - 360,
      //   };
      case 5:
        if (isDesktop) {
          return {
            top: positionContainer.bottom + 10,
            left: positionContainer.left - 180,
          };
        }
        if (isMobile) {
          return {
            top: 620,
            right: -positionContainer.left,
          };
        }
        return {
          top: 620,
          right: -positionContainer.left,
        };
    }
  };

  const handleCloseModal = () => {
    setOpacity(true);
    setCurrentStep(-1);
  };

  const handleSetStep = (value: number) => async () => {
    setOpacity(true);
    if (value > 0 && !completedSteps.includes(currentStep)) {
      await updateSettings({
        variables: {
          settings: {
            filterInput,
            tutorialPassedStep: completedSteps.concat(currentStep),
          },
        },
      });
      setCompletedStep(currentStep);
    } else {
      setCurrentStep(currentStep + value);
    }
  };

  const getLabelButton = () => {
    switch (currentStep) {
      case 4:
        return 'Finish';
      case 5:
        return 'OK';
      default:
        return 'Next';
    }
  };

  const isShowMask = () => {
    if (currentStep === 3) {
      return false;
    }
    return !(currentStep === 5 && !isDesktop);
  };

  const currentContent = modalContent[currentStep];

  const bottomContainerEl = (
    <StyledBottomContainer>
      {currentStep > 0 && currentStep < 5 && (
        <Button ghost type="primary" shape="round" onClick={handleSetStep(-1)}>
          Back
        </Button>
      )}
      {currentStep < 5 ? <StyledCountSteps>{`${currentStep + 1} of 5`}</StyledCountSteps> : <div />}
      <Button type="primary" shape="round" onClick={handleSetStep(1)}>
        {getLabelButton()}
      </Button>
    </StyledBottomContainer>
  );

  if (currentStep === -1 || currentStep > 5) {
    return null;
  }

  return (
    <StyledModal
      getContainer={() => document.body}
      onCancel={handleCloseModal}
      visible={visible}
      closable={false}
      footer={null}
      currentStep={currentStep}
      style={getModalPosition()}
      zIndex={zIndex}
      maskStyle={{ zIndex: 1999 }}
      mask={isShowMask()}
      isOpacity={isOpacity}
      wrapClassName="ant-modal-wrap-scroll"
    >
      <StyledCloseIcon onClick={handleCloseModal} />
      <StyledTitle>{currentContent.title}</StyledTitle>
      <StyledDescription>{currentContent.description}</StyledDescription>
      {bottomContainerEl}
    </StyledModal>
  );
});

export default TutorialModal;

