import React, { useCallback, useState } from 'react';
import { ReactComponent as StatusCurrentIcon } from '../../../outcomes/components/OutcomeCard/svg/current.svg';
import { ReactComponent as StatusCompletedIcon } from '../../../outcomes/components/OutcomeCard/svg/completed.svg';
import { ReactComponent as StatusFutureIcon } from '../../../outcomes/components/OutcomeCard/svg/future.svg';

import { CompleteOutcomeModalProps } from './types';
import {
  StyledArrowIcon,
  StyledButtonContainer,
  StyledCancelButton,
  StyledCheckbox,
  StyledContentText,
  StyledContentWrapper,
  StyledMessage,
  StyledMessageWrapper,
  StyledModal,
  StyledModalInnerContainer,
  StyledSubmitButton,
  StyledSubtitle,
  StyledSubtitleWrapper,
} from './StyledComponents';
import { OutcomeStatus } from '../../../../core/types/outcome';
import { completeMutation } from '../../../../apollo/stateFields/complete';

const CurrentOutcomeModal = ({
  outcome,
  loading,
  onOkClick,
  onCancelClick,
  ...modalProps
}: CompleteOutcomeModalProps) => {
  const [saveOption, setSaveOption] = useState(false);
  const fromOutcomeStatus = outcome.status === OutcomeStatus.Future ? 'Future' : 'Completed';
  const { setShouldUpdateStatusToCurrent } = completeMutation;

  const handleCancel = useCallback(async () => {
    saveOption && setShouldUpdateStatusToCurrent(false);
    const shouldUpdateStatusToCurrent = saveOption ? false : undefined;
    onCancelClick(undefined, shouldUpdateStatusToCurrent);
  }, [onCancelClick, saveOption]);

  const handleOk = useCallback(async () => {
    saveOption && setShouldUpdateStatusToCurrent(true);
    const shouldUpdateStatusToCurrent = saveOption ? true : undefined;
    onOkClick(undefined, shouldUpdateStatusToCurrent);
  }, [onOkClick, saveOption]);

  return (
    <StyledModal
      centered
      getContainer={() => document.documentElement}
      closable={true}
      onCancel={handleCancel}
      visible={true}
      footer={null}
      width="26.723rem"
      zIndex={2001}
      bodyStyle={{
        backgroundColor: 'var(--color-main-grey-2)',
      }}
      {...modalProps}
    >
      <StyledModalInnerContainer>
        <StyledMessageWrapper>
          <StyledMessage>Change the status of this outcome?</StyledMessage>
        </StyledMessageWrapper>

        <StyledSubtitleWrapper>
          <StyledSubtitle>
            At least one Action connected to this Outcome is now not Done
          </StyledSubtitle>
        </StyledSubtitleWrapper>

        <StyledContentWrapper>
          {outcome.status === OutcomeStatus.Future ? <StatusFutureIcon /> : <StatusCompletedIcon />}
          <StyledContentText>{fromOutcomeStatus}</StyledContentText>
          <StyledArrowIcon />
          <StatusCurrentIcon />
          <StyledContentText>Current</StyledContentText>
        </StyledContentWrapper>

        <StyledButtonContainer>
          <StyledCancelButton isSecondary onClick={handleCancel}>
            {`Leave as ${fromOutcomeStatus}`}
          </StyledCancelButton>
          <StyledSubmitButton isLoading={loading} onClick={handleOk}>
            Change to Current
          </StyledSubmitButton>
        </StyledButtonContainer>

        <StyledCheckbox
          checked={saveOption}
          onChange={() => {
            setSaveOption(!saveOption);
          }}
        >
          Remember this setting
        </StyledCheckbox>
      </StyledModalInnerContainer>
    </StyledModal>
  );
};

export { CurrentOutcomeModal };

