import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { mutateOutcome } from 'context/userWorkspaceContext';
import { FETCH_OUTCOMES } from 'features/outcomes/graphql/queries';
import { CREATE_OUTCOME } from 'features/outcomes/graphql/mutations';
import { checkIsShownEntity, OperationType, updatedCacheData } from 'features/utils';
import { ColumnType } from 'apollo/stateFields/dnd/dndFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { addEntityId } from '../../../context/dndContext/DndActions';
import { Outcome } from '__generated__/graphql';

const useCreateOutcome = () => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;
  const dispatch = dndDispatch;

  return useMutation(CREATE_OUTCOME, {
    update(cache, { data }) {
      const { fetchUserWorkspaceOutcomes = [] } =
        cache.readQuery({
          query: FETCH_OUTCOMES,
          variables: {
            workspaceId: data?.createUserWorkspaceOutcome.workspaceId,
          },
        }) || {};

      cache.writeQuery({
        query: FETCH_OUTCOMES,
        variables: {
          workspaceId: data?.createUserWorkspaceOutcome.workspaceId,
        },
        data: {
          fetchUserWorkspaceOutcomes: updatedCacheData(
            fetchUserWorkspaceOutcomes,
            data?.createUserWorkspaceOutcome,
            OperationType.CREATE,
          ),
        },
      });
      if (data?.createUserWorkspaceOutcome) {
        dispatch(
          addEntityId({
            entityId: data.createUserWorkspaceOutcome.id,
            columnTitle: ColumnType.Outcome,
          }),
        );
        mutateOutcome(
          apolloClient,
          data.createUserWorkspaceOutcome as Outcome,
          'create',
          filterInput,
        );

        if (!checkIsShownEntity(data.createUserWorkspaceOutcome as Outcome, filterInput)) {
          setUpdateFilterInputId({
            type: 'Outcome',
            workspaceId: data.createUserWorkspaceOutcome.workspaceId!,
            id: data.createUserWorkspaceOutcome.id,
          });
        }
      }
    },
  });
};

export default useCreateOutcome;

