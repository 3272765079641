import { Row, Typography } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode } from 'react';

const { Paragraph, Text } = Typography;

export interface IFilterInfoProps<T> {
  filters: {
    values: FilterValue | null;
    render?: ColumnsType<T>[number]['render'];
    col: ColumnsType<T>[number];
  }[];
}

const FilterInfo = <T,>({ filters }: IFilterInfoProps<T>) => {
  return (
    <Row justify="center">
      <Typography>
        You are currently filtering by:
        {filters.map((filterInfo) => {
          const { col, values } = filterInfo;
          if (values == null || values[0] == null) return null;
          const { title, key } = col;
          const defaultRender = (val: any) => val;
          let render = filterInfo.render ?? col.render ?? defaultRender;
          const currentFilteringValues: ReactNode[] = [];
          const [firstValue, ...restValues] = values;
          currentFilteringValues.push(render(firstValue, {} as T, 0));
          for (const val of restValues) {
            val && currentFilteringValues.push(' | ', render(val, {} as T, 0));
          }
          return (
            <Paragraph key={key}>
              <Text strong>{title}</Text>: {currentFilteringValues}
            </Paragraph>
          );
        }, [] as ReactNode[])}
      </Typography>
    </Row>
  );
};

export default FilterInfo;

