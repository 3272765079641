import { NODE_ENV } from 'core/constants/others';

const isProduction = NODE_ENV === 'production' || NODE_ENV === 'prod';

const log = <T extends keyof typeof devConsole>(method: T, ...args: any[]): void => {
  if (isProduction) return;
  console[method](`Dev Console ${method}:`, ...args);
};

export const devConsole = {
  log: (message: any, ...args: any[]) => log('log', message, ...args),
  warn: (message: any, ...args: any[]) => log('warn', message, ...args),
  error: (message: any, ...args: any[]) => log('error', message, ...args),
  info: (message: any, ...args: any[]) => log('info', message, ...args),
};

export default devConsole;

