import React, { FC, useCallback, useEffect } from 'react';
import { FETCH_SETTINGS } from '../../../graphql/queries';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { UPDATE_SETTINGS } from '../../../graphql/mutations';
import { omit } from 'lodash-es';
import { EntityType, Filter, getCacheEntityById } from '../../../features/utils';
import { UPDATE_WORKSPACE_RELATION } from '../../../features/workspaces/graphql/mutations';
import { Permission, Workspace } from '../../types/workspace';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../features/common/graphql/queries';
import {
  DoneColumnFilterTypes,
  ToDoColumnFilterTypes,
} from '../../../apollo/stateFields/boardFilters/boardFiltersFields';
import { AUTH_STATE, AuthValue } from '../../../apollo/stateFields/auth/authFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../apollo/stateFields/userSettings/userSettingsField';
import { userSettingsMutation } from '../../../apollo/stateFields/userSettings';
import { boardFiltersMutation } from '../../../apollo/stateFields/boardFilters';
import { completeMutation } from '../../../apollo/stateFields/complete';
import { coursesMutation } from '../../../apollo/stateFields/courses';
import { tutorialMutation } from '../../../apollo/stateFields/tutorial';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { setBoardColumns } from '../../../context/dndContext/DndActions';
import { errorMutation } from '../../../apollo/stateFields/error';
import { recommendMutation } from '../../../apollo/stateFields/recommend';
import {
  CUSTOM_ERROR_MESSAGE,
  ERROR_STATE,
  ErrorValue,
} from '../../../apollo/stateFields/error/errorFields';
import { getCacheFilterWorkspaceById } from '../../../features/utils/getCacheEntityById';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import { removeKey } from '../../utils/removeKeyFn';
import { Activity } from 'pages/Onboarding/types';

const UserSettingsManager: FC = () => {
  const apolloClient = useApolloClient();
  const { data: authData } = useQuery(AUTH_STATE);
  const { isNewUser, isRegistered }: AuthValue = authData?.auth;
  const { data: errorData } = useQuery(ERROR_STATE);
  const { errorMessage }: ErrorValue = errorData?.error;
  const { setErrorMessage, setErrorWorkspaces, setErrorCode } = errorMutation;
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired }: StripeValue = stripeData?.stripe;
  const { setTimezone } = coursesMutation;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const {
    defaultWorkspaceId,
    isSettingsLoaded,
    startDefaultWorkspaceId,
    defaultWorkspaceName,
  }: UserSettingsValue = userSettingsData?.userSettingsField;
  const {
    setShouldUpdateFilterInput,
    setShouldUpdateStartDate,
    setIsSettingLoaded,
    setShouldActionLinkOutcome,
    setShouldOutcomeLinkAction,
    setDefaultWorkspaceId,
  } = userSettingsMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { addFilterInputWorkspace, setFilterInput } = filterMutation;
  const dispatch = dndDispatch;
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const { data: workspacesResponse, refetch } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const { setShowTutorialVideo, setInitCompletedStep, setUserPreferences } = tutorialMutation;
  const [updateIsNewShared] = useMutation(UPDATE_WORKSPACE_RELATION);
  const { setShouldUpdateStatusToComplete, setShouldUpdateStatusToCurrent } = completeMutation;
  const {
    setDoneColumnStartDate,
    setDoneColumnEndDate,
    setDoneColumnFilter,
    setToDoColumnFilter,
    setToDoColumnStartDate,
    setToDoColumnEndDate,
  } = boardFiltersMutation;
  const { setTutorialStep } = coursesMutation;
  const { setRemindDate, setShouldRemind, setWithAdditionalBtns } = recommendMutation;

  const uploadSettings = useCallback(async () => {
    const uploadSettingsData = await apolloClient.query({
      query: FETCH_SETTINGS,
      fetchPolicy: 'network-only',
    });
    const data = uploadSettingsData?.data || {};
    if (
      data.fetchUserSettings &&
      !isSettingsLoaded
      // && workspacesResponse?.fetchUserWorkspaces.length
    ) {
      const boardColumns = removeKey(data.fetchUserSettings.boardColumns, '__typename');
      const settingsDefaultWorkspaceId = data.fetchUserSettings.defaultWorkspaceId;
      dispatch(setBoardColumns(boardColumns));
      setShouldUpdateStartDate(data.fetchUserSettings.setStartDateToToday ?? null);
      setShouldUpdateStatusToComplete(data.fetchUserSettings.shouldUpdateStatusToComplete ?? null);
      setShouldUpdateStatusToCurrent(data.fetchUserSettings.shouldUpdateStatusToCurrent ?? null);
      setShouldUpdateFilterInput(data.fetchUserSettings.setUpdateFilterInput ?? null);
      setShouldActionLinkOutcome(data.fetchUserSettings.shouldActionLinkOutcome ?? null);
      setShouldOutcomeLinkAction(data.fetchUserSettings.shouldOutcomeLinkAction ?? null);
      // TODO fix this casting
      setUserPreferences(data.fetchUserSettings.userPreferences?.[0] as unknown as Activity[]);
      setInitCompletedStep(data.fetchUserSettings.tutorialPassedStep as unknown as number);

      setRemindDate(data.fetchUserSettings?.recommendedCompanyRemindDate);
      if (
        data.fetchUserSettings?.recommendedCompany === null &&
        data.fetchUserSettings?.recommendedCompanyRemindDate === null
      ) {
        setWithAdditionalBtns(true);
        setShouldRemind(true);
      }

      if (!isRegistered || (isNewUser && isRegistered)) {
        if (data.fetchUserSettings.filterInput?.length) {
          defaultWorkspaceId !== -1 && addFilterInputWorkspace(defaultWorkspaceId);
        } else {
          defaultWorkspaceId !== -1 &&
            setFilterInput([{ workspaceId: defaultWorkspaceId, tagIds: [] }]);
        }
      }
      if (isNewUser && !!defaultWorkspaceId && defaultWorkspaceId !== -1) {
        const filterOptions = data.fetchUserSettings.filterInput
          ?.map((input) => omit(input, '__typename'))
          .filter((f) => f.workspaceId !== -1);
        setFilterInput(filterOptions as Filter[]);
        setShowTutorialVideo(true);
      } else {
        const filterOptions = data.fetchUserSettings.filterInput
          ?.map((input) => omit(input, '__typename'))
          .filter((f) => f.workspaceId !== -1);
        setFilterInput(filterOptions as Filter[]);
      }
      if (data.fetchUserSettings.coursePassedStep) {
        setTutorialStep(data.fetchUserSettings.coursePassedStep);
      }
      if (!data.fetchUserSettings.timezone || data.fetchUserSettings.timezone.indexOf('/') === -1) {
        const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await updateSettings({
          variables: {
            settings: {
              timezone: defaultTimezone,
            },
          },
        });
        setTimezone(defaultTimezone);
      } else {
        setTimezone(data.fetchUserSettings.timezone);
      }
      if (settingsDefaultWorkspaceId && settingsDefaultWorkspaceId > 0) {
        setDefaultWorkspaceId(settingsDefaultWorkspaceId);
      } else {
        if (startDefaultWorkspaceId !== -1) {
          setDefaultWorkspaceId(startDefaultWorkspaceId);
          await updateSettings({
            variables: {
              settings: {
                defaultWorkspaceId: startDefaultWorkspaceId,
              },
            },
          });
        }
      }
      if (data.fetchUserSettings?.doneColumnFilter?.filter) {
        setDoneColumnFilter(
          data.fetchUserSettings?.doneColumnFilter?.filter as DoneColumnFilterTypes,
        );
        setDoneColumnStartDate(
          data.fetchUserSettings?.doneColumnFilter?.startDate
            ? new Date(data.fetchUserSettings?.doneColumnFilter?.startDate)
            : null,
        );
        setDoneColumnEndDate(
          data.fetchUserSettings?.doneColumnFilter?.endDate
            ? new Date(data.fetchUserSettings?.doneColumnFilter?.endDate)
            : null,
        );
      } else {
        await updateSettings({
          variables: {
            settings: {
              doneColumnFilter: {
                name: 'Done',
                filter: DoneColumnFilterTypes.ANYTIME,
              },
            },
          },
        });
      }
      if (data.fetchUserSettings?.toDoColumnFilter?.filter) {
        setToDoColumnFilter(
          data.fetchUserSettings?.toDoColumnFilter?.filter as ToDoColumnFilterTypes,
        );
        setToDoColumnStartDate(
          data.fetchUserSettings?.toDoColumnFilter?.startDate
            ? new Date(data.fetchUserSettings?.toDoColumnFilter?.startDate)
            : null,
        );
        setToDoColumnEndDate(
          data.fetchUserSettings?.toDoColumnFilter?.endDate
            ? new Date(data.fetchUserSettings?.toDoColumnFilter?.endDate)
            : null,
        );
      } else {
        await updateSettings({
          variables: {
            settings: {
              toDoColumnFilter: {
                name: 'ToDo',
                filter: ToDoColumnFilterTypes.ANYTIME,
              },
            },
          },
        });
      }
    }
  }, [isNewUser, isSettingsLoaded]);

  useEffect(() => {
    if (isSettingsLoaded && !!defaultWorkspaceId && filterInput.length === 0) {
      addFilterInputWorkspace(defaultWorkspaceId);
    }
  }, [isSettingsLoaded]);

  useEffect(() => {
    if (isSubscriptionExpired && filterInput.length > 1) {
      setFilterInput([{ workspaceId: defaultWorkspaceId || startDefaultWorkspaceId, tagIds: [] }]);
    }
  }, [isSubscriptionExpired]);

  useEffect(() => {
    if (isSettingsLoaded) {
      const onLoadFilterInput = !isSubscriptionExpired
        ? filterInput.filter((w) => w.workspaceId !== -1)
        : filterInput.length > 1
        ? [{ workspaceId: defaultWorkspaceId || startDefaultWorkspaceId, tagIds: [] }]
        : filterInput.filter((w) => w.workspaceId !== -1);

      updateSettings({
        variables: {
          settings: {
            filterInput: onLoadFilterInput,
          },
        },
      });

      filterInput.forEach((filter) => {
        const space = getCacheEntityById(
          apolloClient,
          filter.workspaceId,
          EntityType.WORKSPACE,
          filterInput,
        ) as unknown as Workspace;
        if (space?.isNewShared) {
          updateIsNewShared({
            variables: {
              workspaceId: filter.workspaceId,
              relationUserWokspace: {
                isNewShared: false,
              },
            },
          });
        }
      });
    }
  }, [filterInput, isSettingsLoaded]);

  useEffect(() => {
    uploadSettings().then(() => setIsSettingLoaded(true));
  }, []);

  useEffect(() => {
    if (
      defaultWorkspaceId !== -1 &&
      workspacesResponse?.fetchUserWorkspacesWithInvitedCount.length
    ) {
      const workspaceWithInvitedCount =
        workspacesResponse?.fetchUserWorkspacesWithInvitedCount.find(
          (w) => w.id === defaultWorkspaceId,
        );
      if (workspaceWithInvitedCount?.permission === Permission.Viewer) {
        refetch().then((res) => {
          const defaultWorkspace = res.data.fetchUserWorkspacesWithInvitedCount.find(
            (w) => w.id === defaultWorkspaceId,
          );
          if (
            defaultWorkspace &&
            defaultWorkspace?.permission === Permission.Viewer &&
            !errorMessage
          ) {
            setErrorCode(405);
            setErrorMessage(CUSTOM_ERROR_MESSAGE.NO_RELATION);
            setErrorWorkspaces([
              {
                id: defaultWorkspaceId,
                permission: Permission.Editor,
                type: 'Workspace',
                name: defaultWorkspace.name || defaultWorkspaceName || 'Default',
              },
            ]);
          }
        });
      } else if (!workspaceWithInvitedCount) {
        refetch().then((res) => {
          if (
            !res.data.fetchUserWorkspacesWithInvitedCount.some(
              (w) => w.id === defaultWorkspaceId,
            ) &&
            !errorMessage
          ) {
            setErrorCode(405);
            setErrorMessage(CUSTOM_ERROR_MESSAGE.NO_RELATION);
            const cachedWorkspace = getCacheFilterWorkspaceById(
              apolloClient,
              filterInput,
              defaultWorkspaceId,
            );
            setErrorWorkspaces([
              {
                id: defaultWorkspaceId,
                permission: Permission.Editor,
                type: 'Workspace',
                name: cachedWorkspace?.name || defaultWorkspaceName || 'Default',
              },
            ]);
          }
        });
      }
    }
  }, [defaultWorkspaceId, workspacesResponse?.fetchUserWorkspacesWithInvitedCount, filterInput]);

  return <></>;
};

export { UserSettingsManager };

