import { Button } from './Button';
import { ButtonProps } from './types';

export const SubmitButton = ({ disabled, isDisabled, htmlType, ...restProps }: ButtonProps) => (
  <Button
    {...restProps}
    disabled={disabled ?? isDisabled}
    isDisabled={isDisabled ?? disabled}
    htmlType={htmlType ?? 'submit'}
  />
);

