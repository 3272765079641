import { StyledButtonsContainer, StyledDescription } from '../StyledComponents';
import { Upload, UploadProps } from 'antd';
import { Button } from '../../../core/components/common';
import { UploadOutlined } from '@ant-design/icons';
import { API_REST_DELETE_USERS_URL } from '../../../core/integrations/api/config';
import { useAuth } from '../../../context/authContext';
import axios from 'axios';
import devConsole from 'core/utils/devConsole';
import { useRef, useState } from 'react';
import LabeledCheckbox from '../ApplyUsersCheckbox/ApplyUsersCheckbox';
import UserErrorDataTable from '../UserErrorDataTable/UserErrorDataTable';
import { UploadFile } from 'antd/es/upload/interface';
import { DeletedUserResponse, FailedUserInfo } from '../UserErrorDataTable/types';
import { DeletedUsersResults } from '__generated__/graphql';
import UserDeleteResultsTable from '../UserDeleteResultsTable/UserDeleteResultsTable';
import CsvDeleteInstructions from '../CsvUploadInstructions/CsvDeleteInstructions';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

interface DeleteUsersCsvProps {
  setIsSuccess: (isSuccess: boolean) => void;
  setErrorMessage: (errorMessage: string | null) => void;
}

const DeleteUsersCsv = ({ setIsSuccess, setErrorMessage }: DeleteUsersCsvProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const deleteActionRef = useRef<(() => void) | null>(null);
  const [isTestRun, setIsTestRun] = useState<boolean>(false);
  const [wasTestRun, setWasTestRun] = useState<boolean>(false);
  const [deletedList, setDeletedList] = useState<DeletedUsersResults[]>([]);
  const [failedList, setFailedList] = useState<FailedUserInfo[]>([]);
  const [uploadedFile, setUploadedFile] = useState<UploadFile | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const { idToken } = useAuth();

  const handleConfirm = () => {
    setShowConfirmModal(false);
    deleteActionRef.current && deleteActionRef.current();
  };

  const handleCancel = () => {
    console.log('handleCancel');
    setShowConfirmModal(false);
    setIsLoading(false);
    setUploadedFile(undefined);
    deleteActionRef.current = null;
  };
  const uploadProps: UploadProps = {
    action: API_REST_DELETE_USERS_URL + (isTestRun ? '-testrun' : ''),
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${idToken}`,
      Accept: '*/*',
    },
    showUploadList: {
      showRemoveIcon: false,
      showPreviewIcon: true,
    },
    customRequest: (options: any) => {
      const data = new FormData();
      data.append('file', options.file);
      const config = {
        headers: {
          authorization: `Bearer ${idToken}`,
          'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        },
      };
      const deleteAction = () =>
        axios
          .post<DeletedUserResponse>(options.action, data, config)
          .then(({ data }) => {
            options.onSuccess(data, options.file);
            const { failed, deleted } = data;
            setDeletedList(deleted);
            setFailedList(failed);
            setIsSuccess(true);
            setUploadedFile(options.file);
            setWasTestRun(isTestRun);
          })
          .catch((err: Error) => {
            setErrorMessage('Oops');
            devConsole.error(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      if (isTestRun) {
        deleteAction();
      } else {
        setShowConfirmModal(true);
        deleteActionRef.current = deleteAction;
      }
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        devConsole.info(info.file, info.fileList);
      } else {
        setIsLoading(true);
      }
      console.log(info.file.status);
      if (info.file.status === 'done') {
        setIsSuccess(true);
        setUploadedFile(info.file);
        setWasTestRun(isTestRun);
      } else if (info.file.status === 'error') {
        setErrorMessage('Ooops');
      }
    },
    fileList: uploadedFile ? [uploadedFile] : [],
    accept: '.xlsx, .xls, .csv',
  };

  return (
    <>
      <StyledDescription>Delete users by csv file.</StyledDescription>
      <CsvDeleteInstructions onError={setErrorMessage} onSuccess={setIsSuccess} />
      <LabeledCheckbox setValue={setIsTestRun} value={isTestRun}>
        Test Run
      </LabeledCheckbox>
      <StyledButtonsContainer>
        <Upload {...uploadProps}>
          <Button
            icon={<UploadOutlined />}
            isLoading={isLoading}
            disabled={isLoading}
            isDisabled={isLoading}
          >
            Click to Upload
          </Button>
        </Upload>
      </StyledButtonsContainer>
      {deletedList.length > 0 && (
        <UserDeleteResultsTable
          dataSource={deletedList}
          isLoading={isLoading}
          isTestRun={wasTestRun}
        />
      )}
      {failedList.length > 0 && (
        <UserErrorDataTable
          userErrorDataList={failedList}
          isLoading={isLoading}
          title={`Users that ${wasTestRun ? 'would have ' : ''}failed`}
        />
      )}
      <ConfirmModal onConfirm={handleConfirm} onCancel={handleCancel} visible={showConfirmModal} />
    </>
  );
};

export default DeleteUsersCsv;

