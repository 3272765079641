import { Workspace } from '../types/workspace';

export const addTypenameToAllWorkspaces = (workspaces: Workspace[], isForAll?: boolean) => {
  return workspaces.map((w) => {
    const actions = w.actions?.map((a) => {
      return {
        ...a,
        workspaceId: Number(a.workspaceId),
        __typename: 'Action',
        description: a?.description || '',
        actualPoints: a?.actualPoints || null,
        estimatedPoints: a?.estimatedPoints || null,
        priority: a?.priority || null,
        estimatedTime: a?.estimatedTime || null,
        startDate: a?.startDate || null,
        actualStartDate: a?.actualStartDate || null,
        actualEndDate: a?.actualEndDate || null,
        endDate: a?.endDate || null,
        actionTimeFormat: a?.actionTimeFormat || null,
        spendTime: a?.spendTime || null,
        updateDate: a?.updateDate || null,
        name: a?.name || '',
        outcome: a?.outcome
          ? {
              ...a?.outcome,
              isAllActionsDone: a.outcome?.isAllActionsDone || null,
              name: a.outcome?.name || '',
              status: a.outcome?.status || null,
            }
          : null,
      };
    });
    const outcomes = w.outcomes?.map((o) => {
      return {
        ...o,
        actions: o.actions || null,
        workspaceId: Number(o.workspaceId),
        __typename: 'Outcome',
        description: o?.description || '',
        endDate: o?.endDate || null,
        updateDate: o?.updateDate || null,
        startDate: o?.startDate || null,
        completeDate: o?.completeDate || null,
        name: o?.name || '',
      };
    });
    const notes = w.notes?.map((n) => {
      return {
        ...n,
        workspaceId: Number(n.workspaceId),
        __typename: 'Note',
        createDate: n?.createDate,
        name: n?.name || '',
      };
    });
    const tags = w.tags?.map((t) => {
      return {
        ...t,
        workspaceId: Number(t.workspaceId),
        __typename: 'Tag',
        name: t?.name || '',
        actionId: t.actionId || null,
        updateDate: t?.updateDate || null,
      };
    });
    return {
      ...w,
      __typename: 'Workspace',
      actions: actions || [],
      outcomes: outcomes || [],
      notes: notes || [],
      tags: tags || [],
      id: Number(w.id),
      description: w?.description || null,
      createDate: isForAll ? w?.createDate || null : null,
    };
  }) as Workspace[];
};
