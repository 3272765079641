import React, {
  ChangeEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isEmpty, isFinite, omit } from 'lodash';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Button, Divider, Drawer, Typography } from 'antd';
import { Formik } from 'formik';
import { Checkbox } from 'formik-antd';
import * as Yup from 'yup';

import { ArrowDownIcon, CloseIcon } from 'core/icons';
import { MouseOverCursorWrapper } from 'core/components/MouseOverCursorWrapper';
import { Action, ActionStatus } from 'core/types/action';
import { theme } from 'core/styles/styled-components';
import closeIcon from 'core/img/close-icon@1x.png';
import { FETCH_OUTCOMES } from 'features/outcomes/graphql/queries';
import {
  FETCH_USER_WORKSPACE,
  FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
} from 'features/common/graphql/queries';
import { AddTags } from 'features/common/components/form/AddTags';
import { EditActionContentProps } from './types';
import { DEFAULT_ACTION_TIME_FORMAT, getInitialValues } from './utils';
import { adjustActualDateAccordingStatus } from '../../../utils';
import { ActionStatusComponent } from '../common/ActionStatusComponent';
import DataRowComponent from '../common/DataRowComponent';
import DurationRowComponent from '../common/DurationRowComponent';
import { convertToMs, sortWorkspaces } from '../utils';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { formatISO, parseISO, startOfDay } from 'date-fns';
import { Messages } from 'core/constants/messages';
import { TagOption } from '../../../../common/components/form/AddTags/AddTags';
import {
  DebouncedTextAreaInput,
  DebouncedTextInput,
} from '../../../../../core/enchancedComponents/DebouncedTextField/DebouncedTextField';
import { Permission, Workspace } from '../../../../../core/types/workspace';
import { NOT_ASSIGNED_WORKSPACE_NAME } from '../../../../../core/constants/others';
import { Option, Select } from '../../../../../core/components/common/Select';
import useSubscribeEntity from '../../../../../hooks/useSubscribeEntity';
import { SUBSCRIBE_ACTION } from '../../../../../graphql/subscriptions';
import { toast, ToastContent } from 'react-toastify';
import useUpdateMoveAction from '../../../../interactions/action/useUpdateMoveAction';
import useMoveAction from '../../../../interactions/action/useMoveAction';
import { useUserWorkspaceContext } from '../../../../../context/userWorkspaceContext';
import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
  ERROR_STATE,
  ErrorValue,
} from '../../../../../apollo/stateFields/error/errorFields';
import { useCheckSharedEntityMoved } from '../../../../../hooks/useCheckSharedEntityMoved';
import { Loader } from '../../../../../core/components/common';
import { useLazyLoadEntity } from '../../../../../hooks/useLazyLoadEntity';
import { setTimeoutPromise } from '../../../../../core/utils/promiseTimeout';
import { errorMutation } from '../../../../../apollo/stateFields/error';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../apollo/stateFields/userSettings/userSettingsField';
import { boardEditedEntityMutation } from '../../../../../apollo/stateFields/boardEditedEntity';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import {
  COMPLETE_STATE,
  CompleteValue,
} from '../../../../../apollo/stateFields/complete/completeFields';
import ActionPriorityComponent from '../common/ActionPriorityComponent';
import { FETCH_FOCUS_TIMER } from '../../../graphql/queries';
import { ActionTimeFormat } from '../types';
import { ActionInput } from '__generated__/graphql';

const { Title } = Typography;

const StyledTitle = styled(Title)`
  && {
    margin-bottom: 9px;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 100%;
    @media ${theme.device.mobile.min} {
      width: 100%;
    }
    @media ${theme.device.tablet.min} {
      width: 384px;
    }
    @media ${theme.device.desktop.min} {
      width: 847px;
      height: auto;
      top: 110px;
      bottom: 0;
    }
  }
  .ant-drawer-content {
    border-top-left-radius: 4px;
  }
  .ant-drawer-body {
    padding: 0;
  }
  h4 {
    font-size: 14px;
  }
  .ant-input,
  textarea.ant-input,
  .ant-picker {
    display: block;
  }
  .ant-input {
    line-height: 32px;
  }
  .ant-divider-horizontal {
    margin: 34px 0;
    &.mainDivider {
      margin-bottom: 0;
      @media ${theme.device.desktop.min} {
        display: none;
      }
    }
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const StyledDataRowComponentWrapper = styled.div`
  margin-bottom: 35px;
`;

const StyledFormDiv = styled.div`
  background: var(--color-main-grey-2);
  height: 100%;

  @media ${theme.device.desktop.min} {
    background: var(--color-dark-grey);
    display: flex;
  }
`;

const StyledMainContent = styled.div`
  padding: 32px 24px 0;

  @media ${theme.device.desktop.min} {
    flex: 1;
    padding: 24px 32px 24px 40px;
    background: var(--color-main-grey-2);
    overflow-y: auto;
  }
`;

const StyledSideContent = styled.div`
  padding: 24px 24px 100px;
  position: relative;
  min-height: calc(100% - 326px);
  overflow: auto;

  @media ${theme.device.mobile.min} {
    background-color: var(--color-main-grey-2);
  }

  @media ${theme.device.desktop.min} {
    background-color: transparent;
    width: 320px;
    padding: 30px 24px 24px 28px;
  }
`;

const StyledArchivedCheckbox = styled(Checkbox)`
  margin: 0 16px;
`;

const StyledStatusRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media ${theme.device.tablet.max} {
    flex-wrap: wrap;
  }
`;

const StyledStatusRowInner = styled.div`
  @media ${theme.device.tablet.max} {
    width: 100%;
  }
`;

const StyledInputName = styled(DebouncedTextInput)`
  && {
    line-height: 40px;
    height: 40px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    .ant-select-item.ant-select-item-option {
      background-color: var(--color-white);

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
`;

const StyledDebouncedTextAreaInput = styled(DebouncedTextAreaInput)`
  && {
    height: 495px;

    @media ${theme.device.tablet.max} {
      height: 72px;
    }

    @media ${theme.device.mobile.max} {
      height: 72px;
    }
  }
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.4285em;
  color: #202635;
  line-height: 1.1428;
`;

export const StyledRemoveButton = styled(Button)`
  min-width: 0;
  width: 26px;
  height: 26px;
  margin: 0 8px;
  padding: 0;
  border-color: transparent;
`;

export const StyledCloseButton = styled(StyledRemoveButton)`
  margin: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2001;
  @media ${theme.device.tablet} {
    top: 20px;
    right: 20px;
  }
  @media ${theme.device.desktop.min} {
    display: none;
  }
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EditActionContent = React.memo(
  ({ actions, isLoading, setIsLoading, onClose }: EditActionContentProps) => {
    const { data } = useQuery(ERROR_STATE);
    const { errorMessage }: ErrorValue = data?.error;
    const { setErrorMessage, setErrorWorkspaces, setEntityType } = errorMutation;
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { data: filterData } = useQuery(FILTER_STATE);
    const { filterInput }: FilterInputValue = filterData?.filterInput;
    const { setUpdateFilterInputId } = filterMutation;
    const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
    const { actionEditedEntity }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
    const { showEditActionModal } = boardEditedEntityMutation;
    const [action, setAction] = useState<Action | null>(actionEditedEntity);
    const [fixedAction, setFixedAction] = useState<Action | null>(null);
    const [formTouched, setFormTouched] = useState<boolean>(false);
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [updateAction, { loading: updateLoading }] = useUpdateMoveAction(action);
    const [moveAction, { loading: moveLoading }] = useMoveAction(action);
    const [currentWorkspaceId, setCurrentWorkspaceId] = useState<number>(
      action?.workspaceId || actionEditedEntity?.workspaceId || defaultWorkspaceId!,
    );
    const prevActionStatus = useRef<ActionStatus | null>(null);
    const actionStatusBeforeChange = useRef<ActionStatus | null>(null);
    const apolloClient = useApolloClient();
    const {
      getEntity,
      data: actionData,
      resetData: resetActionData,
      loading,
      // FIXME
    } = useLazyLoadEntity(CUSTOM_ENTITY_TYPES.ACTION, action?.workspaceId!);
    const mutationLoading = useMemo(() => {
      return updateLoading || moveLoading || false;
    }, [updateLoading, moveLoading]);
    const prevWorkspace = useRef<Workspace | null>(null);
    const { data: focusTimerData } = useQuery(FETCH_FOCUS_TIMER);

    const [fetchOutcomes, { data: fetchUserWorkspaceOutcomesResponse }] = useLazyQuery(
      FETCH_OUTCOMES,
      {
        fetchPolicy: 'network-only',
      },
    );

    const [getWorkspace] = useLazyQuery(FETCH_USER_WORKSPACE, {
      fetchPolicy: 'network-only',
    });

    const initialValues = useMemo(() => getInitialValues(action), [action]);
    const { data: subscriptionData } = useSubscribeEntity(
      SUBSCRIBE_ACTION,
      action?.workspaceId,
      action?.id,
    );

    const getOutcomes = useCallback(() => {
      if (action?.outcome && fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes) {
        if (action?.isArchived) {
          return [
            ...fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes.filter((outcome) => {
              return outcome.id !== action.outcome?.id;
            }),
            action.outcome,
          ];
        } else {
          return [...fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes];
        }
      } else if (
        action?.outcome &&
        !fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes
      ) {
        return [action.outcome];
      } else {
        return fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes || [];
      }
    }, [action, fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes]);

    const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
    const workspaces: Workspace[] =
      workspacesResponse?.fetchUserWorkspacesWithInvitedCount
        .slice()
        .sort((a, b) => sortWorkspaces(a, b, defaultWorkspaceId)) || [];

    const workspace = useMemo(
      () => workspaces.find((workspace) => workspace.id === action?.workspaceId),
      [workspaces, action],
    );

    const viewerPermission = useMemo(() => {
      return workspace?.permission === Permission.Viewer || false;
    }, [workspaces, action]);
    const editorPermission = useMemo(
      () => workspace?.permission === Permission.Editor || false,
      [workspaces, action],
    );
    const workspaceOptions = useMemo(() => {
      return workspaces
        .filter((workspace) => workspace.name !== NOT_ASSIGNED_WORKSPACE_NAME)
        .filter(
          (workspace) =>
            workspace?.permission !== Permission.Viewer ||
            (subscriptionData?.subscribeToChanges?.action?.workspaceId
              ? subscriptionData?.subscribeToChanges?.action?.workspaceId === workspace.id
              : action?.workspaceId === workspace.id) ||
            false,
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((workspace) => ({
          label: workspace.name,
          value: workspace.id,
        }));
    }, [
      workspaces,
      action?.workspaceId,
      subscriptionData?.subscribeToChanges?.action?.workspaceId,
    ]);

    const validationSchema = Yup.object().shape({
      name: Yup.string().max(256, 'Max length of name is 256 characters!').required(),
      status: Yup.string().required(),
      isArchived: Yup.boolean().nullable(),
      description: Yup.string()
        .max(1000, 'Max length of description is 1000 characters!')
        .nullable(),
      workspaceId: Yup.number().nullable(),
      outcomeId: Yup.number().nullable(),
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
      actualStartDate: Yup.mixed().when('status', {
        is: (status: string) => status === ActionStatus.Done || status === ActionStatus.Doing,
        then: Yup.date().required(),
        otherwise: Yup.date().nullable(),
      }),
      actualEndDate: Yup.mixed().when('status', {
        is: (status: string) => status === ActionStatus.Done,
        then: Yup.date().required(),
        otherwise: Yup.date().nullable(),
      }),
      estimatedTime: Yup.number().min(0).nullable(),
      spendTime: Yup.number().min(0).nullable(),
      actionTimeFormat: Yup.string().when(['estimatedTime', 'spendTime'], {
        is: (estimatedTime: number, spendTime: number) =>
          isFinite(estimatedTime) || isFinite(spendTime),
        then: Yup.string().required(),
      }),
      inputTags: Yup.array(),
    });

    const onSubmit = useCallback(
      async (values: any) => {
        const actionValues = omit(values, 'isArchived') as any;

        if (action === null) {
          return;
        }
        let variables: {
          actionValues: ActionInput;
          outcomeId?: number | null;
          targetOutcomeId?: number | null;
          targetWorkspaceId?: number | null;
          workspaceId?: number | null;
        } =
          action.workspaceId === actionValues.workspaceId
            ? { actionValues, workspaceId: action.workspaceId }
            : {
                actionValues,
                workspaceId: action.workspaceId,
                targetWorkspaceId: actionValues.workspaceId,
              };
        try {
          if (
            (!!action?.outcome?.id &&
              actionValues.outcomeId &&
              actionValues.outcomeId !== action?.outcome?.id) ||
            actionValues.workspaceId !== action?.workspaceId
          ) {
            variables = action?.outcome?.id
              ? {
                  ...variables,
                  outcomeId: action?.outcome?.id,
                  targetOutcomeId: actionValues.outcomeId,
                  targetWorkspaceId: actionValues.workspaceId,
                }
              : actionValues.outcomeId
              ? {
                  ...variables,
                  targetWorkspaceId: actionValues.workspaceId,
                  targetOutcomeId: actionValues.outcomeId,
                }
              : {
                  ...variables,
                  targetWorkspaceId: actionValues.workspaceId,
                };
            return moveAction({
              refetchQueries: [{ query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT }],
              variables,
            }).then((res) => {
              res.data?.moveUserWorkspaceAction.action &&
                setAction(res.data?.moveUserWorkspaceAction.action as Action);
              if (
                workspacesResponse?.fetchUserWorkspacesWithInvitedCount &&
                workspacesResponse.fetchUserWorkspacesWithInvitedCount.some(
                  (w) => w.id === res?.data?.moveUserWorkspaceAction?.action?.workspaceId,
                )
              ) {
                // FIXME
                handleFetchOutcomes(res?.data?.moveUserWorkspaceAction?.action?.workspaceId!);
              }
              sendEvent('action-move', 'Submit edit action', {
                'Action ID': action?.id,
              });
              return res;
            });
          } else {
            if (
              variables.actionValues.status &&
              actionStatusBeforeChange?.current !== variables.actionValues.status
            ) {
              variables = {
                ...variables,
                actionValues: {
                  id: variables.actionValues.id,
                  status: variables.actionValues.status,
                  actualEndDate: variables.actionValues.actualEndDate,
                  actualStartDate: variables.actionValues.actualStartDate,
                },
              };
              actionStatusBeforeChange.current = variables.actionValues.status!;
            }
            return updateAction({
              refetchQueries: [
                ...(focusTimerData?.fetchFocusTimer?.actionId === action.id
                  ? [FETCH_FOCUS_TIMER, FETCH_USER_WORKSPACES_WITH_INVITED_COUNT]
                  : [FETCH_USER_WORKSPACES_WITH_INVITED_COUNT]),
              ],
              variables,
            }).then((res) => {
              res.data?.updateUserWorkspaceAction &&
                setAction(res.data?.updateUserWorkspaceAction as Action);
              sendEvent('action-edit', 'Submit edit action', {
                'Action ID': action?.id,
              });
              return res;
            });
          }
        } catch (error) {
          console.error(error);
          toast(error as ToastContent);
        }
      },
      [
        updateAction,
        moveAction,
        action,
        apolloClient,
        filterInput,
        focusTimerData?.fetchFocusTimer,
        actionStatusBeforeChange?.current,
      ],
    );

    const setArchive = useCallback(
      async (isArchived: boolean) => {
        if (action === null) {
          return;
        }
        showEditActionModal(null);
        try {
          await updateAction({
            variables: {
              actionValues: {
                id: action.id,
                isArchived,
              },
              workspaceId: action.workspaceId,
            },
          });

          sendEvent(
            isArchived ? 'action-archive' : 'action-unarchive',
            isArchived ? 'Action archive' : 'Action unarchive',
            {
              'Action ID': action.id,
            },
          );
        } catch (error) {
          console.error(error);
          toast(error as ToastContent);
        }
      },
      [updateAction, action, apolloClient, filterInput],
    );

    const onSave = useCallback(
      async (actionValues: any) => {
        if (!action) {
          return;
        }

        const { outcomeId, ...values } = actionValues;

        const updatedValues = omit(
          {
            ...values,
            inputTags: values.inputTags.map((tagOption: any) => ({
              name: tagOption.label,
            })),
            spendTime: values?.spendTime
              ? Math.round(
                  convertToMs(
                    values.spendTime,
                    action?.actionTimeFormat || DEFAULT_ACTION_TIME_FORMAT,
                  ) / 1000,
                )
              : null,
            estimatedTime: values?.estimatedTime
              ? Math.round(
                  convertToMs(
                    values?.estimatedTime,
                    action?.actionTimeFormat || DEFAULT_ACTION_TIME_FORMAT,
                  ) / 1000,
                )
              : null,
            actionTimeFormat: values?.actionTimeFormat ? values.actionTimeFormat : null,
            ...(values.actualStartDate
              ? {
                  actualStartDate: formatISO(startOfDay(parseISO(values.actualStartDate))),
                }
              : {
                  actualStartDate: null,
                }),
            ...(values.actualEndDate
              ? {
                  actualEndDate: formatISO(startOfDay(parseISO(values.actualEndDate))),
                }
              : {
                  actualEndDate: null,
                }),
            workspaceId: actionValues.workspaceId,
            ...(actionValues.outcomeId !== action.outcome?.id && {
              outcomeId: actionValues.outcomeId,
            }),
          },
          'outcome',
          'durationType',
          'isArchived',
          'tags',
          '__typename',
        );
        return onSubmit(updatedValues);
      },
      [onSubmit, action],
    );

    const handleDurationChange = useCallback(
      async (duration: string) => {
        if (action) {
          await updateAction({
            variables: {
              actionValues: {
                id: action.id,
                actionTimeFormat: duration as ActionTimeFormat,
              },
              workspaceId: action.workspaceId,
            },
          });
        }
      },
      [action],
    );

    const handleFetchOutcomes = useCallback(
      async (workspaceId?: number) => {
        return fetchOutcomes({
          variables: {
            workspaceId: workspaceId || action?.workspaceId || actionEditedEntity?.workspaceId,
          },
        });
      },
      [action?.workspaceId, actionEditedEntity],
    );

    const FormWrapper = useCallback((props: PropsWithChildren<ReactNode>): JSX.Element => {
      return <StyledFormDiv>{props.children}</StyledFormDiv>;
    }, []);

    useCheckSharedEntityMoved(action, CUSTOM_ENTITY_TYPES.ACTION, action?.workspaceId, isLoading);

    useEffect(() => {
      if (mutationLoading) {
        setIsLoading(true);
      } else {
        setTimeoutPromise(100).then(() => {
          setIsLoading(false);
        });
      }
    }, [mutationLoading]);

    useEffect(() => {
      if (action) {
        const workspace = workspaces.find((workspace) => workspace.id === action?.workspaceId);
        if (!errorMessage) {
          if (!workspace) {
            if (prevWorkspace.current?.id) {
              getWorkspace({
                variables: {
                  workspaceId: prevWorkspace.current?.id,
                },
              }).then((res) => {
                if (!res.data?.fetchUserWorkspace.id) {
                  setErrorMessage(CUSTOM_ERROR_MESSAGE.NO_RELATION);
                  setErrorWorkspaces([
                    {
                      id: prevWorkspace.current?.id!,
                      permission: Permission.Viewer,
                      type: 'Workspace',
                      name: prevWorkspace.current?.name,
                    },
                  ]);
                }
              });
            }
          } else {
            prevWorkspace.current = workspace;
          }
        }
      }
    }, [workspaces, action]);

    useEffect(() => {
      if (action?.workspaceId && !isLoading) {
        setCurrentWorkspaceId(action.workspaceId);

        if (actionEditedEntity && action.workspaceId !== actionEditedEntity?.workspaceId) {
          showEditActionModal({
            ...action,
            workspaceId: action.workspaceId,
          });
        }
      }
    }, [action?.workspaceId]);

    useEffect(() => {
      if (!!subscriptionData?.subscribeToChanges?.action) {
        if (
          workspaces.some((w) => w.id === subscriptionData?.subscribeToChanges?.action.workspaceId)
        ) {
          setAction(subscriptionData?.subscribeToChanges?.action);
        } else {
          setErrorMessage(CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED);
          setEntityType(CUSTOM_ENTITY_TYPES.ACTION);
        }
      } else if (!!subscriptionData?.subscribeToChanges) {
        if (workspaces.some((w) => w.id === subscriptionData?.subscribeToChanges?.workspaceId)) {
          setAction(subscriptionData?.subscribeToChanges);
        } else {
          setErrorMessage(CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED);
          setEntityType(CUSTOM_ENTITY_TYPES.ACTION);
        }
      }
    }, [subscriptionData?.subscribeToChanges]);

    useEffect(() => {
      let editedAction = null;
      const boardAction = actions.find((a) => a.id === action?.id);

      if (actionEditedEntity) {
        if (!boardAction && !actionData && !action) {
          getEntity({ ...actionEditedEntity, workspaceId: currentWorkspaceId });
        } else {
          if (fixedAction) {
            editedAction = fixedAction as Action;
          } else if (!!boardAction) {
            editedAction = boardAction as Action;
          } else if (subscriptionData?.subscribeToChanges?.action) {
            editedAction = subscriptionData?.subscribeToChanges?.action as Action;
          } else if (subscriptionData?.subscribeToChanges) {
            editedAction = subscriptionData?.subscribeToChanges as Action;
          } else if (action) {
            editedAction = action;
          } else if (!!actionData) {
            editedAction = actionData as Action;
          } else if (!isLoading) {
            // getEntity({
            //   ...actionEditedEntity,
            //   workspaceId: action?.workspaceId || currentWorkspaceId,
            // });
          }
        }
      } else {
        resetActionData();
      }
      setAction(editedAction);
    }, [actionData, actions]);

    useEffect(() => {
      if (actionEditedEntity) {
        if (action && !loading && !isLoading && !formSubmitting) {
          getEntity({
            ...action,
            workspaceId: currentWorkspaceId || action.workspaceId,
          });
        }
      } else {
        resetActionData();
      }
    }, [actions.length]);

    useEffect(() => {
      if (action) {
        prevActionStatus.current = action.status;

        if (actionStatusBeforeChange.current === null) {
          actionStatusBeforeChange.current = action.status;
        }
      }
    }, [action]);

    useEffect(() => {
      if (
        workspacesResponse?.fetchUserWorkspacesWithInvitedCount &&
        workspacesResponse.fetchUserWorkspacesWithInvitedCount.some(
          (w) => w.id === action?.workspaceId,
        )
      ) {
        handleFetchOutcomes();
      } else {
        action && getEntity(action);
      }
    }, [action?.workspaceId]);

    useEffect(() => {
      if (action) {
        handleFetchOutcomes();
      }
    }, [action?.outcome?.id]);

    useEffect(() => {
      if (actionData && actionData.workspaceId !== action?.workspaceId) {
        setFixedAction(actionData as Action);
        setAction(actionData as Action);
      }

      if (actionData && fixedAction && actionData.workspaceId === fixedAction.workspaceId) {
        setFixedAction(null);
      }
    }, [actionData]);

    return (
      <>
        {action && workspace ? (
          <Formik
            enableReinitialize
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              return onSave(values);
            }}
          >
            {({
              values,
              setFieldValue,
              errors,
              isSubmitting,
              submitForm,
              validateForm,
              isValid,
              touched,
            }) => {
              const isArchived = values.isArchived;
              const handleSubmitForm = async (): Promise<any> => {
                if (isValid) {
                  setTimeout(() => {
                    return submitForm();
                  }, 200);
                }
              };

              useEffect(() => {
                if (!formTouched && !isEmpty(touched)) {
                  setFormTouched(true);
                }
              }, [touched]);

              useEffect(() => {
                setFormSubmitting(isSubmitting);
              }, [isSubmitting]);

              useEffect(() => {
                const outcomeId = action?.outcome?.id;
                if (getOutcomes().find((outcome) => outcome.id === outcomeId)) {
                  setFieldValue('outcomeId', outcomeId);
                } else if (viewerPermission || editorPermission) {
                  setFieldValue('outcomeId', null);
                }
              }, [action, fetchUserWorkspaceOutcomesResponse?.fetchUserWorkspaceOutcomes]);
              return (
                <FormWrapper>
                  <StyledMainContent>
                    <StyledTitle level={3}>
                      {viewerPermission ? `View Action` : `Edit Action`}
                    </StyledTitle>
                    <StyledStatusRow>
                      <StyledStatusRowInner>
                        <ActionStatusComponent
                          disabled={isArchived || viewerPermission || isLoading}
                          onChange={(value: ActionStatus) => {
                            adjustActualDateAccordingStatus({
                              status: value,
                              actualStartDate: values?.startDate?.toString(),
                              actualEndDate: values?.endDate?.toString(),
                              setActualStartDate: (value) =>
                                setFieldValue('actualStartDate', value),
                              setActualEndDate: (value) => setFieldValue('actualEndDate', value),
                              prevStatus: prevActionStatus.current || undefined,
                              prevActualStartDate: values?.actualStartDate?.toString(),
                            });
                            validateForm(values);
                            prevActionStatus.current = value;
                            handleSubmitForm();
                          }}
                        />

                        <StyledArchivedCheckbox
                          disabled={viewerPermission || isLoading}
                          name="isArchived"
                          onChange={(e) => {
                            setArchive(e.target.checked);
                          }}
                        >
                          Archived
                        </StyledArchivedCheckbox>
                      </StyledStatusRowInner>
                      <ActionPriorityComponent
                        action={action}
                        disabled={isArchived || viewerPermission || isLoading}
                      />
                    </StyledStatusRow>

                    <StyledLabel>Name</StyledLabel>
                    <StyledInputName
                      key={`${isArchived}${viewerPermission}${isLoading}name`}
                      disabled={isArchived || viewerPermission || isLoading}
                      name="name"
                      maxLength={256}
                      value={values.name ?? undefined}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('name', e?.target?.value);
                      }}
                      onBlur={handleSubmitForm}
                      autoComplete="off"
                    />
                    <StyledLabel>Description</StyledLabel>
                    <StyledDebouncedTextAreaInput
                      key={`${isArchived}${viewerPermission}${isLoading}description`}
                      disabled={isArchived || viewerPermission || isLoading}
                      name="description"
                      maxLength={1000}
                      value={values.description!}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setFieldValue('description', e?.target?.value);
                      }}
                      onBlur={handleSubmitForm}
                    />
                    <Divider className="mainDivider" />
                  </StyledMainContent>

                  <StyledSideContent>
                    <StyledLabel>Space</StyledLabel>
                    <StyledSelect
                      disabled={isArchived || viewerPermission || isLoading}
                      name="workspaceId"
                      suffixIcon={<ArrowDownIcon />}
                      placeholder="Select Space"
                      options={workspaceOptions}
                      value={values.workspaceId}
                      onSelect={async (id: any) => {
                        setFieldValue('workspaceId', id);
                        setCurrentWorkspaceId(id);
                        if (filterInput.some((input) => input.workspaceId === id)) {
                          setUpdateFilterInputId({
                            type: 'Action',
                            workspaceId: id,
                            id: action?.id,
                          });
                        }
                        if (id === action.workspaceId) {
                          setFieldValue('outcomeId', action?.outcome?.id);
                        } else {
                          setFieldValue('outcomeId', null);
                        }
                        const res = await handleSubmitForm();
                        if (
                          actionEditedEntity &&
                          !errorMessage &&
                          res?.data?.moveUserWorkspaceAction?.action
                        ) {
                          showEditActionModal(res?.data?.moveUserWorkspaceAction?.action);
                        }
                      }}
                    />
                    <StyledLabel>Link to Outcome</StyledLabel>
                    <Select
                      disabled={isArchived || viewerPermission || isLoading}
                      name="outcomeId"
                      suffixIcon={<ArrowDownIcon />}
                      value={values.outcomeId}
                      labelInValue={!getOutcomes()?.length}
                      onSelect={async () => {
                        await handleSubmitForm();
                        // await handleFetchOutcomes();
                      }}
                      notFoundContent={Messages.NO_RESULTS}
                      style={{ marginBottom: '0' }}
                    >
                      {getOutcomes().map((outcome) => (
                        <Option value={outcome.id} key={outcome.id}>
                          {outcome.name}
                        </Option>
                      ))}
                    </Select>
                    <Divider />

                    <StyledDataRowComponentWrapper>
                      <StyledTitle level={4}>Planned date</StyledTitle>
                      <DataRowComponent
                        startDate={{
                          name: 'startDate',
                          label: 'Start',
                          value: values?.startDate?.toString(),
                          disabled: isArchived || viewerPermission || isLoading,
                          onChange: handleSubmitForm,
                        }}
                        endDate={{
                          name: 'endDate',
                          label: 'End',
                          value: values?.endDate?.toString(),
                          disabled: isArchived || viewerPermission || isLoading,
                          onChange: handleSubmitForm,
                        }}
                        setFieldValue={setFieldValue}
                      />
                    </StyledDataRowComponentWrapper>

                    <StyledTitle level={4}>Actual date</StyledTitle>
                    <DataRowComponent
                      startDate={{
                        name: 'actualStartDate',
                        label: 'Start',
                        value: values?.actualStartDate?.toString(),
                        disabled:
                          isArchived ||
                          (values.status === ActionStatus.Todo && !values?.actualStartDate) ||
                          (values.status !== ActionStatus.Done &&
                            values.status !== ActionStatus.Doing &&
                            values.status !== ActionStatus.Todo) ||
                          viewerPermission ||
                          isLoading,
                        onChange: handleSubmitForm,
                        hideRemoveDateButton:
                          values.status === ActionStatus.Done ||
                          values.status === ActionStatus.Doing,
                      }}
                      endDate={{
                        name: 'actualEndDate',
                        label: 'End',
                        value: values?.actualEndDate?.toString(),
                        disabled:
                          isArchived ||
                          values.status !== ActionStatus.Done ||
                          viewerPermission ||
                          isLoading,
                        onChange: handleSubmitForm,
                        hideRemoveDateButton: values.status === ActionStatus.Done,
                      }}
                      setFieldValue={setFieldValue}
                    />

                    <Divider />
                    <StyledDataRowComponentWrapper>
                      <StyledTitle level={4}>Planned / Actual effort</StyledTitle>
                      <DurationRowComponent
                        disabled={isArchived || viewerPermission || isLoading}
                        disabledSelect={
                          isArchived ||
                          (!isFinite(Number(values.estimatedTime)) &&
                            !isFinite(Number(values.actualPoints)))
                        }
                        setFieldValue={setFieldValue}
                        actionTimeFormat={values.actionTimeFormat}
                        error={!!errors.actionTimeFormat}
                        actualValue={values.spendTime}
                        estimatedValue={values.estimatedTime}
                        onChangePoints={() => {}}
                        onChangeType={handleDurationChange}
                        onBlurPoints={handleSubmitForm}
                      />
                    </StyledDataRowComponentWrapper>

                    <AddTags
                      tags={action.tags || []}
                      workspaceId={action?.workspaceId as number}
                      isDisabled={isArchived || viewerPermission || isLoading || false}
                      onChange={submitForm}
                      currentValue={values.inputTags as TagOption[]}
                    />
                  </StyledSideContent>
                </FormWrapper>
              );
            }}
          </Formik>
        ) : (
          <StyledLoader />
        )}
      </>
    );
  },
);

const EditAction = React.memo(() => {
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { actionEditedEntity }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
  const { showEditActionModal } = boardEditedEntityMutation;
  const { actions, refetch } = useUserWorkspaceContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: completeData } = useQuery(COMPLETE_STATE);
  const { outcomeToComplete, outcomeToIncomplete }: CompleteValue = completeData?.complete;

  const handleClose = useCallback(() => {
    !isLoading && showEditActionModal(null);
  }, [isLoading]);

  return (
    <MouseOverCursorWrapper
      targetClassName="ant-drawer-mask"
      cursorImage={closeIcon}
      isOpen={!!actionEditedEntity?.id && !outcomeToComplete && !outcomeToIncomplete}
    >
      <StyledDrawer
        destroyOnClose
        maskClosable
        width=""
        placement="right"
        closable={false}
        onClose={handleClose}
        visible={!!actionEditedEntity?.id}
        zIndex={2000}
      >
        <StyledCloseButton ghost shape="circle" icon={<CloseIcon />} onClick={handleClose} />
        <StyledContainer>
          {actionEditedEntity?.id && (
            <EditActionContent
              actions={actions}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              onClose={handleClose}
            />
          )}
        </StyledContainer>
      </StyledDrawer>
    </MouseOverCursorWrapper>
  );
});

export { EditAction };
