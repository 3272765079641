import { PLANS_TYPES, stripeInitialValue, stripeVar } from './stripeFields';
import { BmSubscriptionPlans, StripeSubscriptionIntervals } from '../../../graphql/types';

const resetStripe = () => {
  const value = {
    ...stripeInitialValue,
  };
  stripeVar(value);
};

const setPlansVariant = (payload: PLANS_TYPES) => {
  const value = {
    ...stripeVar(),
    plansVariant: payload,
  };
  stripeVar(value);
};

const setShowStripeModal = (payload: boolean | 'hide') => {
  const value = {
    ...stripeVar(),
    showStripeModal: payload,
  };
  stripeVar(value);
};

const setIsCardDataFulfilled = (payload: boolean) => {
  const value = {
    ...stripeVar(),
    isCardDataFulfilled: payload,
  };
  stripeVar(value);
};

const setShowStripeType = (payload: BmSubscriptionPlans | null) => {
  const value = {
    ...stripeVar(),
    showStripeType: payload,
  };
  stripeVar(value);
};

const setShowPurchaseInfoModal = (payload: null | 'success' | 'fail') => {
  const value = {
    ...stripeVar(),
    showPurchaseInfoModal: payload,
  };
  stripeVar(value);
};

const setErrorMessage = (payload: string) => {
  const value = {
    ...stripeVar(),
    errorMessage: payload,
  };
  stripeVar(value);
};

const setBillingVariant = (payload: StripeSubscriptionIntervals) => {
  const value = {
    ...stripeVar(),
    billingVariant: payload,
  };
  stripeVar(value);
};

const setIsSubscriptionExpired = (payload: boolean) => {
  const value = {
    ...stripeVar(),
    isSubscriptionExpired: payload,
  };
  stripeVar(value);
};

const setIsSubscriptionLoading = (payload: boolean) => {
  const value = {
    ...stripeVar(),
    isSubscriptionLoading: payload,
  };
  stripeVar(value);
};

const setIsSubscriptionCanceled = (payload: boolean) => {
  const value = {
    ...stripeVar(),
    isSubscriptionCanceled: payload,
  };
  stripeVar(value);
};

const setIsSubscriptionExpiredTrial = (payload: boolean | null) => {
  const value = {
    ...stripeVar(),
    isSubscriptionExpiredTrial: payload,
  };
  stripeVar(value);
};

const setIsForSubscriptionChange = (payload: string | null) => {
  const value = {
    ...stripeVar(),
    isForSubscriptionChange: payload,
  };
  stripeVar(value);
};

const setExpiredSubscriptionText = (payload: string) => {
  const value = {
    ...stripeVar(),
    expiredSubscriptionText: payload,
  };
  stripeVar(value);
};

export {
  setPlansVariant,
  setBillingVariant,
  setShowStripeModal,
  setIsCardDataFulfilled,
  setShowStripeType,
  setShowPurchaseInfoModal,
  setErrorMessage,
  setIsSubscriptionExpired,
  setIsSubscriptionLoading,
  setIsSubscriptionExpiredTrial,
  setIsForSubscriptionChange,
  setExpiredSubscriptionText,
  setIsSubscriptionCanceled,
  resetStripe,
};

