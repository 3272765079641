import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ClockIcon } from 'core/svg/clock.svg';
import { ReactComponent as ArrowIcon } from 'core/svg/arrow-right-2.svg';
import { formatDate, getNowDate, isDateBeforeOrEqual } from '../../../../../../core/utils/date';
import { DatesProps } from './types';
import { parseDuration } from '../../../../../../core/utils/duration';
import { ActionStatus } from '../../../../../../core/types/action';
import { DEFAULT_ACTION_TIME_FORMAT } from '../../EditAction/utils';

const StyledDatesContainer = styled.div<{ isFocusTimer?: boolean }>`
  padding-top: 8px;
  display: flex;
  font-size: 0.5rem;
  color: var(--color-dark-blue);

  ${({ isFocusTimer }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
      `;
    }
  }}
`;

const StyledDatesCol = styled.div``;
const StyledDash = styled.span`
  margin: 0 2px;
`;

const StyledDatesRow = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  align-items: center;
  line-height: 11px;
  height: 16px;

  ${(props) =>
    props.paddingBottom &&
    css`
      padding-bottom: 3px;
    `}
`;

const StyledClockIcon = styled(ClockIcon)`
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px 2px;
`;

const StyledArrowIcon = styled(ArrowIcon)<{ isFocusTimer?: boolean }>`
  margin: 3px 9px 5px;
  ${({ isFocusTimer }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
      `;
    } else {
      return css`
        color: #202635;
      `;
    }
  }}
`;

const StyledDate = styled.span<{ highlighted?: boolean }>`
  ${(props) =>
    props.highlighted &&
    css`
      display: inline-block;
      padding: 2px;
      background: rgba(186, 0, 0, 0.1);
      border: 1px solid rgba(186, 0, 0, 0.2);
      border-radius: 2px;
      line-height: 1;
    `}
`;

const StyledDiff = styled.span<{ negative?: boolean }>`
  display: inline-block;
  margin: 0 4px;
  padding: 2px;
  background: rgba(60, 186, 0, 0.3);
  border: 1px solid rgba(60, 186, 0, 0.3);
  border-radius: 2px;
  line-height: 1;

  ${(props) =>
    props.negative &&
    css`
      background: rgba(186, 0, 0, 0.1);
      border: 1px solid rgba(186, 0, 0, 0.2);
    `}
`;

const Dates = React.memo(({ action, className, isFocusTimer }: DatesProps) => {
  const {
    startDate,
    endDate,
    actualStartDate,
    actualEndDate,
    actionTimeFormat,
    spendTime,
    estimatedTime,
    status,
  } = action;

  const timeFormat = actionTimeFormat ? actionTimeFormat : DEFAULT_ACTION_TIME_FORMAT;
  const parsedPoints =
    estimatedTime && timeFormat ? parseDuration(estimatedTime, timeFormat) : undefined;
  const parsedActualPoints =
    spendTime && timeFormat ? parseDuration(spendTime, timeFormat) : undefined;
  const hasPlannedDates = parsedPoints || startDate || endDate;
  const hasActualDates = parsedActualPoints || actualStartDate || actualEndDate;
  const nowDate = getNowDate();
  const isHighlightedPlannedStartDate =
    status !== ActionStatus.Done ? isDateBeforeOrEqual(startDate, nowDate) : false;
  const isHighlightedPlannedEndDate =
    status !== ActionStatus.Done ? isDateBeforeOrEqual(endDate, nowDate) : false;
  const diffEffort =
    parsedPoints && parsedActualPoints ? parsedActualPoints.points - parsedPoints.points : null;

  if (!hasPlannedDates && !hasActualDates) {
    return null;
  }

  return (
    <StyledDatesContainer className={className} isFocusTimer={isFocusTimer}>
      {hasPlannedDates && (
        <StyledDatesCol>
          <StyledDatesRow paddingBottom>
            Planned
            <StyledClockIcon />
            {parsedPoints && (
              <>
                {parsedPoints.points} {parsedPoints.label && parsedPoints.label.toLowerCase()}
              </>
            )}
          </StyledDatesRow>
          <StyledDatesRow>
            {startDate && (
              <>
                {!endDate && 'Start: '}
                <StyledDate highlighted={isHighlightedPlannedStartDate}>
                  {formatDate(startDate)}
                </StyledDate>
              </>
            )}
            {startDate && endDate && <StyledDash> - </StyledDash>}
            {endDate && (
              <>
                {!startDate && 'End: '}
                <StyledDate highlighted={isHighlightedPlannedEndDate}>
                  {formatDate(endDate)}
                </StyledDate>
              </>
            )}
          </StyledDatesRow>
        </StyledDatesCol>
      )}

      {hasPlannedDates && hasActualDates && <StyledArrowIcon isFocusTimer={isFocusTimer} />}

      {hasActualDates && (
        <StyledDatesCol>
          <StyledDatesRow paddingBottom>
            Actual
            <StyledClockIcon />
            {parsedActualPoints && (
              <>
                {parsedActualPoints.points}{' '}
                {parsedActualPoints.label && parsedActualPoints.label.toLowerCase()}
              </>
            )}
            {!!diffEffort && (
              <StyledDiff negative={diffEffort > 0}>
                {diffEffort > 0 && '+'}
                {Number.isInteger(diffEffort) ? diffEffort : diffEffort.toFixed(2)}
              </StyledDiff>
            )}
          </StyledDatesRow>
          <StyledDatesRow>
            {actualStartDate && (
              <>
                {!actualEndDate && 'Start: '}
                <StyledDate>{formatDate(actualStartDate)}</StyledDate>
              </>
            )}
            {actualStartDate && actualEndDate && <StyledDash> - </StyledDash>}
            {actualEndDate && (
              <>
                {!actualStartDate && 'End: '}
                <StyledDate>{formatDate(actualEndDate)}</StyledDate>
              </>
            )}
          </StyledDatesRow>
        </StyledDatesCol>
      )}
    </StyledDatesContainer>
  );
});

export { Dates };

