import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { toast, ToastContent } from 'react-toastify';
import { useQuery } from '@apollo/client';
import { Workspace } from 'core/types/workspace';
import { ReactComponent as SettingsIcon } from 'core/svg/settings.svg';
import useCreateUserWorkspace from 'features/workspaces/interactions/useCreateUserWorkspace';
import useUpdateUserWorkspace from 'features/workspaces/interactions/useUpdateUserWorkspace';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from 'features/common/graphql/queries';
import { EditWorkspaceModal } from '../EditWorkspaceModal';
import { NOT_ASSIGNED_WORKSPACE_NAME } from 'core/constants/others';
import { sendEvent } from 'core/integrations/sentry/events';
import { useMediaQuery } from 'react-responsive';
import { theme } from '../../../../core/styles/styled-components';
import { WorkspaceFilterModalProps } from './types';
import bodyScrollLock from '../../../../core/utils/bodyScrollLock';
import ManageWorkSpacesModal from '../ManageWorkSpacesModal/ManageWorkSpacesModal';
import { FETCH_USER_INVITES } from '../../graphql/queries';
import { useHistory } from 'react-router-dom';
import { ACTION_PATH } from '../../../../core/constants/routePaths';
import usePollDataOnActivity from '../../../../hooks/usePollDataOnActivity';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import { userSettingsMutation } from '../../../../apollo/stateFields/userSettings';
import { manageWorkspacesMutation } from '../../../../apollo/stateFields/manageWorkspaces';
import {
  MANAGE_WORKSPACES_STATE,
  ManageWorkspacesValue,
} from '../../../../apollo/stateFields/manageWorkspaces/manageWorkspacesField';
import {
  TUTORIAL_STATE,
  TutorialValue,
} from '../../../../apollo/stateFields/tutorial/tutorialField';

const StyledWrapper = styled.div`
  position: relative;
`;

const SharedCounter = styled.div`
  position: absolute;
  top: -5px;
  right: 5px;

  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(0deg, rgba(60, 186, 0, 0.7), rgba(60, 186, 0, 0.7)), #ffffff;
  color: var(--color-white);
`;

const StyledOpenModalButton = styled(({ settingsModalVisible, isHighlighted, ...rest }) => (
  <Button {...rest} />
))<{
  settingsModalVisible: boolean;
  isHighlighted: boolean;
}>`
  margin-right: 12px;
  width: 86px;
  height: 30px;
  display: flex;
  padding: 0 8px 0 4px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-radius: 4px;
  background-color: transparent;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  color: white;

  svg {
    fill: white;
  }

  &:hover {
    border-color: transparent;
    background-color: var(--color-dark-blue);
    color: white;
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      z-index: 2000;
      border: 1px dashed #ffffff;
      border-radius: 4px;
      background-color: var(--color-deep-gray);
    `}
`;

const WorkspaceFilterModal = React.memo(({ clickOutside }: WorkspaceFilterModalProps) => {
  const history = useHistory();
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { currentStep: currentTutorialStep }: TutorialValue = tutorialData?.tutorial;
  const { data: manageWorkspacesData } = useQuery(MANAGE_WORKSPACES_STATE);
  const { isManageWorkspacesModalVisible: settingsModalVisible }: ManageWorkspacesValue =
    manageWorkspacesData?.manageWorkspaces;
  const { toggleManageWorkspacesModal } = manageWorkspacesMutation;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { unassignedWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { setUnassignedWorkspaceId } = userSettingsMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [buttonCoordinates, setButtonCoordinates] = useState<[number, number]>([0, 0]);
  const [initialValues, setInitialValues] = useState<Partial<Workspace>>({
    name: '',
    description: '',
  });
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const [sendInputEvent, setSendInputEvent] = useState<boolean>(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [createUserWorkspace] = useCreateUserWorkspace();
  const [updateUserWorkspace] = useUpdateUserWorkspace();

  const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const { data: userInvites, refetch, startPolling, stopPolling } = useQuery(FETCH_USER_INVITES);

  usePollDataOnActivity(startPolling, stopPolling, refetch, 120000);

  const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];

  useEffect(() => {
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    if (buttonRect) {
      setButtonCoordinates([buttonRect.left, buttonRect.top]);
    }
  }, []);

  useEffect(() => {
    if (unassignedWorkspaceId === 0 || !workspaces.some((w) => w.id === unassignedWorkspaceId)) {
      const unassignedWorkspace = workspaces.find((w) => w.name === NOT_ASSIGNED_WORKSPACE_NAME);
      if (unassignedWorkspace) {
        setUnassignedWorkspaceId(unassignedWorkspace.id);
      }
    }
  }, [workspaces]);

  const getInitialValues = () => {
    const value = initialValues.name === undefined ? '' : undefined;
    return {
      name: value,
      description: value,
    };
  };

  const handleSubmit = async (values: any) => {
    try {
      const { __typename, usersCount, isNewShared, ...newValues } = values;

      if (newValues.id) {
        await updateUserWorkspace({ variables: { workspace: newValues } });
        sendEvent('workspace-update', 'Workspace update', {
          Name: newValues.name,
        });
        return;
      }

      await createUserWorkspace({ variables: { workspace: newValues } });
      sendEvent('workspace-create', 'Workspace create', {
        Name: newValues.name,
      });
    } catch (error) {
      console.error(error);
      toast(error as ToastContent);
    } finally {
      setEditModalVisible(false);
      setInitialValues(getInitialValues());
    }
  };

  const handleCancel = () => {
    setEditModalVisible(false);
    setInitialValues(getInitialValues());
  };

  useEffect(() => {
    if (sendInputEvent) {
      setSendInputEvent(false);
      sendEvent('filter-update', 'Update filter input', { filterInput });
    }
  }, [filterInput, sendInputEvent]);

  useEffect(() => {
    if (!isDesktop) {
      if (settingsModalVisible) {
        bodyScrollLock.enable();
      } else {
        bodyScrollLock.disable();
      }
    }
  }, [settingsModalVisible]);

  const handleToggleModal = useCallback(() => {
    if (history.location.pathname !== ACTION_PATH) {
      history.push(ACTION_PATH);
    }
    toggleManageWorkspacesModal();
  }, [toggleManageWorkspacesModal]);

  const filterModalEl = (
    <ManageWorkSpacesModal
      visible={settingsModalVisible}
      onClose={handleToggleModal}
      clickOutside={clickOutside}
      workspaces={workspaces}
      invites={userInvites?.fetchUserInvites}
      filterInput={filterInput}
      style={{
        left: buttonCoordinates[0],
        top: buttonCoordinates[1],
      }}
    />
  );

  const editWorkspaceModalEl = (
    <EditWorkspaceModal
      visible={editModalVisible}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      style={{
        left: buttonCoordinates[0],
        top: buttonCoordinates[1],
      }}
      initialValues={initialValues}
    />
  );

  const controlsEl = (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <StyledOpenModalButton
        ref={buttonRef}
        icon={<SettingsIcon />}
        onClick={handleToggleModal}
        settingsModalVisible={settingsModalVisible}
        isHighlighted={currentTutorialStep === 2}
      >
        Spaces
      </StyledOpenModalButton>
    </div>
  );

  return (
    <StyledWrapper>
      {controlsEl}
      {filterModalEl}
      {editWorkspaceModalEl}
      {userInvites?.fetchUserInvites?.length && (
        <SharedCounter>
          {userInvites.fetchUserInvites.length <= 9 ? userInvites.fetchUserInvites.length : `9+`}
        </SharedCounter>
      )}
    </StyledWrapper>
  );
});

export { WorkspaceFilterModal };
