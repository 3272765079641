import { AcceptInviteResult } from '__generated__/graphql';
import { SelectEventHandler } from 'rc-menu/lib/interface';

export enum UserMenuRoles {
  SECONDARY_OWNER = 'SECONDARY_OWNER',
  PRIMARY_OWNER = 'PRIMARY_OWNER',
  EDITOR = 'EDITOR',
  CONTRIBUTOR = 'CONTRIBUTOR',
  MOVER = 'MOVER',
  VIEWER = 'VIEWER',
  REMOVE = 'REMOVE',
  LEAVE = 'LEAVE',
}

export const CONTEXT_MENU_OPTIONS_BY_ROLES = {
  FOR_WORKSPACE: [UserMenuRoles.EDITOR, UserMenuRoles.VIEWER],
  FOR_USER: [UserMenuRoles.VIEWER],
  FOR_EDITOR: [UserMenuRoles.EDITOR, UserMenuRoles.REMOVE],
  FOR_PRIMARY_OWNER: [UserMenuRoles.PRIMARY_OWNER, UserMenuRoles.REMOVE],
};

export interface SharedRoleSelectorProps {
  onSelect: SelectEventHandler;
  role: string;
  className?: string;
  isText?: boolean;
  disabled?: boolean;
  roles: string[];
}

export interface SharedUserRoleItemProps {
  user: AcceptInviteResult;
  className?: string;
  roles: UserMenuRoles[];
  handleSpaceLeave: () => void;
}

export interface SharedPeopleSelectorProps {
  addedUsers?: AcceptInviteResult[];
  initialValue?: string[];
  validate: (value: boolean) => void;
  setIsSearchValid: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
  onEmailSelect: (value: string[]) => void;
}

