import { gql } from '__generated__/gql';
import 'features/actions/graphql/fragments';
import 'features/common/graphql/fragments';
import 'features/outcomes/graphql/fragments';
import './fragments';

const FILTER_USER_WORKSPACES_STRING = /* GraphQL */ `
  query FilterUserWorkspaces($filters: [FilterUserWorkspacesInput!]!) {
    filterUserWorkspaces(filters: $filters) {
      id
      name
      permission
      isNewShared
      actions {
        ...actionFields
      }
      outcomes {
        ...outcomeFields
      }
      tags {
        ...tagFields
      }
    }
  }
`;

export const FILTER_USER_WORKSPACES = gql(FILTER_USER_WORKSPACES_STRING);

const FETCH_USER_INVITES_STRING = /* GraphQL */ `
  query FetchUserInvites {
    fetchUserInvites {
      ...userInviteFields
    }
  }
`;

export const FETCH_USER_INVITES = gql(FETCH_USER_INVITES_STRING);

const FETCH_WORKSPACE_INVITES_BY_ID_STRING = /* GraphQL */ `
  query FetchWorkspaceInvitesById($workspaceId: Int!) {
    fetchWorkspaceInvitesById(workspaceId: $workspaceId) {
      count
      users {
        ...workspaceUserInviteFields
      }
    }
  }
`;

export const FETCH_WORKSPACE_INVITES_BY_ID = gql(FETCH_WORKSPACE_INVITES_BY_ID_STRING);

const FETCH_WORKSPACE_INVITES_BY_IDS_STRING = /* GraphQL */ `
  query FetchWorkspaceInvitesByIds($workspaceIds: [Int!]) {
    fetchWorkspaceInvitesByIds(workspaceIds: $workspaceIds) {
      count
      users {
        ...workspaceUserInviteFields
      }
      workspaceId
    }
  }
`;

export const FETCH_WORKSPACE_INVITES_BY_IDS = gql(FETCH_WORKSPACE_INVITES_BY_IDS_STRING);

const SEARCH_USER_INVITES_STRING = /* GraphQL */ `
  query SearchUserInvites($query: String! = "") {
    searchUserInvites(query: $query) {
      ...userFields
    }
  }
`;

export const SEARCH_USER_INVITES = gql(SEARCH_USER_INVITES_STRING);
