import { FormEvent } from 'react';
import {
  StyledButtonContainer,
  StyledCancelButton,
  StyledForm,
  StyledModal,
  StyledSubmitButton,
  StyledTitle,
} from '../StyleModalComponents';

interface ICancelModalProps {
  onConfirm: (e?: FormEvent) => void;
  onCancel: () => void;
  visible: boolean;
}

const CancelModal = ({ onConfirm, visible, onCancel }: ICancelModalProps) => {
  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();
    onConfirm(e);
  };
  return (
    <StyledModal
      destroyOnClose
      getContainer={() => document.body}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      mask={false}
      closable={true}
    >
      <StyledForm onSubmit={handleConfirm}>
        <StyledTitle>Are you sure you want to discard all changes?</StyledTitle>
        <StyledButtonContainer>
          <StyledSubmitButton>Discard Changes</StyledSubmitButton>
          <StyledCancelButton onClick={onCancel}>Go back</StyledCancelButton>
        </StyledButtonContainer>
      </StyledForm>
    </StyledModal>
  );
};

export default CancelModal;

