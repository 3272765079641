import styled from 'styled-components';
import { EXPECT_LIST_ITEMS } from '../constants';
import { ExpectListItemProps } from '../types';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../core/styles/styled-components/theme';
import { ReactComponent as GraphImage } from '../../img/graph.svg';

const StyledContentWrapper = styled.div`
  position: relative;
  @media ${theme.device.standardDesktop.max} {
    padding-top: 250px;
  }
  @media ${theme.device.mobile.max} {
    padding-top: 0;
  }
`;
const StyledGraphImage = styled(GraphImage)`
  position: absolute;
  right: 0;
  top: -130px;
  width: 500px;
  height: 325px;
`;
const StyledMobileGraphImage = styled(GraphImage)`
  width: 100%;
  height: auto;
  margin-top: -40px;
  margin-bottom: 16px;
`;
const StyledList = styled.ul`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media ${theme.device.tablet.min} {
    justify-content: space-between;
  }
`;
const StyledListItem = styled.li`
  width: 100%;
  height: auto;
  padding: 10px 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;

  background: linear-gradient(
    180deg,
    rgba(242, 242, 244, 0.5) 0%,
    rgba(255, 255, 255, 0.5) 31.77%,
    rgba(236, 247, 249, 0.5) 100%
  );
  border: 1px solid #e7ebec;
  box-shadow: inset 0px 10px 15px rgba(58, 239, 250, 0.07);
  border-radius: 8px;

  @media ${theme.device.tablet.min} {
    width: 94px;
    height: 88px;
    flex-direction: column;
    margin-bottom: 24px;
  }
`;
const StyledListItemWrapper = styled.div`
  width: auto;
  padding: 0 2px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${theme.device.hugeDesktop.min} {
    width: 15.5%;
  }

  @media ${theme.device.largeDesktop.max} {
    width: 25%;
  }

  @media ${theme.device.standardDesktop.max} {
    width: 15.5%;
  }

  @media ${theme.device.tablet.max} {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @media ${theme.device.mobile.max} {
    width: auto;
  }
`;
const StyledTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--color-dark-blue);

  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  @media ${theme.device.tablet.min} {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const ListItemContent = ({ title, Icon }: ExpectListItemProps) => {
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });

  return (
    <StyledListItemWrapper>
      <StyledListItem>
        <StyledIconWrapper>
          <Icon width={isMobile ? 20 : 32} height={isMobile ? 20 : 32} />
        </StyledIconWrapper>
        <StyledTitle>{title}</StyledTitle>
      </StyledListItem>
    </StyledListItemWrapper>
  );
};

// const ListItem = ({ title, Icon }: any) => {
//   return (
//     <StyledListItem>
//       <StyledIconWrapper>
//         <Icon />
//       </StyledIconWrapper>
//       <StyledTitle>{title}</StyledTitle>
//     </StyledListItem>
//   );
// };

const ExpectContent = () => {
  const isStandardDesktop = useMediaQuery({
    query: `(min-width: ${theme.size.standardDesktop}px)`,
  });
  const isTablet = useMediaQuery({ query: theme.device.tablet.min });

  return (
    <>
      <StyledContentWrapper>
        {isTablet && !isStandardDesktop && <StyledGraphImage />}
        {!isTablet && <StyledMobileGraphImage />}
        <StyledList>
          {EXPECT_LIST_ITEMS.map((item) => (
            <ListItemContent key={item.title} {...item} />
          ))}
        </StyledList>
      </StyledContentWrapper>
    </>
  );
};

export default ExpectContent;

