import { Outcome, OutcomeStatus } from 'core/types/outcome';
import { getNowDate, isDateBefore } from './date';
import { Action } from '../types/action';

export const newOutcome = (status: OutcomeStatus) => {
  const nowDate = getNowDate();
  switch (status) {
    case OutcomeStatus.Current:
      return {
        startDate: nowDate,
      };
    case OutcomeStatus.Completed:
      return {
        startDate: nowDate,
        endDate: nowDate,
        completeDate: nowDate,
      };
    default:
      return {};
  }
};

const updateOutcomeOnFutureStatus = (outcome: Outcome, status: OutcomeStatus): Outcome => {
  const updatedOutcome = Object.assign({}, outcome);
  const { startDate, endDate } = updatedOutcome;
  const nowDate = getNowDate();
  switch (status) {
    case OutcomeStatus.Current:
      updatedOutcome.startDate = nowDate;
      updatedOutcome.endDate =
        isDateBefore(updatedOutcome.startDate, endDate) && endDate ? endDate : null;
      updatedOutcome.status = OutcomeStatus.Current;
      break;
    case OutcomeStatus.Completed: {
      updatedOutcome.endDate = isDateBefore(startDate, endDate) && endDate ? endDate : nowDate;
      updatedOutcome.startDate =
        isDateBefore(startDate, updatedOutcome.endDate) && startDate ? startDate : nowDate;
      updatedOutcome.completeDate = nowDate;
      updatedOutcome.status = OutcomeStatus.Completed;
      break;
    }
  }
  return updatedOutcome;
};

const updateOutcomeOnCompletedStatus = (outcome: Outcome, status: OutcomeStatus): Outcome => {
  const updatedOutcome = Object.assign({}, outcome);
  const nowDate = getNowDate();
  switch (status) {
    case OutcomeStatus.Current: {
      updatedOutcome.endDate = null;
      updatedOutcome.startDate = getNowDate();
      updatedOutcome.status = OutcomeStatus.Current;
      break;
    }
    case OutcomeStatus.Future: {
      updatedOutcome.endDate = null;
      updatedOutcome.startDate = null;
      updatedOutcome.status = OutcomeStatus.Future;
      break;
    }
    case OutcomeStatus.Completed: {
      updatedOutcome.completeDate = updatedOutcome.endDate ? updatedOutcome.endDate : nowDate;
      break;
    }
  }
  return updatedOutcome;
};

const updateOutcomeOnCurrentStatus = (outcome: Outcome, status: OutcomeStatus): Outcome => {
  const updatedOutcome = Object.assign({}, outcome);
  const { startDate, endDate } = updatedOutcome;
  const nowDate = getNowDate();
  const datesValid = isDateBefore(startDate, endDate);

  switch (status) {
    case OutcomeStatus.Completed: {
      updatedOutcome.endDate = isDateBefore(startDate, endDate) && endDate ? endDate : nowDate;
      updatedOutcome.startDate =
        isDateBefore(startDate, updatedOutcome.endDate) && startDate ? startDate : nowDate;
      updatedOutcome.completeDate = nowDate;
      updatedOutcome.status = OutcomeStatus.Completed;
      break;
    }
    case OutcomeStatus.Future: {
      updatedOutcome.startDate =
        datesValid && startDate && isDateBefore(nowDate, startDate) ? startDate : null;
      updatedOutcome.endDate =
        datesValid && endDate && isDateBefore(nowDate, endDate) ? endDate : null;
      updatedOutcome.status = OutcomeStatus.Future;
    }
  }
  return updatedOutcome;
};

const updateFunctionByOutcomeStatus = {
  [OutcomeStatus.Future]: updateOutcomeOnFutureStatus,
  [OutcomeStatus.Completed]: updateOutcomeOnCompletedStatus,
  [OutcomeStatus.Current]: updateOutcomeOnCurrentStatus,
};

export const updateOutcomeByStatus = (
  outcome: Outcome,
  prevStatus: OutcomeStatus,
  newStatus: OutcomeStatus,
) => {
  return updateFunctionByOutcomeStatus[prevStatus](outcome, newStatus);
};

export const updateOutcomeActions = (outcome: Outcome, actionId: number) => {
  return {
    id: outcome.id,
    actionIds: outcome.actions?.map((a) => a.id).concat(actionId),
  };
};

export const updateActionOutcome = (action: Action, outcomeId: number) => {
  return {
    id: action.id,
    inputTags: action.tags?.map((t) => ({ name: t.name })),
    outcomeId,
  };
};

