import { ApolloClient } from '@apollo/client';
import { Action } from '../../core/types/action';
import { Outcome } from '../../core/types/outcome';
import { FETCH_USER_WORKSPACE_TAGS } from '../common/graphql/queries';

export const updateWorkspaceTagsData = (
  client: ApolloClient<any>,
  entity: Action | Outcome,
): void => {
  const workspaceTagsData = client.readQuery({
    query: FETCH_USER_WORKSPACE_TAGS,
    variables: {
      workspaceId: entity.workspaceId,
    },
  });

  if (!workspaceTagsData) {
    return;
  }
  const updatedTagsData = { ...workspaceTagsData };

  if (entity.tags?.length) {
    entity.tags.forEach((newTag) => {
      const isExist = updatedTagsData.fetchUserWorkspaceTags.some((tag) => tag.id === newTag.id);
      updatedTagsData.fetchUserWorkspaceTags = isExist
        ? updatedTagsData.fetchUserWorkspaceTags
        : [
            ...updatedTagsData.fetchUserWorkspaceTags,
            {
              workspaceId: null,
              updateDate: null,
              createDate: null,
              actionId: null,
              ...newTag,
            },
          ];
    });
  }

  client.writeQuery({
    query: FETCH_USER_WORKSPACE_TAGS,
    variables: {
      workspaceId: entity.workspaceId,
    },
    data: updatedTagsData,
  });
};

