import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { ReactComponent as PointIcon } from '../../img/point.svg';
import { BmSubscriptionPlans, StripeSubscriptionIntervals } from '../../../graphql/types';
import {
  FETCH_STRIPE_CREDIT_CARD,
  FETCH_STRIPE_LATEST_INVOICE,
  FETCH_STRIPE_SUBSCRIPTION,
  FETCH_STRIPE_UPCOMING_INVOICE,
} from '../../../graphql/queries';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { Loader } from '../../../core/components/common';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import { INIT_STRIPE_CUSTOMER } from '../../../graphql/mutations';

const StyledPaymentContainer = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
`;

const StyledPaymentTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

const StyledPaymentInfoWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const StyledDate = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

const StyledPrice = styled(StyledDate)``;
const StyledCardInfo = styled(StyledDate)`
  margin-left: 8px;
  font-weight: 400;
`;

const StyledPointIcon = styled(PointIcon)`
  margin: 0 8px;
`;

const StyledLoader = styled(Loader)`
  margin-left: 8px;
`;

const UserProfilePaymentInfo = () => {
  const [paymentInfoLoaded, setPaymentInfoLoaded] = useState<boolean>(false);
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired }: StripeValue = stripeData?.stripe;
  const { data: userSubscriptionData } = useQuery(FETCH_STRIPE_SUBSCRIPTION);
  const {
    data: upcomingInvoiceData,
    refetch: refetchUpcomingInvoice,
    loading: upcomingInvoiceLoading,
  } = useQuery(FETCH_STRIPE_UPCOMING_INVOICE);
  const {
    data: latestInvoiceData,
    refetch: refetchLatestInvoice,
    loading: latestInvoiceLoading,
  } = useQuery(FETCH_STRIPE_LATEST_INVOICE);
  const {
    data: creditCardData,
    loading: cardLoading,
    refetch,
  } = useQuery(FETCH_STRIPE_CREDIT_CARD);
  const isRecurring =
    userSubscriptionData?.fetchStripeSubscription?.subscription?.isCancelAtPeriodEnd === false;
  const nextPaymentDate = upcomingInvoiceData?.fetchStripeUpcomingInvoice?.periodStart
    ? upcomingInvoiceData?.fetchStripeUpcomingInvoice?.periodStart * 1000
    : Date.now();
  const lastPaymentDate = latestInvoiceData?.fetchStripeLatestInvoice?.periodStart
    ? latestInvoiceData?.fetchStripeLatestInvoice?.periodStart * 1000
    : Date.now();
  // const cancelDate = userSubscriptionData?.fetchStripeSubscription?.subscription?.canceledAt
  //   ? userSubscriptionData?.fetchStripeSubscription?.subscription?.canceledAt * 1000
  //   : undefined;
  const amount = userSubscriptionData?.fetchStripeSubscription?.subscription?.amount || 0;
  const nextPrice = upcomingInvoiceData?.fetchStripeUpcomingInvoice?.total || 0;
  const prevPrice = latestInvoiceData?.fetchStripeLatestInvoice?.total || 0;

  useEffect(() => {
    refetch();
    refetchUpcomingInvoice();
    refetchLatestInvoice();
  }, []);

  useEffect(() => {
    if (isRecurring || !isSubscriptionExpired) {
      if (!upcomingInvoiceLoading && !latestInvoiceLoading && !cardLoading) {
        setPaymentInfoLoaded(true);
      } else {
        setPaymentInfoLoaded(false);
      }
    } else {
      if (!upcomingInvoiceLoading && !latestInvoiceLoading) {
        setPaymentInfoLoaded(true);
      } else {
        setPaymentInfoLoaded(false);
      }
    }
    if (upcomingInvoiceLoading || latestInvoiceLoading || cardLoading) {
      setPaymentInfoLoaded(false);
    }
  }, [upcomingInvoiceLoading, latestInvoiceLoading, cardLoading]);

  if (
    !amount &&
    userSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan !==
      BmSubscriptionPlans.TrialFree &&
    userSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan !==
      BmSubscriptionPlans.TrialThreeMonth
  ) {
    return null;
  }

  const renderCardInfo = () => {
    if (!isRecurring || isSubscriptionExpired) {
      return null;
    } else if (!!creditCardData?.fetchStripeCreditCard && !cardLoading) {
      return (
        <StyledCardInfo>{`(${capitalize(creditCardData?.fetchStripeCreditCard?.brand!)}  •••• ${
          creditCardData?.fetchStripeCreditCard?.last4
        })`}</StyledCardInfo>
      );
    } else return null;
  };

  const getPaymentDate = () => {
    if (isRecurring) {
      if (isSubscriptionExpired) {
        return format(lastPaymentDate, 'MMM dd, yyyy');
      } else {
        return format(nextPaymentDate, 'MMM dd, yyyy');
      }
    } else {
      return format(lastPaymentDate, 'MMM dd, yyyy');
    }
  };

  return (
    <StyledPaymentContainer>
      <StyledPaymentTitle>
        {isRecurring && !isSubscriptionExpired
          ? `Next ${
              userSubscriptionData?.fetchStripeSubscription?.subscription?.interval ===
              StripeSubscriptionIntervals.Month
                ? 'monthly'
                : 'yearly'
            } payment`
          : 'Last payment:'}
      </StyledPaymentTitle>
      <StyledPaymentInfoWrapper>
        {!paymentInfoLoaded ? (
          <StyledLoader size="small" />
        ) : (
          <>
            <StyledDate>{getPaymentDate()}</StyledDate>
            <StyledPointIcon />
            <StyledPrice>
              $
              {isRecurring && !isSubscriptionExpired
                ? (nextPrice / 100).toFixed(2)
                : (prevPrice / 100).toFixed(2)}
            </StyledPrice>
            {renderCardInfo()}
          </>
        )}
      </StyledPaymentInfoWrapper>
    </StyledPaymentContainer>
  );
};

export default UserProfilePaymentInfo;

