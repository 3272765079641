import React, { ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Typography } from 'antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { isEmpty } from 'lodash';

import { Button } from 'core/components/common';
import { EditWorkspaceModalProps } from './types';
import { theme } from '../../../../core/styles/styled-components';
import {
  DebouncedTextAreaInput,
  DebouncedTextInput,
} from '../../../../core/enchancedComponents/DebouncedTextField/DebouncedTextField';
import { useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../common/graphql/queries';
import { Permission } from '../../../../core/types/workspace';

const StyledModal = styled(Modal)`
  margin: 0;

  @media ${theme.device.tablet.max} {
    z-index: 1100;
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    left: auto !important;
    width: 384px !important;
    padding: 0 !important;
  }

  @media ${theme.device.mobile.max} {
    left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
  }

  .ant-modal-body {
    padding: 32px;

    @media ${theme.device.tablet.max} {
      padding: 32px 24px 20px 24px;
    }

    @media ${theme.device.mobile.max} {
      padding: 32px 16px 20px 16px;
    }
  }
`;

const { Title } = Typography;

const StyledContainer = styled.div``;

const StyledForm = styled(Form)``;

const StyledMainContent = styled.div`
  @media ${theme.device.tablet.max} {
    height: calc(var(--app-height) - 52px); //padding
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const StyledInputName = styled(DebouncedTextInput)`
  height: 40px;
  margin-bottom: 24px;
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 700;
  margin-bottom: 8px;
`;

const StyledButtonContainer = styled.div`
  @media ${theme.device.tablet.max} {
    background: #e5e6ea;
    margin: 0 -24px -20px -24px;
    padding: 20px 24px;
  }

  @media ${theme.device.mobile.max} {
    margin: 0 -16px -20px -16px;
    padding: 20px 16px;
  }
`;

const StyledTitle = styled(Title)`
  && {
    line-height: 1;
    margin-bottom: 24px;

    @media ${theme.device.mobile.max} {
      font-size: 20px;
    }
  }
`;

const StyledTextArea = styled(DebouncedTextAreaInput)`
  && {
    margin-bottom: 40px;
  }
`;

const StyledCancelButton = styled(Button)`
  border: 1px solid rgba(32, 38, 53, 0.4);
  background-color: transparent;
  height: 32px;
  color: #202635;
  margin-left: 16px;
  font-size: 14px;

  @media ${theme.device.desktop.min} {
    display: none;
  }
`;

const EditWorkspaceModal = ({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  ...modalProps
}: EditWorkspaceModalProps) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().max(64, 'Max length of name is 64 characters!').required(),
    description: Yup.string().max(1000, 'Max length of description is 1000 characters!').nullable(),
  });

  const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const viewerPermission = useMemo(
    () =>
      workspacesResponse?.fetchUserWorkspacesWithInvitedCount?.find(
        (workspace) => workspace.id === initialValues?.id,
      )?.permission === Permission.Viewer || false,
    [workspacesResponse?.fetchUserWorkspacesWithInvitedCount, initialValues],
  );

  return (
    <StyledModal
      getContainer={() => document.body}
      onCancel={onCancel}
      visible={visible}
      closable
      mask={false}
      footer={null}
      width={571}
      {...modalProps}
    >
      <StyledContainer>
        <Formik
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, isSubmitting, errors, dirty }) => {
            return (
              <StyledForm>
                <StyledMainContent>
                  <div>
                    <StyledTitle level={3}>{values.id ? 'Edit' : 'Add new'} Space</StyledTitle>

                    <StyledLabel>{values.id ? 'Name' : 'Name it'}</StyledLabel>
                    <StyledInputName
                      name="name"
                      disabled={viewerPermission}
                      maxLength={64}
                      value={values.name}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('name', e?.target?.value);
                      }}
                      autoComplete="off"
                    />

                    <StyledLabel>{values.id ? 'Description' : 'Add a description'}</StyledLabel>
                    <StyledTextArea
                      disabled={viewerPermission}
                      autoSize={{ minRows: 6, maxRows: 8 }}
                      name="description"
                      maxLength={1000}
                      value={values.description}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setFieldValue('description', e?.target?.value);
                      }}
                    />
                  </div>
                  <StyledButtonContainer>
                    <SubmitButton
                      disabled={!isEmpty(errors) || !dirty || viewerPermission}
                      loading={isSubmitting}
                      type="primary"
                      shape="round"
                    >
                      {values.id ? 'Save Changes' : 'Add new Space'}
                    </SubmitButton>
                    <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
                  </StyledButtonContainer>
                </StyledMainContent>
              </StyledForm>
            );
          }}
        </Formik>
      </StyledContainer>
    </StyledModal>
  );
};

export { EditWorkspaceModal };
