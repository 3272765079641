import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { kebabCase, noop } from 'lodash-es';
import { MultiSortedContextMenuData, WorkspaceMenuOption, WorkspaceMenuProps } from './types';
import { useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../graphql/queries';
import { sortWorkspaceOptions } from './utils';
import { EntityName, Filter } from '../../../utils';
import { NOT_ASSIGNED_WORKSPACE_NAME } from '../../../../core/constants/others';
import { Permission } from '../../../../core/types/workspace';
import { renameNotAssignedWorkspace } from '../../../actions/components/ActionBoard/NewInformation/utils';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';

const StyledContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  & .ant-dropdown {
    z-index: 10;
  }

  & .ant-menu-item {
    height: 26px;
    line-height: 26px;

    span {
      max-width: 250px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      display: block;
    }
  }

  & .ant-menu-item {
    margin: 0 !important;
  }

  .ant-menu-vertical {
    border-right: none;
  }
`;

const StyledMenuWrapper = styled.div`
  padding: 2px;
  border-radius: 3px;
  background: #fff;
`;

const StyledMenu = styled(Menu)`
  max-height: 101px;
  min-width: 100px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }
`;

const StyledMenuOption = styled(Menu.Item)<{ highlighted?: boolean; isDivider?: boolean }>`
  font-size: 12px;
  font-weight: ${(props) => (props.highlighted ? 700 : 400)};
  padding: 5px 10px;
  margin: 0;
  border-radius: 2px;

  &.ant-menu-item-selected {
    background-color: var(--color-white) !important;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    align-items: center;
    display: flex;
    position: relative;

    svg {
      height: 16px;
      left: -25px;
      position: absolute;
    }
  }

  ${({ isDivider }) =>
    isDivider &&
    css`
      height: 40px !important;

      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #e7e8eb !important;
        margin: 7px 0;
      }
    `}
`;

const StyledDivider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e7e8eb !important;
  margin: 4px 0;
`;

const renderDefaultContextMenu: WorkspaceMenuProps['renderContextMenu'] = (
  options,
  onOptionClick,
  highlightedContextMenuOptions = [],
  optionDividerId?: number,
) => {
  return (
    <StyledMenuWrapper>
      <StyledMenu>
        {options.map((option) => (
          <Fragment key={kebabCase(option?.value?.toString())}>
            {option?.value === optionDividerId && <StyledDivider />}
            <StyledMenuOption
              key={kebabCase(option?.value?.toString())}
              onClick={() => onOptionClick(option)}
              highlighted={
                !!highlightedContextMenuOptions.find((opt) => opt?.value === option?.value)
              }
              // isDivider={option?.value === optionDividerId}
            >
              {option?.icon && <option.icon />} {option?.label}
            </StyledMenuOption>
          </Fragment>
        ))}
      </StyledMenu>
    </StyledMenuWrapper>
  );
};

const WorkspaceSelectMenu = React.memo(
  ({
    className,
    children,
    currentWorkspaceId = -1,
    disabled = false,
    onClick,
    onDoubleClick,
    renderContextMenu = renderDefaultContextMenu,
    onContextMenuOptionClick = noop,
    isForCreate,
    isMultiSorted,
    renderChildren,
    entityName,
  }: WorkspaceMenuProps) => {
    const [multiSortedContextMenuData, setMultiSortedContextMenuData] =
      useState<MultiSortedContextMenuData>({ options: [], optionDividerId: -1 });
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { data: filterData } = useQuery(FILTER_STATE);
    const { filterInput }: FilterInputValue = filterData?.filterInput;
    const containerRef = useRef(null);
    const getDropdownContainer = useCallback(() => containerRef.current || document.body, []);
    const { data: workspacesResponse } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
    const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];

    const contextMenuOptions = workspaces
      .slice()
      .filter(
        (workspace) =>
          workspace.name !== NOT_ASSIGNED_WORKSPACE_NAME || entityName === EntityName.NOTE,
      )
      .filter((workspace) => workspace.permission !== Permission.Viewer)
      // .filter((workspace) => workspace.permission !== Permission.EDITOR)    //hide spaces with editor permission
      .map((workspace) => {
        return {
          label: renameNotAssignedWorkspace(workspace.name),
          value: workspace.id,
        };
      });
    const sortedContextMenuOptions = sortWorkspaceOptions(contextMenuOptions, defaultWorkspaceId);

    useEffect(() => {
      if (isForCreate || isMultiSorted) {
        const getMultiSortedContextMenuOptions = () => {
          const defaultWorkspace = contextMenuOptions.find((w) => w.value === defaultWorkspaceId);
          const selectedWorkspaces = contextMenuOptions.filter((w) => {
            if (w.value === defaultWorkspaceId) {
              return false;
            }
            return filterInput.some((filter: Filter) => filter.workspaceId === w.value);
          });
          const unselectedWorkspaces = contextMenuOptions.filter((w) => {
            if (w.value === defaultWorkspaceId) {
              return false;
            }
            return !filterInput.some((filter: Filter) => filter.workspaceId === w.value);
          });

          const allWorkspaces = [
            defaultWorkspace,
            ...sortWorkspaceOptions(selectedWorkspaces, defaultWorkspaceId),
            ...sortWorkspaceOptions(unselectedWorkspaces, defaultWorkspaceId),
          ];

          setMultiSortedContextMenuData({
            options: allWorkspaces as WorkspaceMenuOption[],
            optionDividerId: (unselectedWorkspaces.length && unselectedWorkspaces[0].value) || -1,
          });
        };

        getMultiSortedContextMenuOptions();
      }
    }, [isForCreate, isMultiSorted, workspacesResponse, filterInput]);

    const highlightedWorkspace = contextMenuOptions.filter(
      (option) => option.value === currentWorkspaceId,
    );

    const childrenEl = isForCreate
      ? renderChildren && renderChildren(highlightedWorkspace[0]?.label)
      : children;

    const options =
      isForCreate || isMultiSorted ? multiSortedContextMenuData.options : sortedContextMenuOptions;

    return (
      <StyledContainer
        disabled={disabled}
        ref={containerRef}
        className={className}
        onClick={!disabled ? onClick : undefined}
        onDoubleClick={!disabled ? onDoubleClick : undefined}
      >
        {contextMenuOptions.length > 1 ? (
          <Dropdown
            trigger={!disabled ? ['click'] : []}
            overlay={renderContextMenu(
              options,
              onContextMenuOptionClick,
              highlightedWorkspace,
              multiSortedContextMenuData.optionDividerId,
            )}
            getPopupContainer={getDropdownContainer}
          >
            {childrenEl}
          </Dropdown>
        ) : (
          childrenEl
        )}
      </StyledContainer>
    );
  },
);

export default WorkspaceSelectMenu;

