import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { mutateAction } from 'context/userWorkspaceContext';
import { FETCH_ACTIONS } from 'features/actions/graphql/queries';
import { UPDATE_ACTION } from 'features/actions/graphql/mutations';
import { OperationType, updatedCacheData, updateWorkspaceTagsData } from 'features/utils';
import { checkActionForComplete } from '../utils';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { completeMutation } from '../../../apollo/stateFields/complete';
import { Action } from '__generated__/graphql';

const useUpdateAction = () => {
  const apolloClient = useApolloClient();
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;

  return useMutation(UPDATE_ACTION, {
    update(cache, { data }) {
      const { fetchUserWorkspaceActions = [] } =
        cache.readQuery({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: data?.updateUserWorkspaceAction.workspaceId,
          },
        }) || {};

      cache.writeQuery({
        query: FETCH_ACTIONS,
        data: {
          fetchUserWorkspaceActions: updatedCacheData(
            fetchUserWorkspaceActions,
            data?.updateUserWorkspaceAction,
            OperationType.UPDATE,
          ),
        },
        variables: {
          workspaceId: data?.updateUserWorkspaceAction.workspaceId,
        },
      });
      if (data?.updateUserWorkspaceAction) {
        mutateAction(
          apolloClient,
          data?.updateUserWorkspaceAction as Action,
          'update',
          filterInput,
        );
        updateWorkspaceTagsData(apolloClient, data?.updateUserWorkspaceAction as Action);
      }
      if (data?.updateUserWorkspaceAction.outcome) {
        checkActionForComplete(
          setOutcomeToComplete,
          setOutcomeToIncomplete,
          data?.updateUserWorkspaceAction as Action,
        );
      }
    },
  });
};

export default useUpdateAction;

