import { gql } from '__generated__/gql';

import './fragments';
import '../../outcomes/graphql/fragments';

//Actions

const CREATE_ACTION_STRING = /* GraphQL */ `
  mutation CreateAction($actionValues: NewActionInput!, $workspaceId: Int = -1) {
    createUserWorkspaceAction(action: $actionValues, workspaceId: $workspaceId) {
      ...actionFields
    }
  }
`;

export const CREATE_ACTION = gql(CREATE_ACTION_STRING);

const UPDATE_ACTION_STRING = /* GraphQL */ `
  mutation UpdateAction(
    $actionValues: ActionInput!
    $workspaceId: Int = -1
    $targetWorkspaceId: Int
  ) {
    updateUserWorkspaceAction(
      action: $actionValues
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      ...actionFields
    }
  }
`;

export const UPDATE_ACTION = gql(UPDATE_ACTION_STRING);

const MOVE_ACTION_STRING = /* GraphQL */ `
  mutation MoveAction(
    $actionValues: ActionInput!
    $workspaceId: Int = -1
    $targetWorkspaceId: Int
    $outcomeId: Int
    $targetOutcomeId: Int
  ) {
    moveUserWorkspaceAction(
      action: $actionValues
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
      outcomeId: $outcomeId
      targetOutcomeId: $targetOutcomeId
    ) {
      action {
        ...actionFields
      }
      historicOutcome {
        isAllActionsDone
        ...outcomeFields
      }
    }
  }
`;

export const MOVE_ACTION = gql(MOVE_ACTION_STRING);

const DELETE_ACTION_STRING = /* GraphQL */ `
  mutation DeleteAction($actionValues: ActionInput!, $workspaceId: Int = -1) {
    deleteUserWorkspaceAction(action: $actionValues, workspaceId: $workspaceId)
  }
`;

export const DELETE_ACTION = gql(DELETE_ACTION_STRING);

const DUPLICATE_ACTION_STRING = /* GraphQL */ `
  mutation DuplicateAction($actionId: Int!, $workspaceId: Int = -1) {
    duplicateUserWorkspaceAction(actionId: $actionId, workspaceId: $workspaceId) {
      ...actionFields
    }
  }
`;

export const DUPLICATE_ACTION = gql(DUPLICATE_ACTION_STRING);

// Notes

const CREATE_NOTE_STRING = /* GraphQL */ `
  mutation CreateNote($note: NewNoteInput!, $workspaceId: Int = -1) {
    createUserWorkspaceNote(note: $note, workspaceId: $workspaceId) {
      ...noteFields
    }
  }
`;

export const CREATE_NOTE = gql(CREATE_NOTE_STRING);

const UPDATE_NOTE_STRING = /* GraphQL */ `
  mutation UpdateNote($note: NoteInput!, $workspaceId: Int = -1, $targetWorkspaceId: Int) {
    updateUserWorkspaceNote(
      note: $note
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      ...noteFields
    }
  }
`;

export const UPDATE_NOTE = gql(UPDATE_NOTE_STRING);

const MOVE_NOTE_STRING = /* GraphQL */ `
  mutation MoveNote($note: NoteInput!, $workspaceId: Int = -1, $targetWorkspaceId: Int = -1) {
    moveUserWorkspaceNote(
      note: $note
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      ...noteFields
    }
  }
`;

export const MOVE_NOTE = gql(MOVE_NOTE_STRING);

const CONVERT_NOTE_TO_OUTCOME_STRING = /* GraphQL */ `
  mutation ConvertNoteToOutcome($noteId: Int!, $workspaceId: Int = -1, $outcome: NewOutcomeInput!) {
    convertNoteToUserWorkspaceOutcome(
      noteId: $noteId
      workspaceId: $workspaceId
      outcome: $outcome
    ) {
      ...outcomeFields
    }
  }
`;

export const CONVERT_NOTE_TO_OUTCOME = gql(CONVERT_NOTE_TO_OUTCOME_STRING);

const CONVERT_NOTE_TO_ACTION_STRING = /* GraphQL */ `
  mutation ConvertNoteToAction($noteId: Int!, $workspaceId: Int = -1, $action: NewActionInput!) {
    convertNoteToUserWorkspaceAction(noteId: $noteId, workspaceId: $workspaceId, action: $action) {
      ...actionFields
    }
  }
`;

export const CONVERT_NOTE_TO_ACTION = gql(CONVERT_NOTE_TO_ACTION_STRING);

const DELETE_NOTE_STRING = /* GraphQL */ `
  mutation DeleteNote($note: NoteInput!, $workspaceId: Int = -1) {
    deleteUserWorkspaceNote(note: $note, workspaceId: $workspaceId)
  }
`;

export const DELETE_NOTE = gql(DELETE_NOTE_STRING);

const START_FOCUS_TIMER_STRING = /* GraphQL */ `
  mutation CreateFocusTimer($actionId: Int!) {
    createFocusTimer(actionId: $actionId) {
      ...focusTimerFields
    }
  }
`;

export const START_FOCUS_TIMER = gql(START_FOCUS_TIMER_STRING);

const CANCEL_FOCUS_TIMER_STRING = /* GraphQL */ `
  mutation CancelFocusTimer {
    cancelFocusTimer
  }
`;

export const CANCEL_FOCUS_TIMER = gql(CANCEL_FOCUS_TIMER_STRING);

const PAUSE_FOCUS_TIMER_STRING = /* GraphQL */ `
  mutation PauseFocusTimer($spendTime: Int!) {
    pauseFocusTimer(spendTime: $spendTime) {
      ...focusTimerFields
    }
  }
`;

export const PAUSE_FOCUS_TIMER = gql(PAUSE_FOCUS_TIMER_STRING);

const RESUME_FOCUS_TIMER_STRING = /* GraphQL */ `
  mutation ResumeFocusTimer {
    resumeFocusTimer {
      ...focusTimerFields
    }
  }
`;

export const RESUME_FOCUS_TIMER = gql(RESUME_FOCUS_TIMER_STRING);

const FINISH_FOCUS_TIMER_STRING = /* GraphQL */ `
  mutation FinishFocusTimer($spendTime: Int!) {
    finishFocusTimer(spendTime: $spendTime)
  }
`;

export const FINISH_FOCUS_TIMER = gql(FINISH_FOCUS_TIMER_STRING);

