import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { StyledTabContainer } from '../StyledComponents';
import { Collapse } from 'antd';
import ViewEditOrg from '../ViewEditOrg/ViewEditOrg';
import AddOrganization from '../AddOrganization/AddOrganization';
import DeleteOrganization from '../DeleteOrganization/DeleteOrganization';
// import ChangeCurriculum from '../ChangeCurriculum/ChangeCurriculum';
// import AssignUserFreePlan from '../AssignUserFreePlan/AssignUserFreePlan';

const { Panel } = Collapse;

const CompanySettings = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (isSuccess) {
      toast.success('Successful');
      setIsSuccess(false);
    }
  }, [isSuccess]);

  return (
    <StyledTabContainer>
      <Collapse>
        <Panel header="Add Organization" key="1">
          <AddOrganization setIsSuccess={setIsSuccess} setErrorMessage={setErrorMessage} />
        </Panel>
        {/* <Panel header="Change (and Reset) Curriculum" key="2">
          <ChangeCurriculum setIsSuccess={setIsSuccess} setErrorMessage={setErrorMessage} />
        </Panel> */}
        {/* <Panel header="Assign Users to 1 Year Free Plan" key="3">
          <AssignUserFreePlan setIsSuccess={setIsSuccess} setErrorMessage={setErrorMessage} />
        </Panel> */}
        <Panel header="View and Edit Organization" key="4">
          <ViewEditOrg setIsSuccess={setIsSuccess} setErrorMessage={setErrorMessage} />
        </Panel>
        <Panel header="Delete Organization" key="5">
          <DeleteOrganization onError={setErrorMessage} onSuccess={setIsSuccess} />
        </Panel>
      </Collapse>
    </StyledTabContainer>
  );
};

export default CompanySettings;

