import React, { useEffect, useState } from 'react';
import { FirebaseSocialAuth } from '../index';
import styled from 'styled-components';
import { Formik } from 'formik';
import { validationSchema } from './validation';
import { Checkbox, Form, SubmitButton } from 'formik-antd';
import { Link } from 'react-router-dom';
import { SIGN_IN_PATH } from '../../../../core/constants/routePaths';
import { InputTextField } from '../../../../core/components/form';
import SignInAndSignUpLayout from '../SignInAndSignUpLayout';
import { useAuth } from '../../../../context/authContext';
import { SignUpData } from '../../types';

const StyledButtonsContainer = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledInputContainer = styled.div`
  display: flex;

  & > div:nth-child(1) {
    margin-right: 4px;
  }
  & > div:nth-child(2) {
    margin-left: 4px;
  }
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
`;

const StyledOutLink = styled.a`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 16px;

  .ant-checkbox-inner {
    background-color: #fff;

    &::after {
      border-color: var(--color-dark-blue);
    }
  }

  .ant-checkbox + span {
    padding-top: 12px;
    font-size: 12px;
    line-height: 14px;
  }
`;

const initialValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  isAgree: false,
};

const SignUp = () => {
  const { signUpWithEmail } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const label = 'Sign up';

  const handleSignUp = async (values: SignUpData) => {
    if (signUpWithEmail) {
      const res = await signUpWithEmail(values);
      setShowSuccessMessage(res);
    }
  };

  const saveSharePlatform = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('share');

    if (param) {
      localStorage.setItem('share', param);
    }
  };

  useEffect(() => {
    saveSharePlatform();
  }, []);

  const signUpFormEl = (
    <Formik
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSignUp}
    >
      {({ values, isSubmitting, errors }) => {
        return (
          <Form>
            <StyledInputContainer>
              <InputTextField
                name="firstName"
                placeholder="First name"
                autoComplete="off"
                displayError={!!errors.firstName}
                maxLength={100}
              />
              <InputTextField
                name="lastName"
                placeholder="Last name"
                autoComplete="off"
                displayError={!!errors.lastName}
                maxLength={100}
              />
            </StyledInputContainer>
            <InputTextField
              name="email"
              placeholder="Email"
              autoComplete="off"
              displayError={!!errors.email}
            />
            <InputTextField
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="off"
              displayError={!!errors.password}
            />
            <StyledCheckbox name="isAgree">
              I have read and agree with{' '}
              <StyledOutLink href="https://billionminds.com/terms-and-privacy/" target="_blank">
                Terms of Service and Privacy Policy
              </StyledOutLink>
            </StyledCheckbox>
            <StyledButtonsContainer>
              <StyledLink to={SIGN_IN_PATH}>Go to Sign in</StyledLink>
              <SubmitButton
                disabled={!values.isAgree}
                loading={isSubmitting}
                type="primary"
                shape="round"
              >
                Create account
              </SubmitButton>
            </StyledButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <SignInAndSignUpLayout
      title={label}
      showSuccessMessage={showSuccessMessage}
      formComponent={signUpFormEl}
      socialAuthComponent={<FirebaseSocialAuth label={label} />}
    />
  );
};

export default SignUp;

