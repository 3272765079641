import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BmSubscriptionPlans, MockedFetchStripePrices } from '../../../graphql/types';
import {
  FETCH_STRIPE_CREDIT_CARD,
  FETCH_STRIPE_PRICES,
  FETCH_STRIPE_SUBSCRIPTION,
} from '../../../graphql/queries';
import styled, { css } from 'styled-components';
import { Button } from 'antd';
import * as routePaths from '../../../core/constants/routePaths';
import { format, isAfter, differenceInDays, startOfDay } from 'date-fns';
import pluralize from 'pluralize';
import { theme } from '../../../core/styles/styled-components';
import { getPlanByType } from '../../../pages/utils';
import {
  PLANS_TYPES,
  STRIPE_STATE,
  StripeValue,
} from '../../../apollo/stateFields/stripe/stripeFields';
import { Link } from 'react-router-dom';
import { Loader } from '../../../core/components/common';
import UserProfilePaymentInfo from '../../../pages/UserProfile/UserProfilePaymentInfo/UserProfilePaymentInfo';
import { ConfirmModal } from '../../common/components/ConfirmModal';
import { CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END } from '../../../graphql/mutations';
import { USER_PLANS_PATH } from '../../../core/constants/routePaths';
import NotAvailableFeatures from '../../../pages/AvailablePlansPage/NotAvailableFeatures/NotAvailableFeatures';

const StyledPlanContainer = styled.div`
  margin-top: 40px;
`;

const StyledPlanName = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  @media ${theme.device.mobile.max} {
    font-size: 18px;
  }
`;

const StyledPlanNameLink = styled(Link)`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  transition: all ease-in-out 200ms;

  :hover {
    opacity: 0.8;
  }

  @media ${theme.device.mobile.max} {
    font-size: 18px;
  }
`;

const StyledPlanInfoTime = styled.p<{ isExpired?: boolean; noMargin?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  color: #202635;
  margin-top: 4px;
  margin-bottom: 0;

  ${({ isExpired }) =>
    isExpired &&
    css`
      color: #ba0000;
    `}

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0 !important;
    `}

  @media ${theme.device.mobile.max} {
    font-size: 12px;
    margin-top: 6px;
  }
`;

const StyledPlanInfoContainer = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

const StyledPlanType = styled.span<{ plantType?: PLANS_TYPES }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #7340dd;

  ${({ plantType }) =>
    plantType === PLANS_TYPES.Individual &&
    css`
      color: #20b4f3;
    `}
`;

const StyledDot = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #202635;
  opacity: 0.5;
  margin: 0 8px;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export const StyledCancelButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  box-shadow: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid rgba(32, 38, 53, 0.2);
  border-radius: 36px;
  background-color: transparent;

  span {
    color: var(--color-dark-blue);
  }

  &:hover,
  &:focus {
    background-color: transparent;
    border: 1px solid rgba(32, 38, 53, 0.4);
  }

  & > *:first-child {
    margin-right: 5px;
  }
`;

const StyledCancelInfo = styled.div`
  margin-left: 20px;
  max-width: 155px;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: center;
`;

const UserProfileInfoPlans = () => {
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired, isSubscriptionCanceled }: StripeValue = stripeData?.stripe;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [getBmSubscription, { data: fetchUserBmSubscriptionData, refetch }] =
    useLazyQuery(FETCH_STRIPE_SUBSCRIPTION);
  const { refetch: refetchCard } = useQuery(FETCH_STRIPE_CREDIT_CARD);
  const [getStripePrices, { data: fetchStripePricesData }] =
    useLazyQuery<MockedFetchStripePrices>(FETCH_STRIPE_PRICES);
  const [cancelStripeSubscriptionAtPeriodEnd] = useMutation(
    CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END,
  );
  const userSubscriptionData = fetchUserBmSubscriptionData?.fetchStripeSubscription?.bmSubscription;
  const plansData = fetchStripePricesData?.fetchStripePrices;

  const handleCancelSubscription = async () => {
    setLoading(true);
    await cancelStripeSubscriptionAtPeriodEnd();
    await refetch();
    await refetchCard();
    setLoading(false);
    setShowModal(false);
  };

  useEffect(() => {
    getBmSubscription();
    getStripePrices();
  }, []);

  const renderExpired = (expirationDate: number) => {
    return `Expired: ${format(expirationDate, 'MMM dd, yyyy')}`;
  };

  const buyPlanJSX = (
    <Link to={routePaths.USER_PLANS_PATH}>
      <StyledButton type="primary" shape="round">
        Buy plan
      </StyledButton>
    </Link>
  );

  const cancelSubscriptionJSX = (
    <StyledButtonWrapper>
      <StyledCancelButton onClick={() => setShowModal(true)}>
        Cancel subscription
      </StyledCancelButton>
      <StyledCancelInfo>
        After cancellation your plan will be active until the end of the paid period
      </StyledCancelInfo>
    </StyledButtonWrapper>
  );

  const renderTrial = () => {
    if (!userSubscriptionData) {
      return null;
    }

    const { expiration } = userSubscriptionData;
    const expirationDate = +expiration! * 1000;
    const isExpired = isAfter(new Date(), new Date(expirationDate));
    const dayCount = differenceInDays(startOfDay(new Date(expirationDate)), startOfDay(new Date()));
    // const dayCount = intervalToDuration({ start: new Date(), end: new Date(expirationDate) }).days;
    const neededDayCount = dayCount === 0 ? 1 : dayCount;
    return (
      <>
        <StyledPlanName>Free trial</StyledPlanName>
        <StyledPlanInfoTime isExpired={isExpired}>
          {isExpired
            ? renderExpired(expirationDate)
            : `${neededDayCount} ${pluralize('day', neededDayCount)} left`}
        </StyledPlanInfoTime>
        {buyPlanJSX}
        <NotAvailableFeatures />
      </>
    );
  };

  const renderPlan = () => {
    if (!userSubscriptionData) {
      return null;
    }

    const { expiration } = userSubscriptionData;
    const expirationDate = +expiration! * 1000;
    const cancelDate = fetchUserBmSubscriptionData?.fetchStripeSubscription?.subscription
      ?.canceledAt
      ? fetchUserBmSubscriptionData?.fetchStripeSubscription?.subscription?.canceledAt * 1000
      : undefined;
    // const isExpired = expirationDate === 0 ? false : isAfter(new Date(), new Date(expirationDate));
    const planData = getPlanByType(plansData, userSubscriptionData!.bmSubscriptionPlan!);
    const plantType = planData?.metadata.category;

    if (!planData) {
      return null;
    }
    // userSubscriptionData.bmSubscriptionPlan === BmSubscriptionTypes.SelfGuided_Free

    return (
      <>
        <StyledPlanInfoContainer>
          <StyledPlanType plantType={plantType}>{plantType}</StyledPlanType>
          <StyledDot>•</StyledDot>
          <StyledPlanInfoTime noMargin isExpired={isSubscriptionExpired}>
            {isSubscriptionExpired
              ? isSubscriptionCanceled && cancelDate
                ? renderExpired(cancelDate)
                : renderExpired(expirationDate)
              : userSubscriptionData.bmSubscriptionPlan === BmSubscriptionPlans.SelfGuidedFree
              ? expirationDate
                ? `Active until: ${format(expirationDate, 'MMM dd, yyyy')}`
                : 'Free for life'
              : fetchUserBmSubscriptionData?.fetchStripeSubscription?.subscription
                  ?.isCancelAtPeriodEnd === false
              ? `Renews on: ${format(expirationDate, 'MMM dd, yyyy')}`
              : `Active until: ${format(expirationDate, 'MMM dd, yyyy')}`}
          </StyledPlanInfoTime>
        </StyledPlanInfoContainer>
        <StyledPlanNameLink to={USER_PLANS_PATH}>{planData?.metadata.title}</StyledPlanNameLink>
        <UserProfilePaymentInfo />

        {isSubscriptionExpired
          ? buyPlanJSX
          : fetchUserBmSubscriptionData?.fetchStripeSubscription?.subscription
              ?.isCancelAtPeriodEnd === false && cancelSubscriptionJSX}
        <NotAvailableFeatures />
      </>
    );
  };

  const renderContent = () => {
    if (!userSubscriptionData || !plansData) {
      return <Loader size="large" centered={true} />;
    } else {
      if (
        userSubscriptionData.bmSubscriptionPlan === BmSubscriptionPlans.TrialFree ||
        userSubscriptionData.bmSubscriptionPlan === BmSubscriptionPlans.TrialThreeMonth
      ) {
        return renderTrial();
      }
      return renderPlan();
    }
  };

  return (
    <>
      <StyledPlanContainer>{renderContent()}</StyledPlanContainer>
      {!isSubscriptionExpired && (
        <ConfirmModal
          closable
          isConfirmLoading={loading}
          cancelText={'Continue using'}
          visible={showModal}
          title={'Cancel subscription?'}
          confirmText={'Cancel subscription'}
          middleContent={
            <StyledDescription>
              Recurring payment will be disabled and your plan will be active until the end of the
              paid period
            </StyledDescription>
          }
          onCancel={() => setShowModal(false)}
          onOk={handleCancelSubscription}
        />
      )}
    </>
  );
};

export default UserProfileInfoPlans;

