import { Checkbox, CheckboxProps } from 'antd';
import { StyledLabel } from '../StyledComponents';
import { useEffect } from 'react';

type LabeledCheckboxProps = Omit<CheckboxProps, 'onChange'> & {
  setValue: (value: boolean) => void;
};

const LabeledCheckbox = ({
  setValue,
  children,
  disabled,
  checked,
  value,
  ...restProps
}: LabeledCheckboxProps) => {
  useEffect(() => {
    if (disabled) {
      setValue(false);
    }
  }, [disabled, setValue]);
  return (
    <StyledLabel>
      {children ?? 'Also apply for existing Corporate Users'}
      <Checkbox
        style={{ marginLeft: '1rem' }}
        disabled={disabled}
        value={value ?? checked}
        checked={checked ?? value}
        onChange={(e) => {
          if (disabled) return;
          setValue(e.target.checked);
        }}
        {...restProps}
      />
    </StyledLabel>
  );
};

export default LabeledCheckbox;

