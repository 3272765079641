import { gql, makeVar } from '@apollo/client';
import { Permission } from '../../../core/types/workspace';
import { OPERATION_NAME } from '../../types';
import { Filter } from '../../../features/utils';

export interface ErrorWorkspace {
  id: number;
  permission: Permission;
  type: string;
  name?: string;
}

export enum CUSTOM_ERROR_MESSAGE {
  LEAVE_WORKSPACE_AS_CREATOR = 'LEAVE_WORKSPACE_AS_CREATOR',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NO_RELATION = 'NO_RELATION',
  ENTITY_SPACE_CHANGED = 'ENTITY_SPACE_CHANGED',
  NOT_ALLOWED_ON_ARCHIVED = 'NOT_ALLOWED_ON_ARCHIVED',
  INVALID_METHOD_INPUT = 'INVALID_METHOD_INPUT',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  INSUFFICIENT_PERMISSIONS = 'INSUFFICIENT_PERMISSIONS',
  REMOVE_YOURSELF_FROM_WORKSPACE = 'REMOVE_YOURSELF_FROM_WORKSPACE',
}

export enum CUSTOM_ENTITY_TYPES {
  ACTION = 'Action',
  OUTCOME = 'Outcome',
  ACTION_OR_OUTCOME = 'Action (Outcome)',
  NOTE = 'Note',
  WORKSPACE = 'WORKSPACE',
  USER = 'User',
  CORPORATE_ACCOUNT = 'Corporate Account',
}

export interface OperationVariables {
  [index: string]: any;
  workspaceId?: number;
  filters?: Filter[];
}

export interface ErrorValue {
  errorMessage: CUSTOM_ERROR_MESSAGE | undefined | null;
  errorCode: number | undefined | null;
  errorWorkspaces?: ErrorWorkspace[];
  entityType?: CUSTOM_ENTITY_TYPES | null;
  errorVariables?: OperationVariables | undefined;
  errorOperation?: OPERATION_NAME | undefined;
}

export const errorInitialValue: ErrorValue = {
  errorMessage: undefined,
  errorCode: undefined,
  errorWorkspaces: [],
  entityType: undefined,
  errorVariables: undefined,
  errorOperation: undefined,
};

export const errorVar = makeVar<ErrorValue>(errorInitialValue);
export const ERROR_STATE = gql`
  query Error {
    error @client
  }
`;
