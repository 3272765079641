import { DomainChangeAction } from '__generated__/graphql';
import { CoursesTitle } from 'apollo/stateFields/courses/coursesFields';
import { getTitle } from 'features/courses/utils';
import { BmSubscriptionPlans } from 'graphql/types';
import { CoursesGroups } from 'pages/const';

export const getCourseGroupTitle = (group: CoursesTitle[]) =>
  group.map((course) => getTitle(course)).join('/ ');

export interface ICurriculumSelectOption {
  value: number;
  label: string;
}

export const CoursesGroupsSelectOpts: ICurriculumSelectOption[] = CoursesGroups.map((group, i) => ({
  label: getCourseGroupTitle(group),
  value: i,
}));

export const CoursesGroupDefaultValue = CoursesGroupsSelectOpts[1];

export const findCourseByCurriculumType = (curriculumType: CoursesTitle[]) =>
  CoursesGroupsSelectOpts.find((group) => group.label === getCourseGroupTitle(curriculumType));

export interface ISubscriptionSelectOption {
  value: number;
  label: BmSubscriptionPlans;
}

export const SubscriptionSelectOpts: ISubscriptionSelectOption[] = [
  { value: 0, label: BmSubscriptionPlans.SelfGuidedFree },
  { value: 1, label: BmSubscriptionPlans.TrialFree },
];

export const ChangeEmailOptions = Object.values(DomainChangeAction);

export const ChangeEmailSelectOptions = [
  {
    value: 0,
    label: 'Keep their current settings',
  },
  {
    value: 1,
    label: 'Reset their settings',
  },
  {
    value: 2,
    label: 'Join their new Company settings',
  },
];

export const getValueFromObjectWithKeys = (obj: object, keys: string[]): any =>
  keys.reduce(
    (value, key) => (value && typeof obj === 'object' ? value[key as keyof object] : value),
    obj as object,
  );

export const createSorter =
  (compareFn: (valueA: any, valueB: any) => number, ...keys: string[]) =>
  (a: any, b: any) => {
    const valueA = getValueFromObjectWithKeys(a, keys);
    const valueB = getValueFromObjectWithKeys(b, keys);
    return compareFn(valueA, valueB);
  };

export const sorterByString = (...keys: string[]) => {
  const compareFn = (valueA: string, valueB: string) => {
    if (!valueA) return -1;
    if (!valueB) return 1;
    return valueA.localeCompare(valueB);
  };
  return createSorter(compareFn, ...keys);
};

export const sorterByNumber = (...keys: string[]) => {
  const compareFn = (valueA: number, valueB: number) => valueA - valueB;
  return createSorter(compareFn, ...keys);
};

