export enum OPERATION_NAME {
  UpdateNote = 'UpdateNote',
  ConvertNoteToAction = 'ConvertNoteToAction',
  ConvertNoteToOutcome = 'ConvertNoteToOutcome',
  MoveNote = 'MoveNote',
  FetchUserWorkspaceNote = 'FetchUserWorkspaceNote',
  CreateAction = 'CreateAction',
  UpdateAction = 'UpdateAction',
  MoveAction = 'MoveAction',
  FetchAction = 'FetchAction',
  FetchActions = 'FetchActions',
  UpdateOutcome = 'UpdateOutcome',
  FetchOutcomes = 'FetchOutcomes',
  FetchUserWorkspaceTags = 'FetchUserWorkspaceTags',
  ArchiveOutcome = 'ArchiveOutcome',
  UnarchiveOutcome = 'UnarchiveOutcome',
  MoveOutcome = 'MoveOutcome',
  DuplicateAction = 'DuplicateAction',
  FetchOutcome = 'FetchOutcome',
  FetchWorkspaceInvitesById = 'FetchWorkspaceInvitesById',
  FetchWorkspaceInvitesByIds = 'FetchWorkspaceInvitesByIds',
  RemoveUserFromWorkspace = 'RemoveUserFromWorkspace',
  FetchAndFilterUserWorkspacesWithEntities = 'FetchAndFilterUserWorkspacesWithEntities',
  FilterUserWorkspaces = 'FilterUserWorkspaces',
  FetchUserWorkspacesWithInvitedCount = 'FetchUserWorkspacesWithInvitedCount',

  // -------- Admin --------

  // ---- Add/View/Edit User ----

  // Queries
  FetchUserByEmail = 'FetchUserByEmail',
  // Mutations
  UpdateFreeSubscriptionExpirationByEmail = 'UpdateFreeSubscriptionExpirationByEmail',
  UpdateCoursesAvailableList = 'UpdateCoursesAvailableList',
  AddNewUser = 'AddNewUser',
  UpdateUserAsAdmin = 'UpdateUserAsAdmin',
  ResetUserCurriculumByEmail = 'ResetUserCurriculumByEmail',

  // ---- Company Settings ----

  // Mutations
  CreateCorporateAccount = 'CreateCorporateAccount',
  UpdateCorporateAccount = 'UpdateCorporateAccount',
  SwitchSubscriptionToSelfGuidedFreeByCorporateAccount = 'SwitchSubscriptionToSelfGuidedFreeByCorporateAccount',
}

export const ENTITY_NAME_BY_OPERATION_NAME: Record<string, Set<OPERATION_NAME>> = {
  OUTCOME: new Set([
    OPERATION_NAME.UpdateOutcome,
    OPERATION_NAME.ArchiveOutcome,
    OPERATION_NAME.UnarchiveOutcome,
    OPERATION_NAME.MoveOutcome,
    OPERATION_NAME.FetchOutcome,
    OPERATION_NAME.FetchOutcomes,
  ]),
  ACTION: new Set([
    OPERATION_NAME.UpdateAction,
    OPERATION_NAME.MoveAction,
    OPERATION_NAME.CreateAction,
    OPERATION_NAME.FetchAction,
    OPERATION_NAME.DuplicateAction,
  ]),
  NOTE: new Set([
    OPERATION_NAME.UpdateNote,
    OPERATION_NAME.ConvertNoteToAction,
    OPERATION_NAME.ConvertNoteToOutcome,
    OPERATION_NAME.MoveNote,
    OPERATION_NAME.FetchUserWorkspaceNote,
  ]),
  WORKSPACE: new Set([
    OPERATION_NAME.FetchAndFilterUserWorkspacesWithEntities,
    OPERATION_NAME.FilterUserWorkspaces,
    OPERATION_NAME.FetchUserWorkspacesWithInvitedCount,
  ]),
  USER: new Set([
    OPERATION_NAME.FetchUserByEmail,
    OPERATION_NAME.UpdateFreeSubscriptionExpirationByEmail,
    OPERATION_NAME.UpdateCoursesAvailableList,
    OPERATION_NAME.AddNewUser,
    OPERATION_NAME.UpdateUserAsAdmin,
    OPERATION_NAME.ResetUserCurriculumByEmail,
  ]),
  CORPORATE_ACCOUNT: new Set([
    OPERATION_NAME.CreateCorporateAccount,
    OPERATION_NAME.UpdateCorporateAccount,
    OPERATION_NAME.SwitchSubscriptionToSelfGuidedFreeByCorporateAccount,
  ]),
};

