import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import theme from '../../../core/styles/styled-components/theme';
import CourseCard from '../CourseCard/CourseCard';
import { useQuery } from '@apollo/client';
import { FETCH_COURSES_SETTINGS } from '../../../graphql/queries';
import TipsContent from '../TipsContent/TipsContent';
import {
  COURSES_STATE,
  CoursesFields,
  CoursesTitle,
} from '../../../apollo/stateFields/courses/coursesFields';
import { coursesMutation } from '../../../apollo/stateFields/courses';

const StyledModal = styled(Modal)`
  margin: 0;
  padding: 0;

  @media ${theme.device.tablet.max} {
    top: 0;
    height: 100vh;
  }

  && {
    @media ${theme.device.mobile.max} {
      height: 100vh;
      width: 100% !important;
      left: 0 !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      max-width: 100%;
    }
  }

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    box-shadow: 0px 1px 32px rgba(32, 38, 53, 0.2);
    border-radius: 3px 3px 0px 0px;
    height: 100%;
    width: 420px;
    left: 24px;
    top: -64px;

    @media ${theme.device.tablet.max} {
      height: 100%;
      border-radius: 0;
      left: 0;
      top: 0;
    }

    @media ${theme.device.mobile.max} {
      height: 100%;
      width: 100%;
      border-radius: 0;
      left: 0;
      top: 0;
    }
  }

  .ant-modal-body {
    height: 100%;
    padding: 16px 8px 16px 16px;

    @media ${theme.device.mobile.max} {
      padding: 16px;
    }
  }
`;
const StyledWrapper = styled.div`
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 8px 0 0;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }

  @media ${theme.device.tablet.max} {
    max-height: calc(100vh - 40px);
  }

  @media ${theme.device.mobile.max} {
    max-height: calc(100vh - 40px);
  }
`;
const StyledTitle = styled.h3`
  margin: 0 0 16px 0;
  padding: 8px;

  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
`;
const StyledSubTitle = styled.div`
  margin: 0 0 24px 0;
  padding: 0 8px 8px;
  max-width: 340px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`;

const VideoPopup = React.memo(() => {
  const { data: coursesData } = useQuery(COURSES_STATE);
  const {
    firstCourseTitle,
    secondCourseTitle,
    tipsSection,
    showCoursesPopup,
    tutorialStep,
    activeVideo,
  }: CoursesFields = coursesData?.courses;
  const { setShowCoursesPopup, setFirstCourseTitle, setSecondCourseTitle } = coursesMutation;
  const wrapperRef = useRef<any>(null);
  const [zIndex, setZIndex] = useState(1000);
  const { data } = useQuery(FETCH_COURSES_SETTINGS);
  const courses = data?.fetchUserSettings.coursesConsts;

  useEffect(() => {
    if (courses) {
      if (courses.Foundations && courses.MasteringPersonalEffectiveness) {
        setFirstCourseTitle(CoursesTitle.Foundations);
        setSecondCourseTitle(CoursesTitle.MasteringPersonalEffectiveness);
      } else if (courses.Fundamentals && courses.RemoteWorkProfessional) {
        setFirstCourseTitle(CoursesTitle.Fundamentals);
        setSecondCourseTitle(CoursesTitle.RemoteWorkProfessional);
      } else if (courses.FundamentalsManager && courses.RemoteWorkProfessionalManager) {
        setFirstCourseTitle(CoursesTitle.FundamentalsManager);
        setSecondCourseTitle(CoursesTitle.RemoteWorkProfessionalManager);
      }
    }
  }, [courses]);

  useEffect(() => {
    if (activeVideo) {
      setShowCoursesPopup(false);
    }
  }, [activeVideo]);

  useEffect(() => {
    if (tutorialStep > -1 && tutorialStep < 2) {
      setZIndex(3000);
    } else {
      setZIndex(1000);
    }
  }, [tutorialStep]);

  useEffect(() => {
    tipsSection && wrapperRef?.current?.scrollIntoView();
  }, [tipsSection]);

  return (
    <>
      <StyledModal
        getContainer={() => document.body}
        visible={showCoursesPopup}
        onCancel={() => {
          setShowCoursesPopup(false);
        }}
        footer={null}
        mask={false}
        closable={true}
        zIndex={zIndex}
      >
        <StyledWrapper>
          {tipsSection ? (
            <div ref={wrapperRef}>
              <TipsContent sectionName={tipsSection} />
            </div>
          ) : (
            <>
              <StyledTitle>Skills Library</StyledTitle>
              <StyledSubTitle>
                Learn/Do experiences that embed skills for the anywhere/anytime workplace.
              </StyledSubTitle>
              {courses?.[firstCourseTitle] && (
                <CourseCard index={0} course={courses[firstCourseTitle]} title={firstCourseTitle} />
              )}
              {courses?.[secondCourseTitle] && (
                <CourseCard
                  index={1}
                  course={courses?.[secondCourseTitle]}
                  title={secondCourseTitle}
                />
              )}
            </>
          )}
        </StyledWrapper>
      </StyledModal>
      {/*<VideoTutorialPopup />*/}
    </>
  );
});

export default VideoPopup;

