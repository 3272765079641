import { manageWorkspacesInitialValue, manageWorkspacesVar } from './manageWorkspacesField';
import { Workspace } from '../../../core/types/workspace';
import { AcceptInviteResult } from '__generated__/graphql';

const resetManageWorkspace = () => {
  const value = {
    ...manageWorkspacesInitialValue,
  };
  manageWorkspacesVar(value);
};

const setSharedWorkspace = (payload: null | Workspace) => {
  const value = {
    ...manageWorkspacesVar(),
    workspaceToShare: payload,
  };
  manageWorkspacesVar(value);
};

const setLeaveWorkspace = (payload: null | Workspace) => {
  const value = {
    ...manageWorkspacesVar(),
    workspaceToLeave: payload,
  };
  manageWorkspacesVar(value);
};

const setUserToRemove = (payload: null | AcceptInviteResult) => {
  const value = {
    ...manageWorkspacesVar(),
    userToRemove: payload,
  };
  manageWorkspacesVar(value);
};

const toggleManageWorkspacesModal = (payload?: boolean) => {
  let value = {
    ...manageWorkspacesVar(),
    isManageWorkspacesModalVisible: !manageWorkspacesVar().isManageWorkspacesModalVisible,
  };

  if (payload !== undefined) {
    value.isManageWorkspacesModalVisible = payload;
  }

  manageWorkspacesVar(value);
};

const setEditedWorkspace = (payload: null | number) => {
  const value = {
    ...manageWorkspacesVar(),
    editedWorkspaceId: payload,
  };
  manageWorkspacesVar(value);
};

export {
  setSharedWorkspace,
  setLeaveWorkspace,
  setUserToRemove,
  toggleManageWorkspacesModal,
  setEditedWorkspace,
  resetManageWorkspace,
};

