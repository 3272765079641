import { gql } from '__generated__/gql';
import '../../../../../../features/actions/graphql/fragments';
import '../../../../../../features/outcomes/graphql/fragments';

const SEARCH_ENTITIES_STRING = /* GraphQL */ `
  query SearchEntities($query: String = "", $offset: Int, $limit: Int) {
    searchUserWorkspacesEntities(query: $query, offset: $offset, limit: $limit) {
      note {
        data {
          ...noteFields
        }
        count
      }
      action {
        data {
          ...actionFields
        }
        count
      }
      outcome {
        data {
          ...outcomeFields
        }
        count
      }
    }
  }
`;

export const SEARCH_ENTITIES = gql(SEARCH_ENTITIES_STRING);

