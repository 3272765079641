import { Modal } from 'antd';
import { Button, SubmitButton } from 'core/components/common';
import { theme } from 'core/styles/styled-components';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  margin: auto;
  padding: 0;
  z-index: 2500;
  max-width: 480px;
  top: 20%;

  @media ${theme.device.mobile.max} {
    max-width: 360px;
  }

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    border-radius: 3px;
    height: auto;
  }

  .ant-modal-body {
    position: relative;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${theme.device.mobile.max} {
    height: 100%;
  }
`;
export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 24px;
`;
export const StyledInputContainer = styled.div``;
export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #202635;
  font-weight: 600;
`;
export const StyledButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;

  @media ${theme.device.mobile.max} {
    margin-top: auto;
  }
`;

export const StyledButton = styled(Button)`
  height: 32px;
  font-size: 14px;
`;

export const StyledSubmitButton = styled(SubmitButton)`
  height: 32px;
  font-size: 14px;
`;

export const StyledCancelButton = styled(StyledButton)`
  background-color: transparent;
  color: #202635;
  margin-left: 10px;

  @media ${theme.device.mobile.max} {
    border: 1px solid var(--color-dark-blue);
  }
`;

