import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import theme from '../../../../core/styles/styled-components/theme';
import { StyledButton } from '../CompleteOutcome/StyledComponents';
import DefaultWorkSpaceSelector from './DefaultWorkSpaceSelector';
import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
  ERROR_STATE,
  ErrorValue,
} from '../../../../apollo/stateFields/error/errorFields';
import { useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../graphql/queries';
import { NOT_ASSIGNED_WORKSPACE_NAME } from '../../../../core/constants/others';
import { Permission } from '../../../../core/types/workspace';
import { FETCH_ALL_NOTES, FETCH_FOCUS_TIMER } from '../../../actions/graphql/queries';
import { errorMutation } from '../../../../apollo/stateFields/error';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import { boardEditedEntityMutation } from '../../../../apollo/stateFields/boardEditedEntity';
import { coursesMutation } from '../../../../apollo/stateFields/courses';
import { manageWorkspacesMutation } from '../../../../apollo/stateFields/manageWorkspaces';
import { searchBarMutation } from '../../../../apollo/stateFields/searchBar';
import { isAdminOperationByEntity } from 'apollo/utils';
import devConsole from 'core/utils/devConsole';

const StyledModal = styled(Modal)`
  && {
    .ant-modal-content {
      max-height: 480px;
      height: 100%;
      width: 100%;
      margin: auto;
      background-color: #eff0f2;
      border-radius: 3px;
    }

    .ant-modal-body {
      padding: 41px 24px 32px 24px;
      height: 100%;
    }

    @media ${theme.device.tablet.min} {
      .ant-modal-content {
        width: 480px;
      }
    }

    @media ${theme.device.mobile.max} {
      .ant-modal-content {
        width: calc(100% - 32px);
        margin: 0 auto;
      }
    }
  }
`;

const StyledModalContent = styled.div`
  text-align: center;
`;

const StyledTitleModal = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
`;

const StyledDescriptionModal = styled.p`
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  margin: 0;
`;

const StyledSubmitButton = styled(StyledButton)`
  margin-top: 24px;
  white-space: nowrap;
`;

const SharedEntityInfoModal = React.memo(() => {
  const { data } = useQuery(ERROR_STATE);
  const { errorMessage, errorVariables, errorWorkspaces, entityType }: ErrorValue = data?.error;
  const { resetError } = errorMutation;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId, startDefaultWorkspaceId }: UserSettingsValue =
    userSettingsData?.userSettingsField;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { toggleFilterInputWorkspace, removeFilterInputWorkspace } = filterMutation;
  const { toggleManageWorkspacesModal } = manageWorkspacesMutation;
  const { setShowCoursesPopup } = coursesMutation;
  const { setSharedWorkspace } = manageWorkspacesMutation;
  const { closeAllEditModals } = boardEditedEntityMutation;
  const { toggleSearchBar } = searchBarMutation;
  const [showModal, setShowModal] = useState(false);
  const [errorType, setErrorType] = useState(1);
  const [workspaceName, setWorkspaceName] = useState<string | undefined>('this');

  const { refetch: refetchFocusTimer } = useQuery(FETCH_FOCUS_TIMER);
  const { data: workspacesResponse, refetch } = useQuery(FETCH_USER_WORKSPACES_WITH_INVITED_COUNT);
  const { refetch: refetchNotes } = useQuery(FETCH_ALL_NOTES);
  const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];
  const filteredWorkspaces = useMemo(() => {
    return [...workspaces]
      .filter((w) => w.name !== NOT_ASSIGNED_WORKSPACE_NAME)
      .filter((w) => w.permission !== Permission.Viewer)
      .filter((w) => !errorWorkspaces?.some((errorWorkspace) => errorWorkspace.id === w.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [workspaces]);

  const allFilteredWorkspaces = useMemo(() => {
    return [...workspaces]
      .filter((w) => w.name !== NOT_ASSIGNED_WORKSPACE_NAME)
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [workspaces]);

  const isRemovedFromDefault = errorWorkspaces?.some(
    (errorWorkspace) => +errorWorkspace.id === defaultWorkspaceId,
  );
  const isRemovedFromFilter = errorWorkspaces?.some((errorWorkspace) =>
    filterInput?.some((filter) => filter.workspaceId === +errorWorkspace.id),
  );

  const checkWorkspaceInFilter = (id: number) => {
    return !!allFilteredWorkspaces.find((w) => w.id === id);
  };

  const handleClose = (defaultId?: number) => {
    let innerFilterInput = filterInput.slice().filter((input) => {
      return !errorWorkspaces?.some((errWorkspace) => +errWorkspace.id === input.workspaceId);
    });
    isRemovedFromFilter &&
      errorWorkspaces?.forEach((errorWorkspace) => {
        if (!checkWorkspaceInFilter(+errorWorkspace.id)) {
          removeFilterInputWorkspace(+errorWorkspace.id);
        }
      });

    if (defaultId && !filterInput.some((filter) => filter.workspaceId === defaultId)) {
      toggleFilterInputWorkspace(defaultId);
    } else {
      if (!filterInput.length || !innerFilterInput.length) {
        toggleFilterInputWorkspace(defaultId || defaultWorkspaceId);
      }
    }
    setShowModal(false);
    resetError();
  };

  const handleCancel = () => {
    if (errorType !== 1) {
      let innerFilterInput = filterInput.slice().filter((input) => {
        return !errorWorkspaces?.some((errWorkspace) => +errWorkspace.id === input.workspaceId);
      });
      isRemovedFromFilter &&
        errorWorkspaces?.forEach((errorWorkspace) => {
          if (!checkWorkspaceInFilter(+errorWorkspace.id)) {
            removeFilterInputWorkspace(+errorWorkspace.id);
          }
        });
      if (!filterInput.length || !innerFilterInput.length) {
        toggleFilterInputWorkspace(defaultWorkspaceId);
      }
      setShowModal(false);
      resetError();
    }
  };

  const closeOtherModal = () => {
    closeAllEditModals();
    toggleSearchBar(false);
    setShowCoursesPopup(false);
    toggleManageWorkspacesModal(false);
    refetchFocusTimer();
  };

  const getContent = () => {
    switch (errorType) {
      case 1:
        return caseOneEl;
      case 2:
        return caseTwoEl;
      case 3:
        return caseThreeEl;
      case 4:
        return caseFourEl;
      default:
        return null;
    }
  };

  const caseOneEl = (
    <>
      <StyledTitleModal>{`You no longer have access to ${workspaceName} Space.`}</StyledTitleModal>
      <StyledDescriptionModal>Please select another Default Space.</StyledDescriptionModal>
      <DefaultWorkSpaceSelector filteredWorkspaces={filteredWorkspaces} onClose={handleClose} />
    </>
  );

  const caseTwoEl = (
    <>
      <StyledTitleModal>{`You no longer have access to ${workspaceName} Space.`}</StyledTitleModal>
      <StyledSubmitButton onClick={() => handleClose()}>OK</StyledSubmitButton>
    </>
  );

  const caseThreeEl = (
    <>
      <StyledTitleModal>{`This ${
        entityType === 'Note'
          ? 'Incoming note'
          : !entityType
          ? CUSTOM_ENTITY_TYPES.ACTION_OR_OUTCOME
          : entityType
      } has been moved to another Space.`}</StyledTitleModal>
      <StyledSubmitButton onClick={() => handleClose()}>OK</StyledSubmitButton>
    </>
  );

  const caseFourEl = (
    <>
      <StyledTitleModal>{`You no longer have access to make this update.`}</StyledTitleModal>
      <StyledDescriptionModal>{`This ${
        entityType === 'Note' ? 'Incoming note' : entityType
      } is archived.`}</StyledDescriptionModal>
      <StyledSubmitButton onClick={() => handleClose()}>OK</StyledSubmitButton>
    </>
  );

  useEffect(() => {
    if (errorWorkspaces && errorWorkspaces[0]?.name) {
      devConsole.info('Unexpected workspace:', errorWorkspaces);
      setWorkspaceName(errorWorkspaces[0].name);
    }
  }, [errorWorkspaces]);

  useEffect(() => {
    if (!errorMessage) {
      return;
    }
    devConsole.info('errorMessage - entityType', {
      errorMessage,
      entityType,
      errorWorkspaces,
      errorVariables,
      isRemovedFromDefault,
      isRemovedFromFilter,
    });
    if (isAdminOperationByEntity(entityType)) {
      return;
    }
    switch (errorMessage) {
      case CUSTOM_ERROR_MESSAGE.NO_RELATION:
        refetchNotes();
        setSharedWorkspace(null);
        closeOtherModal();
        refetch().then((res) => {
          const refetchedWorkspaces = res.data.fetchUserWorkspacesWithInvitedCount
            .filter((w) => w.name !== NOT_ASSIGNED_WORKSPACE_NAME)
            .filter((w) => w.permission !== Permission.Viewer)
            .filter((w) => !errorWorkspaces?.some((errorWorkspace) => errorWorkspace.id === w.id))
            .sort((a, b) => a.name.localeCompare(b.name));

          if (
            !!entityType &&
            !!errorVariables?.workspaceId &&
            !!errorVariables?.targetWorkspaceId &&
            refetchedWorkspaces.some((w) => w.id === errorVariables.workspaceId) &&
            !refetchedWorkspaces.some((w) => w.id === errorVariables.targetWorkspaceId)
          ) {
            if (isRemovedFromDefault) {
              setErrorType(1);
            } else if (isRemovedFromFilter) {
              setErrorType(2);
            } else {
              setErrorType(2);
            }
            setShowModal(true);
          } else if (
            !!entityType &&
            !!errorVariables?.workspaceId &&
            res.data.fetchUserWorkspacesWithInvitedCount.some(
              (w) => w.id === errorVariables.workspaceId,
            )
          ) {
            setErrorType(3);
            setShowModal(true);
            return;
          } else if (
            refetchedWorkspaces.some(
              (w) =>
                w.id === errorVariables?.workspaceId &&
                !errorVariables?.targetWorkspaceId &&
                entityType === CUSTOM_ENTITY_TYPES.ACTION_OR_OUTCOME,
            )
          ) {
            setErrorType(3);
            setShowModal(true);
            return;
          }
          // else if (
          //   !!errorOperation &&
          //   (errorOperation === OPERATION_NAME.FetchOutcomes ||
          //     errorOperation === OPERATION_NAME.FetchUserWorkspaceTags ||
          //     errorOperation === OPERATION_NAME.FetchActions) &&
          //   !!errorVariables?.workspaceId &&
          //   !res.data.fetchUserWorkspacesWithInvitedCount.some(
          //     (w) => w.id === errorVariables.workspaceId,
          //   )
          // ) {
          //   setType(3);
          //   setShowModal(true);
          //   return;
          // }
          else if (refetchedWorkspaces.length && isRemovedFromDefault) {
            setErrorType(1);
            setShowModal(true);
            return;
          } else if (refetchedWorkspaces.length && isRemovedFromFilter) {
            setErrorType(2);
            setShowModal(true);
            return;
          } else if (refetchedWorkspaces.length && entityType === CUSTOM_ENTITY_TYPES.WORKSPACE) {
            errorVariables?.filters?.forEach((errorWorkspace) => {
              if (!checkWorkspaceInFilter(+errorWorkspace.workspaceId)) {
                removeFilterInputWorkspace(+errorWorkspace.workspaceId);
              }
              if (!refetchedWorkspaces.some((w) => w.id === defaultWorkspaceId)) {
                const newDefaultId = refetchedWorkspaces.some(
                  (w) => w.id === startDefaultWorkspaceId,
                )
                  ? startDefaultWorkspaceId
                  : refetchedWorkspaces[0].id;
                handleClose(newDefaultId);
              }
            });
            return;
          } else if (refetchedWorkspaces.length) {
            setErrorType(2);
            setShowModal(true);
          }
          return;
        });
        return;

      case CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED:
        closeOtherModal();
        setErrorType(3);
        setShowModal(true);
        return;

      case CUSTOM_ERROR_MESSAGE.INVALID_METHOD_INPUT:
        closeOtherModal();
        setErrorType(3);
        setShowModal(true);
        return;

      case CUSTOM_ERROR_MESSAGE.BAD_USER_INPUT:
        closeOtherModal();
        setErrorType(3);
        setShowModal(true);
        return;

      case CUSTOM_ERROR_MESSAGE.NOT_ALLOWED_ON_ARCHIVED:
        closeOtherModal();
        setErrorType(4);
        setShowModal(true);
        return;

      default:
        devConsole.info('Unknown error message', { errorMessage, entityType });
        return;
    }
  }, [errorMessage, entityType]);

  if (!showModal) {
    return null;
  }

  return (
    <StyledModal
      centered
      visible={showModal}
      getContainer={false}
      closable={false}
      onCancel={handleCancel}
      footer={null}
    >
      <StyledModalContent>{getContent()}</StyledModalContent>
    </StyledModal>
  );
});

export default SharedEntityInfoModal;

