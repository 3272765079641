import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Select, Tag } from 'antd';
import styled from 'styled-components';
import { checkEmail } from '../utils';
import { SharedPeopleSelectorProps } from '../types';
import { StyledDefaultImage, StyledImage } from '../StyledComponents/StyledComponents';
import { useQuery } from '@apollo/client';
import { SEARCH_USER_INVITES } from '../../../graphql/queries';
import { theme } from '../../../../../core/styles/styled-components';

const { Option } = Select;

const StyledSelectWrapper = styled.div`
  position: relative;

  @media ${theme.device.tablet.min} {
    width: 262px;
  }
`;

const StyledSelect = styled(Select)<{ error: boolean }>`
  width: 100%;
  border-radius: 2px;
  ${(props) => (props.error ? `border: 1px solid var(--color-red);` : '')}

  .ant-select-selector {
    max-height: 100px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #a4a6ad;
      border-radius: 10px;
    }
  }
`;

const StyledOptionWrapper = styled.div<{ textToHighlight: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgb(196, 196, 196, 0.5);
`;

const StyledUserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledName = styled.div`
  max-width: 135px;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-dark-blue);
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${theme.device.tablet.min} {
    max-width: 175px;
  }
`;

const StyledEmail = styled.div`
  max-width: 135px;
  font-weight: 400;
  font-size: 12px;
  color: var(--color-dark-blue);
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${theme.device.tablet.min} {
    max-width: 175px;
  }
`;

const StyledTag = styled(Tag)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-dark-blue);
  padding: 5px 6px;
  margin-bottom: 2px;
  margin-right: 3px;

  background-color: rgb(196, 196, 196, 0.35);
  border-radius: 4px;
  border: transparent;
`;

const StyledTagText = styled.p`
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const StyledErrorLabel = styled.p`
  color: var(--color-red);
  margin: 0;
`;

const StyledErrorMessage = styled.p`
  position: absolute;
  left: 0;
  bottom: -12px;
  font-weight: 400;
  font-size: 9px;
  color: rgba(186, 0, 0, 1);
`;

const SharedPeopleSelector = ({
  className,
  addedUsers = [],
  disabled,
  onEmailSelect,
  initialValue = [],
  validate,
  setIsSearchValid,
}: SharedPeopleSelectorProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>(initialValue);
  const [users, setUsers] = useState<any[]>([]);
  const [emailError, setEmailError] = useState<number>(0);
  const [existedError, setExistedError] = useState<number>(0);

  const { data: searchUserResponce } = useQuery(SEARCH_USER_INVITES, {
    variables: {
      query: '',
    },
  });

  const handleChange = useCallback(
    (value: any) => {
      setSelectedUsers(value as string[]);
      setSearchValue('');
      onEmailSelect(value);
    },
    [selectedUsers],
  );

  const handleBlur = useCallback(() => {
    const email = searchValue;
    if (email && email.length) {
      !users.some((user) => user.email === email) &&
        setUsers((prev: any) => [...prev, { email, name: email }]);
      setSelectedUsers((prev) => [...prev, email]);
      setSearchValue('');
      onEmailSelect([...selectedUsers, email]);
    }
  }, [selectedUsers, searchValue]);

  const handleEnterPress = useCallback(
    (event: any) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        const email = event.target.value.toLowerCase().trim() as string;
        let isValid: boolean = false;
        if (email && email.length) {
          isValid = checkEmail(email);

          !users.some((user) => user.email === email) &&
            setUsers((prev: any) => [...prev, { email, name: email }]);
          setSelectedUsers((prev) => [...prev, email]);
          setSearchValue('');
          if (!isValid) {
            event.target.blur();
          }
          onEmailSelect([...selectedUsers, email]);
        }
      }
    },
    [selectedUsers],
  );

  const children: any[] = useMemo(() => {
    return users.map((user: any, index) => {
      return (
        <Option key={`${user?.email}${index}`} value={user?.email}>
          <StyledOptionWrapper textToHighlight={user?.email?.toLowerCase()}>
            {user?.photoURL ? (
              <StyledImage preview={false} src={user?.photoURL || ''} alt="avatar" />
            ) : (
              <StyledDefaultImage>
                {user?.name
                  ? user?.name?.substring(0, 1).toUpperCase()
                  : user.email?.substring(0, 1).toUpperCase()}
              </StyledDefaultImage>
            )}
            <StyledUserInfo>
              <StyledName>{user.name}</StyledName>
              <StyledEmail>
                <Highlighter
                  textToHighlight={user?.email?.toLowerCase()}
                  searchWords={[searchValue]}
                  highlightStyle={{
                    padding: 0,
                    backgroundColor: 'rgba(255,194,39,0.3)',
                    borderRadius: '2px',
                  }}
                />
              </StyledEmail>
            </StyledUserInfo>
          </StyledOptionWrapper>
        </Option>
      );
    });
  }, [searchValue, users, searchUserResponce]);

  const tagRender = (props: any, onTagClick?: (query: string) => void) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const tagLabel = users?.find((user: any) => user.email === value)?.email;
    return (
      <StyledTag
        onMouseDown={() => {
          // onPreventMouseDown()
        }}
        closable={closable}
        onClose={onClose}
        onClick={async () => {
          await onClose();
          onTagClick && onTagClick(value as string);
        }}
      >
        <StyledTagText>
          {tagLabel && !checkEmail(tagLabel) ? (
            <StyledErrorLabel>{tagLabel}</StyledErrorLabel>
          ) : !emailError &&
            !!existedError &&
            !!addedUsers?.some((user) => user.email === value) ? (
            <StyledErrorLabel>{tagLabel}</StyledErrorLabel>
          ) : (
            tagLabel
          )}
        </StyledTagText>
      </StyledTag>
    );
  };

  useEffect(() => {
    setUsers((old) => {
      return searchUserResponce?.searchUserInvites?.length
        ? [
            ...old,
            ...searchUserResponce?.searchUserInvites.filter(
              (user) => !old.some((oldUser) => user.email === oldUser.email),
            ),
          ]
        : old;
    });
  }, [searchUserResponce]);

  useEffect(() => {
    const emailErrorsCount = selectedUsers.filter((email) => !checkEmail(email)).length;
    const existedEmailsCount = selectedUsers.filter((email) =>
      addedUsers?.some((user) => user.email === email),
    ).length;
    if (emailErrorsCount) {
      setEmailError(emailErrorsCount);
    } else {
      setEmailError(0);
    }
    if (existedEmailsCount) {
      setExistedError(existedEmailsCount);
    } else {
      setExistedError(0);
    }
  }, [selectedUsers, addedUsers, users]);

  useEffect(() => {
    validate(!emailError && !existedError && !!selectedUsers.length);
  }, [emailError, existedError, selectedUsers]);

  useEffect(() => {
    if (searchValue && searchValue.length && checkEmail(searchValue)) {
      setIsSearchValid(true);
      return;
    }
    setIsSearchValid(false);
  }, [searchValue]);

  useEffect(() => {
    setSelectedUsers(initialValue);
  }, [initialValue]);

  return (
    <StyledSelectWrapper className={className}>
      <StyledSelect
        error={!!emailError || !!existedError}
        mode="multiple"
        notFoundContent={null}
        disabled={disabled}
        value={selectedUsers}
        searchValue={searchValue}
        tagRender={(props) => {
          return tagRender(props, setSearchValue);
        }}
        filterOption={(input, option) =>
          option?.children.props.textToHighlight.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Add emails"
        onChange={handleChange}
        onSearch={(value) => {
          setSearchValue(value);
        }}
        onBlur={handleBlur}
        onInputKeyDown={handleEnterPress}
      >
        {children}
      </StyledSelect>
      {emailError >= 2 && <StyledErrorMessage>Emails are not valid</StyledErrorMessage>}
      {emailError === 1 && <StyledErrorMessage>Email is not valid</StyledErrorMessage>}
      {existedError >= 2 && !emailError && (
        <StyledErrorMessage>These emails have already been added to this Space</StyledErrorMessage>
      )}
      {existedError === 1 && !emailError && (
        <StyledErrorMessage>This email has already been added to this Space</StyledErrorMessage>
      )}
    </StyledSelectWrapper>
  );
};

export default SharedPeopleSelector;

