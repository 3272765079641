import { useEffect, useState } from 'react';
import { StyledTabContainer } from '../StyledComponents';
import { Collapse } from 'antd';
import { toast } from 'react-toastify';
import UpsertUsersCsv from '../UpsertUsersCsv/UpsertUsersCsv';
import DeleteUsersCsv from '../DeleteUsersCsv/DeleteUsersCsv';
import ExportUsersV3 from '../ExportUsers/ExportUsers';
const { Panel } = Collapse;

const ImportExportUsers = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Successful');
      setIsSuccess(false);
    }
  }, [isSuccess]);

  return (
    <StyledTabContainer>
      <Collapse>
        <Panel header="Add/Update users" key="1">
          <UpsertUsersCsv setIsSuccess={setIsSuccess} setErrorMessage={setErrorMessage} />
        </Panel>
        <Panel header="Delete users" key="2">
          <DeleteUsersCsv setIsSuccess={setIsSuccess} setErrorMessage={setErrorMessage} />
        </Panel>
        <Panel header="Export Users" key="5">
          <ExportUsersV3 onError={setErrorMessage} onSuccess={setIsSuccess} />
        </Panel>
      </Collapse>
    </StyledTabContainer>
  );
};

export default ImportExportUsers;

