import { useCallback, useEffect, useMemo, useState } from 'react';
import { StyledDescription } from '../../../../pages/AdminPanel/StyledComponents';
import { Popover, Row, Space, Table, TablePaginationConfig } from 'antd';
import { Button } from 'core/components/common';
import FilterInfo from './FilterInfo';
import DynamicColTransfer from './DynamicColTransfer';
import { usePagination } from '../../../hooks/usePagination';
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import { keyBy } from 'lodash-es';

export interface IDynamicTableProps<T extends object> {
  onKeysUpdate?: (newTargetKeys: string[]) => void;
  onFiltersUpdate?: (newFilters: Record<string, FilterValue | null>) => void;
  onSorterUpdate?: (sorterResults: SorterResult<T>) => void;
  onPaginationUpdate?: (pagination: TablePaginationConfig) => void;
  onRefresh?: () => void;
  baseColumns: ColumnsType<T>;
  filterInfoTransformFnByKeys?: Record<string, ColumnsType<T>[number]['render']>;
  initialColumnKeys?: string[];
  initialPagination?: TablePaginationConfig;
  dataSource: T[];
  disabledKeys?: string[];
  total?: number;
  loading?: boolean;
  rowKey: string;
}

const DynamicTable = <T extends object>({
  baseColumns,
  onFiltersUpdate,
  onKeysUpdate,
  onPaginationUpdate,
  filterInfoTransformFnByKeys,
  initialColumnKeys,
  initialPagination,
  onSorterUpdate,
  dataSource,
  total,
  disabledKeys,
  loading,
  onRefresh,
  rowKey,
}: IDynamicTableProps<T>) => {
  const [pagination, { setPagination }] = usePagination(initialPagination ?? {});
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({});
  const [sorting, setSorting] = useState<SorterResult<T>>({});
  const [allColumns, setAllColumns] = useState(baseColumns);
  const [columnsKeys, setColumnKeys] = useState(initialColumnKeys ?? []);

  const targetKeysSet = useMemo(() => new Set(columnsKeys), [columnsKeys]);
  const getInitialCurrentColumns = useCallback(() => {
    if (!initialColumnKeys) return baseColumns;
    return baseColumns.filter(({ key }) => targetKeysSet.has(key as string));
  }, [baseColumns, targetKeysSet]);

  const [currentColumns, setCurrentColumns] = useState(getInitialCurrentColumns());

  const baseColumnsByKey = useMemo(() => keyBy(baseColumns, 'key'), [baseColumns]) as Record<
    string,
    ColumnsType<T>[number]
  >;
  const filtersProp = useMemo(
    () =>
      Object.entries(filters).map(([key, values]) => ({
        values,
        render: (filterInfoTransformFnByKeys ?? {})[key],
        col: baseColumnsByKey[key],
      })),
    [baseColumnsByKey, filterInfoTransformFnByKeys, filters],
  );

  useEffect(() => {
    setCurrentColumns(allColumns.filter(({ key }) => targetKeysSet.has(key as string)));
  }, [allColumns, targetKeysSet]);

  useEffect(() => {
    onFiltersUpdate && onFiltersUpdate(filters);
  }, [filters, onFiltersUpdate]);
  useEffect(() => {
    setPagination((old) => ({ ...old, total }));
  }, [total, setPagination]);
  useEffect(() => {
    onSorterUpdate && onSorterUpdate(sorting);
  }, [sorting, onSorterUpdate]);
  useEffect(() => {
    onPaginationUpdate && onPaginationUpdate(pagination);
  }, [pagination, onPaginationUpdate]);
  useEffect(() => {
    onKeysUpdate && onKeysUpdate(columnsKeys);
  }, [columnsKeys, onKeysUpdate]);

  const handleFiltersUpdate = useCallback((filters: Record<string, FilterValue | null>) => {
    setFilters((oldFilters) => {
      const newFilters = { ...oldFilters, ...filters };
      setAllColumns((old) =>
        old.map((col) => {
          const { key } = col;
          const filteredValue = newFilters[key!] ?? null;
          return { ...col, filteredValue };
        }),
      );
      return newFilters;
    });
  }, []);
  const handleSortUpdate = useCallback((sorterResult: SorterResult<T>) => {
    setSorting(sorterResult);
    setAllColumns((old) =>
      old.map((col) => {
        const { key } = col;
        const { columnKey, order } = sorterResult;
        return { ...col, sortOrder: columnKey === key ? order : null };
      }),
    );
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters({});
    setAllColumns((old) => old.map((col) => ({ ...col, filteredValue: null })));
  }, []);
  const handleResetPagination = () => {
    setPagination({ ...(initialPagination ?? {}), total });
  };
  const handleResetOrder = useCallback(() => {
    setSorting({});
    setAllColumns((old) => old.map((col) => ({ ...col, sortOrder: null })));
  }, []);
  const handleResetColumns = useCallback(() => {
    setColumnKeys([...(initialColumnKeys ?? [])]);
  }, [initialColumnKeys]);
  const handleReset = useCallback(() => {
    handleResetFilters();
    handleResetPagination();
    handleResetOrder();
    handleResetColumns();
    setAllColumns(baseColumns);
    setCurrentColumns(getInitialCurrentColumns());
  }, [handleResetColumns, handleResetFilters, handleResetOrder, handleResetPagination]);

  return (
    <div>
      <StyledDescription>Export users</StyledDescription>
      <Row>
        <Popover
          content={
            <DynamicColTransfer
              baseTableColumns={allColumns}
              initialColumnKeys={initialColumnKeys}
              disabledKeys={disabledKeys}
              setTableColumns={setCurrentColumns}
              columnKeys={columnsKeys}
              onKeysUpdate={setColumnKeys}
            />
          }
          title="Add/Remove columns"
          trigger="click"
        >
          <Button isDisabled={loading} disabled={loading}>
            Add/Remove columns
          </Button>
        </Popover>
      </Row>
      <Row justify="end" gutter={[16, 16]} style={{ padding: '0 8px 8px' }}>
        <Space>
          <Button isDisabled={loading} disabled={loading} onClick={handleResetColumns}>
            Reset Columns
          </Button>
          <Button isDisabled={loading} disabled={loading} onClick={handleResetFilters}>
            Reset Filters
          </Button>
          <Button isDisabled={loading} disabled={loading} onClick={handleResetPagination}>
            Reset Pagination
          </Button>
          <Button isDisabled={loading} disabled={loading} onClick={handleResetOrder}>
            Reset Sorter
          </Button>
          <Button isDisabled={loading} disabled={loading} onClick={handleReset}>
            Reset Table
          </Button>
          {onRefresh && (
            <Button isLoading={loading} onClick={onRefresh}>
              Refresh
            </Button>
          )}
        </Space>
      </Row>
      <Table
        columns={currentColumns}
        loading={loading}
        rowKey={rowKey}
        dataSource={dataSource}
        style={{ minHeight: 600, overflowX: 'scroll' }}
        onChange={(pagination, filters, sorting, { action }) => {
          switch (action) {
            case 'filter':
              handleFiltersUpdate(filters);
              break;
            case 'paginate':
              setPagination({ ...initialPagination, ...pagination });
              break;
            case 'sort':
              if (!Array.isArray(sorting)) {
                handleSortUpdate(sorting.order ? sorting : {});
              }
          }
        }}
        pagination={pagination}
      />
      <FilterInfo filters={filtersProp} />
      <Row justify="center">
        Sorting by:{' '}
        {sorting.order && `${baseColumnsByKey[sorting.columnKey!].title} ${sorting.order}ing`}
      </Row>
    </div>
  );
};

export default DynamicTable;

