import styled from 'styled-components';
import { theme } from '../../core/styles/styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.div`
  background-color: var(--color-main-grey);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const PageWrapper = styled.div`
  padding: 0 0 20px 20px;
  @media ${theme.device.mobile.min} {
    padding-top: 90px;
  }
  @media ${theme.device.desktop.min} {
    padding-top: 20px;
  }
`;
export const StyledTabsContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
`;
export const StyledTitleWrapper = styled.div`
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledTitle = styled.span`
  font-size: 1.77rem;
  font-weight: 700;
`;
export const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: var(--color-dark-blue);
  font-size: 1rem;
`;

export const StyledTabContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 180px);
  overflow: auto;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }

  .ant-input,
  textarea.ant-input {
    border: 1px solid var(--color-grey);
  }
`;

export const StyledDescription = styled.p`
  font-size: 16px;

  display: flex;
  flex-direction: column;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const StyledModalContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .ant-descriptions-item-container {
      align-items: center;
    }
    .ant-descriptions-item-content {
      align-items: center;
    }
    .ant-descriptions-item-label {
      color: var(--color-grey);
    }
  }
  width: 100%;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #202635;
  font-weight: 600;
`;

