import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFields';
import { boardEditedEntityMutation } from '../../../../../../apollo/stateFields/boardEditedEntity';
import styled from 'styled-components';
import { InputNumber, Modal, Radio, Select, Switch } from 'antd';
import { theme } from '../../../../../../core/styles/styled-components';
import { StyledSpace } from '../../../../../common/components/ColumnFilters/StyledComponents/StyledComponents';
import { FETCH_FOCUS_TIMER } from '../../../../graphql/queries';
import { ArrowDownIcon } from '../../../../../../core/icons';
import { Button } from '../../../../../../core/components/common';
import { millisecondsToHours } from 'date-fns';
import { UPDATE_FOCUS_TIMER_SETTINGS } from '../../../../../../graphql/mutations';
import { FETCH_FOCUS_TIMER_SETTINGS } from '../../../../../../graphql/queries';
import { ActionTimeFormat } from '../../types';
import { convertFromMs } from '../../utils';
import { FocusTimerType } from '__generated__/graphql';

const StyledModal = styled(Modal)<{ width: number }>`
  margin: 0;
  padding: 0;

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    border-radius: 3px;
    height: auto;
    width: ${(props) => `${props.width}px`};
  }

  .ant-modal-body {
    position: relative;
    height: 100%;
    padding: 16px;
  }
`;
const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.device.mobile.max} {
    height: 100%;
  }
`;
const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 24px;
`;
const StyledTitleAndInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledAntdFixContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledInputNumber = styled(InputNumber)`
  && {
    width: 44px;
    border-radius: 4px;
    input {
      padding: 0 3px;
      color: var(--color-dark-blue) !important;
    }
  }
`;
const StyledSelect = styled(Select)`
  && {
    background-color: transparent;
    margin: 0 0 0 8px;
    width: 60px;

    .ant-select-selector {
      width: 100%;
      padding: 0 !important;
    }
  }
`;
const StyledRadioWrapper = styled.div``;

export const StyledRadio = styled(Radio)`
  && {
    width: 100%;
  }
  margin-bottom: 4px;

  span.ant-radio + * {
    width: 100%;
    padding: 0 0 0 8px;
  }
`;

const StyledRadioTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
`;

const StyledSwitchWrapper = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 9px;
  line-height: 10px;
`;

const StyledSwitch = styled(Switch)<{ isChecked: boolean }>`
  && {
    margin: 0 6px 0 0;
    //background-color: var(--color-dark-blue);
    background-color: ${(props) =>
      `${props.isChecked ? 'var(--color-dark-blue)' : 'gba(32, 38, 53, 0.7)'}`};
  }
`;

const StyledRadioSubTitle = styled.div`
  font-weight: 400;
  font-size: 9px;
  line-height: 10px;
`;

const StyledButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  @media ${theme.device.mobile.max} {
    margin-top: auto;
    //justify-content: flex-start;
  }
`;

const StyledButton = styled(Button)`
  //border: 1px solid rgba(32, 38, 53, 0.4);
  height: 32px;
  font-size: 14px;
  width: 60%;

  @media ${theme.device.mobile.max} {
    //width: auto;
  }
`;

const StyledCancelButton = styled(StyledButton)`
  //border: 1px solid rgba(32, 38, 53, 0.4);
  background-color: transparent;
  color: #202635;
  width: 30%;

  @media ${theme.device.mobile.max} {
    border: 1px solid var(--color-dark-blue);
    //width: auto;
    //margin-left: 16px;
  }
`;

export { FocusTimerType } from '__generated__/graphql';

const FocusSettingsModal = ({
  actionId,
  position,
}: {
  actionId: number;
  position: { x: number; y: number; width: number };
}) => {
  const { data: settingsData } = useQuery(FETCH_FOCUS_TIMER_SETTINGS);
  const [updateSettings, { loading: settingsLoading }] = useMutation(UPDATE_FOCUS_TIMER_SETTINGS);
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { showFocusSettingsForActionId }: BoardEditedEntityValue =
    boardEditedData?.boardEditedEntity;
  const { setShowFocusSettingsForActionId } = boardEditedEntityMutation;
  const periodInitialValue = settingsData?.fetchUserSettings?.focusTimerType
    ? settingsData?.fetchUserSettings?.focusTimerType
    : FocusTimerType.Fixed;
  const soundInitialValue = settingsData?.fetchUserSettings?.focusTimerIsBipSound || false;
  const getFixedPeriodAndOptionsInitialValue = (isOptions?: boolean) => {
    let value;
    if (settingsData?.fetchUserSettings) {
      value = settingsData?.fetchUserSettings?.focusTimerFixedPeriod
        ? settingsData?.fetchUserSettings?.focusTimerFixedPeriod * 1000
        : 1.8e6;
    } else {
      value = settingsData?.fetchUserSettings?.focusTimerFixedPeriod
        ? settingsData?.fetchUserSettings?.focusTimerFixedPeriod * 1000
        : 1.8e6;
    }

    if (millisecondsToHours(value) >= 1) {
      return isOptions
        ? ActionTimeFormat.Hours
        : convertFromMs(value, ActionTimeFormat.Hours).toString();
    } else {
      return isOptions
        ? ActionTimeFormat.Minutes
        : convertFromMs(value, ActionTimeFormat.Minutes).toString();
    }
  };
  const fixedPeriodInitialValue = getFixedPeriodAndOptionsInitialValue();
  const [periodType, setPeriodType] = useState<FocusTimerType>(periodInitialValue);
  const [sound, setSound] = useState<boolean>(soundInitialValue);
  const [fixedInputValue, setFixedInputValue] = useState<string>(fixedPeriodInitialValue);
  const [periodOptions, setPeriodOptions] = useState<string>(
    getFixedPeriodAndOptionsInitialValue(true),
  );

  useEffect(() => {
    setPeriodType(periodInitialValue);
    setPeriodOptions(getFixedPeriodAndOptionsInitialValue(true));
    setSound(soundInitialValue);
    setFixedInputValue(fixedPeriodInitialValue);
  }, [settingsData?.fetchUserSettings]);

  if (!showFocusSettingsForActionId || showFocusSettingsForActionId !== actionId) {
    return null;
  }

  const onRadioChange = (e: any) => {
    setPeriodType(e.target.value as FocusTimerType);
  };

  const handleSubmit = async (e: any) => {
    let result;
    if (periodType === FocusTimerType.Open) {
      result = null;
    } else {
      if (periodOptions === ActionTimeFormat.Minutes) {
        result = (Number(fixedInputValue) * 60000).toFixed(2);
      } else {
        result = (Number(fixedInputValue) * 3.6e6).toFixed(2);
      }
    }

    await updateSettings({
      variables: {
        settings: {
          ...(result !== null && { focusTimerFixedPeriod: Math.round(Number(result) / 1000) }),
          focusTimerIsBipSound: periodType === FocusTimerType.Fixed ? sound : false,
          focusTimerType: periodType,
        },
      },
      refetchQueries: [FETCH_FOCUS_TIMER, FETCH_FOCUS_TIMER_SETTINGS],
    });
    setShowFocusSettingsForActionId(null);
  };

  const handleTimeFormatChange = (value: any) => {
    setPeriodOptions(value);
    if (value === ActionTimeFormat.Minutes) {
      if (Number(fixedInputValue) > 1440) {
        setFixedInputValue('1440');
      }
    } else if (value === ActionTimeFormat.Hours) {
      if (Number(fixedInputValue) > 24) {
        setFixedInputValue('24');
      }
    }
  };

  return (
    <StyledModal
      destroyOnClose
      getContainer={() => document.body}
      visible={showFocusSettingsForActionId === actionId}
      onCancel={() => {
        setPeriodType(periodInitialValue);
        setPeriodOptions(getFixedPeriodAndOptionsInitialValue(true));
        setSound(soundInitialValue);
        setFixedInputValue(fixedPeriodInitialValue);
        setShowFocusSettingsForActionId(null);
      }}
      footer={null}
      mask={false}
      closable={true}
      width={position.width}
      style={{
        left: position.x - position.width + 30,
        top: position.y,
      }}
    >
      <StyledContentWrapper>
        <StyledTitle>Focus Settings</StyledTitle>
        <StyledRadioWrapper>
          <Radio.Group onChange={onRadioChange} value={periodType} style={{ width: '100%' }}>
            <StyledSpace direction="vertical">
              <StyledRadio
                value={FocusTimerType.Fixed}
                key={`${FocusTimerType.Fixed}`}
                onClick={() => {}}
              >
                <StyledTitleAndInputWrapper>
                  <StyledRadioTitle>Fixed period</StyledRadioTitle>
                  <StyledAntdFixContainer
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <StyledInputNumber
                      disabled={periodType !== FocusTimerType.Fixed}
                      min={0}
                      max={periodOptions === ActionTimeFormat.Hours ? 24 : 1440}
                      value={fixedInputValue}
                      decimalSeparator={','}
                      onChange={(value: any) => {
                        const parsedValue = Number(value).toFixed(2);
                        setFixedInputValue(parsedValue);
                      }}
                    />
                    <StyledSelect
                      value={periodOptions}
                      size={'small'}
                      suffixIcon={<ArrowDownIcon />}
                      onChange={handleTimeFormatChange}
                      disabled={periodType !== FocusTimerType.Fixed}
                      bordered={false}
                      options={[
                        {
                          value: ActionTimeFormat.Minutes,
                          label: 'min',
                        },
                        {
                          value: ActionTimeFormat.Hours,
                          label: 'hrs',
                        },
                      ]}
                    />
                  </StyledAntdFixContainer>
                </StyledTitleAndInputWrapper>
                <StyledAntdFixContainer
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <StyledSwitchWrapper>
                    <StyledSwitch
                      disabled={periodType !== FocusTimerType.Fixed}
                      checked={sound}
                      size="small"
                      isChecked={sound}
                      onChange={() => {
                        setSound((old) => !old);
                      }}
                    />
                    Beep when the timer ends
                  </StyledSwitchWrapper>
                </StyledAntdFixContainer>
              </StyledRadio>
              <StyledRadio
                value={FocusTimerType.Open}
                key={`${FocusTimerType.Open}`}
                onClick={() => {}}
              >
                <StyledRadioTitle>Open Focus</StyledRadioTitle>
                <StyledRadioSubTitle>Time limit 24 hours</StyledRadioSubTitle>
              </StyledRadio>
            </StyledSpace>
          </Radio.Group>
        </StyledRadioWrapper>
        <StyledButtonContainer>
          <StyledButton
            isDisabled={periodType === FocusTimerType.Fixed && !Number(fixedInputValue)}
            isLoading={settingsLoading}
            onClick={handleSubmit}
          >
            Save
          </StyledButton>
          <StyledCancelButton
            onClick={() => {
              setPeriodType(periodInitialValue);
              setPeriodOptions(getFixedPeriodAndOptionsInitialValue(true));
              setSound(soundInitialValue);
              setFixedInputValue(fixedPeriodInitialValue);
              setShowFocusSettingsForActionId(null);
            }}
          >
            Cancel
          </StyledCancelButton>
        </StyledButtonContainer>
      </StyledContentWrapper>
    </StyledModal>
  );
};

export default FocusSettingsModal;

