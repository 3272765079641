import { gql } from '__generated__/gql';
import './fragments';

//Actions
const FETCH_ACTIONS_STRING = /* GraphQL */ `
  query FetchActions($workspaceId: Int = -1) {
    fetchUserWorkspaceActions(workspaceId: $workspaceId) {
      ...actionFields
    }
  }
`;

export const FETCH_ACTIONS = gql(FETCH_ACTIONS_STRING);

const FETCH_ACTION_STRING = /* GraphQL */ `
  query FetchAction($actionId: Int!, $workspaceId: Int!) {
    fetchUserWorkspaceAction(actionId: $actionId, workspaceId: $workspaceId) {
      ...actionFields
    }
  }
`;

export const FETCH_ACTION = gql(FETCH_ACTION_STRING);

//Notes
const FETCH_ALL_NOTES_STRING = /* GraphQL */ `
  query FetchAllNotes {
    fetchUserWorkspacesNotes {
      ...noteFields
    }
  }
`;

export const FETCH_ALL_NOTES = gql(FETCH_ALL_NOTES_STRING);

const FETCH_NOTES_STRING = /* GraphQL */ `
  query FetchNotes($workspaceId: Int = -1) {
    fetchUserWorkspaceNotes(workspaceId: $workspaceId) {
      ...noteFields
    }
  }
`;

export const FETCH_NOTES = gql(FETCH_NOTES_STRING);

const FETCH_NOTE_STRING = /* GraphQL */ `
  query FetchNote($noteId: Int!, $workspaceId: Int!) {
    fetchUserWorkspaceNote(noteId: $noteId, workspaceId: $workspaceId) {
      ...noteFields
    }
  }
`;

export const FETCH_NOTE = gql(FETCH_NOTE_STRING);

const FETCH_FOCUS_TIMER_STRING = /* GraphQL */ `
  query FetchFocusTimer {
    fetchFocusTimer {
      ...focusTimerFields
    }
  }
`;

export const FETCH_FOCUS_TIMER = gql(FETCH_FOCUS_TIMER_STRING);

