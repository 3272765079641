import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { StyledDescription } from '../StyledComponents';
import { format } from 'date-fns';
import {
  getSubscriptionPlanTitle,
  getTitle,
  internalSubscriptionPlanTitles,
} from 'features/courses/utils';
import { CoursesTitle } from 'apollo/stateFields/courses/coursesFields';
import { UserData } from '../UserErrorDataTable/types';

export enum UserDataTableType {
  Create,
  Update,
}

interface UserDataTableProps {
  userDataList: UserData[];
  title: string;
  type: UserDataTableType;
  isLoading?: boolean;
}

const baseColumns: ColumnsType<UserData> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Expiration Date',
    dataIndex: 'expiration',
    key: 'expiration',
    render: (expiration) => expiration && format(expiration * 1000, 'MMM dd, yyyy'),
  },
  {
    title: 'Subscription Plan',
    dataIndex: 'bmSubscriptionPlan',
    key: 'bmSubscriptionPlan',
    render: (subscriptionPlan) => subscriptionPlan && getSubscriptionPlanTitle(subscriptionPlan),
    filters: Object.entries(internalSubscriptionPlanTitles).map(([key, value]) => ({
      value: key,
      text: value,
    })),
    onFilter: (bmSubscriptionPlan, userData) => userData.bmSubscriptionPlan === bmSubscriptionPlan,
  },
  {
    title: 'Curriculum',
    dataIndex: 'coursesAvailableList',
    key: 'coursesAvailableList',
    render: (courses) => courses && `${getTitle(courses[0])} / ${getTitle(courses[1])}`,
    filters: Object.values(CoursesTitle).map((apiName) => ({
      value: apiName,
      text: getTitle(apiName),
    })),
    onFilter: (courseTitle, userData) =>
      userData.coursesAvailableList?.includes(courseTitle as CoursesTitle) ?? false,
  },
];

const updateColumns = [
  ...baseColumns,
  {
    title: 'New Email',
    dataIndex: 'newEmail',
    key: 'newEmail',
  },
  {
    title: 'Change Email Action',
    dataIndex: 'changeEmailAction',
    key: 'changeEmailAction',
  },
];

const UserDataTable = ({ userDataList, title, type, isLoading }: UserDataTableProps) => {
  let columns =
    type === UserDataTableType.Update && userDataList.some(({ newEmail }) => !!newEmail)
      ? updateColumns
      : baseColumns;
  return (
    <>
      <StyledDescription>{title}</StyledDescription>
      <Table
        columns={columns}
        dataSource={userDataList}
        rowKey="email"
        loading={isLoading}
        onChange={console.log}
      />
      ;
    </>
  );
};

export default UserDataTable;

