import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { Card } from '../../../../../core/components/common';
import { InviteCardProps } from './types';
import { SharedPeopleIcon } from '../SharedPeopleIcon/SharedPeopleIcon';
import { useMutation, useQuery } from '@apollo/client';
import {
  ACCEPT_USER_WORKSPACE_INVITE,
  DECLINE_USER_WORKSPACE_INVITE,
} from '../../../graphql/mutations';
import { FETCH_USER_INVITES, FILTER_USER_WORKSPACES } from '../../../graphql/queries';
import WorkspaceLabel from '../../WorkspaceLabels/WorkspaceLabel';
import {
  FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
  FETCH_USER_WORKSPACES_WITH_ENTITIES,
} from '../../../../common/graphql/queries';
import { theme } from '../../../../../core/styles/styled-components';
import { FETCH_ALL_NOTES } from '../../../../actions/graphql/queries';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${theme.device.tablet.min} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledInviteText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;

  span {
    display: inline-block;
    margin-right: 3px;
    font-weight: 700;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p {
    display: inline-block;
    margin: 0;
  }

  @media ${theme.device.tablet.min} {
    margin: 0;
  }
`;

const StyledLabel = styled.div<{ isDefault?: boolean; isNew?: boolean }>`
  display: flex;
  align-items: center;
  height: 16px;
  padding: 2px 3px;
  margin-right: 4px;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  border-radius: 2px;
  color: var(--color-dark-blue);
  border: 1px solid rgba(32, 38, 53, 0.04);
  background-color: rgba(32, 38, 53, 0.1);

  svg {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }

  ${({ isDefault }) =>
    isDefault &&
    css`
      background-color: var(--color-purple);
      color: var(--color-blue-2);
      border-color: rgba(177, 190, 249, 0.4);
    `}
  ${({ isNew }) =>
    isNew &&
    css`
      background: rgba(198, 248, 188, 0.5);
      color: #206200;
      border-color: rgba(177, 190, 249, 0.4);
    `}
`;

const StyledLabelContainer = styled.div`
  display: flex;
`;

const StyledCard = styled(Card)<{ isNew?: boolean }>`
  && {
    padding: 11px 14px 12px 10px;
    margin-bottom: 8px;

    .ant-select {
      margin: 0;
    }

    .options-button {
      top: 8px;
      right: 4px;
    }

    ${({ isNew }) =>
      isNew &&
      css`
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: linear-gradient(0deg, rgba(198, 248, 188, 0.2), rgba(198, 248, 188, 0.2)),
          linear-gradient(180deg, rgba(252, 252, 252, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%);
      `}
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledAcceptButton = styled(Button)`
  border: 1px solid transparent;
  color: var(--color-dark-blue);
  border-radius: 36px;
  font-size: 9px;
  line-height: 1;
  font-weight: 500;
  height: 16px;
  padding: 0 8px;

  &:focus,
  &:hover,
  &:active {
    border-color: transparent;
    color: var(--color-dark-blue);
  }
`;
const StyledDeclineButton = styled(Button)`
  color: var(--color-dark-blue);
  border-radius: 36px;
  font-size: 9px;
  line-height: 1;
  font-weight: 500;
  height: 16px;
  padding: 0 8px;
  margin-right: 8px;
  border: 1px solid rgba(32, 38, 53, 0.4);
  background-color: transparent;

  &:focus,
  &:hover,
  &:active {
    background-color: transparent;
    border-color: rgba(32, 38, 53, 0.4);
    color: var(--color-dark-blue);
  }
`;

const StyledTitle = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin: 11px 0 2px 0;
`;

const StyledDescription = styled.p`
  font-size: 9px;
  line-height: 12px;
  margin-bottom: 12px;
`;

const InviteCard = ({ invite }: InviteCardProps) => {
  const [acceptInvite, { loading: acceptLoading }] = useMutation(ACCEPT_USER_WORKSPACE_INVITE);
  const [declineInvite, { loading: declineLoading }] = useMutation(DECLINE_USER_WORKSPACE_INVITE);
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;

  const handleAccept = useCallback(
    async (event) => {
      event.currentTarget.disabled = true;
      await acceptInvite({
        variables: {
          workspaceId: invite.id,
        },
        update(cache, { data }) {
          const { fetchUserWorkspacesWithEntities = [] } =
            cache.readQuery({
              query: FETCH_USER_WORKSPACES_WITH_ENTITIES,
            }) || {};

          cache.writeQuery({
            query: FETCH_USER_WORKSPACES_WITH_ENTITIES,
            data: {
              fetchUserWorkspacesWithEntities: data?.acceptUserWorkspaceInvite.workspace
                ? [
                    ...fetchUserWorkspacesWithEntities,
                    {
                      ...data.acceptUserWorkspaceInvite.workspace,
                      actions: [],
                      notes: [],
                      outcomes: [],
                      tags: [],
                    },
                  ]
                : fetchUserWorkspacesWithEntities,
            },
          });
        },
        refetchQueries: [
          { query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT },
          { query: FETCH_USER_INVITES },
          { query: FETCH_ALL_NOTES },
          ...(!!filterInput.length
            ? [{ query: FILTER_USER_WORKSPACES, variables: { filters: filterInput } }]
            : []),
        ],
      });
    },
    [invite, acceptInvite],
  );

  const handleDecline = useCallback(
    async (event) => {
      event.currentTarget.disabled = true;
      await declineInvite({
        variables: {
          workspaceId: invite.id,
        },
        refetchQueries: [
          { query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT },
          { query: FETCH_USER_INVITES },
        ],
      });
    },
    [invite, declineInvite],
  );

  const renderAcceptDeclineButtons = useCallback(() => {
    return (
      <StyledButtonsWrapper>
        <StyledDeclineButton loading={declineLoading} onClick={handleDecline}>
          Decline
        </StyledDeclineButton>
        <StyledAcceptButton loading={acceptLoading} onClick={handleAccept}>
          Accept this invite
        </StyledAcceptButton>
      </StyledButtonsWrapper>
    );
  }, []);

  const renderLabels = useCallback(() => {
    return (
      <StyledLabelContainer>
        <WorkspaceLabel isNew />
        {invite.permission && (
          <WorkspaceLabel permission={invite.permission} isShared={invite.usersCount > 1} />
        )}
        {invite.usersCount > 1 && <SharedPeopleIcon count={invite.usersCount} />}
      </StyledLabelContainer>
    );
  }, [invite]);

  const headerSharedCardEl = <StyledHeaderContainer>{renderLabels()}</StyledHeaderContainer>;

  return (
    <StyledCard headerContent={headerSharedCardEl} isNew>
      <StyledTitle>{invite.name}</StyledTitle>
      <StyledDescription>{invite.description}</StyledDescription>
      <StyledFooter>
        <StyledInviteText>
          <span>{invite.inviteFromUser.name || invite.inviteFromUser.email}</span>
          <p>{'has invited you'}</p>
        </StyledInviteText>
        {renderAcceptDeclineButtons()}
      </StyledFooter>
    </StyledCard>
  );
};

export { InviteCard };

