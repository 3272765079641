import { FormEvent, useState } from 'react';
import { StyledButtonsContainer, StyledDescription } from '../StyledComponents';
import { domainRegex } from 'pages/const';
import { Input } from 'antd';
import { Button, SubmitButton } from 'core/components/common';
import DeleteOrgModal from '../DeleteOrgModal/DeleteOrgModal';
import { useLazyQuery } from '@apollo/client';
import { FETCH_CORPORATE_ACCOUNT_BY_DOMAIN } from 'graphql/queries';
import devConsole from 'core/utils/devConsole';

interface IDeleteOrganizationProps {
  onSuccess: (isSuccess: boolean) => void;
  onError: (errorMessage: string | null) => void;
}

const DeleteOrganization = ({ onError, onSuccess }: IDeleteOrganizationProps) => {
  const [domain, setDomain] = useState('');
  const [fetchOrgByDomain, { loading }] = useLazyQuery(FETCH_CORPORATE_ACCOUNT_BY_DOMAIN, {
    fetchPolicy: 'network-only',
  });
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false);
  const handleReset = () => {
    setDomain('');
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const { data } = await fetchOrgByDomain({ variables: { domain } });
      if (!data?.fetchCorporateAccountByDomain?.domain) {
        throw new Error('Organization not found');
      }
      setShowDeleteOrgModal(true);
    } catch (error) {
      devConsole.error(error);
      onError('Oops');
    }
  };
  const handleClose = () => {
    setShowDeleteOrgModal(false);
  };
  const isSubmitDisabled = !domain.length || !domainRegex.test(domain);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <StyledDescription>Delete organization by domain</StyledDescription>
        <Input
          value={domain}
          onChange={(e) => {
            setDomain(e.target.value);
          }}
          bordered
          size="large"
          placeholder="Organization domain"
        />
        <StyledButtonsContainer>
          <Button isSecondary onClick={handleReset}>
            Reset
          </Button>
          <SubmitButton isDisabled={isSubmitDisabled} isLoading={loading}>
            Submit
          </SubmitButton>
        </StyledButtonsContainer>
      </form>
      <DeleteOrgModal
        onClose={handleClose}
        domain={domain}
        onError={onError}
        isVisible={showDeleteOrgModal}
        onSuccess={onSuccess}
      />
    </div>
  );
};

export default DeleteOrganization;

