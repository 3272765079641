import React, { useCallback, useEffect, useState } from 'react';
import { SelectEventHandler } from 'rc-menu/lib/interface';
import styled, { css } from 'styled-components';

import { Outcome, OutcomeStatus } from 'core/types/outcome';
import { newOutcome } from 'core/utils/outcome';
import { sendEvent } from 'core/integrations/sentry/events';
import { EntityCardColumn } from 'features/common/components/EntityCardColumn';
import { useUserWorkspaceContext } from 'context/userWorkspaceContext';
import { getOutcomesByCompleteDate, getOutcomesByStatus, getSortedOutcomes } from '../../utils';
import { OutcomeStatusFilter } from '../OutcomeStatusFilter';
import { OutcomeDatePicker } from '../OutcomeDatePicker';
import { OutcomeCard } from '../OutcomeCard';
import { Draggable, DraggableChildrenFn } from 'react-beautiful-dnd';
import { Moment } from 'moment';
import { useApolloClient, useQuery } from '@apollo/client';
import { theme } from '../../../../core/styles/styled-components';
import useCreateOutcome from '../../../interactions/outcome/useCreateOutcome';
import { ColumnType } from 'apollo/stateFields/dnd/dndFields';
import { EditOutcome } from '../../../actions/components/ActionBoard/EditOutcome';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import { utilsMutation } from '../../../../apollo/stateFields/utils';
import { dndDispatch } from '../../../../context/dndContext/dndContext';
import { setCurrentOutcomeColumnStatus } from '../../../../context/dndContext/DndActions';
import useDndContext from '../../../../context/dndContext/useDndContext';
import { EntityType, getCacheEntityById } from '../../../utils';

const StyledWrapper = styled.div<{ isHighlight: boolean }>`
  padding: 12px 8px 8px 18px;
  background: rgba(0, 0, 0, 0.05);
  margin-right: 8px;
  position: relative;

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      z-index: 2501;
    `}
  @media ${theme.device.largeDesktop.min} {
    margin-right: 12px;
    width: 412px;
  }
  &: before {
    content: '';
    display: block;
    position: absolute;
    right: 412px;
    width: calc((100vw - 1920px) / 2);
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
  }
`;

const StyledOutcomeCard = styled((props) => <OutcomeCard {...props} />)`
  && {
    padding: 5px;

    svg {
      margin-left: -3px;
    }
  }
`;

const StyledEntityCardColumn = styled(EntityCardColumn)`
  background-color: initial;
  padding: 0;
  width: 244px;

  @media ${theme.device.standardDesktop.min} {
    width: calc((100vw - 24px) / 5);
  }

  @media ${theme.device.largeDesktop.min} {
    width: 380px;
  }

  @media ${theme.device.tablet.max} {
    width: 324px;
  }

  @media ${theme.device.mobile.max} {
    width: 238px;
  }
`;

const StyledOutcomeFilters = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInner = styled.div<{ isHighlight: boolean }>`
  border: 1px solid transparent;
  padding-left: 6px;
  height: 100%;

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      border: 1px dashed #ffffff;
      border-radius: 4px;
      background-color: #c9e5eb;
      width: 80%;
      height: auto;
    `};
`;

const OutcomeCardColumn = React.memo(
  ({
    className,
    isDropDisabled,
    isCombineEnabled,
    isMovingDisabled,
    isHighlight,
    sourceDragStartColumn,
  }: {
    className?: string;
    isDropDisabled: boolean;
    isCombineEnabled?: boolean;
    isMovingDisabled?: boolean;
    isHighlight?: boolean;
    sourceDragStartColumn: string | null;
  }) => {
    const { boardColumns, currentOutcomeColumnStatus } = useDndContext();
    const dispatch = dndDispatch;
    const { outcomeColumn } = boardColumns;
    const { setSourceDragStartColumn } = utilsMutation;
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { data: filterData } = useQuery(FILTER_STATE);
    const { filterInput }: FilterInputValue = filterData?.filterInput;
    const [date, setDate] = useState<Moment | undefined>(undefined);
    const { outcomes } = useUserWorkspaceContext();
    const [createOutcome] = useCreateOutcome();
    const apolloClient = useApolloClient();

    const sortedOutcomes = outcomes ? getSortedOutcomes(outcomes) : [];
    let filteredOutcomes = sortedOutcomes
      ? getOutcomesByStatus(sortedOutcomes, currentOutcomeColumnStatus)
      : [];

    filteredOutcomes =
      date && filteredOutcomes && currentOutcomeColumnStatus === OutcomeStatus.Completed
        ? getOutcomesByCompleteDate(filteredOutcomes, date)
        : filteredOutcomes;

    const handleSelectStatus: SelectEventHandler = ({ key }) => {
      dispatch(setCurrentOutcomeColumnStatus(key as OutcomeStatus));
    };

    const renderOutcomeClone: DraggableChildrenFn = (provided, snapshot, rubric) => {
      const outcome = getCacheEntityById(
        apolloClient,
        Number(rubric.draggableId),
        EntityType.OUTCOME,
        filterInput,
      ) as Outcome;

      return (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <OutcomeCard outcome={outcome} />
        </div>
      );
    };

    const onCardFormSubmit = useCallback(
      async (name, workspaceId) => {
        const outcomeValues = {
          name,
          status: currentOutcomeColumnStatus,
          ...newOutcome(currentOutcomeColumnStatus),
        };
        await createOutcome({
          variables: { workspaceId, outcomeValues },
        });

        sendEvent('outcome-create', 'Outcome create', {
          Name: name,
          Status: currentOutcomeColumnStatus,
        });
      },
      [currentOutcomeColumnStatus, createOutcome, apolloClient, filterInput, defaultWorkspaceId],
    );

    const onOutcomeDatePickerChange = (date: any) => {
      setDate(date);
    };

    const editOutcomeModalEl = <EditOutcome outcomes={outcomes} />;

    useEffect(() => {
      setSourceDragStartColumn(sourceDragStartColumn);
    }, [sourceDragStartColumn]);

    return (
      <StyledWrapper className={className} isHighlight={!!isHighlight}>
        <StyledInner data-tutorial="1" isHighlight={!!isHighlight}>
          <StyledOutcomeFilters>
            <OutcomeStatusFilter
              status={currentOutcomeColumnStatus}
              onSelect={handleSelectStatus}
            />
            {currentOutcomeColumnStatus === OutcomeStatus.Completed && (
              <OutcomeDatePicker onChange={onOutcomeDatePickerChange} value={date && date} />
            )}
          </StyledOutcomeFilters>
          <StyledEntityCardColumn
            isDraggable={false}
            isDropDisabled={isDropDisabled}
            isCombineEnabled={isCombineEnabled}
            isMovingDisabled={isMovingDisabled}
            entityName={ColumnType.Outcome}
            title=""
            name={outcomeColumn.title}
            cardData={outcomeColumn.entityIds}
            renderCard={(index, outcomeId) => {
              const entity = filteredOutcomes.find((outcome) => outcome.id === outcomeId);
              if (entity !== undefined && !entity.isArchived) {
                return (
                  <StyledOutcomeCard
                    noBg
                    key={`${outcomeId}`}
                    outcome={entity}
                    draggablePosition={index}
                    isDraggable={true}
                    apolloClient={apolloClient}
                    filterInput={filterInput}
                  />
                );
              }
              return (
                <Draggable draggableId={`${outcomeId}`} index={index} key={`${outcomeId}`}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div style={{ height: '1px', marginTop: '-1px' }} />
                    </div>
                  )}
                </Draggable>
              );
            }}
            onSubmit={({ name, workspaceId }) => onCardFormSubmit(name, workspaceId)}
            renderClone={renderOutcomeClone}
          />
        </StyledInner>
        {editOutcomeModalEl}
      </StyledWrapper>
    );
  },
);

export { OutcomeCardColumn };

