import { FetchResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import devConsole from './devConsole';

/**
 * Checks for errors in the response and throws an error if any.
 * @param response The response to check for errors.
 * @param errorMessage The error message to throw if there are errors in the response.
 * @throws {GraphQLError} If there are errors in the response.
 */
export const checkForResponseErrors = (response: FetchResult, errorMessage?: string): void => {
  const { errors } = response;
  if (!errors) return;
  if (!errorMessage) throw new GraphQLError('Ooops, something went wrong. Please try again later.');
  const graphQLError = new GraphQLError(errorMessage);
  const message = errors?.[0]?.message;
  if (message) graphQLError.message += `: ${message}`;
  throw graphQLError;
};

export const logResponseError = (e: unknown, setError?: (errorMessage: string) => void): void => {
  let errorMessage = 'Ooops';
  if (e instanceof GraphQLError) errorMessage = e.message;
  devConsole.error(errorMessage, e);
  if (setError) setError(errorMessage);
};

