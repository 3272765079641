import { memo, useState } from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import { InputTextField } from '../../../core/components/form';
import { useMutation } from '@apollo/client';
import { ADMIN_ADD_NEW_USER } from '../../../graphql/mutations';
import { Select } from 'antd';
import { BmSubscriptionPlans } from '../../../graphql/types';
import { getTime } from 'date-fns';
import devConsole from 'core/utils/devConsole';
import {
  ISubscriptionSelectOption,
  SubscriptionSelectOpts,
  ICurriculumSelectOption,
  CoursesGroupsSelectOpts,
} from '../utils';
import { CourseGroup, CoursesGroups, emailRegex } from 'pages/const';
import { StyledButtonsContainer } from '../StyledComponents';
import { Button, ExpirationDatePicker, SubmitButton } from 'core/components/common';

const StyledInputContainer = styled.div`
  display: flex;

  & > div:nth-child(1) {
    margin-right: 4px;
  }
  & > div:nth-child(2) {
    margin-left: 4px;
  }
`;

interface IInitialValues {
  email: string;
  firstName: string;
  lastName?: string;
  coursesAvailableList?: CourseGroup;
  bmSubscriptionPlan?: BmSubscriptionPlans;
  expiration?: number;
}

const initialValues: IInitialValues = {
  email: '',
  firstName: '',
};

interface IAddUserProps {
  onSuccess: () => void;
  onError: () => void;
}

const AddUser = ({ onSuccess, onError }: IAddUserProps) => {
  const [curriculumInput, setCurriculumInput] = useState<ICurriculumSelectOption | null>(null);
  const [subscriptionInput, setSubscriptionInput] = useState<ISubscriptionSelectOption | null>(
    null,
  );
  const [expirationDateInput, setExpirationDateInput] = useState<Date | null>(null);
  const [addNewUser, { loading: addNewUserLoading }] = useMutation(ADMIN_ADD_NEW_USER);

  const handleSubmit = async ({
    coursesAvailableList,
    bmSubscriptionPlan,
    expiration,
    ...restFormValues
  }: IInitialValues) => {
    const values: IInitialValues = restFormValues;
    if (curriculumInput?.value != null) {
      values.coursesAvailableList = CoursesGroups[curriculumInput.value];
    }
    if (subscriptionInput?.label != null) {
      values.bmSubscriptionPlan = subscriptionInput.label;
    }
    if (expirationDateInput != null) {
      values.expiration = Math.floor(getTime(expirationDateInput) / 1000);
    }
    try {
      await addNewUser({
        variables: {
          values,
        },
      });
      onSuccess();
    } catch (error) {
      devConsole.error(error);
      onError();
    }
  };

  const handleReset = (_values: IInitialValues, { setValues }: FormikHelpers<IInitialValues>) => {
    setCurriculumInput(null);
    setSubscriptionInput(null);
    setExpirationDateInput(null);
    setValues(initialValues);
  };

  const signUpFormEl = (
    <Formik
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      {({ values, isSubmitting: _isSubmitting, errors }) => {
        return (
          <Form>
            <StyledInputContainer>
              <InputTextField
                name="email"
                placeholder="Email"
                defaultValue=""
                autoComplete="off"
                displayError={!!errors.email}
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <InputTextField
                name="firstName"
                placeholder="First name"
                defaultValue=""
                autoComplete="off"
                displayError={!!errors.firstName}
                maxLength={100}
              />
              <InputTextField
                name="lastName"
                placeholder="Last name"
                autoComplete="off"
                displayError={!!errors.lastName}
                maxLength={100}
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <Select
                bordered
                placeholder="Subscription"
                labelInValue
                allowClear
                style={{ width: '100%' }}
                onChange={(option) => {
                  if (option?.label != null) {
                    setSubscriptionInput(option);
                  } else {
                    devConsole.warn('option.label is null');
                    setSubscriptionInput(null);
                  }
                }}
                options={SubscriptionSelectOpts}
                value={subscriptionInput!}
              />
              <Select
                bordered
                placeholder="Curriculum"
                labelInValue
                allowClear
                style={{ width: '100%' }}
                onChange={(option) => {
                  if (option?.value != null) {
                    setCurriculumInput(option);
                  } else {
                    devConsole.warn('option.value is null');
                    setCurriculumInput(null);
                  }
                }}
                options={CoursesGroupsSelectOpts}
                value={curriculumInput!}
              />
              <ExpirationDatePicker
                value={expirationDateInput}
                setExpirationDate={setExpirationDateInput}
                size="middle"
              />
            </StyledInputContainer>
            <StyledButtonsContainer>
              <Button isSecondary htmlType="reset">
                Reset
              </Button>
              <SubmitButton
                isLoading={addNewUserLoading}
                isDisabled={addNewUserLoading || !emailRegex.test(values.email)}
              >
                Create user
              </SubmitButton>
            </StyledButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );

  return signUpFormEl;
};

export default memo(AddUser);

