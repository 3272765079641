import { FormEvent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADMIN_DELETE_CORPORATE_ACCOUNT } from '../../../graphql/mutations';
import devConsole from 'core/utils/devConsole';
import {
  StyledButtonContainer,
  StyledCancelButton,
  StyledForm,
  StyledInputContainer,
  StyledLabel,
  StyledModal,
  StyledSubmitButton,
  StyledTitle,
} from '../StyleModalComponents';
import CancelModal from '../CancelModal/CancelModal';
import { DomainDeleteAction } from '__generated__/graphql';
import { Select } from 'antd';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

type DeleteActionOption = {
  value: number;
  label: string;
};

const curriculumActionOptions = [
  {
    value: 0,
    label: "Keep corporate users' curriculum",
  },
  {
    value: 1,
    label: "Reset corporate users' curriculum",
  },
];

const subscriptionActionOptions = [
  {
    value: 0,
    label: "Keep corporate users' subscription",
  },
  {
    value: 1,
    label: "Reset corporate users' subscription",
  },
];

const deleteActions = [DomainDeleteAction.Keep, DomainDeleteAction.Reset];

interface IDeleteOrgModalProps {
  domain: string;
  isVisible: boolean;
  onSuccess: (isSuccess: boolean) => void;
  onError: (errorMessage: string | null) => void;
  onClose: () => void;
}

const DeleteOrgModal = ({
  domain,
  onSuccess,
  onError,
  onClose,
  isVisible,
}: IDeleteOrgModalProps) => {
  const [deleteOrg, { loading }] = useMutation(ADMIN_DELETE_CORPORATE_ACCOUNT);
  const [curriculumActionOption, setCurriculumActionOption] = useState<DeleteActionOption | null>(
    null,
  );
  const [subscriptionActionOption, setSubscriptionActionOption] =
    useState<DeleteActionOption | null>(null);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const handleDeleteOrg = async (e?: FormEvent) => {
    e?.preventDefault();
    setShowConfirmModal(false);
    try {
      if (!curriculumActionOption || !subscriptionActionOption) {
        throw new Error('Missing delete action options');
      }
      await deleteOrg({
        variables: {
          values: {
            domain,
            curriculumAction: deleteActions[curriculumActionOption.value],
            subscriptionAction: deleteActions[subscriptionActionOption.value],
          },
        },
      });
      onSuccess(true);
    } catch (error) {
      devConsole.error(error);
      onError('Oops');
    } finally {
      handleClose();
    }
  };

  const isEmpty = (() => {
    return curriculumActionOption === null && subscriptionActionOption === null;
  })();

  const isModified = (() => {
    return curriculumActionOption !== null || subscriptionActionOption !== null;
  })();

  const isInvalid = (() => {
    return curriculumActionOption === null || subscriptionActionOption === null;
  })();

  const isDisabled = !isModified || isEmpty || isInvalid;

  const handleOpenCancel = () => {
    if (isModified) {
      setShowCancelModal(true);
    } else {
      onClose();
    }
  };

  const handleClose = useCallback(() => {
    setCurriculumActionOption(null);
    setSubscriptionActionOption(null);
    onClose();
  }, [onClose]);

  const handleAcceptCancel = () => {
    setShowCancelModal(false);
    handleClose();
  };

  const handleSubmit = (e : FormEvent) => {
    e.preventDefault();
    setShowConfirmModal(true);
  }

  return (
    <>
      <StyledModal
        destroyOnClose
        getContainer={() => document.body}
        visible={isVisible}
        onCancel={handleOpenCancel}
        footer={null}
        mask={false}
        closable={true}
      >
        <StyledForm onSubmit={handleSubmit}>
          <StyledTitle>Delete Organization</StyledTitle>
          <p>
            Deleting this organization may affect the curriculum and subscription of the corporate
            users related to the domain <b>{domain}</b>.
          </p>
          <p>
            Please choose an action to perform on the corporate user's curriculum and subscription
            when deleting the organization.
          </p>
          <StyledInputContainer>
            <StyledLabel>Curriculum Action</StyledLabel>
            <p>
              This action will only be applied to all corporate users using Org Curriculum by
              default
            </p>
            <Select
              labelInValue
              value={curriculumActionOption as DeleteActionOption}
              options={curriculumActionOptions}
              style={{ width: '100%' }}
              placeholder="Choose an option"
              onChange={(option) => {
                if (option?.value != null) {
                  setCurriculumActionOption(option);
                } else {
                  setCurriculumActionOption(null);
                }
              }}
            />
          </StyledInputContainer>
          <StyledInputContainer>
            <StyledLabel>Subscription Action</StyledLabel>
            <p>
              This action will only be applied to all corporate users with INTERNAL subscriptions
              (not including external subscriptions like Stripe, etc.)
            </p>
            <Select
              labelInValue
              value={subscriptionActionOption as DeleteActionOption}
              options={subscriptionActionOptions}
              placeholder="Choose an option"
              style={{ width: '100%' }}
              onChange={(option) => {
                if (option?.value != null) {
                  setSubscriptionActionOption(option);
                } else {
                  setSubscriptionActionOption(null);
                }
              }}
            />
          </StyledInputContainer>
          <StyledButtonContainer>
            <StyledSubmitButton isDisabled={isDisabled} isLoading={loading}>
              Delete
            </StyledSubmitButton>
            <StyledCancelButton onClick={handleOpenCancel}>Cancel</StyledCancelButton>
          </StyledButtonContainer>
        </StyledForm>
      </StyledModal>
      <CancelModal
        onConfirm={handleAcceptCancel}
        onCancel={() => setShowCancelModal(false)}
        visible={showCancelModal}
      />
      <ConfirmModal onConfirm={handleDeleteOrg} onCancel={() => setShowConfirmModal(false)} visible={showConfirmModal} />
    </>
  );
};

export default DeleteOrgModal;

