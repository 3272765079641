import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as HelpIcon } from './svg/help.svg';
import { ReactComponent as PlayActiveIcon } from './svg/play-active.svg';
import { ReactComponent as PlayDefaultIcon } from './svg/play-default.svg';
import { ReactComponent as PlayWatchedIcon } from './svg/play-watched.svg';
import { ReactComponent as FinalTestingIcon } from './svg/finial-testing.svg';
import { ReactComponent as OutLinkIcon } from './svg/out-link.svg';
import { theme } from '../../../core/styles/styled-components';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { CoursesConstsArgsVideoValues } from '../../../graphql/types';
import { FETCH_COURSES_SETTINGS } from '../../../graphql/queries';
import { CurrentStep } from './types';
import {
  checkCourseSpecialIdsEqual,
  getIsAvailableVideo,
  getIsReadyForTest,
  getRestTime,
  getTitle,
} from '../utils';
import { replaceUnderscoreToSpace } from '../../../core/utils/global';
import { coursesMutation } from '../../../apollo/stateFields/courses';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import ExpiredTooltip from '../../../core/components/common/ExpiredTooltip/ExpiredTooltip';
import { USER_PLANS_PATH } from '../../../core/constants/routePaths';
import { Link } from 'react-router-dom';
import {
  COURSES_STATE,
  CoursesFields,
  CoursesTitle,
} from '../../../apollo/stateFields/courses/coursesFields';
import { courseTestingLink } from '../CourseCard/CourseCard';

const StyledContainer = styled.div<{ isShort?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 4px;

  @media ${theme.device.tablet.max} {
    margin-right: 3px;
  }

  @media ${theme.device.mobile.max} {
    ${({ isShort }) =>
      isShort &&
      css`
        margin-left: 5px;
      `}
  }

  .ant-tooltip-arrow {
    display: none !important;
  }

  .ant-tooltip {
    @media ${theme.device.tablet.max} {
      width: 354px !important;
      max-width: 100% !important;
    }

    @media ${theme.device.mobile.max} {
      left: 8px !important;
      right: 8px !important;
      width: calc(100vw - 16px) !important;
      max-width: 100%;
    }
  }
`;

const StyledVideoItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 6px;
  //width: 100%;
`;

const StyledVideoItemTitle = styled.span<{ isCutTitle: boolean; isShort?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-left: 8px;

  @media ${theme.device.mobile.max} {
    margin-left: 0;
    max-width: calc(100vw - 200px);
  }

  ${({ isCutTitle }) =>
    isCutTitle &&
    css`
      @media ${theme.device.tablet.max} {
        max-width: calc(100vw - 200px);
      }
    `}
  ${({ isShort }) =>
    isShort &&
    css`
      @media ${theme.device.mobile.max} {
        max-width: calc(100vw - 230px);
      }
    `}
`;

const StyledVideoItemDuration = styled.span`
  margin-left: 4px;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
  opacity: 0.3;
`;

const StyledHelpIcon = styled(HelpIcon)`
  cursor: pointer;
`;

const StyledFinalTestingLinkContainer = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledFinalTestingContainer = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
`;

const StyledFinalTestingTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
  margin: 0 6px 0 8px;
  padding: 5px 0 2px 0;
  border-bottom: 1px solid gray;
`;

const StyledCourseContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCourseTitle = styled.span<{ isCutCourseTitle: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
  margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isCutCourseTitle }) =>
    isCutCourseTitle &&
    css`
      @media ${theme.device.mobile.max} {
        width: calc(100vw - 168px);
      }
    `}
`;

const StyledBuyButton = styled.a`
  background: #eff0f2;
  border-radius: 32px;
  padding: 3px 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin: 0 24px 0 12px;
  white-space: nowrap;
`;

const StyledSection = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
  margin-right: 26px;
  white-space: nowrap;
`;

const StyledHelpContainer = styled.div`
  p {
    margin: 0;
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: var(--color-white);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  &:focus,
  &:hover,
  &:active {
    color: var(--color-white);
    opacity: 0.8;
  }
`;

const HELP_POPUP = {
  NEXT_VIDEO: {
    title: '',
    description: 'Your next video will be available in ',
  },
  MASTER_CLASS: {
    title: 'Mastering Personal Effectiveness',
    description: 'Master class for the most advanced users',
  },
};

const CourseInfo = React.memo(
  ({ disabled, isShort }: { disabled?: boolean; isShort?: boolean }) => {
    const { setShowVideoPlayer } = coursesMutation;
    const [currentStep, setCurrentStep] = useState<null | CurrentStep>(null);
    const [isHover, setHover] = useState(false);
    const [isCutTitle, setCutTitle] = useState(false);
    const [isCutCourseTitle, setCutCourseTitle] = useState(false);
    const titleVideoRef = useRef<HTMLSpanElement>(null);
    const titleCourseRef = useRef<HTMLSpanElement>(null);
    const helpIconRef = useRef<SVGSVGElement>(null);
    const isMobile = useMediaQuery({ query: theme.device.mobile.max });
    const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
    const { data: stripeData } = useQuery(STRIPE_STATE);
    const { isSubscriptionExpired, expiredSubscriptionText }: StripeValue = stripeData?.stripe;
    const { data: coursesStateData } = useQuery(COURSES_STATE);
    const { firstCourseTitle, secondCourseTitle }: CoursesFields = coursesStateData?.courses;

    const { data } = useQuery(FETCH_COURSES_SETTINGS);
    const coursesData = data?.fetchUserSettings.coursesConsts;
    const coursesInfo = data?.fetchUserSettings.coursesVars;

    const isFoundationDone =
      getIsReadyForTest(
        firstCourseTitle,
        data?.fetchUserSettings.coursesVars?.[firstCourseTitle],
        data?.fetchUserSettings.coursesConsts?.[firstCourseTitle],
      ) || coursesData?.[secondCourseTitle]?.isCourseAvailable;

    useEffect(() => {
      calculateCurrentStep();
    }, [data, isSubscriptionExpired, firstCourseTitle, secondCourseTitle]);

    useEffect(() => {
      if (titleVideoRef.current) {
        const titleWidth = titleVideoRef.current.offsetWidth;
        const screenWidth = window.innerWidth;

        setCutTitle(titleWidth + 160 > screenWidth);
      }

      if (titleCourseRef.current) {
        const titleWidth = titleCourseRef.current.offsetWidth;
        const screenWidth = window.innerWidth;

        setCutCourseTitle(titleWidth + 168 > screenWidth);
      }
    }, [currentStep]);

    const getCurrentAvailableVideo = (name: CoursesTitle): CoursesConstsArgsVideoValues | null => {
      let currentVideo: null | CoursesConstsArgsVideoValues = null;
      if (coursesData && coursesInfo) {
        coursesData[name]?.videos?.forEach((video, index) => {
          const isAvailable =
            firstCourseTitle === name && isFoundationDone
              ? true
              : getIsAvailableVideo(
                  video,
                  name === firstCourseTitle
                    ? coursesData[name]?.isCourseAvailable
                    : isFoundationDone,
                  coursesInfo[name]?.watchedVideoIds,
                  coursesInfo[name]?.lastWatchedId,
                  coursesInfo[name]?.dateLastWatched,
                  coursesData[name],
                  isSubscriptionExpired,
                );

          if (!isAvailable && index > 0 && !currentVideo) {
            currentVideo = coursesData[name]?.videos?.[index - 1] || null;

            if (name === secondCourseTitle && checkCourseSpecialIdsEqual(currentVideo?.id, 12)) {
              currentVideo = coursesData?.[secondCourseTitle]?.videos?.[index] || null;
            }
            return;
          }

          if (
            name === firstCourseTitle &&
            isAvailable &&
            (firstCourseTitle === CoursesTitle.FundamentalsManager ? index === 13 : index === 11)
          ) {
            currentVideo = coursesData[name]?.videos?.[index] || null;
            return;
          }
          if (
            name === secondCourseTitle &&
            isAvailable &&
            secondCourseTitle === CoursesTitle.RemoteWorkProfessionalManager &&
            index === 106
          ) {
            currentVideo = coursesData[name]?.videos?.[index] || null;
            return;
          }
        });
      }

      return currentVideo;
    };

    const isSectionCompleted = (id: number, section: string) => {
      const nextVideo = coursesData?.MasteringPersonalEffectiveness?.videos?.find(
        (v) => v.id === id + 1,
      );

      if (
        coursesInfo?.MasteringPersonalEffectiveness?.watchedVideoIds?.includes(id) &&
        nextVideo &&
        nextVideo.name.indexOf('Masterclass') !== -1
      ) {
        return true;
      }

      return nextVideo ? nextVideo.section !== section : false;
    };

    const isVideoWatched = (id: number, courseName: CoursesTitle) => {
      return coursesInfo && coursesInfo[courseName]?.watchedVideoIds?.includes(id);
    };

    const setFinalTestingStep = (courseName: CoursesTitle) => {
      let stepValue = {
        title: courseName === firstCourseTitle ? 'Verify my learning' : `Start final testing`,
        section: null,
        courseTitle: courseName,
        duration: '',
        isWatched: false,
        isCompleted: true,
        isFree: true,
        isPaid: false,
        isSectionCompleted: false,
        url: '',
        currentVideo: null,
        courseName: null,
      };
      setCurrentStep(stepValue);
    };

    const checkIsWatchedFoundationsCourse = () => {
      if (!firstCourseTitle) {
        return false;
      }
      if (coursesData?.[firstCourseTitle]?.videos?.length) {
        if (
          coursesInfo?.[firstCourseTitle]?.watchedVideoIds?.length &&
          coursesData?.[firstCourseTitle]?.videos?.length &&
          coursesInfo?.[firstCourseTitle]!.watchedVideoIds!.length! >=
            coursesData[firstCourseTitle]!.videos?.length!
        ) {
          return true;
        }
        if (
          firstCourseTitle === CoursesTitle.Foundations &&
          coursesInfo?.[firstCourseTitle]?.watchedVideoIds?.length ===
            coursesData?.[firstCourseTitle]!.videos?.length! - 1 &&
          coursesInfo?.[firstCourseTitle]?.watchedVideoIds?.includes(
            coursesData?.[firstCourseTitle]!.videos?.[
              coursesData?.[firstCourseTitle]!.videos?.length! - 1
            ]?.id!,
          )
        ) {
          return true;
        }
      }
      return false;
    };

    const checkLastWatchedCourse = () => {
      const foundationsCourseDate = coursesInfo?.[firstCourseTitle]?.dateLastWatched;
      const masteringPersonalEffectivenessCourseDate =
        coursesInfo?.[secondCourseTitle]?.dateLastWatched;

      const isAvailableMasteringPersonalEffectiveness =
        isFoundationDone && coursesData?.[firstCourseTitle]?.certificateUrl;

      if (
        (!foundationsCourseDate ||
          (!foundationsCourseDate && !masteringPersonalEffectivenessCourseDate)) &&
        !isFoundationDone
      ) {
        return firstCourseTitle;
      }

      if (!masteringPersonalEffectivenessCourseDate && !isAvailableMasteringPersonalEffectiveness) {
        return firstCourseTitle;
      }

      if (!masteringPersonalEffectivenessCourseDate && isAvailableMasteringPersonalEffectiveness) {
        return secondCourseTitle;
      }

      if (
        foundationsCourseDate &&
        masteringPersonalEffectivenessCourseDate &&
        !isAvailableMasteringPersonalEffectiveness &&
        coursesInfo?.MasteringPersonalEffectiveness?.watchedVideoIds?.length === 1 &&
        checkCourseSpecialIdsEqual(
          coursesInfo?.MasteringPersonalEffectiveness?.watchedVideoIds[0],
          12,
        ) &&
        !coursesData?.[firstCourseTitle]?.certificateUrl
      ) {
        return firstCourseTitle;
      }

      if (foundationsCourseDate && masteringPersonalEffectivenessCourseDate) {
        if (
          new Date(foundationsCourseDate) < new Date(masteringPersonalEffectivenessCourseDate) &&
          isAvailableMasteringPersonalEffectiveness
        ) {
          return secondCourseTitle;
        } else {
          return firstCourseTitle;
        }
      }
    };

    const getCurrentVideoAvailableFoundationsCourse = () => {
      if (coursesData) {
        const notWatchedVideos = coursesData?.[firstCourseTitle]?.videos?.filter((video) => {
          return !coursesInfo?.[firstCourseTitle]?.watchedVideoIds?.includes(video.id);
        });
        if (notWatchedVideos && notWatchedVideos.length) {
          return notWatchedVideos[0];
        }
      }
    };

    const calculateCurrentStep = () => {
      const isCompletedFoundationsCourse = coursesData?.[firstCourseTitle]?.certificateUrl;
      const isCompletedMasteringPersonalEffectivenessCourse =
        coursesData?.[secondCourseTitle]?.certificateUrl;

      const isWatchedFoundationsCourse = checkIsWatchedFoundationsCourse();
      const isWatchedMasteringPersonalEffectivenessCourse =
        coursesData &&
        coursesInfo?.MasteringPersonalEffectiveness?.watchedVideoIds?.find(
          (id) =>
            id ===
            coursesData?.[secondCourseTitle]?.videos?.[
              coursesData?.[secondCourseTitle]!.videos!.length! - 2
            ].id!,
        );

      let isFoundationsCourse =
        checkLastWatchedCourse() === firstCourseTitle &&
        !isWatchedFoundationsCourse &&
        !isCompletedFoundationsCourse;

      const isAvailableMasteringPersonalEffectiveness = isFoundationDone;
      // coursesData?.MasteringPersonalEffectiveness.isCourseAvailable;

      if (
        isFoundationsCourse &&
        isWatchedFoundationsCourse &&
        isCompletedFoundationsCourse &&
        isAvailableMasteringPersonalEffectiveness
      ) {
        isFoundationsCourse = false;
      }

      if (isFoundationsCourse) {
        if (!isWatchedFoundationsCourse && !isAvailableMasteringPersonalEffectiveness) {
          const currentVideo = getCurrentAvailableVideo(firstCourseTitle);

          if (currentVideo) {
            let stepValue = {
              title: currentVideo.name,
              section: currentVideo.section!,
              courseTitle: getTitle(firstCourseTitle),
              duration: currentVideo.duration,
              isWatched: !!isVideoWatched(currentVideo.id, firstCourseTitle),
              isCompleted: false,
              isFree: true,
              isPaid: false,
              isSectionCompleted: false,
              url: currentVideo.url,
              currentVideo: currentVideo,
              courseName: firstCourseTitle,
            };
            setCurrentStep(stepValue);
          }
          return;
        }

        if (!isWatchedFoundationsCourse && isAvailableMasteringPersonalEffectiveness) {
          const currentVideo = getCurrentVideoAvailableFoundationsCourse();

          if (currentVideo) {
            let stepValue = {
              title: currentVideo.name,
              section: currentVideo.section!,
              courseTitle: firstCourseTitle,
              duration: currentVideo.duration,
              isWatched: !!isVideoWatched(currentVideo.id, firstCourseTitle),
              isCompleted: false,
              isFree: true,
              isPaid: false,
              isSectionCompleted: false,
              url: currentVideo.url,
              currentVideo: currentVideo,
              courseName: firstCourseTitle,
            };
            setCurrentStep(stepValue);
          }
          return;
        }

        // if (isWatchedFoundationsCourse && !isCompletedFoundationsCourse) {
        //   setFinalTestingStep(firstCourseTitle);
        //   return;
        // }

        if (isCompletedFoundationsCourse && !isAvailableMasteringPersonalEffectiveness) {
          const currentVideo = coursesData?.[secondCourseTitle]?.videos?.[0];

          if (currentVideo) {
            let stepValue = {
              title: currentVideo.name,
              section: currentVideo.section!,
              courseTitle: secondCourseTitle,
              duration: currentVideo.duration,
              isWatched: false,
              isCompleted: false,
              isFree: true,
              isPaid: false,
              isSectionCompleted: false,
              url: currentVideo.url,
              currentVideo: currentVideo,
              courseName: secondCourseTitle,
            };
            setCurrentStep(stepValue);
          }
          return;
        }
      } else {
        if (isCompletedMasteringPersonalEffectivenessCourse) {
          setCurrentStep(null);
          return;
        }

        if (isAvailableMasteringPersonalEffectiveness) {
          if (!isWatchedMasteringPersonalEffectivenessCourse) {
            const currentVideo = getCurrentAvailableVideo(secondCourseTitle);

            if (currentVideo) {
              let stepValue = {
                title: currentVideo.name,
                section: currentVideo.section!,
                courseTitle: secondCourseTitle,
                duration: currentVideo.duration,
                isWatched: !!isVideoWatched(currentVideo.id, secondCourseTitle),
                isCompleted: false,
                isFree: true,
                isPaid: false,
                isSectionCompleted: isSectionCompleted(currentVideo.id, currentVideo.section!),
                url: currentVideo.url,
                currentVideo: currentVideo,
                courseName: secondCourseTitle,
              };
              setCurrentStep(stepValue);
            }
            return;
          }
          // else {
          //   setFinalTestingStep(secondCourseTitle);
          // }
        } else {
          const currentVideo = coursesData?.[secondCourseTitle]?.videos?.[0];

          if (currentVideo) {
            let stepValue = {
              title: currentVideo.name,
              section: currentVideo.section!,
              courseTitle: secondCourseTitle,
              duration: currentVideo.duration,
              isWatched: false,
              isCompleted: false,
              isFree: false,
              isPaid: false,
              isSectionCompleted: false,
              url: currentVideo.url,
              currentVideo: currentVideo,
              courseName: secondCourseTitle,
            };
            setCurrentStep(stepValue);
          }
          return;
        }
      }
    };

    const handlePlay = () => {
      if (currentStep && currentStep.url?.length) {
        setShowVideoPlayer({
          courseTitle: currentStep.courseName as string,
          video: currentStep.currentVideo as CoursesConstsArgsVideoValues,
        });
      }
    };

    const handleHover = (value: boolean) => () => {
      if (!currentStep?.isWatched) {
        setHover(value);
      }
    };

    const getPlayIcon = () => {
      if (currentStep?.isWatched) {
        return <PlayWatchedIcon />;
      } else if (isHover) {
        return <PlayActiveIcon />;
      }
      return <PlayDefaultIcon />;
    };

    const getNextAvailableVideoTime = useCallback(() => {
      if (
        currentStep &&
        currentStep.courseName &&
        coursesInfo &&
        coursesInfo[currentStep.courseName]
      ) {
        const dateLastWatched = coursesInfo[currentStep.courseName]?.dateLastWatched;

        return getRestTime(dateLastWatched, isSubscriptionExpired) <= 1 &&
          getRestTime(dateLastWatched, isSubscriptionExpired) !== 0
          ? 'less than 1'
          : Math.floor(getRestTime(dateLastWatched, isSubscriptionExpired));
      }
    }, [currentStep, coursesInfo, new Date().getHours()]);

    const getHelpIcon = (isRender = true, data = HELP_POPUP.NEXT_VIDEO) => {
      if (!isRender) return null;
      if (
        currentStep?.isSectionCompleted ||
        currentStep?.isWatched ||
        (!currentStep?.isFree && !currentStep?.isPaid)
      ) {
        const nextAvailableVideoTime = getNextAvailableVideoTime();
        const container = (
          <StyledHelpContainer>
            {data.title && (
              <p>
                <b>{data.title}</b>
              </p>
            )}
            <p>
              {data.title === HELP_POPUP.MASTER_CLASS.title
                ? coursesData?.[secondCourseTitle]?.title
                : !currentStep?.isSectionCompleted &&
                  data.description +
                    `${nextAvailableVideoTime} hour${
                      nextAvailableVideoTime &&
                      ((nextAvailableVideoTime as number) < 2 ||
                        nextAvailableVideoTime === 'less than 1')
                        ? ''
                        : 's'
                    }`}
              {currentStep?.isSectionCompleted &&
                'Discover from the experts how to apply these foundations in your life'}
            </p>
          </StyledHelpContainer>
        );
        return (
          <ExpiredTooltip
            placement={'bottomRight'}
            width={'300px'}
            withoutArrow={true}
            visible={isSubscriptionExpired}
            title={container}
            position={
              isMobile && helpIconRef?.current
                ? {
                    left: -Math.floor(helpIconRef?.current?.getBoundingClientRect().left) + 'px',
                  }
                : undefined
            }
            overlayClassName={'helpIconTooltip'}
          >
            <StyledHelpIcon ref={helpIconRef} />
          </ExpiredTooltip>
        );
      }
    };

    const renderVideoItem = (isShowHelp = true) => {
      if (isMobile && !currentStep?.isPaid && !currentStep?.isFree) {
        return null;
      }
      if (currentStep) {
        return (
          <>
            <StyledVideoItem
              onMouseEnter={() => {
                !disabled && handleHover(true);
              }}
              onMouseLeave={() => {
                !disabled && handleHover(false);
              }}
              onClick={() => {
                !disabled && handlePlay();
              }}
            >
              {getPlayIcon()}
              <StyledVideoItemTitle isCutTitle={isCutTitle} ref={titleVideoRef} isShort={isShort}>
                {currentStep.title}
              </StyledVideoItemTitle>
              {isDesktop && (
                <StyledVideoItemDuration>{currentStep.duration}</StyledVideoItemDuration>
              )}
            </StyledVideoItem>
            {getHelpIcon(isShowHelp)}
          </>
        );
      }
    };

    const renderPaidCourse = () => {
      return (
        <StyledCourseContainer>
          {!currentStep?.isPaid && (
            <>
              <StyledCourseTitle ref={titleCourseRef} isCutCourseTitle={isCutCourseTitle}>
                {currentStep?.courseTitle}
              </StyledCourseTitle>
              {getHelpIcon(true, HELP_POPUP.MASTER_CLASS)}
              {/*<StyledBuyButton href={BUY_MASTERING_COURSE_LINK} target="_blank">*/}
              {/*  Join {isMobile ? '' : 'Program'}*/}
              {/*</StyledBuyButton>*/}
            </>
          )}
          {isDesktop && currentStep?.isSectionCompleted && (
            <StyledSection>
              {replaceUnderscoreToSpace(currentStep.section!) + '’s finished!'}
            </StyledSection>
          )}
          {renderVideoItem(currentStep?.isSectionCompleted || currentStep?.isPaid)}
        </StyledCourseContainer>
      );
    };

    const renderFinalTesting = (currentStep: CurrentStep) => {
      if (disabled || isSubscriptionExpired) {
        return (
          <ExpiredTooltip
            visible={isSubscriptionExpired}
            withoutArrow
            placement={'bottomLeft'}
            title={
              <>
                Testing is not available because your {expiredSubscriptionText}. To enable this,
                please <StyledLink to={USER_PLANS_PATH}>select a paid plan </StyledLink>
              </>
            }
          >
            <StyledFinalTestingContainer>
              <FinalTestingIcon />
              <StyledFinalTestingTitle>{currentStep.title}</StyledFinalTestingTitle>
              {/*{isHover && <OutLinkIcon />}*/}
            </StyledFinalTestingContainer>
          </ExpiredTooltip>
        );
      }
      if (currentStep !== null) {
        return (
          <StyledFinalTestingLinkContainer
            href={courseTestingLink[currentStep.courseTitle as CoursesTitle]}
            target="_blank"
            onMouseEnter={handleHover(true)}
            onMouseLeave={handleHover(false)}
          >
            <FinalTestingIcon />
            <StyledFinalTestingTitle>{currentStep.title}</StyledFinalTestingTitle>
            {isHover && <OutLinkIcon />}
          </StyledFinalTestingLinkContainer>
        );
      }
    };

    const renderContent = () => {
      if (!currentStep) return null;

      // if (currentStep.isCompleted) {
      //   return renderFinalTesting(currentStep);
      // }

      // if (!currentStep.isFree) {
      //   return renderPaidCourse();
      // }

      return renderVideoItem();
    };

    return <StyledContainer isShort={isShort}>{renderContent()}</StyledContainer>;
  },
);

export default CourseInfo;

