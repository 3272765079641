import { useEffect, useState } from 'react';
import { Button, Descriptions } from 'antd';
import EditUserModal from '../EditUserModal/EditUserModal';
import styled from 'styled-components';
import { EditFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { FETCH_USER_BY_EMAIL } from '../../../graphql/queries';
import { format } from 'date-fns';
import { Loader } from '../../../core/components/common';
import {
  getDefaultTypeMessage,
  getFirstAndLastName,
  getTitle,
} from '../../../features/courses/utils';
import { StyledModalContainer } from '../StyledComponents';

const StyledDescriptionItem = styled(Descriptions.Item)`
 /* margin-right: 20px; */
`;

const StyledItemSpan = styled.span`
  /* white-space: nowrap; */
  word-break: break-all;
`;

const StyledEditButton = styled(Button)``;

const DefaultCurriculumTypeSpan = styled.span`
  font-weight: 100;
  font-size: 12px;
  font-style: italic;
  margin-left: 5px;
  word-break: keep-all;
`;

export enum UserEditModalType {
  DisplayName = 'DisplayName',
  CurriculumType = 'CurriculumType',
  SubscriptionExpiration = 'SubscriptionExpiration',
  Email = 'Email',
}

interface UserInfoProps {
  email: string;
  onSuccess: () => void;
  onError: () => void;
  onEmailUpdate: (email: string) => void;
}

const UserInfo = ({ email, onSuccess, onError, onEmailUpdate }: UserInfoProps) => {
  const {
    data: userData,
    loading,
    refetch,
  } = useQuery(FETCH_USER_BY_EMAIL, {
    variables: { email },
    fetchPolicy: 'network-only',
  });
  const [showEditModal, setShowEditModal] = useState<UserEditModalType | null>(null);
  const { firstName, lastName } = getFirstAndLastName(userData?.fetchUserByEmail?.name);
  const expirationDate = userData?.fetchUserByEmail?.bmSubscription?.expiration
    ? +userData?.fetchUserByEmail?.bmSubscription?.expiration * 1000
    : 0;
  const [courseTitle1, courseTitle2] =
    userData?.fetchUserByEmail?.coursesAvailableList?.map((course) => getTitle(course)) ?? [];
  const defaultTypeMessage = getDefaultTypeMessage(userData?.fetchUserByEmail?.defaultType);
  useEffect(() => {
    if (!userData?.fetchUserByEmail && !loading) {
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  return (
    <StyledModalContainer>
      {loading && <Loader size={'large'} />}
      {!loading && !!userData?.fetchUserByEmail?.email ? (
        <>
          <Descriptions title="User Info" size={'default'} layout="vertical">
            <StyledDescriptionItem label="Email">
              <StyledItemSpan>{email}</StyledItemSpan>
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(UserEditModalType.Email)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Display name">
              <StyledItemSpan>{`${firstName} ${lastName} `}</StyledItemSpan>
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(UserEditModalType.DisplayName)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Curriculum" style={{ position: 'relative' }}>
              <StyledItemSpan>
                {courseTitle1} / {courseTitle2}
              </StyledItemSpan>
              {defaultTypeMessage && (
                <DefaultCurriculumTypeSpan>({defaultTypeMessage})</DefaultCurriculumTypeSpan>
              )}
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(UserEditModalType.CurriculumType)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Subscription">
              <StyledItemSpan>
                {userData?.fetchUserByEmail?.bmSubscription?.bmSubscriptionPlan}
              </StyledItemSpan>
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Expire">
              <StyledItemSpan>
                {expirationDate === 0 ? 'free for life' : format(expirationDate, 'MMM dd, yyyy')}
              </StyledItemSpan>
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(UserEditModalType.SubscriptionExpiration)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
          </Descriptions>
          <EditUserModal
            email={email}
            onError={onError}
            onSuccess={(email) => {
              if (email) {
                onEmailUpdate(email);
              } else {
                refetch();
              }
              onSuccess();
            }}
            type={showEditModal}
            onClose={() => {
              setShowEditModal(null);
            }}
          />
        </>
      ) : null}
    </StyledModalContainer>
  );
};

export default UserInfo;

