import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as PlayIcon } from '../svg/play.svg';
import { ReactComponent as SmallPlayIcon } from '../svg/small-play.svg';
import { ReactComponent as PlayIconWatched } from '../svg/play-watched.svg';
import { ReactComponent as WatchedPlayIcon } from '../svg/watched-play.svg';
import { ReactComponent as DownloadIcon } from '../svg/download.svg';
import { ReactComponent as TipsIcon } from '../svg/tips.svg';
import { ReactComponent as OpenIcon } from '../svg/chevron-down.svg';
import { ReactComponent as OpenSectionIcon } from '../svg/open-section.svg';
import { ReactComponent as CloseIcon } from '../svg/chevron-up.svg';
import { ReactComponent as CloseSectionIcon } from '../svg/close-section.svg';
import { ReactComponent as CertificateIcon } from '../svg/certification.svg';
import { Collapse, Steps, Tooltip } from 'antd';
import { Button } from '../../../core/components/common';
import { ReactComponent as HelpIcon } from '../svg/information.svg';
import FoundationBackgroundIcon from '../svg/bg-foundation.svg';
import FoundationBackgroundIconComplete from '../svg/bg-foundation-complete.svg';
import MasteringBackgroundIcon from '../svg/bg-mastering.svg';
import MasteringBackgroundIconComplete from '../svg/bg-mastering-complete.svg';
import CompleteFoundationsIcon from '../svg/complete-foundations-icon.png';
import CompleteMasteringIcon from '../svg/complete-mastering-icon.png';
import { CourseCardSettings, SECTION_WORKBOOK } from '../types';
import {
  ActiveVideoInfo,
  COURSES_STATE,
  CoursesFields,
  CoursesTitle,
} from '../../../apollo/stateFields/courses/coursesFields';
import { CoursesConstsArgsVideoValues, CoursesVideosSections } from '../../../graphql/types';
import { useQuery } from '@apollo/client';
import { FETCH_COURSES_SETTINGS } from '../../../graphql/queries';
import {
  BUY_MASTERING_COURSE_LINK,
  FOUNDATION_COURSE_TESTING_LINK,
  FUNDAMENTALS_COURSE_TESTING_LINK,
  FUNDAMENTALS_MANAGER_COURSE_TESTING_LINK,
  MASTERING_COURSE_TESTING_LINK,
  REMOTE_COURSE_TESTING_LINK,
  REMOTE_MANAGER_COURSE_TESTING_LINK,
} from '../constants';
import {
  checkCourseSpecialIdsEqual,
  getCourseInfoText,
  getIsAvailableVideo,
  getIsReadyForTest,
  getIsRecommendedAvailable,
  getPreviewVideo,
  getRestTime,
  getTitle,
  getWorkbookSize,
  isNextAvailable,
} from '../utils';
import { theme } from '../../../core/styles/styled-components';
import { replaceUnderscoreToSpace } from '../../../core/utils/global';
import { ReactComponent as RightArrowIcon } from '../../../core/svg/arrow-right.svg';
import { coursesMutation } from '../../../apollo/stateFields/courses';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import ExpiredTooltip from '../../../core/components/common/ExpiredTooltip/ExpiredTooltip';
import { Link } from 'react-router-dom';
import { USER_PLANS_PATH } from '../../../core/constants/routePaths';

const { Panel } = Collapse;
const { Step } = Steps;

const StyledTipsLink = styled.div<{ isAvailable?: boolean }>`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-grey);

  span {
    margin-right: 10px;
    color: var(--color-grey) !important;
  }

  svg {
    color: var(--color-grey) !important;
  }

  ${({ isAvailable }) =>
    isAvailable &&
    css`
      color: var(--color-dark-blue);
      cursor: pointer;

      span {
        color: var(--color-dark-blue) !important;
      }

      svg {
        color: var(--color-dark-blue) !important;
      }
    `}
`;

const StyledTipsIcon = styled(TipsIcon)<{ isAvailable?: boolean }>`
  color: var(--color-grey) !important;

  ${({ isAvailable }) =>
    isAvailable &&
    css`
      color: var(--color-dark-blue) !important;
    `}
`;

const StyledDownloadIcon = styled(DownloadIcon)<{ isAvailable?: boolean }>`
  color: var(--color-grey) !important;

  ${({ isAvailable }) =>
    isAvailable &&
    css`
      color: var(--color-dark-blue) !important;
    `}
`;

const StyledCourseCard = styled.div<{ cardTitle: CoursesTitle; isComplete: boolean }>`
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: 320px 170px;
  background-position: top 60px right;

  ${({ cardTitle }) =>
    cardTitle === 'Foundations' &&
    css`
      background-image: url(${FoundationBackgroundIcon});
    `}

  ${({ cardTitle }) =>
    cardTitle === 'MasteringPersonalEffectiveness' &&
    css`
      background-image: url(${MasteringBackgroundIcon});
    `}


  ${({ isComplete, cardTitle }) =>
    isComplete &&
    cardTitle === 'Foundations' &&
    css`
      background-color: #e6fbfe;
      background-image: url(${FoundationBackgroundIconComplete});
    `}

  ${({ isComplete, cardTitle }) =>
    isComplete &&
    cardTitle === 'MasteringPersonalEffectiveness' &&
    css`
      background-color: #efffec;
      background-image: url(${MasteringBackgroundIconComplete});
    `} 
  
  && {
    .mainHeader > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      width: 100%;

      .ant-collapse-arrow {
        right: auto;
        left: 140px;
      }
    }
  }
`;

const StyledCompleteIconWrapper = styled.div<{ title: CoursesTitle }>`
  position: absolute;
  right: 40px;
  top: 130px;
  z-index: 2000;
  width: 64px;
  height: 64px;
  
    ${({ title }) =>
      title === 'Foundations'
        ? css`
            background-image: url(${CompleteFoundationsIcon});
          `
        : css`
            background-image: url(${CompleteMasteringIcon});
          `}}
`;

const StyledTitleWrapper = styled.div`
  display: inline;
`;

const StyledCardTitle = styled.div`
  margin-bottom: 24px;
  max-width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dark-blue);

  @media ${theme.device.mobile.max} {
    max-width: 200px;
  }
`;

const StyledHelpIcon = styled(HelpIcon)`
  display: inline-block;
  margin-bottom: -4px;
  margin-left: 4px;
  cursor: pointer;
`;

const StyledNumberIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  white-space: nowrap;
`;

const StyledPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  flex-wrap: nowrap;

  cursor: pointer;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledDuration = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: var(--color-grey);
`;

const StyledPreviewTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-dark-blue);
  max-width: 250px;
  margin-left: 4px;
`;

const StyledNextVideoTime = styled.div`
  position: absolute;
  bottom: -15px;
  left: 37px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(32, 38, 53, 0.7);
  max-width: 250px;
  white-space: nowrap;
`;

const StyledCoursePlayWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  &:hover .playIcon {
    color: white;

    .bg {
      fill: black;
    }
  }
`;

const StyledPlayIcon = styled(PlayIcon)`
  transition: all ease-in-out 200ms;
`;

const StyledWatchedPlayIcon = styled(SmallPlayIcon)`
  transition: all ease-in-out 200ms;
  color: black;
`;

const StyledCollapse = styled(Collapse)<{ fullWidth?: boolean }>`
  && {
    background-color: transparent;

    .ant-collapse-header {
      display: flex;
      width: 100%;
    }

    .ant-collapse-item {
    }

    ${({ fullWidth }) =>
      fullWidth &&
      css`
        margin-bottom: 24px;
        margin-left: -40px;
        width: calc(100% + 50px);
        border-top: 1px solid #e9e9eb;

        .ant-collapse-item {
          border-color: #e9e9eb !important;
        }

        .ant-collapse-header {
          padding: 12px 24px;
        }

        .ant-steps {
          padding-left: 6px;
          padding-right: 8px;

          .ant-steps-item-title {
            padding-right: 0;
          }
        }
      `}

    .ant-collapse-content > .ant-collapse-content-box {
      padding-right: 0;
    }
  }
`;

const StyledSteps = styled(Steps)`
  && {
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: var(--color-green);
    }

    .ant-steps-item-content {
      display: flex;
      overflow: visible;
    }
    .ant-steps-item-icon {
      background-color: transparent;
    }
    //
    //.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    //  color: var(--color-dark-blue);
    //}
  }
`;

const StyledStep = styled(Step)<{ isAvailable: boolean }>`
  && {
    cursor: ${(props) => (props.isAvailable ? 'pointer' : 'default')};

    span {
      display: block;
      width: 100%;
      color: ${(props) => (props.isAvailable ? 'var(--color-dark-blue)' : 'var(--color-grey)')};
    }

    &:hover .playIcon {
      color: white;

      .bg {
        fill: black;
      }
    }
  }

  .ant-tooltip {
    @media ${theme.device.mobile.max} {
      left: -55px !important;
    }
  }

  .ant-steps-item-title {
    width: 100%;
    margin-top: 3px;
    padding-right: 0;
  }
`;

const StyledMaterial = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledMaterialTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-dark-blue);
  min-width: 200px;
`;

const StyledWordbookTitle = styled.div<{ isAvailable: boolean }>`
  font-size: 14px;
  line-height: 16px;
  min-width: 200px;
  color: ${(props) => (props.isAvailable ? 'var(--color-dark-blue)' : 'var(--color-grey)')};
`;

const StyledCollapseLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--color-dark-blue);
  opacity: 0.1;
  margin: 24px 0;
`;

const StyledCertificationTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-dark-blue);
  margin-bottom: 24px;
`;

const StyledCertificationSubTitle = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-dark-blue);
  padding-left: 34px;
  margin-bottom: 8px;
`;

const StyledCertificationText = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: rgba(32, 38, 53, 0.7);
  padding-left: 34px;
`;

const StyledCertificateIcon = styled(CertificateIcon)`
  position: absolute;
  left: 0;
`;

const StyledButton = styled(Button)`
  height: 24px;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 34px;
`;

const StyledPreviewButton = styled(Button)`
  height: 24px;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 4px;
`;

const StyledExpiredTooltip = styled(ExpiredTooltip)`
  justify-content: flex-start;
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: var(--color-white);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  &:focus,
  &:hover,
  &:active {
    color: var(--color-white);
    opacity: 0.8;
  }
`;

export const courseTestingLink = {
  [CoursesTitle.Foundations]: FOUNDATION_COURSE_TESTING_LINK,
  [CoursesTitle.MasteringPersonalEffectiveness]: MASTERING_COURSE_TESTING_LINK,
  [CoursesTitle.Fundamentals]: FUNDAMENTALS_COURSE_TESTING_LINK,
  [CoursesTitle.RemoteWorkProfessional]: REMOTE_COURSE_TESTING_LINK,
  [CoursesTitle.FundamentalsManager]: FUNDAMENTALS_MANAGER_COURSE_TESTING_LINK,
  [CoursesTitle.RemoteWorkProfessionalManager]: REMOTE_MANAGER_COURSE_TESTING_LINK,
};

const CourseCard = ({ course, title }: CourseCardSettings) => {
  const { data: coursesData } = useQuery(COURSES_STATE);
  const {
    expandCards,
    expandSections,
    showCoursesPopup,
    firstCourseTitle,
    secondCourseTitle,
  }: CoursesFields = coursesData?.courses;
  const {
    setShowVideoPlayer,
    setTipsSection,
    setExpandCard,
    setExpandSection,
    setShowCoursesPopup,
  } = coursesMutation;
  const { data } = useQuery(FETCH_COURSES_SETTINGS);
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired, expiredSubscriptionText }: StripeValue = stripeData?.stripe;

  const currentCourseConstData = useMemo(
    () => data?.fetchUserSettings.coursesConsts[title as CoursesTitle],
    [data?.fetchUserSettings],
  );
  const currentCourseVarData = useMemo(
    () => data?.fetchUserSettings.coursesVars[title as CoursesTitle],
    [data?.fetchUserSettings],
  );

  const isFirstCourseDone =
    getIsReadyForTest(
      firstCourseTitle,
      data?.fetchUserSettings.coursesVars?.[firstCourseTitle],
      data?.fetchUserSettings.coursesConsts?.[firstCourseTitle],
    ) || data?.fetchUserSettings.coursesConsts?.[secondCourseTitle]?.isCourseAvailable;

  const isComplete =
    title === firstCourseTitle
      ? !!currentCourseConstData?.certificateUrl
      : !!currentCourseConstData?.certificateUrl;

  const isReadyForTest = getIsReadyForTest(
    title as CoursesTitle,
    currentCourseVarData,
    currentCourseConstData,
  );

  const nextPreviewVideo = useMemo(
    () =>
      getPreviewVideo(
        title as CoursesTitle,
        currentCourseVarData,
        currentCourseConstData,
        isSubscriptionExpired,
        title === firstCourseTitle ? undefined : isFirstCourseDone,
      ),
    [currentCourseVarData, currentCourseConstData, isFirstCourseDone, title],
  );

  const getNextAvailableVideoTime = useCallback(() => {
    return getRestTime(currentCourseVarData?.dateLastWatched, isSubscriptionExpired) <= 1 &&
      getRestTime(currentCourseVarData?.dateLastWatched, isSubscriptionExpired) !== 0
      ? 'less than 1'
      : title === firstCourseTitle &&
        currentCourseConstData?.videos?.length &&
        checkCourseSpecialIdsEqual(nextPreviewVideo?.id, 11)
      ? 0
      : title === secondCourseTitle &&
        checkCourseSpecialIdsEqual(nextPreviewVideo?.id, 13) &&
        !currentCourseVarData?.watchedVideoIds?.some((id) => checkCourseSpecialIdsEqual(id, 13))
      ? 0
      : Math.floor(getRestTime(currentCourseVarData?.dateLastWatched, isSubscriptionExpired));
  }, [currentCourseVarData, title, nextPreviewVideo, new Date().getHours()]);

  const nextAvailableVideoTime = getNextAvailableVideoTime();

  const handlePlay = (activeVideoInfo: ActiveVideoInfo | null) => () => {
    if (activeVideoInfo) {
      setShowVideoPlayer(activeVideoInfo);
    }
  };

  const handleBuyCourse = () => {
    window.open(BUY_MASTERING_COURSE_LINK, '_blank');
  };

  const handleFinalTesting = () => {
    title && window.open(courseTestingLink[title], '_blank');
  };

  const handleSertificate = () => {
    currentCourseConstData?.certificateUrl &&
      window.open(currentCourseConstData?.certificateUrl, '_blank');
  };

  const generateSectionsData = () => {
    let sectionsData: { [key: string]: CoursesConstsArgsVideoValues[] } = {};
    let sectionsList = [];

    course?.videos?.forEach((video) => {
      if (video.section) {
        if (
          video.name !== 'Program Overview' &&
          video.name !== 'About Remote Work Professional' &&
          video.name !== 'MANAGER: About Remote Work Manager'
        ) {
          if (sectionsData[video.section]) {
            sectionsData[video.section].push(video);
          } else {
            sectionsData[video.section] = [video];
          }
        }
      }
    });

    for (const key in sectionsData) {
      sectionsList.push({
        title: key,
        videos: sectionsData[key],
      });
    }
    return sectionsList;
  };

  const renderWorkbook = (
    title: string,
    sectionName: CoursesVideosSections,
    isAvailable: boolean,
  ) => {
    return (
      <StyledMaterial>
        <ExpiredTooltip
          visible={isSubscriptionExpired && showCoursesPopup}
          title={
            <>
              You cannot download this workbook because your {expiredSubscriptionText}. To enable
              this, please{' '}
              <StyledLink
                to={USER_PLANS_PATH}
                onClick={() => {
                  setShowCoursesPopup(false);
                }}
              >
                select a paid plan{' '}
              </StyledLink>
            </>
          }
          width={'310px'}
          withoutArrow
          placement={'top'}
          overlayClassName={'workbookTooltip'}
        >
          <StyledWordbookTitle isAvailable={isAvailable}>{title}</StyledWordbookTitle>
        </ExpiredTooltip>
        <StyledDuration>{getWorkbookSize(sectionName)}</StyledDuration>
      </StyledMaterial>
    );
  };

  const renderTipsTitle = (isAvailable: boolean) => (
    <StyledTipsLink isAvailable={isAvailable}>
      <ExpiredTooltip
        visible={isSubscriptionExpired && showCoursesPopup}
        title={
          <>
            You cannot access Hacks, Tips and Tricks because your {expiredSubscriptionText}. To
            enable this, please{' '}
            <StyledLink
              to={USER_PLANS_PATH}
              onClick={() => {
                setShowCoursesPopup(false);
              }}
            >
              select a paid plan{' '}
            </StyledLink>
          </>
        }
        width={'310px'}
        withoutArrow
        placement={'top'}
        overlayClassName={'tipsTooltip'}
      >
        <span>Hacks, Tips & Tricks</span>
      </ExpiredTooltip>
      <RightArrowIcon />
    </StyledTipsLink>
  );

  const renderVideoMaterial = (
    title: string,
    duration: string,
    isAvailable: boolean,
    isNext: boolean,
  ) => {
    const getMaterialTitle = () => {
      const plansLink = (
        <StyledLink
          to={USER_PLANS_PATH}
          onClick={() => {
            setShowCoursesPopup(false);
          }}
        >
          select a paid plan{' '}
        </StyledLink>
      );
      if (title.indexOf('Masterclass') !== -1) {
        return 'Discover from the experts how to apply these foundations in your life';
      } else if (isNext && nextAvailableVideoTime !== 0) {
        return (
          <>
            Your next video will be available in {nextAvailableVideoTime} hour
            {(nextAvailableVideoTime as number) < 2 || nextAvailableVideoTime === 'less than 1'
              ? ''
              : 's'}
            . To make videos available on demand, please {plansLink}
          </>
        );
      } else {
        return '';
      }
    };

    return (
      <StyledMaterial>
        <StyledMaterialTitle>
          {isAvailable ? (
            title
          ) : (
            <ExpiredTooltip
              placement="top"
              width={'280px'}
              withoutArrow={true}
              visible={
                ((isNext && nextAvailableVideoTime !== 0) || title.indexOf('Masterclass') !== -1) &&
                showCoursesPopup
              }
              title={getMaterialTitle()}
              overlayClassName={'materialTooltip'}
            >
              {title}
            </ExpiredTooltip>
          )}
        </StyledMaterialTitle>
        <StyledDuration>{duration}</StyledDuration>
      </StyledMaterial>
    );
  };

  const renderSteps = (videos?: CoursesConstsArgsVideoValues[]) => {
    if (!videos?.length) {
      return null;
    }
    const isWorkbookAvailable =
      title === secondCourseTitle &&
      checkCourseSpecialIdsEqual(videos[0].id, 13) &&
      isFirstCourseDone &&
      !isSubscriptionExpired
        ? true
        : getIsAvailableVideo(
            videos[0],
            isFirstCourseDone,
            currentCourseVarData?.watchedVideoIds,
            currentCourseVarData?.lastWatchedId,
            currentCourseVarData?.dateLastWatched,
            currentCourseConstData,
            isSubscriptionExpired,
          ) && !isSubscriptionExpired;

    const isTooltipAvailable =
      getIsAvailableVideo(
        videos[videos.length - 1],
        isFirstCourseDone,
        currentCourseVarData?.watchedVideoIds,
        currentCourseVarData?.lastWatchedId,
        currentCourseVarData?.dateLastWatched,
        currentCourseConstData,
        isSubscriptionExpired,
      ) && !isSubscriptionExpired;

    return (
      <StyledSteps
        direction="vertical"
        size="small"
        current={
          // (currentCourseVarData?.watchedVideoIds[
          //   currentCourseVarData?.watchedVideoIds.length - 1
          // ] || -1) + 1
          0
        }
      >
        {!!videos[0].section &&
          secondCourseTitle !== CoursesTitle.RemoteWorkProfessional &&
          secondCourseTitle !== CoursesTitle.RemoteWorkProfessionalManager && (
            <StyledStep
              title={renderWorkbook('Download Workbook', videos[0].section, isWorkbookAvailable)}
              isAvailable={isWorkbookAvailable}
              icon={<StyledDownloadIcon isAvailable={isWorkbookAvailable} />}
              onClick={() => {
                window &&
                  isWorkbookAvailable &&
                  window
                    ?.open(
                      SECTION_WORKBOOK[videos[0].section as keyof typeof SECTION_WORKBOOK],
                      '_blank',
                    )
                    ?.focus();
              }}
            />
          )}

        {videos.map((video, index) => {
          const isAvailable =
            title === firstCourseTitle &&
            data?.fetchUserSettings.coursesConsts?.[secondCourseTitle]?.isCourseAvailable
              ? true
              : title === secondCourseTitle &&
                checkCourseSpecialIdsEqual(video.id, 13) &&
                isFirstCourseDone
              ? true
              : getIsAvailableVideo(
                  video,
                  title === firstCourseTitle ? true : isFirstCourseDone,
                  currentCourseVarData?.watchedVideoIds,
                  currentCourseVarData?.lastWatchedId,
                  currentCourseVarData?.dateLastWatched,
                  currentCourseConstData,
                  isSubscriptionExpired,
                );
          const lastWatchedVideoId = currentCourseVarData?.lastWatchedId;
          const isNext = isNextAvailable(
            title as CoursesTitle,
            video,
            lastWatchedVideoId,
            currentCourseConstData,
          );
          const isRecommendedAvailable = getIsRecommendedAvailable(
            video,
            currentCourseVarData?.watchedVideoIds,
            currentCourseConstData,
          );

          return (
            <StyledStep
              key={video.id}
              onClick={handlePlay(isAvailable && title ? { video, courseTitle: title } : null)}
              isAvailable={isAvailable}
              icon={
                currentCourseVarData?.watchedVideoIds?.some((id) => id === video.id) ? (
                  <WatchedPlayIcon />
                ) : (isNext && isAvailable) ||
                  isRecommendedAvailable ||
                  (title === firstCourseTitle &&
                    data?.fetchUserSettings.coursesConsts?.[secondCourseTitle]
                      ?.isCourseAvailable) ? (
                  <StyledWatchedPlayIcon className={'playIcon'} />
                ) : (
                  <StyledNumberIcon>{index + 1}</StyledNumberIcon>
                )
              }
              title={renderVideoMaterial(video.name, video.duration, isAvailable, isNext)}
            />
          );
        })}
        {!!videos[0].section &&
          secondCourseTitle !== CoursesTitle.RemoteWorkProfessional &&
          secondCourseTitle !== CoursesTitle.RemoteWorkProfessionalManager && (
            <StyledStep
              title={renderTipsTitle(isTooltipAvailable)}
              isAvailable={isTooltipAvailable}
              icon={<StyledTipsIcon isAvailable={isTooltipAvailable} />}
              onClick={() => {
                isTooltipAvailable && setTipsSection(videos[0].section ?? null);
              }}
            />
          )}
      </StyledSteps>
    );
  };
  const renderStepsContent = () => {
    if (course?.videos?.[0].section) {
      const sections = generateSectionsData();

      return (
        <StyledCollapse
          onChange={(e) => {
            !!title &&
              setExpandSection({
                titles: e as CoursesVideosSections[] | CoursesVideosSections,
              });
          }}
          defaultActiveKey={expandSections}
          bordered={false}
          fullWidth={true}
          expandIcon={({ isActive }) => (isActive ? <CloseSectionIcon /> : <OpenSectionIcon />)}
          expandIconPosition="right"
        >
          {sections.map((section) => (
            <Panel header={replaceUnderscoreToSpace(section.title)} key={section.title}>
              {renderSteps(section.videos)}
            </Panel>
          ))}
        </StyledCollapse>
      );
    } else {
      return (
        <>
          {renderSteps(course?.videos!)}
          {!currentCourseConstData?.certificateUrl && <StyledCollapseLine />}
        </>
      );
    }
  };

  const courseName = getTitle(title as CoursesTitle);
  const getIsNextVideTimeShow = () => {
    if (title === firstCourseTitle) {
      const sortedWatchedIds = currentCourseVarData?.watchedVideoIds?.slice().sort((a, b) => a - b);
      if (
        checkCourseSpecialIdsEqual(
          sortedWatchedIds?.length && sortedWatchedIds[sortedWatchedIds.length - 1],
          10,
        )
      ) {
        return false;
      }
      return (
        !!nextAvailableVideoTime &&
        !data?.fetchUserSettings.coursesConsts?.[secondCourseTitle]?.isCourseAvailable
      );
    } else {
      if (
        !!nextAvailableVideoTime &&
        !data?.fetchUserSettings.coursesConsts?.[secondCourseTitle]?.isCourseAvailable &&
        checkCourseSpecialIdsEqual(nextPreviewVideo?.id, 13)
      ) {
        return false;
      } else if (
        nextAvailableVideoTime !== 0 &&
        data?.fetchUserSettings.coursesConsts?.[secondCourseTitle]?.isCourseAvailable &&
        checkCourseSpecialIdsEqual(nextPreviewVideo?.id, 13)
      ) {
        return true;
      }
      return (
        !!nextAvailableVideoTime &&
        data?.fetchUserSettings.coursesConsts?.[secondCourseTitle]?.isCourseAvailable &&
        !checkCourseSpecialIdsEqual(nextPreviewVideo?.id, 12)
      );
    }
  };

  return (
    <>
      <StyledCourseCard
        // data-video-tutorial={index}
        cardTitle={title as CoursesTitle}
        isComplete={isComplete}
      >
        <StyledCardTitle>
          <StyledTitleWrapper>
            {courseName}
            {!isComplete && (
              <Tooltip
                getTooltipContainer={() => document.body}
                overlayClassName={'titleTooltip'}
                title={getCourseInfoText(title)}
              >
                <StyledHelpIcon />
              </Tooltip>
            )}
          </StyledTitleWrapper>
        </StyledCardTitle>

        {isComplete ? (
          <StyledCertificationSubTitle
            style={{ padding: '28px 0 28px 30px', margin: '5px 0', cursor: 'pointer' }}
            onClick={handleSertificate}
          >
            <StyledCertificateIcon style={{ top: '22px' }} />
            Open a certificate
          </StyledCertificationSubTitle>
        ) : (
          <StyledPreview>
            <StyledWrapper>
              {isReadyForTest && (
                <ExpiredTooltip
                  visible={isSubscriptionExpired && showCoursesPopup}
                  withoutArrow
                  placement={'topLeft'}
                  title={
                    <>
                      Testing is not available because your {expiredSubscriptionText}. To enable
                      this, please{' '}
                      <StyledLink
                        to={USER_PLANS_PATH}
                        onClick={() => {
                          setShowCoursesPopup(false);
                        }}
                      >
                        select a paid plan{' '}
                      </StyledLink>
                    </>
                  }
                >
                  <StyledPreviewButton
                    isDisabled={isSubscriptionExpired}
                    onClick={handleFinalTesting}
                  >
                    {title === firstCourseTitle ? `Verify my learning` : `Start final testing`}
                  </StyledPreviewButton>
                </ExpiredTooltip>
              )}
              {/*{!currentCourseConstData?.isCourseAvailable && !isReadyForTest && (*/}
              {/*  <StyledPreviewButton onClick={handleBuyCourse}>Join Program</StyledPreviewButton>*/}
              {/*)}*/}
              <StyledCoursePlayWrapper
                onClick={() => {
                  if (nextPreviewVideo?.url.length && title) {
                    setShowVideoPlayer({ video: nextPreviewVideo, courseTitle: title });
                  }
                }}
              >
                {currentCourseVarData?.watchedVideoIds?.some(
                  (id) => id === nextPreviewVideo?.id,
                ) ? (
                  <PlayIconWatched />
                ) : (
                  <StyledPlayIcon className={'playIcon'} />
                )}
                <StyledPreviewTitle>{nextPreviewVideo?.name}</StyledPreviewTitle>
                {getIsNextVideTimeShow() && (
                  <StyledNextVideoTime>
                    {`Next available in ${nextAvailableVideoTime}h`}
                  </StyledNextVideoTime>
                )}
              </StyledCoursePlayWrapper>
            </StyledWrapper>
            <StyledDuration>{nextPreviewVideo?.duration}</StyledDuration>
          </StyledPreview>
        )}

        <StyledCollapse
          ghost
          bordered={false}
          expandIcon={({ isActive }) => (isActive ? <CloseIcon /> : <OpenIcon />)}
          expandIconPosition="right"
          className={'mainHeader'}
          onChange={(e) => {
            !!title && setExpandCard({ title, operation: e.length ? 'add' : 'remove' });
          }}
          defaultActiveKey={expandCards.find((cardTitle) => cardTitle === title)}
        >
          <Panel header="Video materials" key={title!}>
            {renderStepsContent()}
            {!currentCourseConstData?.certificateUrl && (
              <>
                <StyledCertificationTitle>
                  {title === firstCourseTitle
                    ? `Testing and Certification`
                    : `Final testing and certification`}
                </StyledCertificationTitle>
                <StyledCertificationSubTitle>
                  <StyledCertificateIcon />
                  {title === firstCourseTitle
                    ? 'Verify your learning and earn a certificate'
                    : 'Earn your Remote Work Professional Certification'}
                </StyledCertificationSubTitle>
                <StyledCertificationText>
                  {title === firstCourseTitle
                    ? 'If you have been watching each video and performing the activities suggested, you should be ready to take and pass this Remote Work Fundamentals Exam.'
                    : 'If you have watched the videos, practiced the techniques, and embedded your learning in this tool, you should now be ready to take and pass the Remote Work Professional Exam.'}
                </StyledCertificationText>
                {isReadyForTest && (
                  <StyledExpiredTooltip
                    visible={isSubscriptionExpired && showCoursesPopup}
                    withoutArrow
                    placement={'topLeft'}
                    title={
                      <>
                        Testing is not available because your {expiredSubscriptionText}. To enable
                        this, please{' '}
                        <StyledLink
                          to={USER_PLANS_PATH}
                          onClick={() => {
                            setShowCoursesPopup(false);
                          }}
                        >
                          select a paid plan{' '}
                        </StyledLink>
                      </>
                    }
                  >
                    <StyledButton isDisabled={isSubscriptionExpired} onClick={handleFinalTesting}>
                      {title === firstCourseTitle ? `Verify my learning` : `Start final testing`}
                    </StyledButton>
                  </StyledExpiredTooltip>
                )}
              </>
            )}
          </Panel>
        </StyledCollapse>
        {isComplete && <StyledCompleteIconWrapper title={title as CoursesTitle} />}
      </StyledCourseCard>
    </>
  );
};

export default CourseCard;

