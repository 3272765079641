import { Table } from 'antd';
import { StyledDescription } from '../StyledComponents';
import { FetchSharedWorkspacesQuery, Workspace } from '__generated__/graphql';
import { Member, useSharedSpacesTableColumns } from './consts';
import { Divider } from 'antd/es';
import { memo, useCallback } from 'react';

export interface ISharedSpacesTableProps {
  dataSource: FetchSharedWorkspacesQuery['fetchSharedWorkspaces'][number];
  isLoading?: boolean;
  onAssignPrimaryOwner: (workspace: Pick<Workspace, 'id'>, user: Member) => void;
  onViewEdit: (workspace: Pick<Workspace, 'id'>, user: Member) => void;
  onDelete: (workspace: Pick<Workspace, 'id'>, user: Member) => void;
}

const SharedSpacesTable = ({
  dataSource,
  isLoading,
  onAssignPrimaryOwner,
  onDelete,
  onViewEdit,
}: ISharedSpacesTableProps) => {
  const { id, name, members } = dataSource;
  const handleAssignPrimaryOwner = useCallback(
    (user: Member) => {
      onAssignPrimaryOwner(dataSource, user);
    },
    [dataSource, onAssignPrimaryOwner],
  );
  const handleDelete = useCallback(
    (user: Member) => {
      onDelete(dataSource, user);
    },
    [dataSource, onDelete],
  );
  const handleViewEdit = useCallback(
    (user: Member) => {
      onViewEdit(dataSource, user);
    },
    [dataSource, onViewEdit],
  );

  const columns = useSharedSpacesTableColumns({
    onAssignPrimaryOwner: handleAssignPrimaryOwner,
    onViewEdit: handleViewEdit,
    onDelete: handleDelete,
  });

  return (
    <>
      <StyledDescription>
        {id} - {name}
      </StyledDescription>
      {members && (
        <Table dataSource={members} rowKey="email" loading={isLoading} columns={columns} />
      )}
      <Divider dashed type="horizontal" />
    </>
  );
};

export default memo(SharedSpacesTable) as typeof SharedSpacesTable;

