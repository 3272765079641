import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { toast, ToastContent } from 'react-toastify';
import { Button } from 'core/components/common';
import { Entity } from 'core/types/entity';
import { ReactComponent as AddIcon } from 'core/svg/add.svg';
import { CreateEntityFormCard } from '../CreateEntityFormCard';
import { EntityCardColumnProps } from './types';
import { OutcomeStatus } from '../../../../core/types/outcome';
import { useQuery } from '@apollo/client';
import {
  TUTORIAL_STATE,
  TutorialValue,
} from '../../../../apollo/stateFields/tutorial/tutorialField';
import useDndContext from '../../../../context/dndContext/useDndContext';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { HeightPreservingItem } from '../../../../core/components/virtuoso/HeightPreserving/HeightPreserving';
import theme from '../../../../core/styles/styled-components/theme';
import { getColumnHeight } from '../../../utils/getColumnSize';
import { useMediaQuery } from 'react-responsive';
import { ScrollSeekPlaceholder } from '../../../../core/components/virtuoso/ScrollSeekPlaceholder/ScrollSeekPlaceholder';

const StyledContainer = styled.div<{ isOutcome: boolean; isCompletedOutcome: boolean }>`
  width: 16.278rem;
  padding: 8px 3px 8px 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  ${(props) => props.isOutcome && 'max-height: calc(100% - 32px);'}
  ${(props) => props.isCompletedOutcome && 'max-height: calc(100% - 78px);'}
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.444rem;
`;

const StyledTitle = styled.span`
  height: 24px;
  font-size: 0.778rem;
  font-weight: 600;
`;

const StyledCardWrapper = styled.div<{
  isScrollable: boolean;
  isAdding: boolean;
  isCompletedOutcome: boolean;
}>`
  && {
    padding-right: 5px;

    @media ${theme.device.desktop.min} {
      height: calc(var(--app-height) - 145px);
    }

    @media ${theme.device.tablet.max} {
      height: calc(var(--app-height) - 270px);
    }

    @media ${theme.device.mobile.max} {
      height: calc(var(--app-height) - 200px);
    }

    // {(props) => props.isScrollable && 'overflow-y: auto;'}
    // {(props) => props.isScrollable && 'overflow-x: hidden;'}
    //
    // {(props) => props.isAdding && 'height: calc(100% - 132px);'}
    //
    // &::-webkit-scrollbar {
    //   width: 4px;
    // }
    //
    // &::-webkit-scrollbar-track {
    //   background: transparent;
    // }
    //
    // &::-webkit-scrollbar-thumb {
    //   background: #a4a6ad;
    //   border-radius: 10px;
    // }
  }
`;

const StyledCardContainer = styled.div<{
  isDraggable?: boolean;
  isDraggingOver?: boolean;
  isMovingDisabled?: boolean;
}>`
  ${(props) =>
    props.isDraggable &&
    css`
      min-height: 24px;
    `}

  ${(props) =>
    props.isDraggingOver &&
    css`
      background-color: rgba(255, 255, 255, 0.5);
    `}
  }

  ${(props) =>
    props.isMovingDisabled &&
    css`
      & [data-rbd-draggable-id] {
        transform: none !important;
      }
      & [data-rbd-placeholder-context-id] {
        display: none !important;
      }
    `}
`;

const StyledVirtuoso = styled(Virtuoso)`
  &::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad !important;
    border-radius: 10px !important;
  }
`;

const StyledCardFormContainer = styled.div`
  margin-bottom: 0.167rem;
`;

const StyledAddingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 0;
`;

const StyledAddCardButton = styled(Button)`
  padding: 0;
  font-size: 0.667rem;
`;

const StyledTopList = styled.div`
  visibility: hidden;
`;

const EntityCardColumnComponent = <T extends Entity>({
  isCombineEnabled = false,
  isDropDisabled = false,
  isMovingDisabled = false,
  isDraggable = true,
  isDroppable = true,
  isCreatePossible = true,
  className,
  name,
  title,
  cardData,
  renderCard,
  onSubmit,
  entityName,
  renderClone,
  dataTutorial,
  columnFilter,
  viewport,
}: EntityCardColumnProps<T>) => {
  const { currentOutcomeColumnStatus } = useDndContext();
  const [showForm, setShowForm] = useState(false);
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { currentStep: currentTutorialStep }: TutorialValue = tutorialData?.tutorial;
  const [scrollable, setScrollable] = useState(false);

  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const columnRef = useRef<HTMLDivElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const isCompletedOutcome =
    currentOutcomeColumnStatus === OutcomeStatus.Completed && entityName === 'Outcome';

  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const isTablet = useMediaQuery({ query: theme.device.tablet.max });
  const isDesktop = useMediaQuery({ query: theme.device.desktop.max });

  const cardsEl = useMemo(() => cardData.map(renderCard), [cardData, renderCard]);

  const onSubmitForm = async (values: Partial<Entity>) => {
    try {
      await onSubmit(values);
    } catch (error) {
      console.error(error);
      toast(error as ToastContent);
    } finally {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (columnRef.current && columnRef.current.clientHeight >= 170) {
      setScrollable(true);
      return;
    } else {
      setScrollable(false);
    }
  });

  return (
    <StyledContainer
      className={className}
      isOutcome={entityName === 'Outcome'}
      isCompletedOutcome={isCompletedOutcome}
      data-tutorial={dataTutorial}
    >
      {title && (
        <StyledHeader>
          <StyledTitle>{title}</StyledTitle>
          {columnFilter && columnFilter}
        </StyledHeader>
      )}
      {isCreatePossible && (
        <>
          <StyledAddingWrapper>
            <StyledAddCardButton
              isSecondary
              icon={<AddIcon />}
              onClick={(e) => {
                setShowForm(true);
                if (virtuosoRef.current) {
                  virtuosoRef?.current?.scrollIntoView({
                    index: 0,
                    behavior: 'smooth',
                  });
                  e.preventDefault();
                }
              }}
              ref={addButtonRef}
            >
              Create {entityName}
            </StyledAddCardButton>
          </StyledAddingWrapper>
          <StyledCardFormContainer>
            {showForm && (
              <CreateEntityFormCard
                isShowWorkspaceSelect={true}
                entityName={entityName}
                closeForm={setShowForm}
                onSubmit={async (values) => {
                  await onSubmitForm(values);
                  setShowForm(false);
                }}
                onCancel={() => setShowForm(false)}
                addButtonRef={addButtonRef}
              />
            )}
          </StyledCardFormContainer>
        </>
      )}
      {currentTutorialStep === -1 && (
        <StyledCardWrapper
          className="column-card-wrapper"
          ref={columnRef}
          isScrollable={scrollable}
          isAdding={showForm}
          isCompletedOutcome={isCompletedOutcome}
        >
          {isDroppable ? (
            <Droppable
              isCombineEnabled={isCombineEnabled}
              isDropDisabled={isDropDisabled}
              droppableId={name}
              renderClone={renderClone}
              mode={'virtual'}
            >
              {(provided, snapshot) => {
                return (
                  <StyledCardContainer
                    isDraggable={isDraggable}
                    isDraggingOver={snapshot.isDraggingOver}
                    isMovingDisabled={isMovingDisabled}
                    ref={provided.innerRef}
                  >
                    {entityName !== 'Note' ? (
                      <>
                        <StyledVirtuoso
                          ref={virtuosoRef}
                          increaseViewportBy={{ top: viewport ?? 500, bottom: viewport ?? 500 }}
                          components={{
                            Item: HeightPreservingItem,
                            ScrollSeekPlaceholder,
                          }}
                          scrollSeekConfiguration={{
                            enter: (velocity) => Math.abs(velocity) > 150,
                            exit: (velocity) => {
                              const shouldExit = Math.abs(velocity) < 50;
                              return shouldExit;
                            },
                            // change: (_velocity, { startIndex, endIndex }) =>
                            // console.log(_velocity, startIndex, endIndex),
                          }}
                          scrollerRef={provided.innerRef as any}
                          data={cardData}
                          style={{
                            width: columnRef?.current?.getBoundingClientRect().width,
                            // entityName === 'Note'
                            //   ? columnRef?.current?.getBoundingClientRect().width &&
                            //     columnRef?.current?.getBoundingClientRect().width - 8
                            //   : columnRef?.current?.getBoundingClientRect().width,
                            height: getColumnHeight(
                              isMobile,
                              isTablet,
                              isDesktop,
                              showForm,
                              entityName,
                            ),
                          }}
                          itemContent={renderCard as any}
                        />
                        <style
                          dangerouslySetInnerHTML={{
                            __html: `
                        [data-known-size]:empty {
                        height: var(--data-known-size)
                      }`,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {cardsEl}
                        {provided.placeholder}
                      </>
                    )}
                  </StyledCardContainer>
                );
              }}
            </Droppable>
          ) : (
            <StyledCardContainer isDraggable={false}>{cardsEl}</StyledCardContainer>
          )}
        </StyledCardWrapper>
      )}
    </StyledContainer>
  );
};

export const EntityCardColumn = React.memo(EntityCardColumnComponent);

