import { axiosGql } from 'features/utils/axiosGql';
import {
  CheckFirebaseUserByEmailQuery,
  CheckFirebaseUserByEmailQueryVariables,
  GeneratePasswordChangedEmailMutation,
  GeneratePasswordChangedEmailMutationVariables,
  GeneratePasswordRecoveryEmailMutation,
  GeneratePasswordRecoveryEmailMutationVariables,
  GenerateVerificationEmailMutation,
  GenerateVerificationEmailMutationVariables,
  GetUserInfoFromDbQuery,
  InitNewFbUserMutation,
  SetUserEmailVerifiedMutation,
  UpdateFirebaseUserMutation,
  UpdateFirebaseUserMutationVariables,
  UpdateUserEntityHttpMutation,
  UpdateUserEntityHttpMutationVariables,
  UserFirebaseInput,
} from '__generated__/graphql';

export const generateVerificationEmail = (
  firstName: string,
  lastName: string,
  email: string,
  link?: string,
) =>
  axiosGql<GenerateVerificationEmailMutation, GenerateVerificationEmailMutationVariables>(
    /* GraphQL */ `
      mutation GenerateVerificationEmail(
        $firstName: String
        $lastName: String
        $email: String!
        $link: String
      ) {
        generateVerificationEmail(
          firstName: $firstName
          lastName: $lastName
          email: $email
          link: $link
        )
      }
    `,
    {
      variables: {
        firstName,
        lastName,
        email,
        link,
      },
      admin: 'a75d4e00-16c9-4bf2-9e9d-3a81aaa7b589',
    },
  );

export const generatePasswordRecoveryEmail = (email: string) =>
  axiosGql<GeneratePasswordRecoveryEmailMutation, GeneratePasswordRecoveryEmailMutationVariables>(
    /* GraphQL */ `
      mutation GeneratePasswordRecoveryEmail($email: String!) {
        generatePasswordRecoveryEmail(email: $email)
      }
    `,
    {
      variables: { email },
      admin: 'a75d4e00-16c9-4bf2-9e9d-3a81aaa7b589',
    },
  );

export const generatePasswordChangedEmail = (token: string) =>
  axiosGql<GeneratePasswordChangedEmailMutation, GeneratePasswordChangedEmailMutationVariables>(
    /* GraphQL */ `
      mutation GeneratePasswordChangedEmail {
        generatePasswordChangedEmail
      }
    `,
    {
      tokenId: token,
    },
  );

export const setUserEmailVerified = (idToken: string) =>
  axiosGql<SetUserEmailVerifiedMutation>(
    /* GraphQL */ `
      mutation SetUserEmailVerified {
        setUserEmailVerified {
          email
        }
      }
    `,
    {
      tokenId: idToken,
    },
  );

export type { UserFirebaseInput } from '__generated__/graphql';

export const updateFirebaseUser = (idToken: string, values: UserFirebaseInput) =>
  axiosGql<UpdateFirebaseUserMutation, UpdateFirebaseUserMutationVariables>(
    /* GraphQL */ `
      mutation UpdateFirebaseUser($values: UserFirebaseInput!) {
        updateFirebaseUser(values: $values) {
          email
        }
      }
    `,
    {
      variables: { values },
      tokenId: idToken,
    },
  );

export const checkFirebaseUserByEmail = (email: string) =>
  axiosGql<CheckFirebaseUserByEmailQuery, CheckFirebaseUserByEmailQueryVariables>(
    /* GraphQL */ `
      query CheckFirebaseUserByEmail($email: String!) {
        checkFirebaseUserByEmail(email: $email)
      }
    `,
    {
      variables: { email },
      admin: 'a75d4e00-16c9-4bf2-9e9d-3a81aaa7b589',
    },
  );

export const getUserInfoFromDB = (token: string) =>
  axiosGql<GetUserInfoFromDbQuery>(
    /* GraphQL */ `
      query GetUserInfoFromDB {
        fetchUser {
          email
          id
          isFBaccountWithPhone
        }
      }
    `,
    {
      tokenId: token,
    },
  );

export const updateUserEntityHttp = (
  isFBaccountWithPhone: boolean,
  tokenId: string,
  email?: string,
) =>
  axiosGql<UpdateUserEntityHttpMutation, UpdateUserEntityHttpMutationVariables>(
    /* GraphQL */ `
      mutation UpdateUserEntityHttp($userParams: UserInput!) {
        updateUserEntity(userParams: $userParams) {
          email
          isFBaccountWithPhone
        }
      }
    `,
    {
      tokenId,
      variables: {
        userParams: { isFBaccountWithPhone, email },
      },
    },
  );

export const initNewFBUser = (tokenId: string) =>
  axiosGql<InitNewFbUserMutation>(
    /* GraphQL */ `
      mutation InitNewFBUser {
        initUser {
          isActivated
          id
          email
          firstName
          lastName
          isRegistered
          workspace {
            id
          }
        }
      }
    `,
    {
      tokenId,
    },
  );

