/* eslint-disable no-useless-escape */
import { CoursesTitle } from 'apollo/stateFields/courses/coursesFields';

export type CourseGroup = CoursesTitle[];

export const CoursesGroups: CourseGroup[] = [
  [CoursesTitle.Foundations, CoursesTitle.MasteringPersonalEffectiveness],
  [CoursesTitle.Fundamentals, CoursesTitle.RemoteWorkProfessional],
  [CoursesTitle.FundamentalsManager, CoursesTitle.RemoteWorkProfessionalManager],
];

export const CoursesGroupByDefault = CoursesGroups[1];

export const emailsRegexp =
  /^[\w\.]+@([\w-]+\.)+[\w-]{2,}\s*(,\s*[\w\.]+@([\w-]+\.)+[\w-]{2,}\s*)*$/m;

export const emailRegex = /^[\w\.]+@([\w-]+\.)+[\w-]{2,}$/m;

export const domainRegex = /^([\w-]+\.)+[\w-]{2,}$/m;

// can either accept emails or numbers separated by commas
export const emailsOrIdsRegexp =
  /^([\w\.]+@([\w-]+\.)+[\w-]{2,}|\d+)\s*(,\s*([\w\.]+@([\w-]+\.)+[\w-]{2,}|\d+)\s*)*$/m;
