import { Header } from '../../core/components/layout';
import { Tabs } from 'antd';
import { ACTION_PATH } from '../../core/constants/routePaths';
import AddViewEditUser from './AddViewEditUser/AddViewEditUser';
import CompanySettings from './CompanySettings/CompanySettings';
import ImportExportUsers from './ImportExportUsers/ImportExportUsers';
import {
  PageWrapper,
  StyledContainer,
  StyledLink,
  StyledTabsContainer,
  StyledTitle,
  StyledTitleWrapper,
} from './StyledComponents';

const AdminPanel = () => {
  return (
    <StyledContainer>
      <Header isThin={true} />
      <PageWrapper>
        <StyledTitleWrapper>
          <StyledTitle>Admin panel </StyledTitle>
          <StyledLink to={ACTION_PATH}>Back to board</StyledLink>
        </StyledTitleWrapper>
        <StyledTabsContainer>
          <Tabs tabPosition={'left'}>
            <Tabs.TabPane tab="Add/View/Edit User" key="User">
              <AddViewEditUser />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Company Settings" key="Company">
              <CompanySettings />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Import/Export Users" key="ImportExport">
              <ImportExportUsers />
            </Tabs.TabPane>
          </Tabs>
        </StyledTabsContainer>
      </PageWrapper>
    </StyledContainer>
  );
};

export default AdminPanel;

