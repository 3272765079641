import { gql } from '__generated__/gql';
import './fragments';

const FETCH_SETTINGS_STRING = /* GraphQL */ `
  query FetchSettings {
    fetchUserSettings {
      boardColumns {
        outcomeColumn {
          ...dndColumnFields
        }
        backlogColumn {
          ...dndColumnFields
        }
        toDoColumn {
          ...dndColumnFields
        }
        doingColumn {
          ...dndColumnFields
        }
        doneColumn {
          ...dndColumnFields
        }
        noteColumn {
          ...dndColumnFields
        }
      }
      filterInput {
        workspaceId
        tagIds
      }
      shouldUpdateStatusToComplete
      shouldUpdateStatusToCurrent
      setStartDateToToday
      setUpdateFilterInput
      shouldOutcomeLinkAction
      shouldActionLinkOutcome
      defaultWorkspaceId
      tutorialPassedStep
      recommendedCompanyRemindDate
      recommendedCompany {
        email
        name
        url
      }
      userPreferences {
        roles
        title
      }
      doneColumnFilter {
        name
        filter
        startDate
        endDate
      }
      toDoColumnFilter {
        name
        filter
        startDate
        endDate
      }
      coursePassedStep
      timezone
      subscriptionReminderTime
      freeTrialReminderTime
    }
  }
`;

export const FETCH_SETTINGS = gql(FETCH_SETTINGS_STRING);

const FETCH_COURSES_SETTINGS_STRING = /* GraphQL */ `
  query FetchCoursesSettings {
    fetchUserSettings {
      coursesConsts {
        ...coursesConstFields
      }
      coursesVars {
        ...coursesVarFields
      }
      skipNotificationTime
    }
  }
`;

export const FETCH_COURSES_SETTINGS = gql(FETCH_COURSES_SETTINGS_STRING);

const FETCH_FOCUS_TIMER_SETTINGS_STRING = /* GraphQL */ `
  query FetchFocusTimerSettings {
    fetchUserSettings {
      focusTimerFixedPeriod
      focusTimerIsBipSound
      focusTimerType
    }
  }
`;

export const FETCH_FOCUS_TIMER_SETTINGS = gql(FETCH_FOCUS_TIMER_SETTINGS_STRING);

const FETCH_STRIPE_PRICES_STRING = /* GraphQL */ `
  query FetchStripePrices {
    fetchStripePrices {
      amount
      bmSubscriptionPlan
      bmSubscriptionPlanId
      created
      currency
      interval
      updated
      metadata
      bmSubscriptionProductId
    }
  }
`;

export const FETCH_STRIPE_PRICES = gql(FETCH_STRIPE_PRICES_STRING);

const FETCH_STRIPE_SUBSCRIPTION_STRING = /* GraphQL */ `
  query FetchStripeSubscription {
    fetchStripeSubscription {
      bmSubscription {
        amount
        bmSubscriptionPlan
        bmSubscriptionPlanId
        bmSubscriptionProvider
        createDate
        createDateFreeSubscription
        error
        expiration
        externalCustomerId
        externalSubscriptionId
        externalUnfinishedSubscriptionId
        id
        interval
        isEligableToFreeSubscription
        isTrialUsed
      }
      subscription {
        amount
        clientSecret
        canceledAt
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        status
        subscriptionId
      }
      unfinishedSubscription {
        amount
        clientSecret
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        status
        subscriptionId
      }
    }
  }
`;

export const FETCH_STRIPE_SUBSCRIPTION = gql(FETCH_STRIPE_SUBSCRIPTION_STRING);

const VALIDATE_STRIPE_PROMO_CODE_STRING = /* GraphQL */ `
  query ValidateStripePromoCode(
    $promoCodeId: String!
    $bmSubscriptionPlanId: String!
    $bmSubscriptionProductId: String!
  ) {
    validateStripePromoCode(
      promoCodeId: $promoCodeId
      bmSubscriptionPlanId: $bmSubscriptionPlanId
      bmSubscriptionProductId: $bmSubscriptionProductId
    ) {
      active
      code
      coupon {
        amountOff
        currency
        duration
        durationInMonth
        id
        maxRedemptions
        name
        percentOff
        timesRedeemed
        valid
      }
      expiresAt
      id
      maxRedemptions
    }
  }
`;

export const VALIDATE_STRIPE_PROMO_CODE = gql(VALIDATE_STRIPE_PROMO_CODE_STRING);

const FETCH_SUBSCRIPTION_CHANGE_DISCOUNT_STRING = /* GraphQL */ `
  query FetchSubscriptionChangeDiscount($newBmSubscriptionPlanId: String!) {
    fetchSubscriptionChangeDiscount(newBmSubscriptionPlanId: $newBmSubscriptionPlanId) {
      discount
      #        numberOfRemainingMonths
      #        numberOfUsedMonth
      #        currentSubscriptionId
      #        currentPlan
      #        newPlan
    }
  }
`;

export const FETCH_SUBSCRIPTION_CHANGE_DISCOUNT = gql(FETCH_SUBSCRIPTION_CHANGE_DISCOUNT_STRING);

const FETCH_STRIPE_CREDIT_CARD_STRING = /* GraphQL */ `
  query FetchStripeCreditCard {
    fetchStripeCreditCard {
      brand
      country
      exp_month
      exp_year
      last4
    }
  }
`;

export const FETCH_STRIPE_CREDIT_CARD = gql(FETCH_STRIPE_CREDIT_CARD_STRING);

const FETCH_STRIPE_UPCOMING_INVOICE_STRING = /* GraphQL */ `
  query FetchStripeUpcomingInvoice {
    fetchStripeUpcomingInvoice {
      hostedInvoiceUrl
      id
      periodEnd
      periodStart
      subtotal
      total
      totalDiscountAmounts {
        amount
        discount
      }
    }
  }
`;

export const FETCH_STRIPE_UPCOMING_INVOICE = gql(FETCH_STRIPE_UPCOMING_INVOICE_STRING);

export const FETCH_STRIPE_LATEST_INVOICE_STRING = /* GraphQL */ `
  query FetchStripeLatestInvoice {
    fetchStripeLatestInvoice {
      hostedInvoiceUrl
      id
      periodEnd
      periodStart
      subtotal
      total
      totalDiscountAmounts {
        amount
        discount
      }
    }
  }
`;

export const FETCH_STRIPE_LATEST_INVOICE = gql(FETCH_STRIPE_LATEST_INVOICE_STRING);

const FETCH_USER_STRING = /* GraphQL */ `
  query FetchUser {
    fetchUser {
      firstName
      lastName
      email
      name
    }
  }
`;
export const FETCH_USER = gql(FETCH_USER_STRING);

export const FETCH_USER_BY_EMAIL_STRING = /* GraphQL */ `
  query FetchUserByEmail($email: String!) {
    fetchUserByEmail(email: $email) {
      bmSubscription {
        expiration
        bmSubscriptionPlan
        bmSubscriptionPlanId
      }
      coursesAvailableList
      email
      name
      id
      defaultType
      firebaseUser {
        providerData {
          providerId
        }
      }
    }
    fetchCorporateAccountByEmail(email: $email) {
      domain
    }
  }
`;

export const FETCH_USER_BY_EMAIL = gql(FETCH_USER_BY_EMAIL_STRING);

const FETCH_CORPORATE_ACCOUNT_BY_DOMAIN_STRING = /* GraphQL */ `
  query FetchCorporateAccountByDomain($domain: String!) {
    fetchCorporateAccountByDomain(domain: $domain) {
      domain
      bmSubscriptionPlan
      bmSubscriptionProvider
      bmSubscriptionExpiration
      coursesAvailableList
    }
  }
`;

export const FETCH_CORPORATE_ACCOUNT_BY_DOMAIN = gql(FETCH_CORPORATE_ACCOUNT_BY_DOMAIN_STRING);

const FETCH_CORPORATE_ACCOUNT_BY_EMAIL_STRING = /* GraphQL */ `
  query FetchCorporateAccountByEmail($email: String!) {
    fetchCorporateAccountByEmail(email: $email) {
      domain
      bmSubscriptionPlan
      bmSubscriptionProvider
      bmSubscriptionExpiration
      coursesAvailableList
    }
  }
`;

export const FETCH_CORPORATE_ACCOUNT_BY_EMAIL = gql(FETCH_CORPORATE_ACCOUNT_BY_EMAIL_STRING);

const FETCH_USERS_BY_FILTER_STRING = /* GraphQL */ `
  query FetchManyUsersByFilter(
    $filter: UserFilterInput
    $pagination: PaginationInput
    $sorting: SortingInput
  ) {
    fetchManyUsersByFilter(filter: $filter, pagination: $pagination, sorting: $sorting) {
      users {
        id
        bmSubscription {
          expiration
          bmSubscriptionPlan
        }
        settings {
          coursesAvailableList
          defaultType
        }
        email
        name
        createDate
        firstName
        lastName
      }
      total
    }
  }
`;

export const FETCH_USERS_BY_FILTER = gql(FETCH_USERS_BY_FILTER_STRING);

const FETCH_CSV_TEMPLATE_STRING = /* GraphQL */ `
  query FetchCsvTemplate($type: CsvTemplateType!) {
    fetchCsvTemplate(type: $type)
  }
`;

export const FETCH_CSV_TEMPLATE = gql(FETCH_CSV_TEMPLATE_STRING);

const ADMIN_FETCH_SHARED_SPACES_STRING = /* GraphQL */ `
  query FetchSharedWorkspaces($params: SharedWorkspaceParamsInput!) {
    fetchSharedWorkspaces(params: $params) {
      id
      name
      members {
        id
        email
        permission
        name
      }
    }
  }
`;

export const ADMIN_FETCH_SHARED_SPACES = gql(ADMIN_FETCH_SHARED_SPACES_STRING);
