import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_USERS_BY_FILTER } from 'graphql/queries';
import { ExportTableUser, ExportTableUserFilters } from './types';
import {
  BASE_COLUMNS,
  TRANSFORM_FN_BY_KEY,
  INITIAL_COLUMN_KEYS,
  DISABLED_KEYS,
  INITIAL_PAGINATION,
} from './consts';
import { usePagination } from '../../../core/hooks/usePagination';
import { SortOrder, SorterResult } from 'antd/es/table/interface';
import { ExportUserField, ExportUsersParams, SortDirection } from '__generated__/graphql';
import DynamicTable from '../../../core/components/common/DynamicTable/DynamicTable';
import { Row } from 'antd';
import { API_REST_EXPORT_USERS_URL } from 'core/integrations/api/config';
import DownloadButton from 'core/components/common/DownloadButton/DownloadButton';

interface IExportUsersProps {
  onSuccess: (isSuccess: boolean) => void;
  onError: (errorMessage: string | null) => void;
}

const getSortDirectionFromSortingResults = (sortOrder?: SortOrder | undefined | null) => {
  if (!sortOrder) return undefined;
  switch (sortOrder) {
    case 'ascend':
      return SortDirection.Asc;
    case 'descend':
      return SortDirection.Desc;
  }
};

const ExportUsers = ({ onError, onSuccess }: IExportUsersProps) => {
  const [pagination, { setTotal, setPagination }] = usePagination(INITIAL_PAGINATION);
  const [filters, setFilters] = useState<ExportTableUserFilters>({});
  const [sorting, setSorting] = useState<SorterResult<ExportTableUser>>({});
  const [columnKeys, setColumnKeys] = useState(INITIAL_COLUMN_KEYS);
  const filterInput = {
    bmSubscriptionPlans: filters.bmSubscriptionPlan ?? undefined,
    coursesAvailableList: filters.coursesAvailableList ?? undefined,
    expirationRange: filters.expiration ? filters.expiration[0] : undefined,
    domain: filters.domain ? filters.domain[0] : undefined,
  };
  const paginationInput = {
    limit: pagination.pageSize,
    skip: (pagination.current! - 1) * pagination.pageSize!,
  };
  const sortingInput = {
    sortBy: (sorting.columnKey as ExportUserField) ?? undefined,
    sortDirection: getSortDirectionFromSortingResults(sorting.order),
  };
  const variables = {
    filter: filterInput,
    pagination: paginationInput,
    sorting: sortingInput,
  };
  const { data, loading, refetch } = useQuery(FETCH_USERS_BY_FILTER, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const [dataSource, setDataSource] = useState<ExportTableUser[]>([]);
  const handleRefresh = async () => {
    await refetch(variables);
  };

  useEffect(() => {
    if (data) {
      const mappedData: ExportTableUser[] = data.fetchManyUsersByFilter.users.map((initUser) => ({
        id: initUser.id,
        firstName: initUser.firstName,
        lastName: initUser.lastName,
        coursesAvailableList: initUser.settings?.coursesAvailableList,
        bmSubscriptionPlan: initUser.bmSubscription?.bmSubscriptionPlan,
        expiration: initUser.bmSubscription?.expiration,
        defaultType: initUser.settings?.defaultType,
        email: initUser.email,
        createDate:
          initUser.createDate != null
            ? new Date(initUser.createDate).getTime() / 1000
            : initUser.createDate,
        domain: initUser.email?.split('@')[1],
      }));
      setDataSource(mappedData);
      setTotal(data.fetchManyUsersByFilter.total);
    }
  }, [data, setTotal]);

  const downloadPayload: ExportUsersParams = {
    fields: columnKeys as ExportUsersParams['fields'],
    filter: filterInput,
    sorting: sortingInput,
  };

  return (
    <div style={{ minHeight: 500 }}>
      <DynamicTable
        rowKey="id"
        dataSource={dataSource}
        onKeysUpdate={setColumnKeys}
        onFiltersUpdate={setFilters}
        onSorterUpdate={setSorting}
        onPaginationUpdate={setPagination}
        baseColumns={BASE_COLUMNS}
        filterInfoTransformFnByKeys={TRANSFORM_FN_BY_KEY}
        initialColumnKeys={INITIAL_COLUMN_KEYS}
        initialPagination={INITIAL_PAGINATION}
        disabledKeys={DISABLED_KEYS}
        total={pagination.total}
        loading={loading}
        onRefresh={handleRefresh}
      />
      <Row justify="end">
        <DownloadButton
          onError={onError}
          onSuccess={onSuccess}
          url={API_REST_EXPORT_USERS_URL}
          body={downloadPayload}
          disabled={loading}
          fileName="users.csv"
        >
          Download CSV
        </DownloadButton>
      </Row>
    </div>
  );
};

export default ExportUsers;

