import { getNowDate } from './date';
import { ActionStatus } from '../types/action';

export const newAction = (status: ActionStatus) => {
  const nowDate = getNowDate();

  switch (status) {
    case ActionStatus.Doing:
      return {
        actualStartDate: nowDate,
      };
    case ActionStatus.Done:
      return {
        actualStartDate: nowDate,
        actualEndDate: nowDate,
      };
    default:
      return {};
  }
};

export function getActualActionDates(
  prevStatus: string,
  newStatus: string,
  isSetToToday: boolean,
  actualStartDate?: string | null,
) {
  const nowDate = getNowDate();

  const datesMap: { [key: string]: any } = {
    [ActionStatus.Backlog]: {
      [ActionStatus.Todo]: [],
      [ActionStatus.Doing]: [nowDate],
      [ActionStatus.Done]: [nowDate, nowDate],
    },
    [ActionStatus.Todo]: {
      [ActionStatus.Backlog]: [null],
      [ActionStatus.Doing]: [actualStartDate ? actualStartDate : nowDate],
      [ActionStatus.Done]: [actualStartDate ? actualStartDate : nowDate, nowDate],
    },
    [ActionStatus.Doing]: {
      [ActionStatus.Backlog]: [null],
      [ActionStatus.Todo]: [actualStartDate ? actualStartDate : null],
      [ActionStatus.Done]: [actualStartDate, nowDate],
    },
    [ActionStatus.Done]: {
      [ActionStatus.Backlog]: [null, null],
      [ActionStatus.Todo]: [actualStartDate ? actualStartDate : null, null],
      [ActionStatus.Doing]: [
        isSetToToday ? nowDate : actualStartDate ? actualStartDate : undefined,
        null,
      ],
    },
  };

  return datesMap[prevStatus][newStatus];
}

