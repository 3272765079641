import React, { useEffect, useState } from 'react';
import { Formik, FormikValues } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { InputTextField } from '../../../../core/components/form';
import { SIGN_IN_PATH, SIGN_UP_PATH } from '../../../../core/constants/routePaths';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../context/authContext';
import SignInLayoutWrapper from '../../../../core/components/SignInLayoutWrapper/SignInLayoutWrapper';
import { validationSchemaForPasswordRecovery } from '../PasswordRecovery/validations';
import PasswordRecoveryLayout from '../PasswordRecovery/PasswordRecoveryLayout';
import { firebase } from '../../../../core/integrations/firebase/init';
import { setTimeoutPromise } from '../../../../core/utils/promiseTimeout';
import { getUserInfoFromDB, initNewFBUser, updateUserEntityHttp } from '../../mutation';
import devConsole from 'core/utils/devConsole';

const StyledButtonsContainer = styled.div<{ noPadding?: boolean }>`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
`;

const initialValues = {
  email: '',
};

const SetEmail = () => {
  const { sendEmailVerification, signOut } = useAuth();
  const [email, setEmail] = useState('');
  const currentUser = firebase.auth()?.currentUser;

  const handleSentLink = async ({ email }: FormikValues) => {
    const token = await currentUser?.getIdToken();
    if (!sendEmailVerification || !email || !token) {
      devConsole.error({ sendEmailVerification, email, token });
      return;
    }

    const userData = await getUserInfoFromDB(token);
    if (!userData?.data?.data?.fetchUser) {
      await initNewFBUser(token);
    }
    await updateUserEntityHttp(true, token, email);
    await setTimeoutPromise(500);
    await sendEmailVerification(email, SIGN_IN_PATH);
    setEmail(email);
  };
  // useEffect(() => {
  //   if (!firebase.auth()?.currentUser) {
  //     history.push(SIGN_IN_PATH);
  //   }
  // }, [history.location.pathname]);

  useEffect(() => {
    const logOut = async () => {
      await firebase.auth().signOut();
      signOut && (await signOut());
    };
    return () => {
      logOut();
    };
  }, []);

  const backLinkEl = <StyledLink to={SIGN_UP_PATH}>Return to Sign up</StyledLink>;

  const formEl =
    !!email || !currentUser ? (
      <StyledButtonsContainer noPadding>{backLinkEl}</StyledButtonsContainer>
    ) : (
      <Formik
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={initialValues}
        validationSchema={validationSchemaForPasswordRecovery}
        onSubmit={handleSentLink}
      >
        {({ isSubmitting, errors, isValid, validateForm }) => {
          useEffect(() => {
            validateForm();
          }, []);
          return (
            <Form>
              <InputTextField
                name="email"
                placeholder="Email"
                autoComplete="off"
                displayError={!!errors.email}
              />
              <StyledButtonsContainer>
                {backLinkEl}
                <SubmitButton
                  disabled={!isValid}
                  loading={isSubmitting}
                  type="primary"
                  shape="round"
                >
                  Confirm
                </SubmitButton>
              </StyledButtonsContainer>
            </Form>
          );
        }}
      </Formik>
    );

  const subtitleEl =
    !!email || !currentUser ? (
      <span>We have sent a link to your email address, so that we can verify your identity.</span>
    ) : (
      'We need a valid email address to provide you with BillionMinds services. Please enter it below.'
    );

  return (
    <SignInLayoutWrapper>
      <PasswordRecoveryLayout
        title={!!email || !currentUser ? 'Check your email' : 'Please provide your email'}
        subtitle={subtitleEl}
        formComponent={formEl}
      />
    </SignInLayoutWrapper>
  );
};

export default SetEmail;

