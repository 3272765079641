import { ReactNode, useState } from 'react';
import { Button } from 'core/components/common';
import { DownloadOutlined } from '@ant-design/icons';
import { useAuth } from 'context/authContext';
import axios, { AxiosResponse } from 'axios';
import devConsole from 'core/utils/devConsole';

interface IDownloadButtonProps {
  url: string;
  children?: ReactNode;
  body?: object;
  disabled?: boolean;
  onSuccess: (isSuccess: boolean) => void;
  onError: (errorMessage: string | null) => void;
  fileName?: string;
  isSecondary?: boolean;
}

const DownloadButton = ({
  children,
  url,
  disabled,
  body,
  fileName,
  onError,
  onSuccess,
  isSecondary
}: IDownloadButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { idToken } = useAuth();

  const handleExport = async () => {
    let blob: AxiosResponse<any>;
    try {
      if (body) {
        blob = await axios.post(url, body, {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${idToken}`,
          },
          responseType: 'blob',
        });
      } else {
        blob = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${idToken}`,
          },
          responseType: 'blob',
        });
      }
      const downloadUrl = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      if (fileName) {
        link.download = fileName;
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      onSuccess(true);
    } catch (e) {
      devConsole.error(e);
      onError(e instanceof Error ? e.message : null);
    } finally {
      setIsDownloading(false);
    }
  };
  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={handleExport}
      isLoading={isDownloading}
      isDisabled={disabled}
      disabled={disabled}
      isSecondary={isSecondary}
    >
      {children ?? 'Download'}
    </Button>
  );
};

export default DownloadButton;

