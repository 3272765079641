import { CsvTemplateType, DomainChangeAction } from '__generated__/graphql';
import { Collapse, Typography, Table, Popover } from 'antd';
import { CoursesTitle } from 'apollo/stateFields/courses/coursesFields';
import { getTitle } from 'features/courses/utils';
import CsvHeadersTable from './CsvHeadersTable';
import CsvTemplateExample from '../CsvTemplateExample/CsvTemplateExample';

const { Panel } = Collapse;
const { Text, Title } = Typography;

const coursesAvailableColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'API Name',
    dataIndex: 'apiName',
    key: 'apiName',
  },
];

const csvHeaderDataSource = [
  {
    header: 'email',
    description: 'Required',
  },
  {
    header: 'first name',
    description: 'Required if adding a new user',
  },
  {
    header: 'last name',
  },
  {
    header: 'curriculum',
    description: 'Two courses titles separated by space (API Name)',
  },
  {
    header: 'subscription plan',
    description: 'Trial_Free | SelfGuided_Free | Trial_ThreeMonth',
  },
  {
    header: 'expiration date',
    description: 'Formatted as YYYY-MM-DD. Must be later than today',
  },
  {
    header: 'new email',
    description: 'In case of changing email',
  },
  {
    header: 'change email action',
    description:
      'It is required in case updating email conflicts: the user leaves and/or joins a Corporate Account',
  },
];

const changeEmailActionColumns = [
  {
    title: 'API Name',
    dataIndex: 'apiName',
    key: 'apiName',
  },
  {
    title: 'Availability',
    dataIndex: 'availability',
    key: 'availability',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

const changeEmailActionDataSource = [
  {
    apiName: DomainChangeAction.Keep,
    availability: 'In all cases',
    description:
      'User gets to keep their current settings, regardless of leaving/joining a Corporate Domain or nothing at all',
  },
  {
    apiName: DomainChangeAction.Upgrade,
    availability: 'When user joins a new Corporate domain',
    description:
      'User gets to start working with Corporate Defaults, inheriting their settings (curriculum, plan, expiry date)',
  },
  {
    apiName: DomainChangeAction.Reset,
    availability: 'When user leaves a company domain WITHOUT joining another one',
    description:
      'User gets their settings reset after leaving a domain. This includes 21 days trial free and system default curriculum',
  },
  {
    apiName: '(Empty)',
    availability: 'When user is neither leaving nor joining a Corporate Domain',
    description:
      'User does not have any conflict due to leaving/joining a Corporate Domain, so they are able to change email without any problem',
  },
];

interface ICsvUploadInstructionsProps {
  onError: (errorMessage: string | null) => void;
  onSuccess: (isSuccess: boolean) => void;
}

const csvValidExampleText = /* CSV */ `first name,last name,email,subscription plan,curriculum,expiration date
Michael,Phelps,michael.phelps@deletelater.org,Trial_Free,Foundations Fundamentals,2024-10-15
Jim,Carrey,jim.carrey@deletelater.org,Trial_ThreeMonth,FundamentalsManager RemoteWorkProfessionalManager,2024-10-15
Michael,Jordan,michael.jordan@deletelater.org,SelfGuided_Free,MasteringPersonalEffectiveness RemoteWorkProfessional,2024-10-15`;

const csvNotValidExampleText = /* CSV */ `first name,last name,email,subscription plan,curriculum,expiration date
notenough,curriculum,john.doe@example.com,Trial_Free,Foundations,2023-12-31
duplicate,curriculum,jane.smith@example.com,Trial_Free,Foundations Foundations,2024-06-30
notvalid,date,alice.johnson@example.org,Trial_Free,Foundations Fundamentals,2023-15-15
notenum,subscriptionplan,bob.williams@example.net,Free_For_Life,Foundations Fundamentals,2024-02-28
Duplicated,Email1,duplicated.email@example.org,Trial_Free,Foundations Fundamentals,2023-10-15
Duplicated,Email2,duplicated.email@example.org,Trial_Free,Foundations Fundamentals,2023-10-15`;

const csvValidWithMissingExampleText = /* CSV */ `first name,last name,email,subscription plan,curriculum
Michael,Phelps,michael.phelps@deletelater.org,,Foundations Fundamentals
Jim,Carrey,jim.carrey@deletelater.org,Trial_ThreeMonth,
Michael,,michael@deletelater.org,SelfGuided_Free,MasteringPersonalEffectiveness RemoteWorkProfessional`;

const csvChangeEmailExampleText = /* CSV */ `email,new email,change email action
john.doe@update.email,john.doe@updated.org,Keep
jane.smith@update2.email,janesmith@updated.net,Keep
alex.johnson@update3.email,alex.johnson@updated.com,Keep`;

const csvCreateExampleTest = /* CSV */ `email,first name
john.doe@update.email,john
jane.smith@update2.email,jane
alex.johnson@update3.email,alex
notvalid@emailaction.com,another`;

const CsvUploadInstructions = ({ onError, onSuccess }: ICsvUploadInstructionsProps) => {
  const courseTitleDataSource = Object.values(CoursesTitle).map((courseTitle) => ({
    apiName: courseTitle,
    title: getTitle(courseTitle),
  }));
  return (
    <Collapse ghost>
      <Panel header="Tips for uploading" key="0">
        <Collapse ghost>
          <Panel header="CSV Headers" key="00">
            <CsvHeadersTable dataSource={csvHeaderDataSource} />
          </Panel>
          <Panel header="Courses Available" key="01">
            <Table
              columns={coursesAvailableColumns}
              dataSource={courseTitleDataSource}
              rowKey="apiName"
              pagination={false}
            />
          </Panel>
          <Panel header="Change Email" key="02">
            In order to change email, most likely it will be necessary to add a column "change email
            action" with one of the following:
            <Table
              columns={changeEmailActionColumns}
              dataSource={changeEmailActionDataSource}
              rowKey="apiName"
              pagination={false}
            />
          </Panel>
          <Panel header="Example" key="03">
            <Text>
              <Title level={5}>Valid</Title>
              <CsvTemplateExample
                csvText={csvValidExampleText}
                type={CsvTemplateType.Upsert}
                onSuccess={onSuccess}
                onError={onError}
              />
              <Title level={5}>Not valid</Title>
              <CsvTemplateExample csvText={csvNotValidExampleText} />
              <Title level={5}>Valid with missing columns and fields</Title>
              <CsvTemplateExample
                csvText={csvValidWithMissingExampleText}
                type={CsvTemplateType.UpsertWithMissingColumns}
                onSuccess={onSuccess}
                onError={onError}
              />
              <Title level={5}>Change Email</Title>
              In order to test these with a test run, remember to{' '}
              <Popover
                content={
                  <CsvTemplateExample
                    csvText={csvCreateExampleTest}
                    type={CsvTemplateType.Create}
                    onSuccess={onSuccess}
                    onError={onError}
                  />
                }
              >
                <Text type="secondary" underline>
                  create the users first
                </Text>
              </Popover>
              .
              <CsvTemplateExample
                csvText={csvChangeEmailExampleText}
                type={CsvTemplateType.ChangeEmail}
                onSuccess={onSuccess}
                onError={onError}
              />
            </Text>
          </Panel>
        </Collapse>
      </Panel>
    </Collapse>
  );
};

export default CsvUploadInstructions;

