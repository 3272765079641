import { Action, ActionPriority, ActionStatus } from '../../../../../core/types/action';
import { ReactComponent as UrgentImportantIcon } from './svg/urgent-important-1.svg';
import { ReactComponent as ImportantIcon } from './svg/important.svg';
import { ReactComponent as UrgentIcon } from './svg/urgent.svg';
import { ReactComponent as NotDefinedIcon } from './svg/not-definrd.svg';
import { ReactComponent as GrayUrgentImportantIcon } from './svg/gray-urgent-important.svg';
import { ReactComponent as GrayImportantIcon } from './svg/gray-important.svg';
import { ReactComponent as GrayUrgentIcon } from './svg/gray-urgent.svg';
import { PriorityTitle } from './constants';

export function getTrimmedString(str: string | undefined, length: number): string {
  if (str !== undefined) {
    return str && str.length > length ? `${str.slice(0, length)}...` : str;
  }
  return '';
}

export const getPriorityIcon = (action: Action) => {
  switch (action.priority) {
    case ActionPriority.ImportantUrgent:
      return action.status === ActionStatus.Done ? (
        <GrayUrgentImportantIcon />
      ) : (
        <UrgentImportantIcon />
      );
    case ActionPriority.Urgent:
      return action.status === ActionStatus.Done ? <GrayUrgentIcon /> : <UrgentIcon />;
    case ActionPriority.Important:
      return action.status === ActionStatus.Done ? <GrayImportantIcon /> : <ImportantIcon />;
    case null:
    case undefined:
      return <NotDefinedIcon />;
    default:
      return <NotDefinedIcon />;
  }
};

export const getPriorityTitle = (priority: ActionPriority | null | undefined) => {
  switch (priority) {
    case ActionPriority.ImportantUrgent:
      return PriorityTitle.IMPORTANT_URGENT;
    case ActionPriority.Urgent:
      return PriorityTitle.URGENT;
    case ActionPriority.Important:
      return PriorityTitle.IMPORTANT;
    case null:
    case undefined:
      return PriorityTitle.NOT_DEFINED;
    default:
      return PriorityTitle.NOT_DEFINED;
  }
};

