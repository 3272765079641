import { Outcome, OutcomeInput } from 'core/types/outcome';
import { DType } from 'core/types/duration';
import { Action } from '../../../../../core/types/action';
import { sortActionCardsByDateAndName } from '../../../utils';

export function getInitialValues(outcome: Outcome | null): Partial<OutcomeInput> {
  if (outcome === null) {
    return {};
  }
  const { __typename, actions, updateDate, ...outcomeInput } = outcome;
  return {
    ...outcomeInput,
    startDate: outcomeInput?.startDate ? outcomeInput.startDate.toString() : '',
    endDate: outcomeInput?.endDate ? outcomeInput.endDate.toString() : '',
    completeDate: outcomeInput?.completeDate ? outcomeInput.completeDate.toString() : '',
    inputTags:
      outcome.tags?.map((tag) => {
        return { label: tag.name, name: tag.name, value: tag.id };
      }) || [],
  };
}

export const getActionOptions = (
  actions: Action[],
  checkedActions?: Action[],
): { label: string; value: number }[] => {
  return actions
    .filter((action) => !action?.outcome)
    .filter((action) => {
      return checkedActions && !checkedActions.some((el) => el?.id === action?.id);
    })
    .sort((a, b) => {
      if (a?.name && b?.name) {
        return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase());
      }
      return 0;
    })
    .map(({ name, id }) => {
      return {
        label: name,
        value: id,
      };
    });
};

export const getLinkedActions = (actions: Action[] = [], outcomeId: number): Action[] => {
  return (
    actions
      .filter((action: Action) => action.outcome?.id === outcomeId)
      .sort(sortActionCardsByDateAndName) || []
  );
};
