import { FormEvent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DefaultCurriculumType } from '../../../graphql/types';
import { ADMIN_RESET_USER_CURRICULUM, ADMIN_UPDATE_USER_ENTITY } from '../../../graphql/mutations';
import { Input, Select } from 'antd';
import { UserEditModalType } from '../UserInfo/UserInfo';
import { getTime } from 'date-fns';
import { FETCH_USER_BY_EMAIL } from '../../../graphql/queries';
import { getFirstAndLastName } from '../../../features/courses/utils';
import devConsole from 'core/utils/devConsole';
import { CoursesGroups, emailRegex } from 'pages/const';
import { findCourseByCurriculumType, CoursesGroupsSelectOpts } from '../utils';
import {
  StyledButton,
  StyledButtonContainer,
  StyledCancelButton,
  StyledForm,
  StyledInputContainer,
  StyledLabel,
  StyledModal,
  StyledSubmitButton,
  StyledTitle,
} from '../StyleModalComponents';
import CancelModal from '../CancelModal/CancelModal';
import { ExpirationDatePicker } from 'core/components/common';
import LabeledCheckbox from '../ApplyUsersCheckbox/ApplyUsersCheckbox';
import { DomainChangeAction } from '__generated__/graphql';
import EmailChangeSelect from '../EmailChangeOptions/EmailChangeSelect';

interface EditUserModalProps {
  type: UserEditModalType | null;
  email: string;
  onSuccess: (email?: string) => void;
  onError: () => void;
  onClose: () => void;
}

const EditUserModal = ({ type, email, onSuccess, onError, onClose }: EditUserModalProps) => {
  const { data: userData } = useQuery(FETCH_USER_BY_EMAIL, {
    variables: { email },
  });
  const defaultType = userData!.fetchUserByEmail!.defaultType;
  const initialExpiration = userData!.fetchUserByEmail!.bmSubscription?.expiration;
  const initialExpirationDate = initialExpiration ? new Date(initialExpiration * 1000) : null;

  const [updateUserEntityAsAdmin] = useMutation(ADMIN_UPDATE_USER_ENTITY);
  const [resetUserCurriculum] = useMutation(ADMIN_RESET_USER_CURRICULUM);

  const [firstNameInput, setFirstNameInput] = useState<string>('');
  const [lastNameInput, setLastNameInput] = useState<string>('');
  const [curriculumType, setCurriculumType] = useState(
    userData!.fetchUserByEmail!.coursesAvailableList ?? CoursesGroups[1],
  );
  const [expirationInput, setExpirationInput] = useState<Date | null>(initialExpirationDate);
  const { firstName, lastName } = getFirstAndLastName(userData?.fetchUserByEmail?.name);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isResetUserCurriculum, setIsResetUserCurriculum] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>(email);
  const [
    fetchExistingEntities,
    { data: existingEntities, loading: isLoadingFetchExistingEntities },
  ] = useLazyQuery(FETCH_USER_BY_EMAIL, {
    fetchPolicy: 'network-only',
  });
  const [domainChangeAction, setDomainChangeAction] = useState<DomainChangeAction | null>(null);
  const [isDomainVerified, setIsDomainVerified] = useState(false);

  const [loading, setLoading] = useState(false);

  const providerId = userData!.fetchUserByEmail!.firebaseUser?.providerData?.[0].providerId;
  const isPasswordProvider = providerId === 'password';
  const isChangeEmailInputDisabled = providerId ? !isPasswordProvider : false;
  const isExistingEmail = !!existingEntities?.fetchUserByEmail?.email;

  const handleUpdateUser = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      switch (type) {
        case UserEditModalType.DisplayName:
          const fn = firstNameInput ? firstNameInput.trim() : firstName;
          const ln = lastNameInput ? lastNameInput.trim() : lastName;
          await updateUserEntityAsAdmin({
            variables: {
              email,
              userParams: {
                firstName: fn,
                lastName: ln,
              },
            },
          });
          break;
        case UserEditModalType.CurriculumType:
          if (isResetUserCurriculum) {
            await resetUserCurriculum({
              variables: {
                email,
              },
            });
          } else {
            await updateUserEntityAsAdmin({
              variables: {
                email,
                userParams: {
                  coursesAvailableList: curriculumType,
                },
              },
            });
          }
          break;
        case UserEditModalType.SubscriptionExpiration:
          if (expirationInput == null) {
            devConsole.warn('Expiration date is null');
            return setLoading(false);
          }
          await updateUserEntityAsAdmin({
            variables: {
              email,
              userParams: {
                expiration: Math.floor(getTime(expirationInput) / 1000),
              },
            },
          });
          setExpirationInput(expirationInput);
          break;
        case UserEditModalType.Email:
          if (isInvalid) {
            devConsole.warn('Email is not valid');
            return setLoading(false);
          }
          await updateUserEntityAsAdmin({
            variables: {
              email,
              userParams: {
                email: emailInput,
              },
              options: {
                emailChangeOptions: {
                  domainChangeAction,
                },
              },
            },
          });
          break;
        default:
          devConsole.warn(`Unknown edit modal type: ${type}`);
          return setLoading(false);
      }
      onSuccess(type === UserEditModalType.Email ? emailInput : undefined);
      onClose();
    } catch (error) {
      devConsole.error(error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const isEmpty = (() => {
    switch (type) {
      case UserEditModalType.DisplayName:
        return !firstNameInput.trim().length || !lastNameInput.trim().length;
      case UserEditModalType.SubscriptionExpiration:
        return !expirationInput;
      case UserEditModalType.Email:
        return !emailInput;
      default:
        return false;
    }
  })();

  const isModified = (() => {
    switch (type) {
      case UserEditModalType.DisplayName:
        return firstNameInput.trim() !== firstName || lastNameInput.trim() !== lastName;
      case UserEditModalType.CurriculumType:
        if (defaultType !== DefaultCurriculumType.None) return true;
        const courses = userData!.fetchUserByEmail!.coursesAvailableList;
        return (
          !!courses &&
          (isResetUserCurriculum ||
            courses[0] !== curriculumType[0] ||
            courses[1] !== curriculumType[1])
        );
      case UserEditModalType.SubscriptionExpiration:
        return (
          expirationInput?.toLocaleDateString() !== initialExpirationDate?.toLocaleDateString()
        );
      case UserEditModalType.Email:
        return email !== emailInput;
      default:
        return false;
    }
  })();

  const isInvalid = (() => {
    switch (type) {
      case UserEditModalType.Email:
        return !emailRegex.test(emailInput) || !isDomainVerified || isExistingEmail;
      default:
        return false;
    }
  })();

  const isDisabled = !isModified || isEmpty || isInvalid;

  useEffect(() => {
    const { firstName, lastName } = getFirstAndLastName(userData!.fetchUserByEmail!.name);
    firstName && setFirstNameInput(firstName);
    lastName && setLastNameInput(lastName);
    return () => {
      setFirstNameInput('');
      setLastNameInput('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData!.fetchUserByEmail!.name]);

  const handleOpenCancel = () => {
    if (isModified) {
      setShowCancelModal(true);
    } else {
      onClose();
    }
  };

  const handleAcceptCancel = () => {
    switch (type) {
      case UserEditModalType.DisplayName:
        const { firstName, lastName } = getFirstAndLastName(userData!.fetchUserByEmail!.name);
        firstName && setFirstNameInput(firstName);
        lastName && setLastNameInput(lastName);
        break;
      case UserEditModalType.CurriculumType:
        const courses = userData!.fetchUserByEmail!.coursesAvailableList;
        setCurriculumType(courses ?? CoursesGroups[1]);
        setIsResetUserCurriculum(false);
        break;
      case UserEditModalType.SubscriptionExpiration:
        setExpirationInput(initialExpirationDate);
        break;
      case UserEditModalType.Email:
        setEmailInput(email);
        setIsDomainVerified(false);
        setDomainChangeAction(null);
        break;
      default:
        devConsole.warn(`Unknown edit modal type: ${type}`);
    }
    setShowCancelModal(false);
    onClose();
  };
  return (
    <>
      <StyledModal
        destroyOnClose
        getContainer={() => document.body}
        visible={!!type}
        onCancel={handleOpenCancel}
        footer={null}
        mask={false}
        closable={true}
      >
        <StyledForm onSubmit={handleUpdateUser}>
          <StyledTitle>Edit User Profile</StyledTitle>
          <StyledInputContainer>
            {type &&
              {
                [UserEditModalType.DisplayName]: (
                  <>
                    <StyledLabel>First Name</StyledLabel>
                    <Input
                      value={firstNameInput}
                      minLength={3}
                      maxLength={100}
                      placeholder={'Enter first name'}
                      onChange={(e) => setFirstNameInput(e.target.value)}
                    />
                    <StyledLabel>Last Name</StyledLabel>
                    <Input
                      value={lastNameInput}
                      minLength={3}
                      maxLength={100}
                      placeholder={'Enter last name'}
                      onChange={(e) => setLastNameInput(e.target.value)}
                    />
                  </>
                ),
                [UserEditModalType.CurriculumType]: (
                  <>
                    <StyledLabel>Curriculum type</StyledLabel>
                    <Select
                      labelInValue
                      defaultValue={findCourseByCurriculumType(curriculumType)}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        setCurriculumType(CoursesGroups[value.value]);
                      }}
                      options={CoursesGroupsSelectOpts}
                      disabled={isResetUserCurriculum}
                    />
                    <LabeledCheckbox
                      setValue={setIsResetUserCurriculum}
                      value={isResetUserCurriculum}
                      disabled={defaultType !== DefaultCurriculumType.None}
                    >
                      Reset user curriculum
                    </LabeledCheckbox>
                  </>
                ),
                [UserEditModalType.SubscriptionExpiration]: (
                  <>
                    <StyledLabel>Expiration date</StyledLabel>
                    <ExpirationDatePicker
                      value={expirationInput}
                      setExpirationDate={setExpirationInput}
                    />
                  </>
                ),
                [UserEditModalType.Email]: (
                  <>
                    <StyledLabel>Email</StyledLabel>
                    <Input
                      value={emailInput}
                      placeholder={'Enter email'}
                      disabled={isChangeEmailInputDisabled}
                      onChange={(e) => {
                        setEmailInput((previousEmail) => {
                          const newEmail = e.target.value;
                          if (!previousEmail || !newEmail || previousEmail !== newEmail) {
                            setIsDomainVerified(false);
                            setDomainChangeAction(null);
                          }
                          return newEmail;
                        });
                      }}
                    />
                    {providerId && !isPasswordProvider && (
                      <>
                        <p>
                          Email change is only available for users with password as their sign-in
                          method
                        </p>
                        <p>
                          Actual: <b>{providerId}</b>
                        </p>
                      </>
                    )}
                    {!isChangeEmailInputDisabled && (
                      <>
                        {isDomainVerified &&
                          (!isExistingEmail ? (
                            <EmailChangeSelect
                              oldDomain={userData!.fetchCorporateAccountByEmail?.domain}
                              newDomain={existingEntities?.fetchCorporateAccountByEmail?.domain}
                              onOptionUpdate={setDomainChangeAction}
                            />
                          ) : (
                            <p>
                              This user already exists with the email you are trying to change to
                            </p>
                          ))}
                        <StyledButtonContainer>
                          <StyledButton
                            disabled={
                              !emailRegex.test(emailInput) ||
                              isEmpty ||
                              !isModified ||
                              isDomainVerified
                            }
                            isDisabled={
                              !emailRegex.test(emailInput) ||
                              isEmpty ||
                              !isModified ||
                              isDomainVerified
                            }
                            onClick={async () => {
                              try {
                                await fetchExistingEntities({
                                  variables: { email: emailInput },
                                });
                              } catch (e) {
                                devConsole.log(e);
                              } finally {
                                setIsDomainVerified(true);
                              }
                            }}
                            isLoading={isLoadingFetchExistingEntities}
                          >
                            Check for domain change
                          </StyledButton>
                        </StyledButtonContainer>
                      </>
                    )}
                  </>
                ),
              }[type]}
          </StyledInputContainer>

          <StyledButtonContainer>
            <StyledSubmitButton isDisabled={isDisabled} isLoading={loading}>
              Save
            </StyledSubmitButton>
            <StyledCancelButton onClick={handleOpenCancel}>Cancel</StyledCancelButton>
          </StyledButtonContainer>
        </StyledForm>
      </StyledModal>
      <CancelModal
        onConfirm={handleAcceptCancel}
        onCancel={() => setShowCancelModal(false)}
        visible={showCancelModal}
      />
    </>
  );
};

export default EditUserModal;

