import { isPast } from 'date-fns';
import { DatePicker } from './DatePicker';
import { DatePickerProps } from 'antd';

interface IExpirationDatePickerProps {
  value: Date | null;
  setExpirationDate: (date: Date | null) => void;
  size?: DatePickerProps['size'];
}

export const ExpirationDatePicker = ({
  value,
  setExpirationDate,
  size,
}: IExpirationDatePickerProps) => (
  <DatePicker
    style={{ width: '100%' }}
    size={size ?? 'large'}
    placeholder="Expiration date"
    value={value}
    showToday={false}
    allowClear={true}
    disabledDate={(current) => current && isPast(current)}
    onChange={(date) => {
      if (date) {
        setExpirationDate(date);
      } else {
        setExpirationDate(null);
      }
    }}
  />
);

