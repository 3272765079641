import { Divider, Table } from 'antd';
import { StyledDescription } from '../StyledComponents';
import { DeletedUsersResults } from '__generated__/graphql';
import { columns, deletedSharedSpacesColumns, DeletedSharedSpacesResults } from './consts';
import styled from 'styled-components';

interface IUserDeleteResultsTableProps {
  dataSource: DeletedUsersResults[];
  isLoading?: boolean;
  isTestRun?: boolean;
}

const StyledTableContainer = styled.div`
  overflow: auto;
  width: 100%;
`;

const UserDeleteResultsTable = ({
  dataSource,
  isLoading,
  isTestRun,
}: IUserDeleteResultsTableProps) => {
  const sharedSpacesResults = dataSource.reduce((acc, userWithSharedSpaces) => {
    const { email, sharedSpaces, savePrimaryUserSharedSpace } = userWithSharedSpaces;
    if (!sharedSpaces?.length) {
      return acc;
    }
    for (const sharedSpace of sharedSpaces) {
      acc.push({
        email,
        ...sharedSpace,
        deleted: !savePrimaryUserSharedSpace,
      });
    }
    return acc;
  }, [] as DeletedSharedSpacesResults[]);
  return (
    <StyledTableContainer>
      <StyledDescription>{isTestRun ? 'Test Run Results' : 'Delete Results'}</StyledDescription>
      <Table dataSource={dataSource} columns={columns} rowKey="email" loading={isLoading} />
      <Divider dashed type="horizontal" />
      <StyledDescription>
        {isTestRun ? 'Test Run Shared Spaces Results' : 'Delete Shared Spaces Results'}
      </StyledDescription>
      <Table
        dataSource={sharedSpacesResults}
        columns={deletedSharedSpacesColumns}
        rowKey="id"
        loading={isLoading}
      />
    </StyledTableContainer>
  );
};

export default UserDeleteResultsTable;

