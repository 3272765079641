import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Tag } from 'core/types/tag';
import { Workspace } from 'core/types/workspace';
import { ReactComponent as CrossIcon } from 'core/svg/cross.svg';
import { Filter } from 'features/utils';
import { useUserWorkspaceContext } from '../../../../context/userWorkspaceContext';
import { sendEvent } from '../../../../core/integrations/sentry/events';
import { Modal } from 'antd';
import { ContextMenuOptions } from './constants';
import { ContextMenuOption } from '../../../../core/components/common/Card/types';
import { useMutation, useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../../core/styles/styled-components/theme';
import { ReactComponent as ArrowDownIcon } from 'core/svg/arrow-down.svg';
import { UPDATE_SETTINGS } from '../../../../graphql/mutations';
import TagFilterItem from './TagFilterItem';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import { userSettingsMutation } from '../../../../apollo/stateFields/userSettings';
import { manageWorkspacesMutation } from '../../../../apollo/stateFields/manageWorkspaces';
import useWorkspaceInvites from '../../../../hooks/useWorkspaceInvites';

const StyledWrapper = styled.div<{ isList?: boolean }>`
  display: flex;
  flex: 1;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  scrollbar-width: none;

  @media ${theme.device.tablet.max} {
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isList }) =>
    isList &&
    css`
      overflow-x: hidden;
      flex-direction: column;
      flex-wrap: wrap;
      padding-right: 35px;
    `};
`;

const StyledMobileContainer = styled(({ loading, ...rest }) => <div {...rest} />)<{
  loading: boolean;
}>`
  display: flex;
  width: calc(100% - 100px);

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0;
    `};
`;

const StyledTagList = styled.ul<{ isList?: boolean }>`
  display: flex;
  list-style: none;
  margin: 0 2px 0 4px;
  padding: 0;
  max-width: 200px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isList }) =>
    isList &&
    css`
      margin: 3px 0 0 0;
      max-width: 100%;
      overflow: hidden;
      flex-wrap: wrap;

      li {
        margin-top: 2px;
      }
    `};
`;

const StyledTagItem = styled.li<{ isShared?: boolean }>`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 20px;
  padding: 0 5px;

  &:not(:last-of-type) {
    margin-right: 3px;
  }
`;

const StyledTagButton = styled.button`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  border: 0;
  color: var(--color-dark-blue);
  font-size: 12px;
  padding: 0;
  pointer-events: none;

  svg {
    margin-left: 5px;
  }
`;

const StyledCountContainer = styled.div<{ isList?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 0 0 12px;
  span {
    padding-right: 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: var(--color-white);
  }

  svg {
    margin-top: 4px;
    cursor: pointer;

    path {
      fill: var(--color-white);
    }

    ${({ isList }) =>
      isList &&
      css`
        transform: rotate(180);
      `};
  }

  ${({ isList }) =>
    isList &&
    css`
      position: absolute;
      top: 10px;
      right: 7px;
    `};
`;

const StyledModal = styled(Modal)`
  && {
    top: 56px;
    margin: 0;
    padding: 0;
    max-width: 100%;

    .ant-modal-content {
      background-color: var(--color-deep-gray);
      max-height: calc(var(--app-height) - 56px);
      overflow-x: scroll;

      .ant-modal-body {
        padding: 16px;
      }
    }
  }
`;

const HeaderTagFilter = React.memo(() => {
  const { workspaces } = useUserWorkspaceContext();
  const { toggleManageWorkspacesModal, setSharedWorkspace, setEditedWorkspace, setLeaveWorkspace } =
    manageWorkspacesMutation;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { setDefaultWorkspaceId } = userSettingsMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { toggleFilterInputWorkspace, deselectTag } = filterMutation;
  const [sendInputEvent, setSendInputEvent] = useState<boolean>(false);
  const [showCount, setShowCount] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadedWorkspaceCount, setLoadedWorkspaceCount] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const { getWorkspaceInvitesById } = useWorkspaceInvites();

  useEffect(() => {
    if (sendInputEvent) {
      setSendInputEvent(false);
      sendEvent('filter-update', 'Update filter input', { filterInput });
    }
  }, [filterInput, sendInputEvent]);

  useLayoutEffect(() => {
    if (
      containerRef.current &&
      itemsRef.current &&
      isMobile
      // loadedWorkspaceCount >= filterInput.length
    ) {
      const containerWidth = Math.ceil(containerRef.current.getBoundingClientRect().width);
      let itemsWidth = 0;

      itemsRef.current = itemsRef.current.slice(0, filterInput.length);

      itemsRef.current.forEach((i) => {
        if (i) {
          itemsWidth += i.getBoundingClientRect().width + 5; // 5 is margin
        }
      });

      if (itemsWidth > containerWidth) {
        setShowCount(true);
      }
      if (itemsWidth < containerWidth && itemsWidth !== 0) {
        setShowCount(false);
      }
    }
  }, [containerRef.current, filterInput, itemsRef.current, isMobile, loadedWorkspaceCount]);

  // useEffect(() => {
  //   if (!isDesktop) {
  //     setLoading(!itemsRef.current.length);
  //   }
  // }, [itemsRef.current.length, isDesktop]);

  const handleSetDefault = async (defaultWorkspaceId: number) => {
    await updateSettings({
      variables: {
        settings: {
          defaultWorkspaceId,
        },
      },
    }).then(() => {
      setDefaultWorkspaceId(defaultWorkspaceId);
    });
  };

  const handleOpenEditModal = ({ id }: Workspace) => {
    setEditedWorkspace(id);
    toggleManageWorkspacesModal();
  };

  const handleRemoveFromBoard = (id: number) => {
    toggleFilterInputWorkspace(id);
    setSendInputEvent(true);
  };

  const handleSetLoadedWorkspaceCount = useCallback(() => {
    setLoadedWorkspaceCount((prev) => prev + 1);
  }, [loadedWorkspaceCount]);

  const handleContextMenuOptionClick = useCallback(
    (option: ContextMenuOption, workspace: Workspace) => {
      const label = option.label;

      switch (label) {
        case ContextMenuOptions.EDIT.label:
          handleOpenEditModal(workspace);
          break;
        case ContextMenuOptions.REMOVE_FROM_BOARD.label:
          handleRemoveFromBoard(workspace.id);
          break;
        case ContextMenuOptions.SET_DEFAULT.label:
          handleSetDefault(workspace.id);
          break;
        case ContextMenuOptions.SHARE.label:
          setSharedWorkspace(workspace);
          break;
        case ContextMenuOptions.LEAVE.label:
          setLeaveWorkspace(workspace);
          break;
        default:
          return;
      }
    },
    [handleOpenEditModal, handleRemoveFromBoard, setSharedWorkspace],
  );

  const handleToggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const renderTags = (filter: Filter, tags: Tag[], isList?: boolean) => {
    const isShared = getWorkspaceInvitesById(filter.workspaceId)?.count
      ? getWorkspaceInvitesById(filter.workspaceId)!.count > 1
      : false;
    const tagsEl = filter.tagIds.map((id: number) => {
      const tag = tags && tags.find((tag: Tag) => tag.id === id);
      if (!tag) {
        return null;
      }
      return (
        <StyledTagItem
          isShared={isShared}
          key={id}
          onClick={() => {
            deselectTag({ workspaceId: filter.workspaceId, tagId: id });
            setSendInputEvent(true);
          }}
        >
          <StyledTagButton>
            <span>{tag.name}</span>
            <CrossIcon />
          </StyledTagButton>
        </StyledTagItem>
      );
    });
    if (!tagsEl.length) return;

    return (
      <div key={filter.workspaceId}>
        <StyledTagList isList={isList}>{tagsEl}</StyledTagList>
      </div>
    );
  };

  const renderLifespaces = (isList?: boolean) => {
    return filterInput
      .slice()
      .sort((a, b) => {
        return a?.workspaceId === defaultWorkspaceId
          ? -1
          : b?.workspaceId === defaultWorkspaceId
          ? 1
          : 0;
      })
      .map((filter: Filter, i) => {
        const workspace = workspaces.find(
          (workspace: Workspace) => workspace.id === filter.workspaceId,
        );
        if (!workspace) {
          return null;
        }
        return (
          <TagFilterItem
            key={i}
            loadedCallback={handleSetLoadedWorkspaceCount}
            workspace={workspace}
            filter={filter}
            index={i}
            handleContextMenuOptionClick={handleContextMenuOptionClick}
            itemsRef={itemsRef}
            renderTags={renderTags}
            isList={isList}
          />
        );
      });
  };

  const renderWorkspaceCount = (isList?: boolean) => {
    if (showCount && isMobile && workspaces?.length) {
      return (
        <StyledCountContainer onClick={handleToggleModal} isList={isList}>
          <span>{workspaces.length}</span>
          <ArrowDownIcon />
        </StyledCountContainer>
      );
    }
  };

  const renderContainer = (isList?: boolean) => (
    <StyledWrapper ref={containerRef} isList={isList}>
      {renderLifespaces(isList)}
    </StyledWrapper>
  );

  const lifespacesDrawer = (
    <StyledModal
      getContainer={false}
      onCancel={handleToggleModal}
      visible={showModal}
      closable={false}
      mask={false}
      footer={null}
      width="100%"
    >
      {renderWorkspaceCount(true)}
      {renderContainer(true)}
    </StyledModal>
  );

  const isLoading = loading || filterInput.length === 0;

  const mobileContent = (
    <StyledMobileContainer loading={isLoading}>
      {lifespacesDrawer}
      {renderContainer()}
      {renderWorkspaceCount()}
    </StyledMobileContainer>
  );

  return <>{isDesktop ? renderContainer() : mobileContent}</>;
});

export default HeaderTagFilter;

