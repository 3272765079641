import React from 'react';
import styled from 'styled-components';
import { SharedUserRoleItemProps, UserMenuRoles } from '../types';
import { StyledDefaultImage, StyledImage } from '../StyledComponents/StyledComponents';
import SharedRoleSelector from '../SharedRoleSelector/SharedRoleSelector';
import { theme } from '../../../../../core/styles/styled-components';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../../../apollo/stateFields/auth/authFields';
import { manageWorkspacesMutation } from '../../../../../apollo/stateFields/manageWorkspaces';
import {
  MANAGE_WORKSPACES_STATE,
  ManageWorkspacesValue,
} from '../../../../../apollo/stateFields/manageWorkspaces/manageWorkspacesField';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 57px;
  border-bottom: 1px solid rgb(196, 196, 196, 0.5);
`;

const StyledUserInfoWrapper = styled.div<{ isPending?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${(props) => (props.isPending ? `opacity: 0.5;` : `opacity: 1;`)}
`;

const StyledUserName = styled.h3<{ isCurrent?: boolean }>`
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${theme.device.mobile.min} {
    max-width: 150px;
  }
  @media ${theme.device.tablet.min} {
    max-width: 340px;
  }

  ${(props) => (props.isCurrent ? `font-weight: 700;` : `font-weight: 400;`)}
`;

const StyledCurrentLabel = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 400;

  //&:before {
  //  content: '';
  //}
`;

const StyledRoleSelector = styled(SharedRoleSelector)`
  border-radius: 2px;

  && {
    background: transparent;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }
  }
`;

const SharedUserRoleItem = ({
  user,
  className,
  roles,
  handleSpaceLeave,
}: SharedUserRoleItemProps) => {
  const { data } = useQuery(AUTH_STATE);
  const { authUser: currentUser }: AuthValue = data?.auth;
  const { data: manageWorkspacesData } = useQuery(MANAGE_WORKSPACES_STATE);
  const { workspaceToShare }: ManageWorkspacesValue = manageWorkspacesData?.manageWorkspaces;
  const { setUserToRemove } = manageWorkspacesMutation;
  const isCurrent = currentUser?.uid === user.externalIdentity;
  const pendingUser = user.isAcceptedInvite === false;

  return (
    <StyledWrapper className={className}>
      <StyledUserInfoWrapper isPending={pendingUser}>
        {user.photoURL ? (
          <StyledImage preview={false} src={user.photoURL || ''} alt="avatar" />
        ) : (
          <StyledDefaultImage>
            {user.name
              ? user.name?.substring(0, 1).toUpperCase()
              : user.email?.substring(0, 1).toUpperCase()}
          </StyledDefaultImage>
        )}
        <StyledUserName isCurrent={isCurrent}>{user.name || user.email}</StyledUserName>
        {isCurrent && <StyledCurrentLabel>・Me</StyledCurrentLabel>}
      </StyledUserInfoWrapper>
      <StyledRoleSelector
        isText={roles.length <= 1}
        role={user.permission!}
        roles={roles}
        onSelect={async (value) => {
          if (value.key === UserMenuRoles.LEAVE) {
            handleSpaceLeave();
          }
          if (value.key === UserMenuRoles.REMOVE) {
            setUserToRemove(workspaceToShare ? { ...user, workspace: workspaceToShare } : user);
          }
        }}
      />
    </StyledWrapper>
  );
};

export default SharedUserRoleItem;

