import { DomainChangeAction } from '__generated__/graphql';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { ChangeEmailOptions, ChangeEmailSelectOptions } from '../utils';

interface IEmailChangeOptionsProps {
  oldDomain?: string | null;
  newDomain?: string | null;
  onOptionUpdate: (action: DomainChangeAction) => void;
}

const [keepOption, resetOption, upgradeOption] = ChangeEmailSelectOptions;

const leaveCompanyOptions = [keepOption, resetOption];

const joinCompanyOptions = [keepOption, upgradeOption];

const EmailChangeSelect = ({ onOptionUpdate, newDomain, oldDomain }: IEmailChangeOptionsProps) => {
  const [emailOptionInput, setEmailOptionInput] = useState(keepOption);
  useEffect(() => {
    (oldDomain || newDomain) && onOptionUpdate(ChangeEmailOptions[emailOptionInput.value]);
  }, [emailOptionInput, oldDomain, newDomain, onOptionUpdate]);
  if (!newDomain && !oldDomain)
    return <p>This user is not leaving nor joining any Corporate domain</p>;
  if (newDomain === oldDomain) {
    return <p>This user is not changing Corporate domain</p>;
  }
  return (
    <>
      <p>
        It seems that this user is{' '}
        {oldDomain ? (
          <>
            leaving Corporate domain <b>{oldDomain}</b>{' '}
            {newDomain && (
              <>
                to join new Corporate domain <b>{newDomain}</b>
              </>
            )}
          </>
        ) : (
          <>
            joining Corporate domain <b>{newDomain}</b>
          </>
        )}
      </p>
      <Select
        labelInValue
        value={emailOptionInput}
        options={newDomain ? joinCompanyOptions : leaveCompanyOptions}
        onChange={setEmailOptionInput}
        style={{ width: '100%' }}
      />
    </>
  );
};

export default EmailChangeSelect;

