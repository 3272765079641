import React from 'react';
import { ActiveCampignFieldNames, UserActivities } from '__generated__/graphql';

// export enum UserActivities {
//   Work = 'WORK',
//   NonWork = 'NON_WORK',
//   Both = 'BOTH',
// }

export { UserActivities } from '__generated__/graphql';

export interface SelectedSpace {
  defaultSpaceName: string;
  spaceName: string;
}

export interface Activity {
  title: UserActivities;
  roles: SelectedSpace[];
}

// export enum OnboardingQuestions {
//   QUESTION_1 = 'WhereDoYouDoMostOfYourWork',
//   QUESTION_2 = 'WhenDoYouDoMostOfYourWork',
// }

export enum OnboardingQuestions {
  QUESTION_1 = ActiveCampignFieldNames.WhereDoYouDoMostOfYourWork,
  QUESTION_2 = ActiveCampignFieldNames.WhenDoYouDoMostOfYourWork,
}

export enum OnboardingAnswers {
  ANSWER_1 = 'I do most of my work physically at the workplace',
  ANSWER_2 = 'I work mostly “on the road” ',
  ANSWER_3 = 'I work mostly from home/shared workspaces',
  ANSWER_4 = 'Other',

  ANSWER_5 = 'I work within office hours',
  ANSWER_6 = 'I sometimes work outside of office hours',
  ANSWER_7 = 'Office hours are so last century, I work anytime!',
  ANSWER_8 = 'Other',
}

export const ONBOARDING_ANSWERS_BY_QUESTION = {
  QUESTION_1: [
    OnboardingAnswers.ANSWER_1,
    OnboardingAnswers.ANSWER_2,
    OnboardingAnswers.ANSWER_3,
    OnboardingAnswers.ANSWER_4,
  ],
  QUESTION_2: [
    OnboardingAnswers.ANSWER_5,
    OnboardingAnswers.ANSWER_6,
    OnboardingAnswers.ANSWER_7,
    OnboardingAnswers.ANSWER_8,
  ],
};

export interface Question {
  question: OnboardingQuestions;
  answer: string | undefined;
  answerIndex: number | undefined;
}

export interface ExpectListItemProps {
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

